import React, { useState, useEffect, useCallback, useRef } from 'react';
import Loader from 'qs-components/Common/Loader';
import { renderSelectedProductImage } from 'qs-data-manager/ProductDetails';

import './styles.scss';
import { ReactComponent as UploadIcon } from 'qs-assets/Media/plus.svg';
import { getActiveProductId, uploadPicturesToProduct } from 'qs-data-manager/Products';
import { convertImageUrlSize } from 'qs-helpers';

export default ({ loading, pictures }) => {
  const [productPictures, setProductPictures] = useState(pictures);
  const [selectedPicture, setSelectedPicture] = useState((pictures[0] || {}).url || '');
  const pickerRef = useRef(null);

  useEffect(() => {
    setProductPictures(pictures);
    setSelectedPicture((pictures[0] || {}).url || '');
  }, [pictures]);

  const loadThisImage = useCallback(url => {
    renderSelectedProductImage({ imageUrl: url, isDefaultImage: false });
    setSelectedPicture(url);
  }, []);

  const onUpload = useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
    const productId = getActiveProductId();
    uploadPicturesToProduct({ e, productId });
    pickerRef.current.value = null;
  }, []);

  return (
    <div id={'ProductPictures'}>
      <div className={'rowContainer'}>
        <div className={'rowHeading'}>More pictures</div>
        <div className={'picturesWrapper'}>
          {loading ? (
            <div className={'picturesLoadingContainer'}>
              <Loader size={'small'} />
            </div>
          ) : (
            <div className={'picturesContainer'}>
              <div>
                {productPictures.map((state, index) => {
                  const isSelected = selectedPicture === state.url;
                  const url = convertImageUrlSize({ size: '100', url: state.url });

                  return (
                    <div className={'pictureImageContainer'}>
                      <div className={'picturesLoader'}>
                        <Loader size={'small'} />
                      </div>
                      <div
                        key={index}
                        className={`pictures picturesAspectRatio ${
                          isSelected ? 'selectedPicture' : ''
                        }`}
                        style={{ backgroundImage: `url(${url})` }}
                        onClick={() => loadThisImage(state.url)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className={'addNewPictures pictures selectedPicture'}>
            <UploadIcon className={'uploadIcon'} />
            <input
              ref={pickerRef}
              type="file"
              name="ProductPictures"
              accept="image/*"
              multiple
              onChange={onUpload}
              className={'filePicker'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
