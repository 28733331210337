import React, { useState, useCallback } from 'react';
import NonVirtualisedList from 'qs-components/Common/NonVirtualisedList';
import TagRow from './TagRow';

import './styles.scss';
import { ReactComponent as TimesIcon } from 'qs-assets/Media/multiply.svg';

export default ({ sortedTags, onClosePopover, tagCountVisible, onToggleTag }) => {

  const renderItem = useCallback(
    ({ item: tag }) => {
      return (
        <TagRow
          tagCountVisible={tagCountVisible}
          tag={tag}
          onToggleTag={onToggleTag}
        />
      );
    },
    [tagCountVisible]
  );

  return (
    <div className="catalogueTagsPopoverContainer">
      <div className="catalogueTagsPopover">
        <NonVirtualisedList
          horizontal={false}
          data={sortedTags}
          renderItem={renderItem}
          keyExtractor={item => item.title}
          contentContainerClassName="popoverTagsList"
          ItemSeparatorComponent={() => <div className="popoverTagSeparator" />}
          ListHeaderComponent={() => (
            <div className="popoverTagHeader">
              <div className="popoverTagHeaderLeft">
                <div className="popoverTagHeaderTitle">Product tags</div>
                <div className="popoverTagHeaderSubtitle">Tap on a tag to filter</div>
              </div>

              <div className="popoverTagHeaderRight">
                <button className="closeButton" onClick={onClosePopover}>
                  <TimesIcon fill="#737F8E" className="closeButtonIcon" />
                </button>
              </div>
            </div>
          )}
          ListFooterComponent={() => <div className="popoverTagSeparator" />}
        />
      </div>
    </div>
  );
};
