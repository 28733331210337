import React, { useState, useEffect, useRef } from 'react';
import Switch from 'react-switch';
import toastr from 'toastr';
import {
  changeCompanyOutOfStockVisibility,
  changeCompanyAllowOrderOnOutOfStock,
  changeCompanyTrackInventory,
  changeCompanyAutoReduceInventory,
  getCompanySettingsFromCache,
  attachCompanySettingsListener,
  removeCompanySettingsListener,
  getCompanySettings
} from 'qs-data-manager/Company';
import { toggleGlobalLoader } from 'qs-helpers';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import SettingsDataRow from '../../Common/SettingsDataRow/SettingsDataRow';

export default () => {
  const autoReduceMapping = useRef({
    VISITOR: true,
    COMPANY: false
  });

  const [inventoryState, setInventoryState] = useState(getCompanySettingsFromCache() || {});

  useEffect(() => {
    const inventoryUpdateListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (err) {
        //TODO add the correct meesage
        toastr.error(err.message);
        return;
      }

      if (loading || refreshing) {
        return;
      }

      setInventoryState(data);
    };

    attachCompanySettingsListener(inventoryUpdateListener);
    getCompanySettings();
    return removeCompanySettingsListener(inventoryUpdateListener);
  });

  const handleSwitchChange = toggleFrom => {
    return (value, event) => {
      event.preventDefault();
      event.stopPropagation();
      onSwitchToggle({ value, toggleFrom });
    };
  };

  const onSwitchToggle = async ({ value, toggleFrom }) => {
    const loaderKey = `onSwitchToggle${Date.now()}`;
    toggleGlobalLoader(loaderKey, true);
    const updates = {};

    let promise = null;

    if (toggleFrom === 'stockVisibility') {
      updates.stockVisibility = value;
      promise = changeCompanyOutOfStockVisibility({ value });
    } else if (toggleFrom === 'orderOnOOS') {
      updates.orderOnOOS = value;
      promise = changeCompanyAllowOrderOnOutOfStock({ value });
    } else if (toggleFrom === 'trackInventory') {
      updates.trackInventory = value;
      promise = changeCompanyTrackInventory({ value });
    } else if (toggleFrom === 'autoReduce') {
      const inventoryValue = value ? 'VISITOR' : 'COMPANY';
      updates.autoReduce = inventoryValue;
      promise = changeCompanyAutoReduceInventory({ value: inventoryValue });
    } else {
      return;
    }

    if (promise) {
      setInventoryState({ ...inventoryState, ...updates });
      await promise;
    }

    toggleGlobalLoader(loaderKey, false);
  };

  const { stockVisibility, orderOnOOS, trackInventory, autoReduce } = inventoryState;

  return (
    <div className="inventory-settings-container">
      <SettingsDataRow
        onSectionClick={() =>
          onSwitchToggle({ value: !stockVisibility, toggleFrom: 'stockVisibility' })
        }
        icon={
          <Switch
            checked={stockVisibility}
            onChange={handleSwitchChange('stockVisibility')}
            onColor="#C0DACE"
            onHandleColor="#4DA47A"
            handleDiameter={25}
            uncheckedIcon={false}
            checkedIcon={false}
            height={17}
            width={40}
          />
        }
        title={'Out of stock visibility'}
      >
        <span>Show out of stock products to catalogue visitors</span>
      </SettingsDataRow>

      {!!stockVisibility && (
        <SettingsDataRow
          onSectionClick={() => onSwitchToggle({ value: !orderOnOOS, toggleFrom: 'orderOnOOS' })}
          icon={
            <Switch
              checked={orderOnOOS}
              onChange={handleSwitchChange('orderOnOOS')}
              onColor="#C0DACE"
              onHandleColor="#4DA47A"
              handleDiameter={25}
              uncheckedIcon={false}
              checkedIcon={false}
              height={17}
              width={40}
            />
          }
          title={'Allow orders on out of stock products'}
        >
          <span>Allow customers to place an order on out of stock products</span>
        </SettingsDataRow>
      )}

      <SettingsDataRow
        onSectionClick={() =>
          onSwitchToggle({ value: !trackInventory, toggleFrom: 'trackInventory' })
        }
        icon={
          <Switch
            checked={trackInventory}
            onChange={handleSwitchChange('trackInventory')}
            onColor="#C0DACE"
            onHandleColor="#4DA47A"
            handleDiameter={25}
            uncheckedIcon={false}
            checkedIcon={false}
            height={17}
            width={40}
          />
        }
        title={'Track inventory'}
      >
        <span>Turn on inventory tracking settings by default on new products</span>
      </SettingsDataRow>

      {!!trackInventory && (
        <SettingsDataRow
          onSectionClick={() =>
            onSwitchToggle({
              value: !autoReduceMapping.current[autoReduce],
              toggleFrom: 'autoReduce'
            })
          }
          icon={
            <Switch
              checked={autoReduceMapping.current[autoReduce]}
              onChange={handleSwitchChange('autoReduce')}
              onColor="#C0DACE"
              onHandleColor="#4DA47A"
              handleDiameter={25}
              uncheckedIcon={false}
              checkedIcon={false}
              height={17}
              width={40}
            />
          }
          title={'Auto reduce inventory on visitor confirmation'}
        >
          <span>Turn on this setting by default for new products</span>
        </SettingsDataRow>
      )}
    </div>
  );
};
