import { updateRemoteWithDeviceId } from 'qs-helpers';
const { remote, ipcRenderer } = window.require('electron');
const nodeOs = remote.require('os');

let cachedUniqueDeviceId = null, waitUntilDeviceIdCreated = null;

export const getUniqueDeviceId = async () => {
  if(waitUntilDeviceIdCreated) {
    return await getMachineIdFromPromise(waitUntilDeviceIdCreated)
  }

  return cachedUniqueDeviceId;
};

const getMachineIdFromPromise = async (machineIdPromise) => {
  const {uniqueDeviceId} = await machineIdPromise
  return uniqueDeviceId;
}

const getCachedUniqueDeviceId = () => {
  if (cachedUniqueDeviceId !== null) {
    return cachedUniqueDeviceId;
  }

  return localStorage.getItem('UNIQUE_DEVICE_ID');
}

const saveIdToNativeStorage = uniqueDeviceId => {
  localStorage.setItem('UNIQUE_DEVICE_ID', uniqueDeviceId);
};

const getMachineId = () => {
  return new Promise(resolve => {
    ipcRenderer.once('machine-id', (event, args) => {
      resolve({
        uniqueDeviceId: args.machineId
      });
    });
    ipcRenderer.send('generate-machine-id');
  });
};

export const generateUniqueDeviceIdIfNotExists = async ({ companyId }) => {
  const uniqueId = getCachedUniqueDeviceId();
  if (uniqueId) {
    cachedUniqueDeviceId = uniqueId;
    return;
  }

  /*
    Cache the promise. This will be helpful if the main process is
    taking too long to respond with the machine Id. Any request to
    fetch the unique Id will wait on the result of this promise
    and only then proceed.
  */
  waitUntilDeviceIdCreated = getMachineId()
  const uniqueDeviceId = await getMachineIdFromPromise(waitUntilDeviceIdCreated);
  saveIdToNativeStorage(uniqueDeviceId);
  updateRemoteWithDeviceId({
    companyId,
    uniqueId: uniqueDeviceId,
    osType: nodeOs.platform(),
    device: 'electron'
  });

  cachedUniqueDeviceId = uniqueDeviceId;
};
