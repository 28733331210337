import React from 'react';
import SettingsRow from '../SettingsRow/SettingsRow';
import ClickHandlerWithLoaderContainer from '../../../Common/ClickHandlerWithLoader/ClickHandlerWithLoaderContainer';
import './LinkSettingsRow.scss';
import Mixpanel from 'qs-data-manager/Mixpanel';

export default ({rowData: { linkUrl, imageSrc, title, description, componentId, componentEvent }, classForContainer}) => {
  let descriptionComponent;
  switch (componentId) {
    case 'QUICKSELL_PARTNER':
      descriptionComponent = (
        <span className="settings-partner">
          Sell QuickSell in your local region and earn 35% commission
        </span>
      );
      break;
    default:
      descriptionComponent = <span>{description}</span>;
      break;
  }

  const handleLinkClick = function(currentClickedLink) {
    switch(currentClickedLink) {
      case 'NEW_UPDATES':
      case 'KNOWLEDGE_CENTER':
      case 'HELP_TRANSLATE':
        Mixpanel.sendEvent({
          eventName: componentEvent.eventName,
          props: componentEvent.props
        });
        break;
      default:
        break
    }
  }

  return (
    <ClickHandlerWithLoaderContainer
      linkProps={{ linkUrl }}
      clickProps={{
        clickHandler: handleLinkClick,
        clickParams: componentId
      }}
      type="link"
      classForContainer={classForContainer}
    >
      <SettingsRow imageSrc={imageSrc} title={title}>
        {descriptionComponent}
      </SettingsRow>
    </ClickHandlerWithLoaderContainer>
  );
};
