import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Row from './Row';
import Loader from 'qs-components/Common/Loader';
import Modal from 'react-responsive-modal';
import CatalogueLib, { getActiveCatalogueId } from 'qs-data-manager/Catalogues';
import CacheCallback from 'qs-helpers/CacheListenerCallback';
import { getElementParents, toggleGlobalLoader } from 'qs-helpers/index';
import Api from 'qs-services/Api';

import './styles.css';

class CatalogueSettings extends Component {
  static propTypes = {
    closeCatalogueSettings: PropTypes.func
  };

  static defaultProps = {
    closeCatalogueSettings: () => {}
  };

  constructor() {
    super();

    this.catalogueId = getActiveCatalogueId() || null;
    const link = CatalogueLib.getCatalogueLinkFromCache(this.catalogueId);
    let catalogueLink = '';
    const fetchingLink = typeof link !== 'string';

    if (typeof link !== 'string') {
      link.then(resp => {
        const { link } = resp;
        this.setState({
          catalogueLink: link,
          fetchingLink: false
        });
      });
    } else {
      catalogueLink = link;
    }

    const settingsCache = CatalogueLib.getCatalogueSettingsFromCache(this.catalogueId);
    const updates = this.getCatalogueSettings(settingsCache);

    this.state = {
      fetchingLink,
      catalogueLink,
      generatingLink: false,
      showResetModal: false,
      modalToggledFrom: '',
      ...updates,
      companySettings: settingsCache.companySettings
    };
  }

  componentDidMount() {
    const { catalogueLink, generatingLink, visitorsEnabled, companySettings } = this.state;

    CatalogueLib.attachCatalogueSettingsListener({
      catalogueId: this.catalogueId,
      listener: this.catalogueSettingsListener
    });
    document.querySelector('body').addEventListener('click', this.handleBodyClick);

    if (catalogueLink && !generatingLink) {
      CatalogueLib.getCatalogueSettings({ catalogueId: this.catalogueId });
    }

    if (catalogueLink && !generatingLink && !Object.keys(companySettings || {}).length) {
      CatalogueLib.getCompanySettings().then(resp => {
        this.setState({
          companySettings: resp.settings ? resp.settings : {}
        });
      });
    }
  }

  componentWillUnmount() {
    CatalogueLib.removeCatalogueSettingsListener({
      catalogueId: this.catalogueId,
      listener: this.catalogueSettingsListener
    });
    document.querySelector('body').removeEventListener('click', this.handleBodyClick);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { catalogueLink, generatingLink, visitorsEnabled, companySettings } = this.state;

    if (catalogueLink && !generatingLink && typeof visitorsEnabled !== 'boolean') {
      CatalogueLib.getCatalogueSettings({ catalogueId: this.catalogueId });
    }

    if (catalogueLink && !generatingLink && !Object.keys(companySettings || {}).length) {
      CatalogueLib.getCompanySettings().then(resp => {
        this.setState({
          companySettings: resp.settings ? resp.settings : {}
        });
      });
    }
  }

  getCatalogueSettings = data => {
    const updates = {};

    if (!data || !data.experiments) {
      return updates;
    }

    updates.visitorsEnabled = data.experiments.visitorsEnabled;
    updates.requestIdentity = data.experiments.visitorIdentity;
    updates.verifyOTP = data.experiments.otpVerification;
    updates.catalogueStockVisibility = data.experiments.stockVisibility;
    updates.orderOnOOS = data.experiments.orderOnOutOfStock;
    updates.stockManagedFrom =
      typeof updates.catalogueStockVisibility === 'boolean' ? 'catalogue' : 'company';
    updates.oosControlledFrom = typeof updates.orderOnOOS === 'boolean' ? 'catalogue' : 'company';

    return updates;
  };

  catalogueSettingsListener = (error, payload) => {
    const { err, loading, data, refreshing } = CacheCallback(error, payload);
    if (err) {
      console.log('catalogueSettingsListener: Error while generating link');
      return;
    }

    if (loading) {
      return;
    }

    const updates = this.getCatalogueSettings(data);

    this.setState(prevState => ({
      ...prevState,
      ...updates
    }));
  };

  onSettingChanged = async (status, field) => {
    const loaderKey = Date.now();

    this.setState({ [field]: status });
    let shouldMakeReq = true;
    toggleGlobalLoader(loaderKey, true);
    const changes = {};

    switch (field) {
      case 'visitorsEnabled':
        changes.visitorsEnabled = status;
        break;
      case 'requestIdentity':
        changes.visitorIdentity = status;
        break;
      case 'verifyOTP':
        changes.otpVerification = status;
        break;
      case 'catalogueStockVisibility':
        shouldMakeReq = false;

        if (this.state.stockManagedFrom === 'company') {
          this.setState({
            stockManagedFrom: 'catalogue'
          });
        }
        await CatalogueLib.changeOutOfStockVisibility({
          catalogueId: this.catalogueId,
          outOfStockVisibility: status
        });
        break;
      case 'orderOnOOS':
        shouldMakeReq = false;
        if (this.state.oosControlledFrom === 'company') {
          this.setState({
            oosControlledFrom: 'catalogue'
          });
        }
        await CatalogueLib.changeOrderOnOutOfStock({
          catalogueId: this.catalogueId,
          allowOrderOnOutOfStock: status
        });
        break;
      default:
        toggleGlobalLoader(loaderKey, false);
        break;
    }

    if (shouldMakeReq) {
      await CatalogueLib.changeCatalogueSettings({ catalogueId: this.catalogueId, changes });
    }
    toggleGlobalLoader(loaderKey, false);
  };

  handleBodyClick = event => {
    const { showResetModal } = this.state;
    if (!showResetModal) {
      event.preventDefault();

      const parents = getElementParents(event.target);
      const parentsClass = parents.map(parent => parent.className);

      if (
        !parentsClass.includes('CatalogueSettingsContainer') &&
        !parentsClass.includes('iconContainer settingsIconContainer') &&
        !parentsClass.includes('productListHeader')
      ) {
        this.props.closeCatalogueSettings(false);
      }
    }
  };

  toggleResetToCompanyDefault = ({ toggleValue, text }) => {
    this.setState({ showResetModal: toggleValue, modalToggledFrom: text });
  };

  resetToCompanySettings = async () => {
    const { modalToggledFrom } = this.state;
    const loaderKey = Date.now();
    toggleGlobalLoader(loaderKey, true);

    if (modalToggledFrom === 'catalogueStockVisibility') {
      this.toggleResetToCompanyDefault({ toggleValue: false, modalToggledFrom: '' });

      await CatalogueLib.changeOutOfStockVisibility({
        catalogueId: this.catalogueId,
        outOfStockVisibility: null
      });

      const { settings } = await Api.getCompanySettings();
      const value =
        settings && typeof settings.showOutOfStockProduct === 'boolean'
          ? settings.showOutOfStockProduct
          : false;

      this.setState({
        catalogueStockVisibility: value,
        stockManagedFrom: 'company'
      });
      toggleGlobalLoader(loaderKey, false);
    } else if (modalToggledFrom === 'orderOnOOS') {
      this.toggleResetToCompanyDefault({ toggleValue: false, modalToggledFrom: '' });

      await CatalogueLib.changeOrderOnOutOfStock({
        catalogueId: this.catalogueId,
        allowOrderOnOutOfStock: null
      });

      const { settings } = await Api.getCompanySettings();

      const value =
        settings && typeof settings.allowOrdersOnOutOfStock === 'boolean'
          ? settings.allowOrdersOnOutOfStock
          : false;

      this.setState({
        ...this.state,
        catalogueStockVisibility: value,
        stockManagedFrom: 'company'
      });
      toggleGlobalLoader(loaderKey, false);
    }
  };

  createLink = async () => {
    this.setState({
      generatingLink: true
    });

    const resp = await CatalogueLib.createCataloguesLink([this.catalogueId]);
    const { link } = resp;
    if (link) {
      CatalogueLib.getCatalogueSettings({ catalogueId: this.catalogueId });

      CatalogueLib.getCompanySettings().then(resp => {
        this.setState({
          companySettings: resp.settings ? resp.settings : {}
        });
      });

      this.setState({
        generatingLink: false,
        catalogueLink: link
      });
    } else {
      console.log(`createLink: Could not create link: ${link}`);
      this.setState({
        generatingLink: false
      });
    }
  };

  renderResetModal = () => (
    <Modal
      open={this.state.showResetModal}
      onClose={() => {
        this.toggleResetToCompanyDefault(false);
      }}
      center
      styles={{ modal: { backgroundColor: 'white', borderRadius: 10 } }}
      showCloseIcon={false}
    >
      <div className="resetInventoryModalContainer">
        <div className="header">Reset Setting</div>
        <div className="title">Are you sure you want to reset this setting</div>
        <div className="buttonCotnainer">
          <div onClick={() => this.toggleResetToCompanyDefault(false)} className="cancelButton">
            Cancel
          </div>
          <div onClick={this.resetToCompanySettings} className="acceptButton">
            Yes
          </div>
        </div>
      </div>
    </Modal>
  );

  render() {
    const {
      requestIdentity,
      verifyOTP,
      visitorsEnabled,
      catalogueStockVisibility,
      stockManagedFrom,
      showResetModal,
      oosControlledFrom,
      orderOnOOS,
      companySettings,
      catalogueLink,
      generatingLink,
      fetchingLink
    } = this.state;

    return (
      <div className="CatalogueSettingsContainer">
        <div className="visibilityStyle">
          {!!fetchingLink && (
            <div className={'loaderContainer'}>
              <Loader size={'large'} />
            </div>
          )}
          {!catalogueLink ? (
            <div className="CatalogueSettingsCreateLink">
              {generatingLink ? (
                <div>
                  <div className="genratingLinkLoaderStyle">
                    <Loader />
                  </div>
                  <p>Creating new link...</p>
                </div>
              ) : (
                <div>
                  <button className="CatalogueCreateLink" onClick={this.createLink}>
                    Create catalogue link
                  </button>
                  <p>Create a link to share this catalogue</p>
                </div>
              )}
            </div>
          ) : (
            <div className={'CatalogueSettingLinkWrapper'}>
              <div className="CatalogueSettingLinkContainer">
                <label className="CatalogueLinkLabel">Catalogue Link</label>
                <div className="CatalogueLink">{catalogueLink}</div>
              </div>
              <Row
                value={visitorsEnabled}
                text="visitorsEnabled"
                settingTitle="Enable catalogue visitors"
                settingDescription="Allow visitors to view catalogue link"
                onSettingChanged={this.onSettingChanged}
                shouldShowLoader={typeof visitorsEnabled !== 'boolean'}
              />
              <Row
                value={requestIdentity}
                text="requestIdentity"
                settingTitle="Request visitor identity"
                settingDescription="Require catalogue visitors to enter name and phone number after viewing catalogue for 15 seconds"
                onSettingChanged={this.onSettingChanged}
                shouldShowLoader={typeof requestIdentity !== 'boolean'}
              />
              <Row
                value={verifyOTP}
                text="verifyOTP"
                settingTitle="Enable OTP verification"
                settingDescription="Require visitors to verify their phone number via OTP SMS"
                onSettingChanged={this.onSettingChanged}
                shouldShowLoader={typeof verifyOTP !== 'boolean'}
              />
              <Row
                value={
                  stockManagedFrom === 'catalogue'
                    ? catalogueStockVisibility
                    : !!companySettings.showOutOfStockProduct
                }
                text="catalogueStockVisibility"
                settingTitle="Out of stock visibility"
                settingDescription="Show out of stock products to catalogue visitors"
                onSettingChanged={this.onSettingChanged}
                stockManagedFrom={stockManagedFrom}
                resetToCompanyDefault={this.toggleResetToCompanyDefault}
                shouldShowLoader={
                  typeof catalogueStockVisibility === 'undefined' ||
                  typeof companySettings.showOutOfStockProduct === 'undefined'
                }
              />
              <Row
                value={
                  stockManagedFrom === 'catalogue'
                    ? orderOnOOS
                    : !!companySettings.allowOrdersOnOutOfStock
                }
                text="orderOnOOS"
                settingTitle="Allow order on out of stock product"
                settingDescription="Allow customers to place an order on out of stock items"
                onSettingChanged={this.onSettingChanged}
                stockManagedFrom={oosControlledFrom}
                resetToCompanyDefault={this.toggleResetToCompanyDefault}
                shouldShowLoader={
                  typeof orderOnOOS === 'undefined' ||
                  typeof companySettings.allowOrdersOnOutOfStock === 'undefined'
                }
              />

              {showResetModal && this.renderResetModal()}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CatalogueSettings;
