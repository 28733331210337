import React, { useState, useRef } from 'react';
import Modal from 'react-responsive-modal';
import './UserNameEdit.scss';
import { updateUserName } from 'qs-data-manager/User';

export default ({ onClose, defaultName }) => {
  const modalStyles = useRef({
    backgroundColor: 'white',
    borderRadius: 10,
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    width: '400px'
  });

  const [name, setName] = useState(defaultName);
  const handleInputchange = function(event) {
    setName(event.target.value);
  };

  const handleNameSave = function(event) {
    event.preventDefault();
    updateUserName(name);
    onClose();
  };

  return (
    <Modal
      open={true}
      center={true}
      onClose={onClose}
      showCloseIcon={false}
      styles={{ modal: modalStyles.current }}
    >
      <h3 className="edit-name title">Change name</h3>
      <form>
        <div className="edit-name input-container">
          <input className="input-box" type="text" value={name} onChange={handleInputchange} />
        </div>
        <div className="edit-name button-container">
          <button type="submit" className="button-plain" onClick={handleNameSave}>
            {' '}
            SAVE{' '}
          </button>
        </div>
      </form>
    </Modal>
  );
};
