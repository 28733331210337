import React, { Component } from 'react';
import { NAVIGATION } from 'qs-helpers/index';
import './styles.css';
import { ReactComponent as CatalogueIcon } from 'qs-assets/Media/folder.svg';
import { ReactComponent as InquiryIcon } from 'qs-assets/Media/inquiryIcon.svg';
import { ReactComponent as SettingsIcon } from 'qs-assets/Media/settingsOutlineIcon.svg';
import { navigateToPath } from 'qs-data-manager';
import eventbus from 'eventing-bus';
import OrderLib from 'qs-data-manager/Order';

const { screens, animationDuration } = NAVIGATION;

class Drawer extends Component {
  constructor(props) {
    super(props);

    const url = window.location.href;
    let currentActiveScreen = screens.catalogueScreen;

    if (url.includes('catalogues')) {
      currentActiveScreen = screens.catalogueScreen;
    } else if (url.includes('inquiries')) {
      currentActiveScreen = screens.inquiriesScreen;
    } else if (url.includes('account-settings')) {
      currentActiveScreen = screens.accountSettingsScreen;
    }

    this.state = {
      currentActiveScreen,
      unreadInquries: OrderLib.UNREAD_INQUIRIES_COUNT.unreadInquiries
    };
  }

  componentDidMount() {
    this.removeEventbus = eventbus.on(OrderLib.UNREAD_INQUIRIES_COUNT.EVENT_BUS_KEY, data => {
      const { unreadInquiryCount } = data;
      this.setState({ unreadInquries: unreadInquiryCount });
    });

    const elements = document.getElementsByClassName('iconContainer');

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];

      element.addEventListener('mouseenter', event => {
        this.onDrawerMouseEnter(event, document);
      });

      const parentNode = element.parentElement.parentElement;

      parentNode.addEventListener('mouseleave', event => {
        this.onDrawerLeave(event, document);
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentActiveScreen } = nextProps;
    const updates = {};

    if (currentActiveScreen !== this.props.currentActiveScreen) {
      updates.currentActiveScreen = currentActiveScreen;
    }
    this.setState(updates);
  }

  componentWillUnmount() {
    if (this.removeEventbus) {
      this.removeEventbus();
    }
  }

  onDrawerMouseEnter = (event, document) => {
    const parentNode = document.getElementsByClassName('drawerContainer');
    const elements = document.getElementsByClassName('hideOptionNames');

    for (let i = 0; i < parentNode.length; i++) {
      const element = parentNode[i];
      element.style.WebkitTransitionDuration = animationDuration.TRANSITION_DURATION;
      element.style.transitionDuration = animationDuration.TRANSITION_DURATION;
      element.style.width = `${NAVIGATION.openDrawerWidth}px`;
    }

    for (let j = 0; j < elements.length; j++) {
      elements[j].classList.add('fade-in');
    }
  };

  onDrawerLeave = (event, document) => {
    const parentNode = document.getElementsByClassName('drawerContainer');
    const elements = document.getElementsByClassName('hideOptionNames');

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove('fade-in');
    }

    for (let i = 0; i < parentNode.length; i++) {
      const element = parentNode[i];
      element.style.WebkitTransitionDuration = animationDuration.TRANSITION_DURATION;
      element.style.transitionDuration = animationDuration.TRANSITION_DURATION;
      element.style.width = `${NAVIGATION.closedDrawerWidth}px`;
    }
  };

  navigate = screenName => {
    this.props.navigation.history.push(screenName);
  };

  navigateToCatalogues = () => {
    this.setState({ currentActiveScreen: screens.catalogueScreen });
    navigateToPath('/catalogues');
  };

  navigateToInquiries = () => {
    this.setState({ currentActiveScreen: screens.inquiriesScreen });
    navigateToPath('/inquiries');
  };

  navigateToAccountSettings = () => {
    this.setState({ currentActiveScreen: screens.accountSettingsScreen });
    navigateToPath('/account-settings');
  };

  render() {
    const { currentActiveScreen, unreadInquries } = this.state;

    const catalogueIconStyles =
      currentActiveScreen === screens.catalogueScreen ? '#8DF0C1' : 'white';

    const inquiryIconStyles = currentActiveScreen === screens.inquiriesScreen ? '#8DF0C1' : 'white';

    const accountSettingsStyles =
      currentActiveScreen === screens.accountSettingsScreen ? '#8DF0C1' : 'white';

    return (
      <div className="drawerContainer">
        <button
          onClick={this.navigateToCatalogues}
          className="sideNavRowContainer catalogueContainer"
        >
          <div className="iconContainer">
            <CatalogueIcon className="drawerIcon" style={{ fill: catalogueIconStyles }} />
          </div>
          <div className="optionName hideOptionNames catalogueOptions">Catalogues</div>
        </button>

        <button onClick={this.navigateToInquiries} className="sideNavRowContainer inquiryContainer">
          <div className="iconContainer inquiryIcon">
            <InquiryIcon className="drawerIcon" style={{ fill: inquiryIconStyles }} />
            {!!Number(unreadInquries) && <div className="unreadInquiryBadge">{unreadInquries}</div>}
          </div>
          <div className="optionName hideOptionNames inquiryOption">Inquiries</div>
        </button>

        <button onClick={this.navigateToAccountSettings} className="sideNavRowContainer">
          <div className="iconContainer">
            <SettingsIcon className="drawerIcon" style={{ fill: accountSettingsStyles }} />
          </div>
          <div className="optionName hideOptionNames accountSettingsOption">Account settings</div>
        </button>
      </div>
    );
  }
}

export default Drawer;
