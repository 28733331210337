export default {
  qsApiEndPoint: 'https://api.quicksell.co',
  qsStagingApiEndPoint: 'https://api-staging.quicksell.co',
  localEndPoint: 'http://localhost:4000',
  loginEndpoint: 'https://9alt1k2v0i.execute-api.us-east-1.amazonaws.com/prod/authenticate-user',
  searchEndPoint: 'https://search.quicksell.co',
  s3TempCredentials: 'https://us-central1-sx-app-a04c1.cloudfunctions.net/getTemporaryCredentials2',
  imageUrlSource: {
    CLOUD_FRONT: 'https://do9uy4stciz2v.cloudfront.net',
    S3: 'https://s3.amazonaws.com/quicksales',
    sources: ['https://do9uy4stciz2v.cloudfront.net', 'https://s3.amazonaws.com/quicksales']
  },
  imageSizes: [100, 200, 400, 'FULL'], // Replace full with a variable and use that variable everywhere
  mixpanelToken:
    process.env.NODE_ENV === 'development'
      ? 'aed3d7d391d5fcefe6034b05e63c4827'
      : '4780b1d26e3d6ff2a6e0936133a6c145',
  sentry: {
    beforeSend(event) {
      if (process.env.NODE_ENV === 'development') {
        return null;
      }

      return event;
    },
    dsn: 'https://a186e5c1f9d6419ba1622cbd6aa10666@sentry.io/1246047',
    dev: process.env.NODE_ENV === 'development'
  },
  isRunningInDesktopEnv: () => window.runningInDesktopEnv
};
