import React, { Component } from 'react';

import CheckBox from 'qs-components/Common/CheckBox';
import PropTypes from 'prop-types';

import './OrderContactHeader.scss';
import { ReactComponent as DeleteIcon } from 'qs-assets/Media/delete-icon.svg';
import { ReactComponent as DownloadIcon } from 'qs-assets/Media/download.svg';

class OrderContactHeader extends Component {
  static propTypes = {
    onDownloadContactOrder: PropTypes.func,
    onDeleteContactOrder: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      areAllSelected: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { allIndividuallySelected } = nextProps;

    const updates = {};

    if (
      allIndividuallySelected !== this.props.allIndividuallySelected &&
      typeof allIndividuallySelected === 'boolean'
    ) {
      updates.areAllSelected = allIndividuallySelected;
    }

    if (Object.keys(updates)) {
      this.setState(updates);
    }
  }

  onSelectAll = () => {
    const { onSelectAllClick } = this.props;

    this.setState(prevState => {
      const currentState = !prevState.areAllSelected;
      onSelectAllClick(currentState);
      return { areAllSelected: currentState };
    });
  };

  onSelectAllTextClick = () => {
    this.onSelectAll();
  };

  render() {
    const { areAllSelected } = this.state;
    const { onDownloadContactOrder, onDeleteContactOrder } = this.props;

    return (
      <div className="OrderHeaderContainer">
        <div className="leftContainer">
          <div className="selectAllContainer">
            <CheckBox checked={areAllSelected} onCheckChanged={this.onSelectAll} />
            <div onClick={this.onSelectAllTextClick} className="selectAll">
              {areAllSelected ? 'Deselect all' : 'Select all'}
            </div>
          </div>
        </div>
        <div className="rightContainer">
          <div onClick={onDownloadContactOrder} className="downloadIconContainer">
            <DownloadIcon className="downloadIcon" />
          </div>
          <div onClick={onDeleteContactOrder} className="deleteIconContainer">
            <DeleteIcon className="deleteIcon" />
          </div>
        </div>
      </div>
    );
  }
}

export default OrderContactHeader;
