import React from 'react';
import './styles.scss'

export default () => {
  return (
    <div id={'EmptyCatalogueList'}>
      No catalogues available to display. Create a new catalogue and upload products in it.
    </div>
  );
}
