import worker from 'workerize-loader!../slave.worker.js'; // eslint-disable-line import/no-webpack-loader-syntax
import workerCommonConfig from '../config';
import imageUploadProcessor from '../../ImageUpload/ImageUploadProcessor';

let instance = worker();

export const uploadImagesUsingWorker = imagesUploadPayload => {
  instance.uploadImages(imagesUploadPayload);
};

instance.addEventListener('message', ({ data = {} } = {}) => {
  switch (data.type) {
    case workerCommonConfig.UPLOAD_IMAGES.type:
      imageUploadProcessor.handleProcessedImages({
        isPrepared: !data.payload.err,
        error: !!data.payload.err,
        file: data.payload.file, // In case of browser
        path: data.payload.path, // In case of native
        pictureId: data.payload.pictureId,
        productId: data.payload.productId,
        retryCount: data.payload.retryCount,
        catalogueId: data.payload.catalogueId, // this should have gone in extraData but is already being in other places
        extraData: data.payload.extraData || {}
      });
      break;
    default:
      break;
  }
});
