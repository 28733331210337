import React, { Component } from 'react';
import Order from 'qs-data-manager/Order';
import Loader from 'qs-components/Common/Loader';
import isEqual from 'lodash.isequal';
import Modal from 'react-responsive-modal';
import cross from 'qs-assets/Media/close_red.png';
import CheckBox from 'qs-components/Common/CheckBox';
import MultiSelectInquiry from 'qs-helpers/MultiSelectInquiry';
import './SingleInquiryRow.scss';
import { getCompanyCurrencySymbol } from 'qs-data-manager/Company';
import { getRenderableSize } from 'qs-helpers';
import * as Sentry from '@sentry/browser';
import Mixpanel from 'qs-data-manager/Mixpanel';
import SetPrice from '../SetPrice/SetPrice';

const SCREEN_NAME = 'INQURIES_SCREEN';

const modalStyles = {
  backgroundColor: 'transparent',
  borderRadius: 10,
  padding: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const priceModalStyles = {
  backgroundColor: 'white',
  borderRadius: 10,
  padding: '2rem',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column'
};

const overlayStyles = { padding: '0px' };

class SingleInquiryRow extends Component {
  constructor(props) {
    super(props);

    const currencySymbol = getCompanyCurrencySymbol();

    this.state = {
      isImageVisible: false,
      imageWidth: 0,
      imageHeight: 0,
      imageUrl: null,
      isCheckboxSelected: false,
      isEditing: false,
      currencySymbol,
      showPriceModal: false,
      priceUpdateInProg: false
    };
  }

  componentDidMount() {
    const { inquiryId } = this.props.inquiry;
    const { attachEditingListener, attachRowSelectAllListener } = MultiSelectInquiry;
    this.populateSize();
    attachEditingListener(inquiryId, this.editingListener);
    attachRowSelectAllListener(inquiryId, this.onSelectAllClicked);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { inquiryId } = nextProps.inquiry;
    const { attachEditingListener, attachRowSelectAllListener } = MultiSelectInquiry;

    let updates = {};

    if (inquiryId !== this.props.inquiry.inquiryId) {
      updates.isCheckboxSelected = false;
      attachEditingListener(inquiryId, this.editingListener);
      attachRowSelectAllListener(inquiryId, this.onSelectAllClicked);
    }

    if (Object.keys(updates)) {
      this.setState(updates);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextState, this.state) || !isEqual(nextProps, this.props);
  }

  editingListener = isEditing => {
    if (this.state.isEditing !== isEditing) {
      this.setState({ isEditing });
    }
  };

  onSelectAllClicked = areAllSelected => {
    if (this.state.isCheckboxSelected !== areAllSelected) {
      this.setState({ isCheckboxSelected: areAllSelected });
    }
  };

  getLargeImageSourcesFromUrl = url => {
    if (!url) {
      return [];
    }

    const cloudfrontUrl = `https://do9uy4stciz2v.cloudfront.net${url.split('quicksales')[1]}`;

    return [cloudfrontUrl, url];
  };

  getSize = uri =>
    new Promise(resolve => {
      const img = document.createElement('IMG');
      img.onload = () => {
        resolve({ height: img.naturalHeight, width: img.naturalWidth });
      };
      img.onerror = () => {
        resolve(null);
      };

      img.setAttribute('src', uri);
    });

  populateSize = async () => {
    const { pictureUrl } = this.props.inquiry.product;
    const sources = this.getLargeImageSourcesFromUrl(pictureUrl);

    for (let i = 0; i < sources.length; i += 1) {
      const imageSize = await this.getSize(sources[i]);
      if (imageSize) {
        this.setState({
          imageWidth: imageSize.width,
          imageHeight: imageSize.height,
          imageUrl: sources[i],
          showLargeImageLoader: false
        });

        return;
      }
    }

    this.setState({
      loading: false,
      failed: true
    });
  };

  toggleStock = async () => {
    if (this.togglingStock) {
      return;
    }

    this.togglingStock = true;

    const { inquiryId, product, stockCount: isStockAvailable } = this.props.inquiry;
    const { stockToggled, orderId } = this.props;

    const stockCount = isStockAvailable ? 0 : 1;

    Mixpanel.sendEvent({
      eventName: 'orders_set_product_stock',
      props: {
        orderId,
        inquiryId: inquiryId,
        productId: product.id,
        stockCount,
        SCREEN_NAME
      }
    });

    try {
      await Order.setStockCount({
        orderId,
        inquiryId: inquiryId,
        productId: product.id,
        stockCount
      });

      stockToggled();
    } catch (ex) {
      console.log('ex', ex);
      stockToggled(ex);
      Sentry.captureException(ex);
    }

    this.togglingStock = false;
  };

  onCloseModal = () => {
    this.setState({ isImageVisible: false });
  };

  toggleModal = () => {
    this.setState({showPriceModal: !this.state.showPriceModal})
  }

  async makeApiCallToUpdatePrice(price, discountedPrice) {
    const {
      inquiry: {inquiryId},
      orderId,
      contact,
      catalogueId
    } = this.props

    await Order.updateInquiriesPrice({
      price,
      discountedPrice,
      inquiryId,
      catalogueId,
      contact,
      orderId
    })

    this.setState({priceUpdateInProg: false})
  }

  updateDataForPrice = (price, discountedPrice) => {
    this.setState({showPriceModal: false, priceUpdateInProg: true})
    this.makeApiCallToUpdatePrice(price, discountedPrice)
  }

  renderModal = () => {
    const { isImageVisible, imageUrl, imageHeight, imageWidth, showLargeImageLoader } = this.state;

    const { width, height } = getRenderableSize({ imageWidth, imageHeight });

    if (isImageVisible) {
      return (
        <Modal
          open={isImageVisible}
          center={true}
          onClose={this.onCloseModal}
          styles={{ modal: modalStyles, overlay: overlayStyles }}
          showCloseIcon={false}
        >
          {showLargeImageLoader ? (
            <div
              style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}
            >
              <Loader size="large" />
            </div>
          ) : (
            <div className="modalContainer">
              <img src={imageUrl} style={{ width: width, height: height }} alt="" />
            </div>
          )}
        </Modal>
      );
    }

    return <div />;
  };

  renderPriceModal() {
    let priceModalComponent = null;
    const { showPriceModal } = this.state;
    const {
      product: { price, discounted_price }
    } = this.props.inquiry;

    if (showPriceModal) {
      priceModalComponent = (
        <Modal
          open={true}
          center={true}
          onClose={this.toggleModal}
          styles={{ modal: priceModalStyles, overlay: overlayStyles }}
          showCloseIcon={false}
        >
          <SetPrice
            onCancel={this.toggleModal}
            onComplete={this.updateDataForPrice}
            price={price}
            discountedPrice={discounted_price}
          />
        </Modal>
      );
    }

    return priceModalComponent
  }

  showBigImage = () => {
    this.setState({ isImageVisible: true });
  };

  renderImage = pictureUrl => {
    const img = document.createElement('IMG');
    img.setAttribute('src', pictureUrl);
    const dimensions = {
      imageHeight: img.naturalHeight,
      imageWidth: img.naturalWidth
    };

    const { height, width } = getRenderableSize(dimensions);

    return <img src={pictureUrl} style={{ width: width, height: height }} alt="" />;
  };

  onCheckboxClick = () => {
    const {
      inquiry: { inquiryId }
    } = this.props;

    this.setState(prevState => {
      const currentState = !prevState.isCheckboxSelected;
      MultiSelectInquiry.onInquirySelect(inquiryId, currentState);
      return { isCheckboxSelected: currentState };
    });
  };

  renderPriceText() {
    const { currencySymbol } = this.state;
    const { product: { price, discounted_price }} = this.props.inquiry;
    if(typeof price !== 'number') {
      return null;
    }

    let discountedPriceDetails, priceStyle='';
    if(typeof discounted_price === 'number') {
      priceStyle = 'text-line-through';
      discountedPriceDetails = (
        <span>{currencySymbol} {discounted_price}</span>
      );
    }

    return (
      <div className={'price'}>
        {discountedPriceDetails} <span className={priceStyle}>{currencySymbol} {price}</span>
      </div>
    );
  }

  render() {
    const {
      product: { price, pictureUrl, name },
      stockCount,
      read,
      isDeletedByVisitor,
      itemCount
    } = this.props.inquiry;

    const { isEditing, isCheckboxSelected, priceUpdateInProg } = this.state;
    const checkbxStyles = isEditing ? ' showCheckbox' : ' hideCheckbox';
    // const isCheckboxSelected = MultiSelectInquiry.isInquirySelected(inquiryId);

    return (
      <div className="inquriesContainer">
        <div className="inquriesWrapper">
          <div className={'checkboxContainer' + checkbxStyles}>
            <CheckBox checked={isCheckboxSelected} onCheckChanged={this.onCheckboxClick} />
          </div>
          <div className="imageContainer">
            <div style={{ position: 'absolute', top: '18px', left: 0, right: 0 }}>
              <Loader size="small" />
            </div>
            <button className="smallImageContainer" onClick={this.showBigImage}>
              <div className="image" style={{ backgroundImage: `url(${pictureUrl})` }} />
            </button>
          </div>
          <div className="rightContainer inquriesRowRightContainer">
            {isDeletedByVisitor && (
              <div className="deletedByVisitorContainer">
                <img src={cross} className="crossIcon" alt="close" />
                <div className="deletedByVisitor">Deleted by visitor</div>
              </div>
            )}

            <div className="nameContainer">
              {!read && <div className="new">NEW</div>}
              <div className="productTitle">{name ? name : 'No product title'}</div>
            </div>
            {this.renderPriceText()}
            <div className="description">
              {`Quantity: ${itemCount}`}
            </div>

            <button onClick={this.toggleModal} className={'set-price'} disabled={priceUpdateInProg}>
              {typeof price === 'number' ? 'Edit price' : 'Set price'}
              {priceUpdateInProg && (
                <div className="set-price-loader">
                  <Loader size="extraSmall" />
                </div>
              )}
            </button>
            <button onClick={this.toggleStock} className={stockCount ? 'markOOS' : 'markOOS OOS'}>
              {stockCount ? 'Mark out of stock' : 'Out of stock'}
            </button>
          </div>
          {this.renderModal()}
          {this.renderPriceModal()}
        </div>
      </div>
    );
  }
}

export default SingleInquiryRow;
