import React, { Fragment } from "react";
import './styles.scss'

export default ({errorMsg, inputId, label, inputClassName='', icon = null, ...restInputProps}) => {
  let errorContainer = null, errorInputClass= '';
  if(errorMsg) {
    errorContainer = (
      <div className="input-seperator input--error-msg">
        <span>{errorMsg}</span>
      </div>
    )
    errorInputClass='input--error'
  }

  return (
    <Fragment>
      <label htmlFor={inputId}>{label}</label>
      <div className={`input-common-container input-seperator ${errorInputClass}`}>
        {icon}
        <div className="input-element-container">
          <input id={inputId} className={`input-element ${inputClassName}`} {...restInputProps} />
          <hr className="input-bottom-border"/>
          { errorContainer }
        </div>
      </div>
    </Fragment>
  )
}
