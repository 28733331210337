import React, { Component } from 'react';

import { AutoSizer } from 'react-virtualized';
import SectionList from 'react-virtualized-sectionlist';
import Order from 'qs-data-manager/Order';
import Firebase from 'qs-config/FirebaseConfig';

import RenderSingleOrder from '../RenderSingleOrder/RenderSingleOrder';
import OrderContactHeader from '../OrderContactHeader/OrderContactHeader';
import toastr from 'toastr';

import './RenderList.css';
import Loader from 'qs-components/Common/Loader';
import PropTypes from 'prop-types';
import MultiSelectOrder from 'qs-helpers/MultiSelectOrder';
import Mixpanel from 'qs-data-manager/Mixpanel';
import { toggleGlobalLoader } from 'qs-helpers';
import * as Sentry from "@sentry/browser";

const ERROR_MESSAGE = 'Something went wrong. Please try again later.';

class RenderList extends Component {
  static propTypes = {
    informHeaderOfContacts: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      refreshing: false,
      contacts: [],
      didErrored: false,
      error: null,
      page: 1,
      isEditing: false,
      areAllSelected: false,
      allIndividuallySelected: false
    };

    this.sectionIds = [];
    this.metaFetched = {};
    this.VISIBLE_ROW_CHANGE_DEBOUNCE_TIME = 100;
    this.visibleRowChangeDebouncer = null;
    this.erroredRequests = [];
    this.rowsVisible = [];
    this.visibleContacts = [];
  }

  componentDidMount() {
    const { catalogueId } = this.props;
    Order.attachContactsListener({ catalogueId }, this.onContactsChange);
    this.getContacts({ catalogueId });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { refreshClicked } = nextProps;

    if (refreshClicked !== this.props.refreshClicked && refreshClicked) {
      this.refreshList();
    }
  }

  refreshList = () => {
    const { catalogueId, refreshCompleted } = this.props;

    this.metaFetched = {};

    refreshCompleted(false, false);
    this.getContacts({ catalogueId });
    this.getContactsMetaFromRows(this.visibleContacts);
  };

  getContactsMetaFromRows = items => {
    const { catalogueId } = this.props;

    const contacts = [];

    items.forEach(item => {
      if (item && !this.metaFetched[item]) {
        contacts.push(item);
        this.metaFetched[item] = true;
      }
    });

    if (!contacts.length) {
      return;
    }

    this.getContactsMeta({ catalogueId, visibleContacts: contacts });
  };

  getContacts = ({ catalogueId }) => {
    // HACK
    const idToken = Firebase.auth().currentUser;

    if (idToken) {
      Order.getContacts({ catalogueId });
    } else {
      setTimeout(() => {
        Order.getContacts({ catalogueId });
      }, 2000);
    }
  };

  onContactsChange = (err, { status, data } = {}) => {
    const { catalogueId } = this.props;

    const stateUpdates = {};

    if (err) {
      this.erroredRequests.push({
        type: 'GET_CONTACTS',
        params: { catalogueId }
      });

      stateUpdates.loading = false;
      stateUpdates.refreshing = false;
      stateUpdates.didErrored = true;
      stateUpdates.error = err;
    } else {
      switch (status) {
        case Order.OPERATION_STATUS.LOADING: {
          stateUpdates.loading = true;
          break;
        }
        case Order.OPERATION_STATUS.REFRESHING: {
          stateUpdates.loading = false;
          stateUpdates.refreshing = true;
          stateUpdates.contacts = data;
          break;
        }
        case Order.OPERATION_STATUS.SUCCESS: {
          stateUpdates.loading = false;
          stateUpdates.refreshing = false;
          stateUpdates.contacts = data;
          break;
        }
        case Order.OPERATION_STATUS.UPDATE: {
          stateUpdates.contacts = data;
          break;
        }
        default:
      }
    }

    this.setState(stateUpdates, () => {
      if (stateUpdates.contacts && this.sectionListRef) {
        const listRef = this.sectionListRef.getListRef();
        listRef.recomputeRowHeights(0);
        listRef.forceUpdateGrid();
      }
    });
  };

  onDownloadContactOrder = () => {
    const { contacts } = MultiSelectOrder.getAllSelectedContacts();
    try {
      Mixpanel.sendEvent({
        eventName: 'order_export_contacts_excel',
        props: { subMenu: 'DOWNLOAD' }
      });
      Order.downloadContactsExcel({ catalogueId: '', contacts }, this.onDownloadContactCallback);
    } catch (err) {
      console.log('err', err);
      Sentry.captureException(err);
    }
  };

  onDeleteContactOrder = async () => {
    const { clearScreenState } = this.props;
    const key = `onDeleteContactOrder${Date.now()}`;

    toggleGlobalLoader(key, true);

    try {
      const { contacts } = MultiSelectOrder.getAllSelectedContacts();
      Mixpanel.sendEvent({ eventName: 'order_delete_contacts' });
      await Order.deleteContacts({ contacts, catalogueId: '' });
      toggleGlobalLoader(key, false);
    } catch (err) {
      console.log('err', err);
      toastr.error(ERROR_MESSAGE);
      toggleGlobalLoader(key, false);
      Sentry.captureException(err);
    }

    clearScreenState();
  };

  onDownloadContactCallback = (err, { status, value }) => {
    const { OPERATION_STATUS } = Order;

    const key = `onDownloadContactCallback${Date.now()}`;
    toggleGlobalLoader(key, true);

    switch (status) {
      case OPERATION_STATUS.PREPARING:
        toggleGlobalLoader(key, false);
        break;

      case OPERATION_STATUS.SUCCESS: {
        const a = document.createElement('a');
        a.href = value;
        a.click();
        toggleGlobalLoader(key, false);
        break;
      }

      default:
        break;
    }
  };

  onContactSelect = () => {
    const { contacts } = this.state;

    const selectedContacts = MultiSelectOrder.getSelectedContactsLength();
    this.setState({
      isEditing: !!MultiSelectOrder.getSelectedContactsLength(),
      allIndividuallySelected: contacts.length === selectedContacts
    });
  };

  onRowsRendered = ({ overscanStartIndex, overscanStopIndex }) => {
    const { catalogueId } = this.props;

    const visibleContacts = [];

    for (let i = overscanStartIndex; i <= overscanStopIndex; i++) {
      const row = this.listenerBlob[i];

      if (row !== 'title' && !this.metaFetched[row]) {
        visibleContacts.push(row);
        this.metaFetched[row] = true;
      }
    }

    if (!visibleContacts.length) {
      return;
    }

    this.visibleContacts = visibleContacts;

    this.getContactsMeta({ catalogueId, visibleContacts });
  };

  getDataBlob = () => {
    const { contacts } = this.state;

    const { dataBlob, sectionIds } = Order.createSections(contacts, 'dateUpdated', {
      colors: Order.CONTACT_COLORS
    });
    const list = [];
    const listenerBlob = [];

    sectionIds.map(sectionId => {
      list.push({ title: sectionId, data: dataBlob[sectionId] });
      const numbers = dataBlob[sectionId].map(({ phone }) => phone);
      listenerBlob.push('title', ...numbers);
    });
    this.listenerBlob = listenerBlob;
    return list;
  };

  getContactsMeta = ({ catalogueId, visibleContacts: contacts }) => {
    Order.getContactsMeta({ catalogueId, contacts }).catch(err => {
      contacts.forEach(contact => {
        delete this.metaFetched[contact];
      });

      this.erroredRequests.push({
        type: 'GET_CONTACTS_META',
        params: { catalogueId, contacts }
      });

      this.setState({ didErrored: true, error: err });
    });
  };

  onSelectAllClick = areAllSelected => {
    const { contacts } = this.state;
    const { isEditing } = MultiSelectOrder.onSelectAll(areAllSelected, contacts);

    this.setState({ areAllSelected, isEditing, allIndividuallySelected: null });
  };

  renderRow = ({ item, key, style }) => {
    const { onContactClick, selectedContact, clearScreenState } = this.props;
    const { isEditing, areAllSelected } = this.state;

    return (
      <div key={key} style={style}>
        <RenderSingleOrder
          contact={item.phone}
          onContactClick={onContactClick}
          selectedContact={selectedContact}
          isEditing={isEditing}
          areAllSelected={areAllSelected}
          onContactSelect={this.onContactSelect} // when checkbox is clicked
          clearScreenState={clearScreenState}
        />
      </div>
    );
  };

  renderHeader = ({ title: sectionId, key, style }) => {
    return (
      <div key={key} style={style}>
        <div className="sectionHeader noselect">{Order.SECTION_HEADER[sectionId] || sectionId}</div>
      </div>
    );
  };

  renderLoader = () => (
    <div className="loaderContainer">
      <Loader size="large" />
    </div>
  );

  renderList = sections => {
    const { isEditing, allIndividuallySelected } = this.state;

    return sections.length ? (
      <div className="AutoSizerList" style={{ height: 'calc(100vh)' }}>
        <AutoSizer>
          {({ width, height }) => (
            <SectionList
              width={width}
              height={height}
              sections={sections}
              sectionHeaderRenderer={this.renderHeader}
              sectionHeaderHeight={52}
              rowHeight={91}
              rowRenderer={this.renderRow}
              onRowsRendered={this.onRowsRendered}
              ref={listRef => {
                this.sectionListRef = listRef;
              }}
            />
          )}
        </AutoSizer>
        {!!isEditing && (
          <OrderContactHeader
            onSelectAllClick={this.onSelectAllClick}
            allIndividuallySelected={allIndividuallySelected}
            onDownloadContactOrder={this.onDownloadContactOrder}
            onDeleteContactOrder={this.onDeleteContactOrder}
          />
        )}
      </div>
    ) : (
      <div className="emptyList">All inquries from your visitors will appear here</div>
    );
  };

  render() {
    const { loading } = this.state;
    const sections = this.getDataBlob();

    return loading ? this.renderLoader() : this.renderList(sections);
  }
}

export default RenderList;
