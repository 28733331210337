import React, { useEffect, useState } from 'react';
import './styles.scss';
import Catalogue from 'qs-components/Catalogue';
import Inquiries from 'qs-components/Inquiries';
import AccountSettings from 'qs-components/AccountSettings';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import UserInfoBar from 'qs-components/Common/UserInfoBar';
import SideNavbar from 'qs-components/Common/SideNavbar';
import LoginScreen from 'qs-components/Authentication/LoginScreen';
import Loader from 'qs-components/Common/Loader';
import firebase from 'qs-config/FirebaseConfig';
import { onLogout, getIsLoggingIn } from 'qs-data-manager/Authentication';
import AppOnReadyHandler from 'qs-helpers/AppOnReadyHandler';
import eventbus from 'eventing-bus';
import LimboState from 'qs-components/Common/LimboState';
import { formWindowEndpointForCatalogue, localstorageExists } from 'qs-helpers';
import { navigateToPath, sideBarStateOnMount, SIDE_BARS_META } from 'qs-data-manager';
import FeatureLimitModal from 'qs-components/Common/FeatureLimitModal';
import ResellerModal from 'qs-components/Common/ResellerModal/ResellModal';
import ForwardToShowroom from 'qs-components/Common/ForwardToShowroom/ForwardToShowroom';
import RenderForMobile from 'qs-components/Common/RenderForMobile/RenderForMobile';
import { checkIfMobile } from 'qs-helpers';
import { setCompanyMetaInCache, getCompanyIdFromCache } from 'qs-data-manager/Company';
import { setUserMetaInCache } from 'qs-data-manager/User';
import Api from 'qs-services/Api';
import { saveCurrentPlan, saveFeatureLimits } from 'qs-data-manager/FeatureUsage';
import { generateUniqueDeviceIdIfNotExists } from 'qs-config/DeviceIdGenerator_APP_RUN_ENV';

export default () => {
  const [isReady, setIsReady] = useState(false); // Ensures firebase auth is ready
  const [loginState, setLoginState] = useState({
    isLoggedIn: false,
    loggingIn: false
  });
  const [showBars, setShowBars] = useState(() => {
    return sideBarStateOnMount();
  });
  const [isMobile] = useState(() => checkIfMobile());

  useEffect(() => {
    const key = SIDE_BARS_META.EVENT_BUS;
    const removeListener = eventbus.on(key, value => {
      setShowBars(value);
    });

    return () => {
      removeListener();
    };
  }, []);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      const pathHasLogin = window.location.href.includes('/login');
      const pathHasNothing = window.location.pathname === '/';

      const isLoggingIn = getIsLoggingIn();
      let isLoggedIn = !!user;

      const setReadyState = () => {
        setTimeout(() => {
          setIsReady(true);
          setLoginState({
            isLoggedIn: isLoggedIn,
            loggingIn: isLoggingIn
          });
        }, 100);
      };

      if (user && !isLoggingIn) {
        const navigateToCatalogue = () => {
          try {
            navigateToPath('/catalogues');
          } catch (err) {
            console.error('navigateToPath: Could not navigate to path /catalogue', err);
            window.location.href = formWindowEndpointForCatalogue();
          }

          setReadyState();
        };
        const storageExists = localstorageExists();
        if(storageExists) {
          generateUniqueDeviceIdIfNotExists({ companyId: getCompanyIdFromCache() });
        }

        if (!storageExists) {
          Promise.all([Api.getCompanyAndUserObject(), Api.getCurrentPlan(), Api.getPaymentPlan()])
            .then(result => {
              const [companyUserMeta, currentPlan, featureLimit] = result;

              const { company, user } = companyUserMeta;

              setCompanyMetaInCache({data: company});
              setUserMetaInCache({data: user});
              saveCurrentPlan(currentPlan);
              saveFeatureLimits(featureLimit);

              if (pathHasLogin || pathHasNothing) {
                navigateToCatalogue();
              } else {
                setReadyState();
              }
              generateUniqueDeviceIdIfNotExists({ companyId: company.id });
              //saveUuid({ companyId: company.id });
            })
            .catch(err => {
              console.log('Api.getCompanyAndUserObject: Could not get data from remote', err);
              onLogout();
              setReadyState();
            });
        } else if (pathHasLogin || pathHasNothing) {
          navigateToCatalogue();
        } else {
          setReadyState();
        }
      } else if (!user) {
        if (!pathHasLogin || pathHasNothing) {
          onLogout();
        }

        setTimeout(() => {
          setIsReady(true);
          setLoginState({
            isLoggedIn: isLoggedIn,
            loggingIn: isLoggingIn
          });
        }, 100);
      }
    });
  }, []);

  if (isMobile) {
    return <RenderForMobile />;
  }

  return (
    <div id={'Navigation'}>
      <div className={`${!!showBars ? 'showBars' : 'hideBars'}`} id={'screenWrapper'}>
        {isReady ? (
          <AppOnReadyHandler loginState={loginState}>
            <Router>
              <Switch>
                <Route exact path="/" component={LimboState} />
                <Route path="/catalogues" component={Catalogue} />
                <Route path="/inquiries" component={Inquiries} />
                <Route path="/account-settings" component={AccountSettings} />
                <Route path={'/login'} component={LoginScreen} />
              </Switch>
            </Router>
            <FeatureLimitModal />
            <ResellerModal />
            <ForwardToShowroom />
          </AppOnReadyHandler>
        ) : (
          <div className={'authNotReady'}>
            <Loader size={'large'} />
          </div>
        )}
      </div>
      {!!showBars && (
        <>
          <SideNavbar />
          <UserInfoBar />
        </>
      )}
    </div>
  );
};
