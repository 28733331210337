import { nativeDB } from 'qs-services/DexieInit';

const saveProductList = async (apiResp = {}, extraData = {}) => {
  const { catalogueId } = extraData;
  if (!catalogueId) {
    console.error(`Missing data catalogueId ${catalogueId} while storing data to native storage`);
    return;
  }
  const data = apiResp.productsList;
  await nativeDB.productListMeta.put(data, catalogueId);
};

const saveProductRowMeta = async (apiResp = {}, extraData = {}) => {
  const { productMeta } = apiResp;
  const data = Object.values(productMeta);
  await nativeDB.productRowMeta.bulkPut(data);
};

export {
  saveProductList,
  saveProductRowMeta
}
