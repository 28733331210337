import CacheRequest from './CacheRequest';
import Api from 'qs-services/Api';
import { debouncer, DEBOUNCER_TYPE, CATALOGUES_LAST_FETCH_TS } from 'qs-helpers';
import { db } from 'qs-config/FirebaseConfig';
import { connector } from './ApiAndCacheConnector';
import {
  createCatalogueInNative,
  updateCatalogueTitleInNative,
  deleteCataloguesFromNative,
  upsertCatalogueRowInNative
} from 'qs-data-manager/Dexie/CatalogueDexieHelpers';
import { toggleGlobalLoader } from 'qs-services/Helpers/index';
import eventbus from 'eventing-bus';
import { ACTIVE_PRODUCT_ID_META } from './Products';
import { setCompanySettingsInCache, getCompanyStockSetting, getCompanyOrderOnOOS } from './Company';
import cloneDeep from 'lodash.clonedeep';
import * as Sentry from '@sentry/browser';

let CHANGES_DEBOUNCER_ID = null;

const CATALOGUE_META_DEBOUNCER = {
  key: 'CATALOGUE_META_DEBOUNCER_KEY',
  timeInMs: 200,
  type: DEBOUNCER_TYPE.ADD
};

export const CATALOGUE_SHARE_TYPES = {
  SINGLE: 'SINGLE',
  MULTI: 'MULTI'
};

export const CREATING_NEW_CATALOGUE = {
  NEW_CATALOGUE_EB_KEY: 'TOGGLE_NEW_CATALOGUE',
  CREATING_CATALOGUE: 'CREATING_NEW',
  DELETE_CATALOGUE: 'DELETE_NEW',
  SAVE_CATALOGUE: 'SAVE_CATALOGUE'
};

export const CATALOGUE_SEARCH = 'CATALOGUE_SEARCH';
export const CATALOGUE_SEARCH_STATE = 'CATALOGUE_SEARCH_STATE';
export const CATALOGUE_SEARCH_TERM = 'CATALOGUE_SEARCH_TERM';

export let ACTIVE_CATALOGUE_META = {
  eventbusKey: 'ACTIVE_CATALOGUE_META',
  catalogueId: null,
  localStorageKey: 'ACTIVE_CATALOGUE_ID'
};

export const CATALOGUE_ROW_TYPES = {
  SEARCH_RESULT: {
    type: 'SEARCH_RESULT',
    height: 40
  },
  CATALOGUE_META: {
    type: 'CATALOGUE_META',
    height: 114,
    overscanCount: 10
  },
  SKELETON_CATALOGUE: {
    type: 'SKELETON_CATALOGUE',
    height: 114
  }
};

export const CSV_UPLOADER_EB_KEY = 'CSV_UPLOADER_EB_KEY';

export const EXCEL_UPLOAD_META = {
  RENDER_ORDER: [
    'IMAGE',
    'TITLE',
    'PRICE',
    'DISCOUNT',
    'DESCRIPTION',
    'TAGS',
    'INVENTORY',
    'NOTES'
  ],
  IMAGE: {
    id: 'IMAGE',
    title: 'product picture url'
  },
  TITLE: {
    id: 'TITLE',
    title: 'product name'
  },
  PRICE: {
    id: 'PRICE',
    title: 'product price'
  },
  DISCOUNT: {
    id: 'DISCOUNT',
    title: 'discounted price'
  },
  DESCRIPTION: {
    id: 'DESCRIPTION',
    title: 'product description'
  },
  TAGS: {
    id: 'TAGS',
    title: 'product tags'
  },
  INVENTORY: {
    id: 'INVENTORY',
    title: 'available quantity'
  },
  NOTES: {
    id: 'NOTES',
    title: 'product notes'
  }
};

export const SHARE_LINK_PROMISE = {};

export const CATALOGUE_META_REQUEST_SEND = {};

// Catalogue list listeners
const attachCatalogueIdsListener = listener => {
  const key = connector.CATALOGUE_LIST_META.cacheKey;
  CacheRequest.attachListener(key, listener);
};
const removeCatalogueIdsListener = listener => {
  const key = connector.CATALOGUE_LIST_META.cacheKey;
  CacheRequest.removeListener(key, listener);
};
const getAllCatalogueIds = () => {
  const key = connector.CATALOGUE_LIST_META.cacheKey;
  const apiName = connector.CATALOGUE_LIST_META.apiFunction;
  CacheRequest.makeRequest(key, apiName, {
    params: [],
    options: {
      nativeStorageKey: connector.CATALOGUE_LIST_META.nativeStorageKey
    }
  });
};

// Catalogue row meta listeners
const attachCatalogueMetaListener = (listener, key) => {
  const sharedCacheKey = connector.CATALOGUE_ROW_META.cacheKey;
  CacheRequest.attachListener(`${sharedCacheKey}${key}`, listener);
};

const removeAttachCatalogueMetaListener = (listener, key) => {
  const sharedCacheKey = connector.CATALOGUE_ROW_META.cacheKey;
  CacheRequest.removeListener(`${sharedCacheKey}${key}`, listener);
};

const catalogueMetaBatchCallback = (response, err) => {
  const sharedCacheKey = connector.CATALOGUE_ROW_META.cacheKey;
  if (err) {
    console.error('ERROR while fetching batched catalogue meta', err);
    return;
  }

  if (!!response && !!response.catalogueMeta && typeof response.catalogueMeta === 'object') {
    Object.keys(response.catalogueMeta).forEach(id => {
      const cache = response.catalogueMeta[id];
      const key = `${sharedCacheKey}${id}`;
      CATALOGUE_META_REQUEST_SEND[id] = true;
      CacheRequest.setCacheForKey(key, cache);
    });
  }
};

const catalogueMetaDebounceCallback = (multipleBatchedCatalogueIds = []) => {
  const batchedCatalogueIds = {};
  const sharedCacheKey = connector.CATALOGUE_ROW_META.cacheKey;

  multipleBatchedCatalogueIds.forEach(batchedCatalogueId => {
    batchedCatalogueId.forEach(({ catalogueId } = {}) => {
      if (!catalogueId) {
        console.error(
          'Catalogue id not found while sending batch request for getting meta',
          catalogueId
        );
        return;
      }

      const ifExists = !!CATALOGUE_META_REQUEST_SEND[catalogueId];

      if (!ifExists) {
        batchedCatalogueIds[catalogueId] = true;
      }
    });
  });

  const renderedCatalogueIds = Object.keys(batchedCatalogueIds);
  if (!renderedCatalogueIds.length) {
    return;
  }
  const oneTimeUniqueKey = `CATALOGUE_META_LISTENER_${new Date().getTime()}`;
  const apiCall = connector.CATALOGUE_ROW_META.apiFunction;

  CacheRequest.makeRequest(oneTimeUniqueKey, apiCall, {
    params: [renderedCatalogueIds],
    options: {
      isBatched: true,
      sharedCacheKey: sharedCacheKey,
      batchCallback: catalogueMetaBatchCallback,
      nativeStorageKey: connector.CATALOGUE_ROW_META.nativeStorageKey
    }
  });
};

const getCatalogueMeta = ({ catalogueIds = [] }) => {
  debouncer(
    { data: catalogueIds, key: CATALOGUE_META_DEBOUNCER.key },
    { time: CATALOGUE_META_DEBOUNCER.timeInMs, type: CATALOGUE_META_DEBOUNCER.type },
    catalogueMetaDebounceCallback
  );
};

// Catalogue tags listernes

const attachCatalogueTagsListener = (listener, catalogueId) => {
  const key = `${connector.CATALOGUE_TAGS.cacheKey}${catalogueId}`;
  CacheRequest.attachListener(key, listener);
};
const removeCatalogueTagsListener = (listener, catalogueId) => {
  const key = `${connector.CATALOGUE_TAGS.cacheKey}${catalogueId}`;
  CacheRequest.removeListener(key, listener);
};
const getCatalogueTags = catalogueId => {
  const key = `${connector.CATALOGUE_TAGS.cacheKey}${catalogueId}`;
  const apiName = connector.CATALOGUE_TAGS.apiFunction;
  CacheRequest.makeRequest(key, apiName, {
    params: [catalogueId],
    options: {
      nativeStorageKey: connector.CATALOGUE_TAGS.nativeStorageKey,
      extraData: {
        catalogueId
      }
    }
  });
};

// CATALOGUE SETTINGS

const attachCatalogueSettingsListener = ({ catalogueId, listener }) => {
  const key = `${connector.CATALOGUE_SETTINGS.cacheKey}${catalogueId}`;
  CacheRequest.attachListener(key, listener);
};

const removeCatalogueSettingsListener = ({ catalogueId, listener }) => {
  const key = `${connector.CATALOGUE_SETTINGS.cacheKey}${catalogueId}`;
  CacheRequest.removeListener(key, listener);
};

const getCatalogueSettings = ({ catalogueId }) => {
  const key = `${connector.CATALOGUE_SETTINGS.cacheKey}${catalogueId}`;
  const apiName = connector.CATALOGUE_SETTINGS.apiFunction;
  CacheRequest.makeRequest(key, apiName, {
    params: [catalogueId],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

// Catalogue link generation
const attachCatalogueLinkListener = ({ catalogueId, listener }) => {
  const key = `${connector.CATALOGUE_LINK.cacheKey}${catalogueId}`;
  CacheRequest.attachListener(key, listener);
};

const removeCatalogueLinkListener = ({ catalogueId, listener }) => {
  const key = `${connector.CATALOGUE_LINK.cacheKey}${catalogueId}`;
  CacheRequest.removeListener(key, listener);
};

const setCatalogueLinkInCache = ({ link, catalogueId }) => {
  if (!link || !catalogueId) {
    console.error(
      `setCatalogueLinkInCache: Could not set catalogueLink in cache link: ${link} catalogueId: ${catalogueId}`
    );
    return;
  }
  const key = `${connector.CATALOGUE_LINK.cacheKey}${catalogueId}`;
  const linkArray = link.split('/s/');
  const linkDomain = `${linkArray[0]}/s`;
  const linkSlug = linkArray[1];
  const allSlugs = linkSlug.split('/');

  const companySlug = allSlugs[0];
  const catalogueSlug = allSlugs[1];
  const randomSlug = allSlugs[2];

  const linkMeta = { linkDomain, companySlug, catalogueSlug, randomSlug };
  CacheRequest.setCacheForKey(key, linkMeta);
  return linkMeta;
};

const createCataloguesLink = async (catalogueIds = []) => {
  try {
    let promiseKey = '';
    if (catalogueIds.length === 1) {
      promiseKey = catalogueIds[0];
    } else {
      promiseKey = `createCataloguesLink${Date.now()}`;
    }

    if (SHARE_LINK_PROMISE[promiseKey]) {
      return SHARE_LINK_PROMISE[promiseKey];
    }

    let resp = {};
    try {
      const linkType = 'NORMAL';
      const promise = Api.createCataloguesLink(catalogueIds, linkType);
      SHARE_LINK_PROMISE[promiseKey] = promise;

      resp = await promise;
      delete SHARE_LINK_PROMISE[promiseKey];
    } catch (err) {
      delete SHARE_LINK_PROMISE[promiseKey];
      Sentry.captureException(err);
    }

    if (catalogueIds.length === 1) {
      setCatalogueLinkInCache({ link: resp.link || '', catalogueId: catalogueIds[0] });
    }

    return resp;
  } catch (err) {
    console.log('createCataloguesLink: Could not create catalogue link', err);
    Sentry.captureException(err);
    return '';
  }
};

const getCatalogueLinkFromRemote = async ({ catalogueId }) => {
  const key = `${connector.CATALOGUE_LINK.cacheKey}${catalogueId}`;
  const promiseKey = catalogueId;
  const linkMeta = CacheRequest.getCacheForKey(key) || null;
  if (linkMeta) {
    const { linkDomain, companySlug, catalogueSlug, randomSlug } = linkMeta;
    return `${linkDomain}/${companySlug}/${catalogueSlug}/${randomSlug}`;
  }

  if (SHARE_LINK_PROMISE[promiseKey]) {
    return SHARE_LINK_PROMISE[promiseKey];
  }

  try {
    SHARE_LINK_PROMISE[promiseKey] = true;

    const promise = Api.getCatalogueLink({
      catalogueId
    });
    SHARE_LINK_PROMISE[promiseKey] = promise;

    const { link } = await promise;

    delete SHARE_LINK_PROMISE[promiseKey];

    if (link) {
      setCatalogueLinkInCache({ link, catalogueId });
    }
  } catch (err) {
    console.log('getCatalogueLinkFromRemote: Could not fetch link from remote', err);
    delete SHARE_LINK_PROMISE[promiseKey];
    Sentry.captureException(err);
  }
};

// HELPER METHODS
const getCatalogueIdsFromCache = () => {
  const key = connector.CATALOGUE_LIST_META.cacheKey;
  return CacheRequest.getCacheForKey(key);
};

const getCatalogueMetaFromCache = key => {
  const sharedCacheKey = connector.CATALOGUE_ROW_META.cacheKey;
  const catalogueMeta = CacheRequest.getCacheForKey(`${sharedCacheKey}${key}`);

  if (!catalogueMeta) {
    return {
      title: null,
      pictureUrls: ['', '', '', ''],
      productCount: null
    };
  }
  return catalogueMeta;
};

const getCatalogueCountFromCache = () => {
  const key = connector.CATALOGUE_LIST_META.cacheKey;
  const cache = CacheRequest.getCacheForKey(key);

  if (cache && cache.catalogueIds) {
    return (cache.catalogueIds || []).length;
  }

  return 0;
};

const deleteCatalogues = async (ids, shouldDeleteFromRemote = true) => {
  const loaderKey = `deleteCatalogues${Date.now()}`;

  toggleGlobalLoader(loaderKey, true);

  if (!ids || !ids.length) {
    toggleGlobalLoader(loaderKey, false);
    return;
  }

  const cacheKey = connector.CATALOGUE_LIST_META.cacheKey;
  const catalogueRowSharedCacheKey = connector.CATALOGUE_ROW_META.cacheKey;
  const { catalogueIds } = CacheRequest.getCacheForKey(`${cacheKey}`);

  const activeCatalogueId = getActiveCatalogueId();
  if (ids.indexOf(activeCatalogueId) > -1) {
    setActiveCatalogueId(null);
  }

  if (ids.length === 1) {
    const id = ids[0];
    for (let i = 0; i < catalogueIds.length; i++) {
      if (catalogueIds[i].catalogueId === id) {
        catalogueIds.splice(i, 1);
        break;
      }
    }
    const key = `${catalogueRowSharedCacheKey}${id}`;
    CacheRequest.deleteCacheForKeys([key]);
    CacheRequest.setCacheForKey(`${cacheKey}`, { catalogueIds });
  } else {
    const catalogueIdsMap = {};
    catalogueIds.forEach(row => {
      catalogueIdsMap[row.catalogueId] = row;
    });
    ids.forEach(id => {
      delete catalogueIdsMap[id];
    });
    const newCatalogueIds = Object.values(catalogueIdsMap).sort(
      (cat1, cat2) => cat2.sortTimestamp - cat1.sortTimestamp
    );

    CacheRequest.deleteCacheForKeys(ids);
    CacheRequest.setCacheForKey(`${cacheKey}`, { catalogueIds: newCatalogueIds });
  }
  const promises = [deleteCataloguesFromNative(ids)];

  if (shouldDeleteFromRemote) {
    promises.push(Api.deleteCatalogues(ids));
  }

  await Promise.all(promises);
  toggleGlobalLoader(loaderKey, false);
};

const updateLocalCatalogueTitle = ({ title, catalogueId }) => {
  const sharedCacheKey = connector.CATALOGUE_ROW_META.cacheKey;
  const cacheKey = `${sharedCacheKey}${catalogueId}`;
  const cachedRow = CacheRequest.getCacheForKey(cacheKey) || {};
  const productMeta = { ...cachedRow };
  productMeta.title = title;
  CacheRequest.setCacheForKey(`${cacheKey}`, productMeta);
};

const updateRemoteCatalogueTitle = async ({ catalogueId, updates }) => {
  return Api.updateCatalogue({ catalogueId, updates });
};

const changeCatalogueTitle = async ({ title, id }) => {
  const loaderKey = `changeCatalogueTitle${id}`;
  toggleGlobalLoader(loaderKey, true);

  const updates = { title };
  updateLocalCatalogueTitle({ title, catalogueId: id });
  await Promise.all([
    updateCatalogueTitleInNative({ title, catalogueId: id }),
    updateRemoteCatalogueTitle({ catalogueId: id, updates })
  ]);

  const date = new Date().toISOString();
  setLastFetchDate(date);
  toggleGlobalLoader(loaderKey, false);
};

const getCatalogueTitle = ({ catalogueId }) => {
  const key = `${connector.CATALOGUE_ROW_META.cacheKey}${catalogueId}`;

  const cache = CacheRequest.getCacheForKey(key) || {};
  if (!cache || !cache.title) {
    return '';
  }

  return cache.title;
};

const searchCatalogueIds = searchTerm => Api.searchCatalogues(searchTerm);

const createLocalCatalogue = ({ title } = {}) => {
  const cacheKey = connector.CATALOGUE_LIST_META.cacheKey;
  const catalogueRowSharedCacheKey = connector.CATALOGUE_ROW_META.cacheKey;
  const catalogueId = db.ref('catalogues').push().key;

  const { catalogueIds = [] } = CacheRequest.getCacheForKey(cacheKey) || {};
  const ts = new Date().getTime();
  catalogueIds.unshift({ catalogueId, sortTimestamp: ts });

  const catalogueMetaKey = `${catalogueRowSharedCacheKey}${catalogueId}`;

  const catalogueMeta = {
    title,
    pictureUrls: [],
    pictureIds: [],
    productCount: 0
  };

  CacheRequest.setCacheForKey(catalogueMetaKey, catalogueMeta);
  CacheRequest.setCacheForKey(cacheKey, { catalogueIds });
  return catalogueId;
};

const createRemoteCatalogue = async meta => {
  return Api.createCatalogue(meta);
};

const createNewCatalogue = async (meta = {}) => {
  try {
    if (!meta.title) {
      console.warn('Trying to create a catalogue without a title');
      return;
    }

    const eventbuskey = `createNewCatalogue${Date.now()}`;
    toggleGlobalLoader(eventbuskey, true);
    const catalogueId = createLocalCatalogue(meta);
    await Promise.all([
      createCatalogueInNative(meta, catalogueId),
      createRemoteCatalogue({
        title: meta.title,
        catalogueId
      })
    ]);
    setActiveCatalogueId(catalogueId);
    const date = new Date().toISOString();
    setLastFetchDate(date);
    toggleGlobalLoader(eventbuskey, false);
  } catch (err) {
    console.log('err', err);
    Sentry.captureException(err);
  }
};

const createCatalogueFromFolder = () => {};

// sets ACTIVE_CATALOGUE_ID and informs about the new catalogueId
export const setActiveCatalogueId = catalogueId => {
  ACTIVE_CATALOGUE_META.catalogueId = catalogueId;
  eventbus.publish(ACTIVE_PRODUCT_ID_META.eventbusKey, { productId: null });
  eventbus.publish(ACTIVE_CATALOGUE_META.eventbusKey, catalogueId);
  localStorage.setItem(ACTIVE_CATALOGUE_META.localStorageKey, catalogueId);
};

export const getActiveCatalogueId = () => ACTIVE_CATALOGUE_META.catalogueId;

// GETTER AND SETTER FOR LAST FETCH TIMESTAMP FOR CATALOGUE SCREEN CHANGES

const getLastFetchDate = () => {
  return CATALOGUES_LAST_FETCH_TS.ts;
};

const setLastFetchDate = date => {
  CATALOGUES_LAST_FETCH_TS.ts = date;
  localStorage.setItem(CATALOGUES_LAST_FETCH_TS.localstorageKey, date);
};

// FIREBASE CHANGES LISTENER CALLBACK

const handleCatalogueChangeListener = ({ timestamp }) => {
  const localTs = getLastFetchDate();

  if (!localTs) {
    setLastFetchDate(timestamp);
    return;
  }

  if (localTs < timestamp) {
    if (CHANGES_DEBOUNCER_ID) {
      clearTimeout(CHANGES_DEBOUNCER_ID);
    }

    CHANGES_DEBOUNCER_ID = setTimeout(() => {
      onCatalogueScreenChange({ timestamp });
    }, 1000);
  }
};

export const onCatalogueScreenChange = async ({ timestamp }) => {
  try {
    CHANGES_DEBOUNCER_ID = null;
    const lastFetchTimestamp = getLastFetchDate();
    const { changes } = await Api.catalogueScreenChanges(lastFetchTimestamp);
    setLastFetchDate(timestamp);

    if (changes && changes.inserted && Object.keys(changes.inserted).length) {
      createNewCataloguesFromChangesApi(changes.inserted);
    }

    if (changes && changes.updated && Object.keys(changes.updated).length) {
      handleUpsertChanges(changes.updated);
    }

    if (changes && changes.removed && Object.keys(changes.removed).length) {
      const ids = Object.keys(changes.removed);
      deleteCatalogues(ids, false);
    }
  } catch (err) {
    console.error('onCatalogueScreenChange: Something went wrong', err);
  }
};

const createNewCataloguesFromChangesApi = async insertedCatalogues => {
  const addedCatalogues = [];
  const addedCatalogueMeta = {};
  const catalogueRowMetaCacheKey = connector.CATALOGUE_ROW_META.cacheKey;

  Object.keys(insertedCatalogues).forEach(catalogueId => {
    const catalogue = insertedCatalogues[catalogueId];

    addedCatalogues.push({
      catalogueId: catalogue.catalogueId,
      sortTimestamp: catalogue.sortTimestamp
    });
    addedCatalogueMeta[catalogueId] = catalogue;
    CacheRequest.setCacheForKey(`${catalogueRowMetaCacheKey}${catalogueId}`, catalogue);
  });

  const catalogueListCacheKey = `${connector.CATALOGUE_LIST_META.cacheKey}`;

  const { catalogueIds } = CacheRequest.getCacheForKey(catalogueListCacheKey);
  const newCataloguesList = [...addedCatalogues, ...catalogueIds].sort(
    (cat1, cat2) => cat2.sortTimestamp - cat1.sortTimestamp
  );

  // const uniqueList = {};
  //
  // [...addedCatalogues, ...catalogueIds].forEach(row => {
  //   uniqueList[row.catalogueId] = uniqueList[row.catalogueId]
  //     ? uniqueList[row.catalogueId].sortTimestamp > row.sortTimestamp
  //       ? uniqueList[row.catalogueId].sortTimestamp
  //       : row.sortTimestamp
  //     : row;
  // });
  //
  // const newCataloguesList = Object.values(uniqueList).sort(
  //   (cat1, cat2) => cat2.sortTimestamp - cat1.sortTimestamp
  // );

  CacheRequest.setCacheForKey(`${connector.CATALOGUE_LIST_META.cacheKey}`, {
    catalogueIds: newCataloguesList
  });

  upsertCatalogueRowInNative(addedCatalogueMeta, addedCatalogues);
};

const handleUpsertChanges = async upsertChanges => {
  const sharedKey = connector.CATALOGUE_ROW_META.cacheKey;
  const catalogueListCacheKey = connector.CATALOGUE_LIST_META.cacheKey;
  const updatedCatalogueMeta = {};

  Object.keys(upsertChanges).forEach(catalogueId => {
    const value = upsertChanges[catalogueId];
    updatedCatalogueMeta[catalogueId] = {
      catalogueId,
      sortTimestamp: value.sortTimestamp
    };
    CacheRequest.setCacheForKey(`${sharedKey}${catalogueId}`, value);
  });

  const updatedCatalogueIds = Object.keys(updatedCatalogueMeta || {});

  let allCatalogueIds =
    (CacheRequest.getCacheForKey(catalogueListCacheKey) || {}).catalogueIds || [];

  allCatalogueIds = cloneDeep(allCatalogueIds);
  const newList = allCatalogueIds
    .map(row => {
      if (updatedCatalogueIds.indexOf(row.catalogueId) > -1) {
        const sortTimestamp = updatedCatalogueMeta[row.catalogueId]
          ? updatedCatalogueMeta[row.catalogueId].sortTimestamp
          : row.sortTimestamp;

        return {
          ...row,
          catalogueId: row.catalogueId,
          sortTimestamp
        };
      }

      return row;
    })
    .sort((row1, row2) => row2.sortTimestamp - row1.sortTimestamp);

  CacheRequest.setCacheForKey(catalogueListCacheKey, { catalogueIds: newList });
  await upsertCatalogueRowInNative(upsertChanges);
};

const getAllUnselectedTags = tags => {
  const catalogueId = getActiveCatalogueId();
  const key = `${connector.CATALOGUE_TAGS.cacheKey}${catalogueId}`;
  const { tags: allTags } = CacheRequest.getCacheForKey(key) || {};
  const unselectedTags = [];
  Object.values(allTags || {}).forEach(tag => {
    const tagId = tag.title;

    if (tagId === 'Out of stock' || tagId === 'In stock') {
      return;
    }

    if (tags.indexOf(tagId) < 0) {
      unselectedTags.push(tagId);
    }
  });

  return unselectedTags;
};

const getCatalogueSettingsFromCache = catalogueId => {
  const defaultSettings = {
    experiments: {
      requestIdentity: null,
      verifyOTP: null,
      visitorsEnabled: null,
      catalogueStockVisibility: false,
      stockManagedFrom: false,
      orderOnOOS: false,
      oosControlledFrom: false,
      showResetModal: false
    },
    companySettings: {}
  };

  if (!catalogueId) {
    return defaultSettings;
  }

  const key = `${connector.CATALOGUE_SETTINGS.cacheKey}${catalogueId}`;
  const data = CacheRequest.getCacheForKey(key);

  const companySettingsKey = `${connector.COMPANY_SETTINGS.cacheKey}`;
  let companySettings = CacheRequest.getCacheForKey(companySettingsKey);
  companySettings = companySettings ? companySettings.settings : {};

  if (data && data.experiments) {
    const stockManagedFrom =
      typeof data.experiments.stockVisibility === 'boolean' ? 'catalogue' : 'company';
    const orderOnOOS =
      typeof data.experiments.orderOnOutOfStock === 'boolean' ? 'catalogue' : 'company';

    return {
      experiments: {
        ...data.experiments,
        stockManagedFrom,
        orderOnOOS
      },
      companySettings
    };
  } else {
    return defaultSettings;
  }
};

const changeCatalogueSettings = ({ catalogueId, changes }) => {
  const key = `${connector.CATALOGUE_SETTINGS.cacheKey}${catalogueId}`;
  const cache = CacheRequest.getCacheForKey(key);
  const cacheUpdates = {};

  if (typeof changes.visitorsEnabled !== 'undefined') {
    cacheUpdates.visitorsEnabled = changes.visitorsEnabled;
  }

  if (typeof changes.visitorIdentity !== 'undefined') {
    cacheUpdates.visitorIdentity = changes.visitorIdentity;
  }

  if (typeof changes.otpVerification !== 'undefined') {
    cacheUpdates.otpVerification = changes.otpVerification;
  }

  const newCache = {
    ...cache,
    experiments: {
      ...cache.experiments,
      ...cacheUpdates
    }
  };

  CacheRequest.setCacheForKey(key, newCache);
  return Api.changeCatalogueSettings({ catalogueId, changes });
};

const changeOutOfStockVisibility = ({ catalogueId, outOfStockVisibility }) => {
  try {
    const key = `${connector.CATALOGUE_SETTINGS.cacheKey}${catalogueId}`;
    const cache = CacheRequest.getCacheForKey(key);
    if (typeof outOfStockVisibility !== 'undefined') {
      let newExperiments = { ...cache.experiments };
      newExperiments.stockVisibility = outOfStockVisibility;
      if (typeof outOfStockVisibility !== 'boolean') {
        newExperiments.stockManagedFrom = 'company';
      }

      const newCache = {
        ...cache,
        experiments: newExperiments
      };

      CacheRequest.setCacheForKey(key, newCache);
    }

    return Api.changeOutOfStockVisibility({ catalogueId, outOfStockVisibility });
  } catch (err) {
    console.log('err', err);
    Sentry.captureException(err);
  }
};

const changeOrderOnOutOfStock = ({ catalogueId, allowOrderOnOutOfStock }) => {
  try {
    const key = `${connector.CATALOGUE_SETTINGS.cacheKey}${catalogueId}`;
    const cache = CacheRequest.getCacheForKey(key);
    if (typeof allowOrderOnOutOfStock !== 'undefined') {
      let newExperiments = { ...cache.experiments };
      newExperiments.orderOnOutOfStock = allowOrderOnOutOfStock;
      if (typeof allowOrderOnOutOfStock !== 'boolean') {
        newExperiments.orderOnOOS = 'company';
      }

      const newCache = {
        ...cache,
        experiments: newExperiments
      };

      CacheRequest.setCacheForKey(key, newCache);
    }
    return Api.changeOrderOnOutOfStock({ catalogueId, allowOrderOnOutOfStock });
  } catch (err) {
    console.log('err', err);
    Sentry.captureException(err);
  }
};

const getAndCreateCatalogueLink = ({ catalogueId }) => {
  if (!catalogueId) {
    return;
  }

  const key = `${connector.CATALOGUE_LINK.cacheKey}${catalogueId}`;
  const linkMeta = CacheRequest.getCacheForKey(key) || null;
  if (linkMeta) {
    const { linkDomain, companySlug, catalogueSlug, randomSlug } = linkMeta;
    return `${linkDomain}/${companySlug}/${catalogueSlug}/${randomSlug}`;
  }

  if (SHARE_LINK_PROMISE[catalogueId]) {
    return SHARE_LINK_PROMISE[catalogueId];
  }

  createCataloguesLink([catalogueId]);
};

const getCatalogueLinkFromCache = catalogueId => {
  if (!catalogueId) {
    return '';
  }

  if (SHARE_LINK_PROMISE[catalogueId]) {
    return SHARE_LINK_PROMISE[catalogueId];
  }

  const key = `${connector.CATALOGUE_LINK.cacheKey}${catalogueId}`;
  const linkMeta = CacheRequest.getCacheForKey(key) || null;

  if (!linkMeta) {
    return '';
  }

  const { linkDomain, companySlug, catalogueSlug, randomSlug } = linkMeta;
  return `${linkDomain}/${companySlug}/${catalogueSlug}/${randomSlug}`;
};

const getCompanySettings = async () => {
  const resp = await Api.getCompanySettings();
  setCompanySettingsInCache({ data: resp });
  return resp;
};

const isCatalogueSelected = catalogueId => {
  const activeCatalogueId = ACTIVE_CATALOGUE_META.catalogueId
    ? ACTIVE_CATALOGUE_META.catalogueId
    : '';

  return catalogueId === activeCatalogueId;
};

const getCatalogueStock = async ({ catalogueId }) => {
  const catalogueSettingsCacheKey = `${connector.CATALOGUE_SETTINGS.cacheKey}${catalogueId}`;

  const data = await Api.getCatalogueSettings(catalogueId);
  CacheRequest.setCacheForKey(catalogueSettingsCacheKey, data);
  const stockManagedFrom =
    typeof data.experiments.stockVisibility === 'boolean' ? 'catalogue' : 'company';

  if (stockManagedFrom === 'catalogue') {
    const data = CacheRequest.getCacheForKey(catalogueSettingsCacheKey);
    if (typeof data.experiments.stockVisibility === 'boolean') {
      return data.experiments.stockVisibility;
    }
  }

  return await getCompanyStockSetting();
};

const getCatalogueOrderOnOOS = async ({ catalogueId }) => {
  const catalogueSettingsCacheKey = `${connector.CATALOGUE_SETTINGS.cacheKey}${catalogueId}`;

  const data = await Api.getCatalogueSettings(catalogueId);
  CacheRequest.setCacheForKey(catalogueSettingsCacheKey, data);
  const orderOnOOSManagedFrom =
    typeof data.experiments.orderOnOutOfStock === 'boolean' ? 'catalogue' : 'company';

  if (orderOnOOSManagedFrom === 'catalogue') {
    const data = CacheRequest.getCacheForKey(catalogueSettingsCacheKey);
    if (typeof data.experiments.orderOnOutOfStock === 'boolean') {
      return data.experiments.orderOnOutOfStock;
    }
  }

  return await getCompanyOrderOnOOS();
};

const getMixpanelCatalogueProps = ({ catalogueId }) => {
  const props = {};
  const catalogueCacheKey = `${connector.CATALOGUE_ROW_META.cacheKey}${catalogueId}`;
  const catalogueCache = CacheRequest.getCacheForKey(catalogueCacheKey);

  props.catalogue_product_count = catalogueCache.productCount;
  props.catalogue_title = catalogueCache.title;

  return props;
};

const computeUniqueSortedIds = ({ catalogueIdsMeta }) => {
  const uniqueCatalogueIds = {};
  catalogueIdsMeta.forEach(row => {
    uniqueCatalogueIds[row.catalogueId] = uniqueCatalogueIds[row.catalogueId]
      ? uniqueCatalogueIds[row.catalogueId] > row.sortTimestamp
        ? uniqueCatalogueIds[row.catalogueId]
        : row.sortTimestamp
      : uniqueCatalogueIds[row.catalogueId];
  });

  return Object.keys(uniqueCatalogueIds)
    .map(id => ({
      catalogueId: id,
      sortTimestamp: uniqueCatalogueIds[id]
    }))
    .sort((cat1, cat2) => cat2.sortTimestamp - cat1.sortTimestamp)
};

export default {
  OPERATION_STATUS: CacheRequest.OPERATION_STATUS,
  attachCatalogueIdsListener,
  removeCatalogueIdsListener,
  getAllCatalogueIds,
  attachCatalogueMetaListener,
  removeAttachCatalogueMetaListener,
  getCatalogueMeta,
  attachCatalogueTagsListener,
  removeCatalogueTagsListener,
  getCatalogueTags,
  attachCatalogueSettingsListener,
  removeCatalogueSettingsListener,
  getCatalogueSettings,
  attachCatalogueLinkListener,
  removeCatalogueLinkListener,
  createCataloguesLink,
  getCatalogueLinkFromRemote,

  getCatalogueMetaFromCache,
  getCatalogueCountFromCache,
  deleteCatalogues,
  getCatalogueIdsFromCache,
  changeCatalogueTitle,
  getCatalogueTitle,
  searchCatalogueIds,
  createNewCatalogue,
  createCatalogueFromFolder,
  setActiveCatalogueId,
  getActiveCatalogueId,
  getLastFetchDate,
  getAllUnselectedTags,
  getCatalogueSettingsFromCache,
  changeCatalogueSettings,
  changeOutOfStockVisibility,
  changeOrderOnOutOfStock,
  getAndCreateCatalogueLink,
  getCatalogueLinkFromCache,
  getCompanySettings,
  isCatalogueSelected,
  handleCatalogueChangeListener,
  setLastFetchDate,
  getCatalogueStock,
  getCatalogueOrderOnOOS,
  getMixpanelCatalogueProps,
  computeUniqueSortedIds
};
