import React from 'react';

import './styles.scss';

export default () => {
  return (
    <div id={'ShimmerContainer'}>
      <div className={'circle circle4'}></div>
      <div className={'circle circle3'}></div>
      <div className={'circle circle2'}></div>
      <div className={'circle circle1'}></div>

      <div className={'react titleRect'}></div>
      <div className={' react productCountRect'}></div>
    </div>
  );
};
