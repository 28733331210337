import Listener from 'qs-helpers/Listener';
import {
  setConfigInCache,
  setCountryConfigInCache,
  setExperimentsInCache
} from 'qs-data-manager/Config';

const listener = new Listener();
const END_POINT = {
  CONFIG_TRANSLATE: () => 'config/translationInviteLink',
  CONFIG_PARTNER: () => 'config/partnerLink',
  CONFIG_NEW_UPDATES: () => 'config/newUpdatesLink',
  CONFIG_KNOWLEDGE_GUIDE: () => 'config/knowledgeGuideLink',
  COUNTRY_SETTINGS: ({ countryCode }) => `country-specific-settings/${countryCode}`,
  EXPERIMENTS: () => `experiments`
};

const attachConfigListener = () => {
  Promise.all([
    attachConfigTranslateListener()
      .then(snapshot => ({ translationInviteLink: snapshot.val() }))
      .catch(() => ({ translationInviteLink: '' })),
    attachConfigPartnerListener()
      .then(snapshot => ({ partnerLink: snapshot.val() }))
      .catch(() => ({ partnerLink: '' })),
    attachConfigNewUpdatesListener()
      .then(snapshot => ({ newUpdates: snapshot.val() }))
      .catch(() => ({ newUpdates: '' })),
    attachConfigKnowledgeGuideListener()
      .then(snapshot => ({ knowledgeGuide: snapshot.val() }))
      .catch(() => ({ knowledgeGuide: '' }))
  ]).then(values => {
    const linkConfigs = values.reduce((accumalatedObj, currVal) => {
      return { ...accumalatedObj, ...currVal };
    }, {});
    setConfigInCache(linkConfigs);
  });
};

const attachConfigTranslateListener = () => {
  const key = END_POINT.CONFIG_TRANSLATE();
  return listener.once({ key, type: 'value' });
};

const attachConfigPartnerListener = () => {
  const key = END_POINT.CONFIG_PARTNER();
  return listener.once({ key, type: 'value' });
};

const attachConfigNewUpdatesListener = () => {
  const key = END_POINT.CONFIG_NEW_UPDATES();
  return listener.once({ key, type: 'value' });
};

const attachConfigKnowledgeGuideListener = () => {
  const key = END_POINT.CONFIG_KNOWLEDGE_GUIDE();
  return listener.once({ key, type: 'value' });
};

const attachCountryFirebaseOnceListener = ({ countryCode }) => {
  if (!countryCode) {
    return;
  }

  attachCountryListener({ countryCode });
};

const attachCountryListener = ({ countryCode }) => {
  const key = END_POINT.COUNTRY_SETTINGS({ countryCode });
  listener
    .once({ key, type: 'value' })
    .then(snapshot => {
      setCountryConfigInCache({ data: snapshot.val() });
    })
    .catch(error => setCountryConfigInCache({ error }));
};

const attachExperimentsOnceListenerFirebase = () => {
  const key = END_POINT.EXPERIMENTS();
  listener
    .once({ key, type: 'value' })
    .then(snapshot => {
      setExperimentsInCache({ data: snapshot.val() });
    })
    .catch(error => setExperimentsInCache({ error }));
};

export {
  attachConfigListener,
  attachCountryFirebaseOnceListener,
  attachExperimentsOnceListenerFirebase
};
