import React, { useState, useCallback, useEffect } from 'react';
import TagsMenu from './TagsMenu';
import NonVirtualisedList from 'qs-components/Common/NonVirtualisedList';
import CatalogueTagReorderModal from './CatalogueTagReorderModal';
import CatalogueLib from 'qs-data-manager/Catalogues';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import { isWebkitBrowser } from 'qs-helpers/index';
import Tag from 'qs-data-manager/Tags';

import './styles.scss';
import { ReactComponent as EditIcon } from 'qs-assets/Media/pencil.svg';

export default ({ activeCatalogueId: catalogueId }) => {
  const [tagsState, setTagState] = useState({
    loading: false,
    refreshing: false,
    err: null,
    sortedTags: null
  });
  const [selectedCount, setSelectedCount] = useState(0);
  const [tagCountVisible, setTagCountVisible] = useState(false);
  const [editModalActive, setEditModalActive] = useState(false);

  const catalogueTagListener = useCallback((error, payload) => {
    const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

    if (err) {
      setTagState(prevState => ({
        ...prevState,
        err,
        loading
      }));

      return;
    }

    if (loading) {
      setTagState(prevState => ({
        ...prevState,
        loading,
        refreshing,
        err
      }));

      return;
    }

    const { tags } = data;
    const sortedTags = Object.keys(tags)
      .sort((t1, t2) => {
        if (tags[t1].v === tags[t2].v) {
          return tags[t1].p - tags[t2].p;
        }

        return tags[t2].v ? 1 : -1;
      })
      .map(tag => tags[tag]);

    setTagState({
      sortedTags,
      loading,
      refreshing,
      err
    });
  }, []);

  useEffect(() => {
    CatalogueLib.attachCatalogueTagsListener(catalogueTagListener, catalogueId);
    CatalogueLib.getCatalogueTags(catalogueId);

    return () => CatalogueLib.removeCatalogueTagsListener(catalogueId, catalogueId);
  }, [catalogueId, catalogueTagListener]);

  const onEditPress = useCallback(e => {
    e.stopPropagation();
    setEditModalActive(prevState => !prevState);
  }, []);

  const onClearTagsPress = useCallback(() => {
    setSelectedCount(0);
    Tag.clearSelectedTags();
  }, []);

  const onEditModalClose = useCallback(e => {
    e.stopPropagation();
    setEditModalActive(prevState => !prevState);
  }, []);

  const onToggleTag = useCallback(
    (tagId, prevTagState) => {
      Tag.onTagClick(tagId, catalogueId);
      setSelectedCount(prevState => (prevTagState ? prevState - 1 : prevState + 1));
    },
    [catalogueId]
  );

  const renderItem = useCallback(
    ({ item: tag }) => {
      const isSelected = Tag.isTagSelected(tag.title);

      return (
        <button
          disabled={!tag.v}
          className={`tag ${isSelected ? 'selectedTag' : ''} ${!tag.v ? 'tagNotVisible' : ''}`}
          onClick={() => onToggleTag(tag.title, isSelected)}
        >
          <span className={`tagText ${!tag.v && 'inactiveTagText'}`}>
            {tag.title}
            {!!tagCountVisible && !!tag.productCount ? `(${tag.productCount})` : ''}
          </span>
        </button>
      );
    },
    [tagCountVisible, onToggleTag]
  );

  const keyExtractor = useCallback(item => item.title, []);

  if (!tagsState.sortedTags || !tagsState.sortedTags.length) {
    return null;
  }

  return (
    <div className="catalogueTagsSelector">
      <div className="tagsContainer">
        <div className="editButtonContainer">
          <button className="editButton" onClick={onEditPress}>
            <EditIcon fill="#4BC68C" />
          </button>
        </div>

        <NonVirtualisedList
          data={tagsState.sortedTags}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          contentContainerClassName={'tagsList'}
          contentContainerStyle={{ overflow: isWebkitBrowser ? 'auto' : 'hidden' }}
          ItemSeparatorComponent={() => <div className="tagSeparator" />}
          ListHeaderComponent={() => <div className="tagSeparator" />}
          ListFooterComponent={() => (
            <div style={{ width: tagsState.sortedTags.length > 2 ? 85 : 10 }} />
          )}
        />

        {tagsState.sortedTags.length > 2 && (
          <TagsMenu
            sortedTags={tagsState.sortedTags}
            tagCountVisible={tagCountVisible}
            onToggleTag={onToggleTag}
          />
        )}
      </div>

      {!!selectedCount && (
        <div className="clearSelectedContainer">
          <div className="clearSelectedButton" onClick={onClearTagsPress}>
            <span className="clearSelectedText">
              Clear {selectedCount} catalogue {selectedCount === 1 ? 'filter' : 'filters'}
            </span>
          </div>
        </div>
      )}

      {!!editModalActive && (
        <CatalogueTagReorderModal
          open={editModalActive}
          onClose={onEditModalClose}
          tags={tagsState.sortedTags}
          catalogueId={catalogueId}
        />
      )}
    </div>
  );
};
