import React, { useState, useEffect, useCallback } from 'react';
import CheckBox from 'qs-components/Common/CheckBox';
import { selectedCatalogue } from 'qs-data-manager/Selected';
import CatalogueLib from 'qs-services/DataManager/Catalogues';

import { ReactComponent as DeleteIcon } from 'qs-assets/Media/trash.svg';
import './styles.scss';

export default ({ onBackClick }) => {
  const [checkbox, setCheckbox] = useState(false);
  const [deleteIconVisibility, setDeleteIconVisibility] = useState(false);

  useEffect(() => {
    selectedCatalogue.addListener(onSelectedStateChange);
    return () => {
      selectedCatalogue.removeListener(onSelectedStateChange);
    };
  }, []);

  const onSelectedStateChange = useCallback(() => {
    const areAllSelected = selectedCatalogue.areAllSelected();
    const isAnySelected = selectedCatalogue.isAnySelected();

    setCheckbox(areAllSelected);
    setDeleteIconVisibility(isAnySelected);
  }, []);

  const onCheckBoxToggle = () => {
    const newValue = !checkbox;
    setCheckbox(newValue);

    if (newValue !== deleteIconVisibility) {
      setDeleteIconVisibility(newValue);
    }

    if (newValue) {
      selectedCatalogue.setAll();
    } else {
      selectedCatalogue.removeAll();
    }
  };

  const deactivateSelectionState = () => {
    selectedCatalogue.deactivate();
  };

  const goBack = () => {
    deactivateSelectionState();
    onBackClick();
  };

  const onDeleteClick = () => {
    const shouldRemove = window.confirm('Are you sure you want to delete the selected catalogues?');

    if (shouldRemove) {
      const ids = Object.keys(selectedCatalogue.getAll());
      CatalogueLib.deleteCatalogues(ids);
      goBack();
    }
  };

  return (
    <div id={'EditCatalogueHeader'}>
      <div className={'selectAllContainer'}>
        <CheckBox checked={checkbox} onCheckChanged={onCheckBoxToggle} />
        <div onClick={onCheckBoxToggle} className={'title'}>
          {!!checkbox ? 'Deselect all' : 'Select all'}
        </div>
      </div>
      <div className={'rightContainer'}>
        {!!deleteIconVisibility ? (
          <DeleteIcon onClick={onDeleteClick} id={'deleteIcon'} />
        ) : (
          <div onClick={goBack} className={'back'}>
            Back
          </div>
        )}
      </div>
    </div>
  );
};
