import React, { Fragment, useState, useRef } from 'react';
import { getCompanyCurrencySymbol } from 'qs-data-manager/Company';
import Input from '../../../Common/Input';
import './SetPrice.scss';
import { isInputNumber, roundNumberToGivenDecimals } from 'qs-helpers';

export default props => {
  let { price, discountedPrice } = props;
  if (price === undefined || price === null) {
    price = '';
  }
  if (discountedPrice === undefined || discountedPrice === null) {
    discountedPrice = '';
  }

  const [actualPrice, setActualPrice] = useState(price);
  const [actualPriceError, setActualPriceError] = useState('');
  const [inputDiscountedPrice, setDiscountedPrice] = useState(discountedPrice);
  const [discountedPriceError, setDiscountedPriceError] = useState('');
  const currencySymbol = useRef(getCompanyCurrencySymbol());

  const handleActualPriceChange = function(event) {
    event.preventDefault();
    setActualPrice(event.target.value);
    setActualPriceError('');
  };

  const handleDiscountedPriceChange = function(event) {
    event.preventDefault();
    setDiscountedPrice(event.target.value);
    setDiscountedPriceError('');
  };

  const validatePrice = function(priceVal) {
    let number, error='';

    //The state is set as blank, hence this is the only invalid value
    if (priceVal !== '') {
      if (isInputNumber(priceVal)) {
        number = Number(priceVal);
        if (number < 0) {
          error = 'Original Price can not be a negative number';
        }
      } else {
        error = 'Invalid Value';
      }
    }

    return { number, error };
  };

  const handleSetPrice = function(event) {
    event.preventDefault();

    const actualPriceProps = validatePrice(actualPrice);
    const numActualPrice = actualPriceProps.number,
      numActualPriceError = actualPriceProps.error;
    setActualPriceError(numActualPriceError);

    const discountedPriceProps = validatePrice(inputDiscountedPrice);
    const numDiscountedPrice = discountedPriceProps.number,
      numDiscountedPriceError = discountedPriceProps.error;
    setDiscountedPriceError(numDiscountedPriceError);

    //Both numbers are valid, validate further
    if (!numActualPriceError && !numDiscountedPriceError) {
      /*
        discountedPrice is not set, hence actual price can not be
        less than the discountedPrice. Hence the API call can be made
        directly. If present, validate if the actual price has been set
        and is greater than the discounted price
      */
      if (typeof numDiscountedPrice === 'number') {
        /*
          Actual price exists and discounted price exists,
          validate if the actual price is more
        */
        if (typeof numActualPrice === 'number') {
          if (numActualPrice < numDiscountedPrice) {
            setDiscountedPriceError('Discounted price can not be more than the original price');
            return;
          }
        } else {
          // Discounted price is present but actual price is unset, show the error
          setActualPriceError('Price is required when discounted price is set');
          return;
        }
      }

      //Everything valid, proceed
      props.onComplete(
        roundNumberToGivenDecimals(numActualPrice, 2),
        roundNumberToGivenDecimals(numDiscountedPrice, 2)
      );
    }
  };

  const handleCancel = function(event) {
    event.preventDefault();
    if (typeof props.onCancel === 'function') {
      props.onCancel();
    }
  };

  return (
    <Fragment>
      <h2 className="price-title">Edit Price</h2>
      <form>
        <div className="input--container">
          <Input
            label={'Original Price'}
            inputId="ogPrice"
            icon={<span className="price-icon">{currencySymbol.current}</span>}
            type="tel"
            value={actualPrice}
            onChange={handleActualPriceChange}
            errorMsg={actualPriceError}
          />
        </div>
        <div className="input--container">
          <Input
            label={'Discounted Price'}
            inputId="discountPrice"
            icon={<span className="price-icon">{currencySymbol.current}</span>}
            type="tel"
            value={inputDiscountedPrice}
            onChange={handleDiscountedPriceChange}
            errorMsg={discountedPriceError}
          />
        </div>
        <div className="price-button-container">
          <button className="price-button" type="button" onClick={handleCancel}>
            CANCEL
          </button>
          <button
            className="price-button price-button--submit"
            type="submit"
            onClick={handleSetPrice}
          >
            SET PRICE
          </button>
        </div>
      </form>
    </Fragment>
  );
};
