import React, { useCallback, useState, useEffect } from 'react';

import './styles.scss';
import eventbus from 'eventing-bus';
import { SAVE_BUTTON_META, bulkSavePrice } from 'qs-data-manager/ProductDetails';

export default ({
  price,
  informDiscountOfPriceChange,
  isBulkEditing,
  activeProductIds,
  currencySymbol
} = {}) => {
  const [priceState, setPriceState] = useState({
    modifiedPrice: price ? `${price}` : '',
    showSaveButton: false
  });

  useEffect(() => {
    setPriceState({
      modifiedPrice: price ? `${price}` : '',
      showSaveButton: false
    });
  }, [price, isBulkEditing]);

  useEffect(() => {
    if(isBulkEditing) {
      setPriceState(prevState => ({
        ...prevState,
        modifiedPrice: '',
        showSaveButton: false
      }));
    }
  }, [(activeProductIds || []).length, isBulkEditing]);

  const onPriceChange = useCallback(
    e => {
      e.stopPropagation();
      const value = e.target.value;

      if (typeof Number(value) !== 'number' || isNaN(Number(value))) {
        return;
      }

      const hasChanged = (price ? `${price}` : '') !== value;

      const showSaveButton = isBulkEditing && hasChanged;

      const valueInNumber = value ? Math.round(Number(value) * 100) / 100 : null;

      setPriceState(prevState => ({
        ...prevState,
        modifiedPrice: value,
        showSaveButton
      }));

      informDiscountOfPriceChange({ newPrice: valueInNumber });

      if (!isBulkEditing) {
        eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
          id: SAVE_BUTTON_META.PRODUCT_PRICE.id,
          hasChanged,
          data: valueInNumber,
          eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id,
        });
      }
    },
    [price, isBulkEditing, informDiscountOfPriceChange]
  );

  const onSaveClick = useCallback(() => {
    const price =
      priceState.modifiedPrice &&
      typeof Number(priceState.modifiedPrice) === 'number' &&
      !isNaN(Number(priceState.modifiedPrice))
        ? Number(priceState.modifiedPrice)
        : null;

    bulkSavePrice({ productIds: activeProductIds, price }).then(() => {
      setPriceState(prevState => ({
        ...prevState,
        showSaveButton: false
      }));
    });
  }, [priceState.modifiedPrice, activeProductIds]);

  return (
    <div id={'PriceContainer'}>
      <div className={'rowContainer'}>
        <div className={'rowHeading'}>Price</div>
        <div className={'inputContainer priceInputContainer'}>
          <div className={'currencySymbol'}>{currencySymbol}</div>
          <input
            onChange={onPriceChange}
            value={priceState.modifiedPrice}
            className={`input ${priceState.showSaveButton ? 'reducedWidth' : ''}`}
          />
          {!!priceState.showSaveButton ? (
            <div className={'saveButton'} onClick={onSaveClick}>
              save
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

// TODO: discount is fucked if price changes and backend is not handling it
