import Api from '../Api';
// NOTE: if CacheRequest should not store data in native then specify *shouldNotStoreInNative* key in object

const CATALOGUE_LIST_SCREEN = {
  CATALOGUE_LIST_META: {
    cacheKey: 'CATALOGUE_LIST_META',
    apiFunction: Api.getCatalogueIds,
    nativeStorageKey: 'CATALOGUE_LIST_META'
  },
  CATALOGUE_ROW_META: {
    cacheKey: 'CATALOGUE_ROW_META',
    apiFunction: Api.getBatchedCatalogueMeta,
    nativeStorageKey: 'CATALOGUE_ROW_META'
  },
  CATALOGUE_TAGS: {
    cacheKey: 'CATALOGUE_TAGS',
    apiFunction: Api.getCatalogueTagsV2,
    nativeStorageKey: 'CATALOGUE_TAGS'
  },
  CATALOGUE_LINK: {
    cacheKey: 'CATALOGUE_LINK',
    apiFunction: Api.createCataloguesLink
  },
  CATALOGUE_SETTINGS: {
    cacheKey: 'CATALOGUE_SETTINGS',
    apiFunction: Api.getCatalogueSettings
  }
};

const PRODUCT_LIST_SCREEN = {
  PRODUCT_LIST_META: {
    cacheKey: 'PRODUCT_LIST_META',
    apiFunction: Api.getProductList,
    nativeStorageKey: 'PRODUCT_LIST_META'
  },
  PRODUCT_ROW_META: {
    cacheKey: 'PRODUCT_ROW_META',
    apiFunction: Api.getBatchedProductList,
    nativeStorageKey: 'PRODUCT_ROW_META'
  }
};

const PRODUCT_DETAILS_SCREEN = {
  PRIVATE_NOTES: {
    cacheKey: 'PRIVATE_NOTES',
    apiFunction: Api.getPrivateNotes
  },
  BASIC_INFO: {
    cacheKey: 'BASIC_INFO',
    apiFunction: Api.getBasicInfo
  },
  INVENTORY: {
    cacheKey: 'INVENTORY',
    apiFunction: Api.getInventory
  }
};

const COMPANY_META = {
  COMPANY_SETTINGS: {
    cacheKey: 'COMPANY_SETTINGS',
    apiFunction: Api.getCompanySettings
  },
  COMPANY_META: {
    cacheKey: 'COMPANY_META'
  },
  COMPANY_SETTINGS_PLAN: {
    cacheKey: 'COMPANY_SETTINGS_PLAN',
    apiFunction: Api.getCompanyPlanSettings
  },
  COMPANY_SETTINGS_PRODUCT_DEMO: {
    cacheKey: 'COMPANY_SETTINGS_PRODUCT_DEMO',
    apiFunction: Api.getCompanyProductSettings
  },
  COMPANY_SETTINGS_FIREBASE: {
    cacheKey: 'COMPANY_SETTINGS_FIREBASE'
  },
  COMPANY_SHARE_TEXT_FIREBASE: {
    cacheKey: 'COMPANY_SHARE_TEXT_FIREBASE'
  }
};

const USER_META = {
  USER_META: {
    cacheKey: 'USER_META'
  }
};

const CONFIG = {
  CONFIG_DATA: {
    cacheKey: 'CONFIG_DATA'
  },
  CONFIG_PRODUCT_DEMO: {
    cacheKey: 'CONFIG_PRODUCT_DEMO',
    apiFunction: Api.getConfigDemoLink
  },
  CONFIG_COUNTRY_CODE: {
    cacheKey: 'CONFIG_COUNTRY_CODE',
    apiFunction: Api.getCountryCode
  },
  CONFIG_COUNTRY: {
    cacheKey: 'CONFIG_COUNTRY'
  },
  CONFIG_EXPERIMENTS: {
    cacheKey: 'CONFIG_EXPERIMENTS'
  }
};

export const connector = {
  ...CATALOGUE_LIST_SCREEN,
  ...PRODUCT_LIST_SCREEN,
  ...PRODUCT_DETAILS_SCREEN,
  ...COMPANY_META,
  ...USER_META,
  ...CONFIG
};
