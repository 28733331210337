import React from 'react';
import './styles.scss';

export default () => {
  return (
    <div id={'EmptyProductDetailsScreen'}>
      Select a product from the products list to view the product details
    </div>
  );
};
