const selectedContacts = new Set();

const onContactSelect = (contact, operation) => {
  if (operation) {
    selectedContacts.add(contact);
  } else if (selectedContacts.has(contact) && !operation) {
    selectedContacts.delete(contact);
  }
};
const getSelectedContactsLength = () => selectedContacts.size;
const isContactSelected = contact => selectedContacts.has(contact);
const onSelectAll = (areAllSelected, contacts) => {
  if (areAllSelected) {
    contacts.forEach(({ phone }) => {
      selectedContacts.add(phone);
    });
  } else {
    selectedContacts.clear();
  }
  return { isEditing: selectedContacts.size };
};
const getAllSelectedContacts = () => ({ contacts: [...selectedContacts] });

export default {
  onContactSelect,
  getSelectedContactsLength,
  isContactSelected,
  onSelectAll,
  getAllSelectedContacts
};
