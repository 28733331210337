import React, { useEffect, useState } from 'react';
import { getImageUrls, RENDERED_PRODUCT_IMAGE_META } from 'qs-data-manager/ProductDetails';
import eventbus from 'eventing-bus';
import Loader from 'qs-components/Common/Loader';
import { convertImageUrlSize } from 'qs-helpers';

import './styles.scss';

const MARGIN_TOP = 50;

export default ({ activeProductIds, activeProductId, isBulkEditing } = {}) => {
  const [height, setHeight] = useState(() => {
    let height = document.getElementById('ProductDetailsScreen').offsetHeight;
    if (isBulkEditing) {
      height = document.getElementById('ProductDetailsScreen').offsetHeight;
      height = height / 2;
      height = height + MARGIN_TOP;
    } else {
      height = document.getElementById('ProductDetailsScreen').offsetHeight;
      height = height / 2;
      height = height - MARGIN_TOP;
    }

    return height;
  });

  const [pictureUrls, setPictureUrls] = useState(() =>
    getImageUrls({ isBulkEditing, activeProductId, activeProductIds })
  );

  // Effect when user wants to see a different product image
  useEffect(() => {
    const removeEventbus = eventbus.on(RENDERED_PRODUCT_IMAGE_META.eventbusKey, ({ imageUrl }) => {
      setPictureUrls([imageUrl]);
    });
    return () => removeEventbus();
  }, []);

  // Effect when activeProductIds change
  useEffect(() => {
    const urls = getImageUrls({ isBulkEditing, activeProductId, activeProductIds });
    setPictureUrls(urls);
  }, [activeProductId, isBulkEditing, (activeProductIds || []).length]);

  useEffect(() => {
    let height = document.getElementById('ProductDetailsScreen').offsetHeight;
    if (isBulkEditing) {
      height = height / 2;
      height = height + MARGIN_TOP;
    } else {
      height = height / 2;
      height = height - MARGIN_TOP;
    }
    setHeight(height);
  }, [isBulkEditing]);

  if (isBulkEditing) {
    return (
      <div id={'multipleProductSelectedImages'} style={{ height: `${height}px` }}>
        <div className={'selectedItemsHeader'}>{activeProductIds.length} items selected</div>

        <div className={'imageContainer'}>
          {pictureUrls.map(fillSizeUrl => {
            const url = convertImageUrlSize({ size: 100, url: fillSizeUrl });
            return (
              <div className={'bulkImageContainer'}>
                <div className={'loader'}>
                  <Loader size={'small'} />
                </div>
                <div
                  style={{ backgroundImage: `url(${url})` }}
                  className={'image imageAspectRatio bulkBlurredImage'}
                />
                <div
                  style={{ backgroundImage: `url(${url})` }}
                  className={'image imageAspectRatio nonBlurredBulkImage'}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={'ProductImageLoaderContainer'}>
        <Loader size={'large'} />
      </div>
      <div
        className="ProductImage blurredImage"
        style={{ backgroundImage: `url(${pictureUrls[0]})`, height: `${height + 100}px` }}
      />
      <div
        className="ProductImage nonBlurredImage"
        style={{
          height,
          backgroundImage: `url(${pictureUrls[0]})`
        }}
      />
    </>
  );
};
