import React, { useState, useEffect, useCallback } from 'react';

import CatalogueProducts from './CatalogueProducts';
import CatalogueTitle from './CatalogueTitle';
import CatalogueProductCount from './CatalogueProductCount';
import Checkbox from 'qs-components/Common/CheckBox';
import { selectedCatalogue } from 'qs-data-manager/Selected.js';
import CatalogueShimmer from './CatalogueShimmer';
import CatalogueLib, {
  setActiveCatalogueId,
  ACTIVE_CATALOGUE_META
} from 'qs-data-manager/Catalogues';
import SkeletonCatalogue from './SkeletonCatalogue';
import eventbus from 'eventing-bus';

import './styles.scss';
import { ReactComponent as DeleteIcon } from 'qs-assets/Media/trash.svg';

export default ({ id, isSkeleton }) => {
  const [editMode, setEditMode] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [catalogueMeta, setCatalogueMeta] = useState({
    meta: CatalogueLib.getCatalogueMetaFromCache(id),
    loading: false,
    refreshing: false,
    error: false
  });
  const [isSelected, setIsSelected] = useState(() => CatalogueLib.isCatalogueSelected(id));

  useEffect(() => {
    const removeEventbus = eventbus.on(ACTIVE_CATALOGUE_META.eventbusKey, catalogueId => {
      setIsSelected(id === catalogueId);
    });

    return () => removeEventbus();
  }, [id]);

  const onSelectedValueChange = useCallback(
    (all = {}) => {
      const selectionState = all[id] || false;
      setCheckboxValue(selectionState);
    },
    [id]
  );

  const onSelectionStateChange = useCallback(isActive => {
    setEditMode(isActive);
  }, []);

  const metaListener = useCallback((err, { status, data } = {}) => {
    const updates = {};
    if (err) {
      updates.loading = false;
      updates.error = err;
    } else {
      switch (status) {
        case CatalogueLib.OPERATION_STATUS.LOADING: {
          updates.loading = true;
          updates.refreshing = false;
          updates.error = null;
          break;
        }
        case CatalogueLib.OPERATION_STATUS.REFRESHING: {
          updates.loading = false;
          updates.refreshing = true;
          updates.error = null;
          updates.meta = data || {};
          break;
        }
        case CatalogueLib.OPERATION_STATUS.SUCCESS: {
          updates.loading = false;
          updates.refreshing = false;
          updates.error = null;
          updates.meta = data || {};
          break;
        }
        case CatalogueLib.OPERATION_STATUS.UPDATE: {
          updates.loading = false;
          updates.refreshing = false;
          updates.error = null;
          updates.meta = data || {};
          break;
        }
        default:
      }

      setCatalogueMeta(prevState => ({ ...prevState, ...updates }));
    }
  }, []);

  useEffect(() => {
    CatalogueLib.attachCatalogueMetaListener(metaListener, id);
    selectedCatalogue.addActiveListener(onSelectionStateChange);
    selectedCatalogue.addListener(onSelectedValueChange);

    return () => {
      selectedCatalogue.removeActiveListener(onSelectionStateChange);
      selectedCatalogue.removeListener(onSelectedValueChange);
      CatalogueLib.removeCatalogueIdsListener(metaListener, id);
    };
  }, [id, onSelectedValueChange, onSelectionStateChange, metaListener]);

  const deleteCatalogue = useCallback(
    e => {
      e.stopPropagation();
      const shouldDeleteCatalogue = window.confirm('Are you sure you want delete this catalogue ?');
      if (shouldDeleteCatalogue) {
        selectedCatalogue.remove(id);
        CatalogueLib.deleteCatalogues([id]);
      }
    },
    [id]
  );

  const toggleCheckbox = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      const newValue = !checkboxValue;

      setCheckboxValue(newValue);

      if (newValue) {
        selectedCatalogue.add(id);
      } else {
        selectedCatalogue.remove(id);
      }
    },
    [checkboxValue, id]
  );

  const onRowClick = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      if (editMode) {
        toggleCheckbox(e);
      } else {
        setActiveCatalogueId(id);
        setIsSelected(true);
      }
    },
    [toggleCheckbox, editMode, id]
  );

  if (isSkeleton) {
    return <SkeletonCatalogue />;
  }

  if (!catalogueMeta.meta.title) {
    return <CatalogueShimmer />;
  }

  return (
    <div onClick={onRowClick} className={`catalogueRow ${isSelected ? 'selectedCatalogue' : ''}`}>
      <div className={'leftContainer'}>
        {!!editMode && (
          <div className={'checkbox'}>
            <Checkbox checked={checkboxValue} onCheckClicked={toggleCheckbox} />
          </div>
        )}
        <CatalogueProducts pictureIds={catalogueMeta.meta.pictureIds} />
        <div className={'titleCountContainer'}>
          <CatalogueTitle title={catalogueMeta.meta.title} catalogueEditMode={editMode} id={id} />
          <CatalogueProductCount productCount={catalogueMeta.meta.productCount} />
        </div>
      </div>
      {!!editMode && (
        <div className={'rightContainer'}>
          <DeleteIcon onClick={deleteCatalogue} className={'deleteIcon'} />
        </div>
      )}
    </div>
  );
};
