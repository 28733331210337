import React, { Component } from 'react';

import Order from 'qs-data-manager/Order';
import RenderSingleOrder from '../RenderSingleOrder/RenderSingleOrder';
import Loader from 'qs-components/Common/Loader';

import { AutoSizer } from 'react-virtualized';
import SectionList from 'react-virtualized-sectionlist';

import './RenderList.css';

const LIST_VIEW_TYPES = {
  SECTION: {
    id: 'SECTION',
    height: 80
  },
  ROW: {
    id: 'ROW',
    height: 88
  },
  ROW_DELETED_BY_VISITOR: {
    id: 'ROW_DELETED_BY_VISITOR',
    height: 107
  },
  SEPARATOR: {
    id: 'SEPARATOR',
    height: 20
  }
};

const ROW_HEIGHT = 105;
const DELETED_BASKET_ROW_HEIGHT = 126;

class RenderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      refreshing: false,
      orders: [],
      didErrored: false,
      error: null
    };
    this.erroredRequests = [];
  }

  UNSAFE_componentWillMount() {
    const { catalogueId, contact } = this.props;

    if (contact) {
      Order.attachContactOrdersListener({ catalogueId, contact }, this.onOrdersChange);
      this.getOrders({ catalogueId, contact });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { catalogueId, contact } = nextProps;
    const { contact: prevContact, catalogueId: prevCatalogueId } = this.props;

    if (contact !== prevContact) {
      Order.removeContactOrdersListener(
        { catalogueId: prevCatalogueId, contact: prevContact },
        this.onOrdersChange
      );
      Order.attachContactOrdersListener({ catalogueId, contact }, this.onOrdersChange);
      if (this.sectionListRef) {
        const listRef = this.sectionListRef.getListRef();
        listRef.scrollToRow(0);
        listRef.recomputeRowHeights(0);
        listRef.forceUpdateGrid();
      }
      this.getOrders({ catalogueId, contact });
    }
  }

  componentWillUnmount() {
    const { catalogueId, contact } = this.props;
    Order.removeContactOrdersListener({ catalogueId, contact }, this.onOrdersChange);
  }

  getOrders = ({ catalogueId, contact }) => {
    Order.getContactOrders({ catalogueId, contact });
  };

  onOrdersChange = (err, { status, data } = {}) => {
    const { catalogueId, contact } = this.props;

    const stateUpdates = {};

    if (err) {
      console.log('GET_ORDERS err', err);
      this.erroredRequests.push({
        type: 'GET_ORDERS',
        params: { catalogueId, contact }
      });

      stateUpdates.loading = false;
      stateUpdates.refreshing = false;
      stateUpdates.didErrored = true;
      stateUpdates.error = err;
    } else {
      switch (status) {
        case Order.OPERATION_STATUS.LOADING: {
          stateUpdates.loading = true;
          break;
        }
        case Order.OPERATION_STATUS.REFRESHING: {
          stateUpdates.loading = false;
          stateUpdates.refreshing = true;
          stateUpdates.orders = data;
          stateUpdates.noOfBaskets = data.length;
          break;
        }
        case Order.OPERATION_STATUS.SUCCESS: {
          stateUpdates.loading = false;
          stateUpdates.refreshing = false;
          stateUpdates.orders = data;
          stateUpdates.noOfBaskets = data.length;
          break;
        }
        case Order.OPERATION_STATUS.UPDATE: {
          stateUpdates.orders = data;
          stateUpdates.noOfBaskets = data.length;

          /*
            No order is present, inform the parent
            An update that returns no order indicates that the
            last order was deleted. Hence inform the parent
            only on update
          */
          if (!stateUpdates.orders || (stateUpdates.orders && !stateUpdates.orders.length)) {
            this.props.noOrderContactSelected()
          }
          break;
        }
        default:
      }
    }

    this.setState(stateUpdates);

    if (typeof stateUpdates.noOfBaskets !== 'undefined') {
      this.props.setNoOfBaskets(stateUpdates.noOfBaskets);
    }

    this.props.isRefreshing(stateUpdates.refreshing);

    if (stateUpdates.orders && this.sectionListRef) {
      const listRef = this.sectionListRef.getListRef();
      listRef.recomputeRowHeights(0);
      listRef.forceUpdateGrid();
    }
  };

  getDataBlob = () => {
    const { orders } = this.state;
    const list = [];
    const formatToComputeHeight = [];

    const { dataBlob, sectionIds } = Order.createSections(
      orders.map(order => ({
        ...order,
        viewType: order.isDeletedByVisitor
          ? LIST_VIEW_TYPES.ROW_DELETED_BY_VISITOR
          : LIST_VIEW_TYPES.ROW
      })),
      'dateUpdated'
    );

    this.sectionIds = sectionIds;

    sectionIds.forEach(sectionId => {
      list.push({ title: sectionId, data: dataBlob[sectionId] });
      formatToComputeHeight.push('title', ...dataBlob[sectionId]);
    });

    this.sections = list;
    this.formatToComputeHeight = formatToComputeHeight;
  };

  renderHeader = ({ title: sectionId, key, style }) => {
    return (
      <div key={key} style={style} className="renderHeader">
        {Order.SECTION_HEADER[sectionId] || sectionId}
      </div>
    );
  };

  renderRow = ({ item, key, style }) => {
    const { seeContactInquiry, contact, selectedOrderId } = this.props;

    return (
      <div key={key} style={style}>
        <RenderSingleOrder
          data={item}
          contact={contact}
          seeContactInquiry={seeContactInquiry}
          selectedOrderId={selectedOrderId}
        />
      </div>
    );
  };

  manageRowHeight = ({ index }) => {
    const singleRowData = this.formatToComputeHeight[index];

    if (singleRowData.isDeletedByVisitor) {
      return DELETED_BASKET_ROW_HEIGHT;
    }

    return ROW_HEIGHT;
  };

  renderList = () => {
    const lengthOfList = this.sections.length;
    if (!!Number(lengthOfList)) {
      return (
        <div className="AutoSizerList">
          <AutoSizer>
            {({ width, height }) => (
              <SectionList
                width={width}
                height={height}
                sections={this.sections}
                sectionHeaderRenderer={this.renderHeader}
                sectionHeaderHeight={47}
                rowHeight={this.manageRowHeight}
                rowRenderer={this.renderRow}
                ref={listRef => (this.sectionListRef = listRef)}
              />
            )}
          </AutoSizer>
        </div>
      );
    }

    return (
      <div
        style={{
          fontSize: 22,
          color: '#748499',
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        There are no inquries to render
      </div>
    );
  };

  render() {
    this.getDataBlob();
    const { loading } = this.state;
    return loading ? (
      <div className="contactOrderLoaderContainer">
        <Loader size="large" />
      </div>
    ) : (
      this.renderList()
    );
  }
}

export default RenderList;
