import React, { useState, useCallback, useEffect } from 'react';
import FilterProductsPopover from './FilterProductsPopover';
import { PRODUCT_SEARCH, resetSearchPriceFilterAndTags } from 'qs-data-manager/Products';
import eventbus from 'eventing-bus';

import { ReactComponent as BackIcon } from 'qs-assets/Media/arrow-left.svg';
import { ReactComponent as CloseIcon } from 'qs-assets/Media/close.svg';
import { ReactComponent as FunnelIcon } from 'qs-assets/Media/funnel.svg';
import './styles.scss';
import Mixpanel from 'qs-data-manager/Mixpanel';
import { getActiveCatalogueId } from 'qs-data-manager/Catalogues';
const DEBOUNCER_META = {
  DEBOUNCER_TIME: 500,
  DEBOUNCED_DATA: null
};
const { PRODUCT_SEARCH_EB_KEY, PRODUCT_SEARCH_STATE, PRODUCT_SEARCH_TERM } = PRODUCT_SEARCH;

export default ({ goBack }) => {
  const [searchText, setSearchText] = useState('');
  const [showPopover, setShowPopover] = useState(false);

  const closeSearch = useCallback(() => {
    eventbus.publish(PRODUCT_SEARCH_EB_KEY, {
      type: PRODUCT_SEARCH_STATE,
      value: false
    });
  }, []);

  useEffect(() => {
    eventbus.publish(PRODUCT_SEARCH_EB_KEY, {
      type: PRODUCT_SEARCH_STATE,
      value: true
    });

    const catalogueId = getActiveCatalogueId();
    Mixpanel.sendEvent({
      eventName: 'search_catalogue_product_opened',
      props: {
        catalogue_id: catalogueId
      }
    });

    return () => {
      closeSearch();
    };
  }, [closeSearch]);

  const onClear = useCallback(() => {
    const value = '';
    setSearchText(value);
    eventbus.publish(PRODUCT_SEARCH_EB_KEY, {
      type: PRODUCT_SEARCH_TERM,
      value
    });
  }, []);

  const backIconClicked = useCallback(() => {
    onClear();
    closeSearch();
    goBack();
    resetSearchPriceFilterAndTags();
  }, [goBack, onClear, closeSearch]);

  const onSearchTextChange = useCallback(e => {
    e.stopPropagation();
    const value = e.target.value;
    setSearchText(value);

    if (DEBOUNCER_META.DEBOUNCED_DATA) {
      clearTimeout(DEBOUNCER_META.DEBOUNCED_DATA);
    }

    DEBOUNCER_META.DEBOUNCED_DATA = setTimeout(() => {

      const catalogueId = getActiveCatalogueId();
      Mixpanel.sendEvent({
        eventName: 'search_catalogue_product_opened',
        props: {
          catalogue_id: catalogueId,
          query: value,
        }
      });

      eventbus.publish(PRODUCT_SEARCH_EB_KEY, {
        type: PRODUCT_SEARCH_TERM,
        value
      });
    }, DEBOUNCER_META.DEBOUNCER_TIME);
  }, []);

  const togglePopover = useCallback(() => {
    setShowPopover(prevState => !prevState);
  }, []);

  return (
    <div id={'SearchProductHeader'} className={'productListHeader'}>
      <FilterProductsPopover showPopover={showPopover} onClosePopover={togglePopover}>
        <div className={'container searchProductContainer'}>
          <div className={'leftContainer'}>
            <BackIcon onClick={backIconClicked} className={'backIcon'} />
            <div className={'productSearch'}>
              <input
                autoFocus
                value={searchText}
                onChange={onSearchTextChange}
                className={'inputBox'}
                placeholder={'Search in title, price, description...'}
              />
              {!!searchText ? <CloseIcon onClick={onClear} className={'closeIcon'} /> : null}
            </div>
          </div>
          <div onClick={togglePopover} className={'rightContainer'}>
            <FunnelIcon className={'funnelIcon'} />
          </div>
        </div>
      </FilterProductsPopover>
    </div>
  );
};
