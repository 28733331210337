import React, { useEffect, useState, useCallback } from 'react';
import ProductHeader from './ProductHeader';
import ProductsList from './ProductsList';
import ProductsFilePicker from './ProductsFilePicker';
import eventbus from 'eventing-bus';
import { ACTIVE_CATALOGUE_META, getActiveCatalogueId } from 'qs-data-manager/Catalogues';
import NoCatalogueSelected from './NoCatalogueSelected';
import CatalogueLib from 'qs-data-manager/Catalogues';
import { useDropzone } from 'react-dropzone';
import { handleDragAndDrop, resetSearchPriceFilterAndTags } from 'qs-data-manager/Products';

import './styles.scss';
import UploadProductHeader from './ProductHeader/UploadProductHeader';
import ConfirmImageUploadModal from './ConfirmImageUploadModal';
import {
  attachProductChangesListener,
  removeProductChangeListener
} from 'qs-services/Firebase/Products';
import Mixpanel from 'qs-data-manager/Mixpanel';

export default () => {
  const [activeCatalogueId, setActiveCatalogueId] = useState(null);

  const onDrop = useCallback(acceptedFiles => {
    handleDragAndDrop({ images: acceptedFiles });
  }, []);
  const { getRootProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    const removeListener = eventbus.on(ACTIVE_CATALOGUE_META.eventbusKey, catalogueId => {
      setActiveCatalogueId(catalogueId);
      resetSearchPriceFilterAndTags();
      Mixpanel.sendEvent({
        eventName: 'START - CatalogueDetailActivity',
        props: {
          catalogueId
        }
      });
    });

    return () => removeListener();
  }, []);

  useEffect(() => {
    if (activeCatalogueId) {
      CatalogueLib.getCatalogueLinkFromRemote({ catalogueId: activeCatalogueId });
      attachProductChangesListener({ catalogueId: activeCatalogueId });
    }

    return activeCatalogueId
      ? () => removeProductChangeListener({ catalogueId: activeCatalogueId })
      : () => {};
  }, [activeCatalogueId]);

  if (!activeCatalogueId) {
    return <NoCatalogueSelected />;
  }

  return (
    <div {...getRootProps()} id={'ProductListScreen'}>
      <ProductHeader activeCatalogueId={activeCatalogueId} />
      <UploadProductHeader activeCatalogueId={activeCatalogueId} />
      <ProductsList activeCatalogueId={activeCatalogueId} />
      {isDragActive && (
        <div className="DropZone">
          <span>DROP PRODUCTS HERE</span>
        </div>
      )}
      <ConfirmImageUploadModal />
    </div>
  );
};
