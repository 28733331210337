import React, { useEffect } from 'react';
import './styles.scss';

import CatalogueListScreen from './CatalogueListScreen';
import ProductsListScreen from './ProductsListScreen';
import ProductDetailsScreen from './ProductDetailsScreen';
import CSVUploader from './CSVUploader';
import WithNavRef from 'qs-helpers/WithNavRef';

export default props => {
  return (
    <WithNavRef navigationRef={props}>
      <div id={'mainAppContainer'}>
        <div className={'container'}>
          <div id={'screenContainer'}>
            <CatalogueListScreen />
            <ProductsListScreen />
            <ProductDetailsScreen />
          </div>
        </div>
        <CSVUploader />
      </div>
    </WithNavRef>
  );
};
