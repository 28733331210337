import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import Tags from 'qs-data-manager/Tags';

import './styles.scss';
import { ReactComponent as TagIcon } from 'qs-assets/Media/tag-icon.svg';
import { ReactComponent as HelpIcon } from 'qs-assets/Media/help-circle.svg';
import { ReactComponent as TimesIcon } from 'qs-assets/Media/close.svg';
import CatalogueTagRow from './CatalogueTagRow/CatalogueTagRow';
import CatalogueTagHelp from './CatalogueTagHelp/CatalogueTagHelp';
import Mixpanel from 'qs-data-manager/Mixpanel';

const SortableItem = SortableElement(({ tag, isVisible, onVisiblePress }) => (
  <CatalogueTagRow tag={tag} isVisible={isVisible} onVisiblePress={onVisiblePress} />
));

const SortableList = SortableContainer(
  ({ items, helpVisible, toggleVisibility, onDismissHelpPress }) => {
    return (
      <div className="tagsList">
        {items.map((item, index) =>
          item === 'HELP' ? (
            <CatalogueTagHelp
              key="HELP"
              isVisible={helpVisible}
              onDismissPress={onDismissHelpPress}
            />
          ) : (
            <SortableItem
              key={item.title}
              tag={item.title}
              isVisible={!!item.v}
              onVisiblePress={() => toggleVisibility(item.title)}
              index={index - 1}
            />
          )
        )}
      </div>
    );
  }
);

class CatalogueTagsModal extends Component {
  static propTypes = {
    catalogueId: PropTypes.string,
    tags: PropTypes.object,
    catalogueTagsTutorialShown: PropTypes.bool,
    onClose: PropTypes.func,
    setCatalogueTagsTutorialShown: PropTypes.func
  };

  static defaultProps = {
    tags: {}
  };

  HEADER_HEIGHT = 53;
  MODAL_MARGIN = 20;

  constructor(props) {
    super(props);

    const listData = Object.keys(props.tags)
      .sort((t1, t2) => props.tags[t1].p - props.tags[t2].p)
      .map(tag => props.tags[tag]);

    this.state = {
      listData,
      helpVisible: !props.catalogueTagsTutorialShown
    };
  }

  // TODO: listData contains stock tags but their visibility has been set to false
  // this behaviour is from the prev version of desktop and since tags code was not modified
  // it is continuing here as well.
  onSortEnd = ({ oldIndex, newIndex }) => {
    const { catalogueId } = this.props;
    const { listData } = this.state;

    const newListData = arrayMove(listData, oldIndex, newIndex);
    const { filteredTags, tagsRemoved } = Tags.filterStockTags(newListData);

    const mergedTags = [...tagsRemoved, ...filteredTags];

    this.setState({ listData: mergedTags });

    Tags.sortCatalogueTags({ catalogueId, tags: newListData });
  };

  toggleVisibility = title => {
    const { catalogueId } = this.props;
    const { listData } = this.state;

    let newVisibility = null;
    const newListData = listData.map(tag => {
      if (tag.title === title) {
        newVisibility = !tag.v;
        return { ...tag, v: newVisibility };
      }

      return tag;
    });

    this.setState({ listData: newListData });

    Tags.setCatalogueTagVisibility({
      catalogueId,
      tag: title,
      v: newVisibility
    });
  };

  onHelpPress = () => {
    const { catalogueTagsTutorialShown, setCatalogueTagsTutorialShown } = this.props;

    this.setState({ helpVisible: !this.state.helpVisible });

    Mixpanel.sendEvent({ eventName: 'catalogue_tags_help_button_clicked' });

    if (!catalogueTagsTutorialShown) {
      setCatalogueTagsTutorialShown();
    }
  };

  onDismissHelpPress = () => {
    const { catalogueTagsTutorialShown, setCatalogueTagsTutorialShown } = this.props;

    this.setState({ helpVisible: false });

    Mixpanel.sendEvent({ eventName: 'catalogue_tags_help_dismiss_button_clicked' });

    if (!catalogueTagsTutorialShown) {
      setCatalogueTagsTutorialShown();
    }
  };

  render() {
    const { helpVisible, listData } = this.state;
    const { onClose } = this.props;

    return (
      <div className="catalogueTagsModal">
        <div className="header">
          <div className="headerLeft">
            <TagIcon fill="#7CD3A3" className="headerIcon" />
            <span className="headerText">Product tags</span>
          </div>

          <div className="headerRight">
            <button className="helpButton" onClick={this.onHelpPress}>
              <HelpIcon fill="#000" className="helpButtonIcon" />
            </button>

            <button className="closeButton" onClick={onClose}>
              <TimesIcon fill="#999" className="closeButtonIcon" />
            </button>
          </div>
        </div>

        <div
          className="content"
          style={{ maxHeight: window.innerHeight - this.HEADER_HEIGHT - 2 * this.MODAL_MARGIN }}
        >
          <SortableList
            useDragHandle
            helpVisible={helpVisible}
            helperClass="activeCatalogueTagSortRow"
            items={['HELP', ...listData]}
            toggleVisibility={this.toggleVisibility}
            onDismissHelpPress={this.onDismissHelpPress}
            onSortEnd={this.onSortEnd}
            lockAxis={'y'}
            axis={'y'}
          />
        </div>
      </div>
    );
  }
}

export default CatalogueTagsModal;
