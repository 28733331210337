import React, { useState, useEffect, useCallback } from 'react';
import { selectedCatalogue } from 'qs-data-manager/Selected.js';
import MultiCatalogueShareModal from 'qs-components/Common/MultiCatalogueShareModal';

import './styles.scss';

export default () => {
  const [selectedCatalogueCount, setSelectedCatalogueCount] = useState(0);
  const [multiCatalogueShare, setMultiCatalogueShare] = useState({
    showMultiShareModal: false,
    catalogueIds: []
  });

  const onSelectedValueChange = useCallback(all => {
    const selectedCount = Object.keys(all || {}).length;
    setSelectedCatalogueCount(selectedCount);
  }, []);

  useEffect(() => {
    selectedCatalogue.addListener(onSelectedValueChange);
    return () => {
      selectedCatalogue.removeListener(onSelectedValueChange);
    };
  }, [onSelectedValueChange]);

  const onShare = useCallback(() => {
    const selectedIds = selectedCatalogue.getAll();
    const catalogueIds = Object.keys(selectedIds);
    setMultiCatalogueShare({
      showMultiShareModal: true,
      catalogueIds
    });
  }, []);

  const onModalClose = useCallback(() => {
    setMultiCatalogueShare({
      showMultiShareModal: false,
      catalogueIds: []
    });
  }, []);

  if (!selectedCatalogueCount) {
    return null;
  }

  if (multiCatalogueShare.showMultiShareModal) {
    return (
      <MultiCatalogueShareModal
        isVisible={multiCatalogueShare.showMultiShareModal}
        catalogueIds={multiCatalogueShare.catalogueIds}
        onModalClose={onModalClose}
      />
    );
  }

  return (
    <div onClick={onShare} id={'MultiShareFooter'}>
      <div className={'shareCount'}>Share {selectedCatalogueCount} selected catalogues</div>
    </div>
  );
};
