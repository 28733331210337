import React, { useState, useEffect, useCallback } from 'react';
import eventbus from 'eventing-bus';
import { CSV_UPLOADER_EB_KEY } from 'qs-data-manager/Catalogues';
import Uploader from './Uploader';
import Loader from 'qs-components/Common/Loader';

import './styles.scss';
import XLSX from 'xlsx';

export default () => {
  const [uploaderState, setUploaderState] = useState({
    visibility: false,
    csvData: null
  });
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const parseFileToCsv = file => {
      return new Promise((res, rej) => {
        const fileReader = new FileReader();
        fileReader.addEventListener('load', e => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          let result = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 }) || [];

          let i;
          for (i = 0; i < result.length; i += 1) {
            const row = result[i];
            if (!row || !row.length) {
              break;
            }
          }

          result = result.slice(0, i);
          res(result);
        });

        fileReader.readAsBinaryString(file);
      });
    };

    const removeListener = eventbus.on(
      CSV_UPLOADER_EB_KEY,
      async (uploaderState, file, loaderState) => {
        if (typeof uploaderState === 'boolean') {
          if (uploaderState && file) {
            const result = await parseFileToCsv(file);
            setUploaderState({ visibility: true, csvData: result });
          } else {
            setUploaderState({ visibility: false, csvData: null });
            setLoader(false);
          }
          return;
        }

        if (typeof loaderState === 'boolean') {
          setLoader(loaderState);
        }
      }
    );

    return () => removeListener();
  }, []);

  if (!uploaderState.visibility) {
    return null;
  }

  return (
    <div id={'CSVUploaderContainer'}>
      <div id={'CSVUploader'}>
        {!!loader && (
          <div className={'loaderContainer'}>
            <Loader size={'large'} />
          </div>
        )}
        <Uploader excelColumnNames={uploaderState.csvData[0]} csvData={uploaderState.csvData} />
      </div>
    </div>
  );
};
