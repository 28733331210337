import { attachCompanyMetaListener, removeCompanyMetaListener } from './Company';
import { attachUserMetaListener, removeUserMetaListener } from './User';
import { attachInquiriesCountListener, removeInquiriesCountListener } from './Inquiries';
import { attachCatalogueChangesListener, removeCatalogueChangesListener } from './Catalogues';
import * as Sentry from '@sentry/browser';
import { attachConfigListener } from './Config';
import { attachConnectivityListener, removeConnectivityListener } from './Connectivity';

const attachDefaultListeners = ({ companyId, userId }) => {
  try {
    attachCompanyMetaListener({ companyId });
    attachConfigListener();
    attachUserMetaListener({ userId });
    attachInquiriesCountListener({ companyId });
    attachCatalogueChangesListener({ companyId });
    attachConnectivityListener();
  } catch (err) {
    console.error('attachListenersOnLogin: Could not attach listener', err);
    Sentry.captureException(err);
    throw new Error(err);
  }
};

const removeDefaultListeners = ({ companyId, userId }) => {
  removeCompanyMetaListener({ companyId });
  removeUserMetaListener({ userId });
  removeInquiriesCountListener({ companyId });
  removeCatalogueChangesListener({ companyId });
  removeConnectivityListener();
};

export { attachDefaultListeners, removeDefaultListeners };
