import React, { useState, useCallback } from 'react';
import { BOTTOM_SHEET_TABS } from 'qs-data-manager/ProductDetails';
import Header from './Header';
import ActiveTabMeta from './ActiveTabMeta';
import BulkEditingBottomSheetHeader from './BulkEditingBottomSheetHeader';

import './styles.scss';

export default ({ activeProductIds, activeProductId = '', isBulkEditing = '' } = {}) => {
  const [activeTab, setActiveTab] = useState(BOTTOM_SHEET_TABS.BASIC_INFO);

  const onChangeTab = useCallback(activeTab => {
    setActiveTab(activeTab);
  }, []);
  const [height] = useState(() => {
    return document.getElementById('ProductDetailsScreen').offsetHeight / 2;
  });

  return (
    <div id={'BottomSheetContainer'}>
      <div
        id={'BottomSheet'}
        className={`${isBulkEditing ? 'bulkBackgroundColor' : ''}`}
        style={{ minHeight: height, marginTop: height }}
      >
        {isBulkEditing ? (
          <BulkEditingBottomSheetHeader selectedCount={(activeProductIds || []).length} />
        ) : null}
        <Header onChangeTab={onChangeTab} activeTab={activeTab} />
        <ActiveTabMeta
          activeTab={activeTab}
          activeProductIds={activeProductIds}
          isBulkEditing={isBulkEditing}
          activeProductId={activeProductId}
        />
      </div>
    </div>
  );
};
