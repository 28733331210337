import React from 'react';
import './styles.scss';

import Navigation from './Navigation';
import 'qs-data-manager/Dexie/ApiAndNativeConnector/index';
import 'qs-master-worker/master.worker.js';

export default () => {
  return (
    <div id={'AppMainContainer'}>
      <Navigation />
    </div>
  );
};
