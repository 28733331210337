import React, { useState, useCallback, useEffect } from 'react';

import './styles.scss';
import '../styles.scss';
import eventbus from 'eventing-bus';
import { SAVE_BUTTON_META, bulkSaveTitle } from 'qs-data-manager/ProductDetails';

export default ({ title, isBulkEditing, activeProductIds }) => {
  const [titleState, setTitleState] = useState({
    modifiedTitle: title || '',
    showSaveButton: false
  });

  useEffect(() => {
    setTitleState({
      modifiedTitle: title || '',
      showSaveButton: false
    });
  }, [title, isBulkEditing]);

  useEffect(
    () => {
      if (isBulkEditing) {
        setTitleState(prevState => ({
          ...prevState,
          modifiedTitle: '',
          showSaveButton: false
        }));
      }
    },
    [(activeProductIds || []).length],
    isBulkEditing
  );

  const onTitleChanged = useCallback(
    e => {
      e.stopPropagation();
      const value = e.target.value;
      const hasChanged = (title || '') !== value;

      const showSaveButton = isBulkEditing && hasChanged;

      setTitleState(prevState => ({
        ...prevState,
        modifiedTitle: value,
        showSaveButton
      }));

      if (!isBulkEditing) {
        eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
          id: SAVE_BUTTON_META.PRODUCT_TITLE.id,
          hasChanged,
          data: value,
          eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id,
        });
      }
    },
    [title, isBulkEditing]
  );

  const onSaveClick = useCallback(() => {
    bulkSaveTitle({ productIds: activeProductIds, title: titleState.modifiedTitle }).then(() => {
      setTitleState(prevState => ({
        ...prevState,
        showSaveButton: false
      }));
    });
  }, [activeProductIds, titleState.modifiedTitle]);

  return (
    <div id={'TitleContainer'}>
      <div className={'rowContainer'}>
        <div className={'rowHeading'}>Title</div>
        <div className={'inputContainer'}>
          <input
            type="text"
            value={titleState.modifiedTitle}
            onChange={onTitleChanged}
            className={`input ${titleState.showSaveButton ? 'restrictWidth' : ''}`}
          />
          {!!titleState.showSaveButton ? (
            <div className={'saveButton'} onClick={onSaveClick}>
              save
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
