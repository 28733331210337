export default {
  eventbusKey: 'MAIN_WORKER_EB_KEY',
  UPLOAD_IMAGES: {
    type: 'UPLOAD_IMAGES',
    eventbusKey: id => {
      if (!id) {
        console.error(`config: UPLOAD_IMAGES: eventbusKey unique id: ${id}`);
      }
      return `UPLOAD_IMAGES_${id}`;
    }
  }
};
