import React, { useState, useCallback, useEffect, useRef } from 'react';
import { uploadFromFilePicker } from 'qs-data-manager/Products';
import { selectedProducts } from 'qs-data-manager/Selected';
import UploadImage from 'qs-assets/Media/photos-upload.png';
import './styles.scss';
import { getActiveCatalogueId } from 'qs-data-manager/Catalogues';

export default ({ changeReduceHeight }) => {
  const [isVisible, setIsVisible] = useState(false);
  const pickerRef = useRef(null);

  const onSelectionStateChange = useCallback(isActive => {
    setIsVisible(!!isActive);
    const reduceHeight = isActive ? 50 : 0;
    changeReduceHeight(reduceHeight)
  }, []);

  useEffect(() => {
    selectedProducts.addActiveListener(onSelectionStateChange);
  }, [onSelectionStateChange]);

  const handleFilesChange = useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
    const catalogueId = getActiveCatalogueId();
    uploadFromFilePicker({ e, catalogueId });
    pickerRef.current.value = null;
  }, []);

  return (
    <div id={'filePicker'} className={`${isVisible ? 'showFilePicker' : ''}`}>
      <img src={UploadImage} />
      <input
        ref={pickerRef}
        type="file"
        name="photos"
        accept="image/*,.csv,.xls,.xlsx"
        multiple
        onChange={handleFilesChange}
      />
    </div>
  );
};
