import { useEffect } from 'react';
import { attachDefaultListeners, removeDefaultListeners } from 'qs-services/Firebase';
import { COMPANY_LOCAL_STORAGE_KEYS } from 'qs-data-manager/Company';
import { setSentryContext } from 'qs-helpers/ErrorReporting';

export default (loginState) => {
  useEffect(() => {
    if (loginState.isLoggedIn && !loginState.loggingIn) {

      // TODO: make a common localStorage function that will always parse and get data from local storage
      const companyId = (
        JSON.parse(localStorage.getItem(COMPANY_LOCAL_STORAGE_KEYS.COMPANY_META)) || {}
      ).id;

      const userId = (JSON.parse(localStorage.getItem(COMPANY_LOCAL_STORAGE_KEYS.USER_META)) || {})
        .id;

      const email = (JSON.parse(localStorage.getItem(COMPANY_LOCAL_STORAGE_KEYS.USER_META)) || {})
        .email;

      attachDefaultListeners({
        companyId,
        userId
      });

      setSentryContext({ userId, email, companyId });

      return () => {
        removeDefaultListeners({ companyId, userId });
      };
    } else {
      return () => {};
    }
  }, [loginState]);
};
