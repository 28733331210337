import React, { useEffect } from 'react';

import CatalogueListHeader from './CatalogueListHeader';
import CatalogueList from './CatalogueList';
import MultiCatalogueShareFooter from './MultiCatalogueShareFooter';

import './styles.scss';
import Mixpanel from 'qs-data-manager/Mixpanel';

export default () => {
  useEffect(() => {
    Mixpanel.sendEvent({ eventName: 'START - MainCataloguesActivity' });
  }, []);

  return (
    <>
      <div id={'CatalogueListScreen'}>
        <CatalogueListHeader />
        <CatalogueList />
        <MultiCatalogueShareFooter />
      </div>
    </>
  );
};
