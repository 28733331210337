import React from 'react';
import './styles.scss'

export default () => {
  return (
    <div id={'NoCatalogueFound'}>
      No catalogues found
    </div>
  );
};
