import eventbus from 'eventing-bus';
import firebase from 'qs-config/FirebaseConfig';
import Api from 'qs-services/Api';
import { attachDefaultListeners } from 'qs-services/Firebase';
import { navigateToPath, showSideBars, hideSideBars } from 'qs-data-manager';
import { PLAN_TYPES, saveCurrentPlan, saveFeatureLimits } from 'qs-data-manager/FeatureUsage';
import moment from 'moment-timezone';
import { formWindowEndpointForLogin } from 'qs-helpers';
import { COMPANY_LOCAL_STORAGE_KEYS } from 'qs-data-manager/Company';
import { setSentryContext, reportError, resetSentryUser } from 'qs-helpers/ErrorReporting';
import AppConfig from 'qs-config/AppConfig';
import imageUploadProcessor from 'qs-services/ImageUpload/ImageUploadProcessor';
import { generateUniqueDeviceIdIfNotExists } from 'qs-config/DeviceIdGenerator_APP_RUN_ENV';

export const AUTH_PROVIDERS = {
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK'
};

export const IS_LOGGING_IN = {
  eventbusKey: 'IS_LOGGING_IN',
  isLoggingIn: false
};

const FIREBASE_ERROR_CODES = {
  CANCELLED_POPUP: 'auth/cancelled-popup-request',
  CLOSED_BY_USER: 'auth/popup-closed-by-user',
  NETWORK_REQ_FAILED: 'auth/network-request-failed'
};

export const getIsLoggingIn = () => IS_LOGGING_IN.isLoggingIn;

// On login
export const onLogin = async provider => {
  try {
    let selectedProvider = null;

    if (provider === AUTH_PROVIDERS.FACEBOOK) {
      selectedProvider = new firebase.auth.FacebookAuthProvider();
    } else if (provider === AUTH_PROVIDERS.GOOGLE) {
      selectedProvider = new firebase.auth.GoogleAuthProvider();
      selectedProvider.addScope('profile');
      selectedProvider.addScope('email');
      selectedProvider.setCustomParameters({ prompt: 'select_account' });
    }

    if (!selectedProvider) {
      const err = 'Use either google or facebook to log in';
      console.error(`onLogin: No provider selected: ${selectedProvider}`, err);
      eventbus.publish(IS_LOGGING_IN.eventbusKey, {
        message: err,
        didError: true
      });
      return;
    }

    IS_LOGGING_IN.isLoggingIn = true;

    let result
    if(AppConfig.isRunningInDesktopEnv()) {
      firebase.auth().signInWithRedirect(selectedProvider)
      result = await firebase.auth().getRedirectResult()
    } else {
      result = await firebase.auth().signInWithPopup(selectedProvider);
    }

    showLoginLoader();

    if (result.user) {
      const token = await firebase.auth().currentUser.getIdToken();
      const credential = firebase.auth.GoogleAuthProvider.credential(token);

      const { user: userAuthData } = result;

      const { userId, user, company } = await Api.login({
        idToken: credential.idToken,
        name: userAuthData.displayName,
        photo: userAuthData.photoURL
      });
      const email = user.email;
      setSentryContext({ userId, email, companyId: company.id });
      localStorage.setItem(COMPANY_LOCAL_STORAGE_KEYS.COMPANY_META, JSON.stringify(company));
      localStorage.setItem(COMPANY_LOCAL_STORAGE_KEYS.USER_META, JSON.stringify(user));

      if (company.currencyCode) {
        const currentPlan = await Api.getCurrentPlan();
        saveCurrentPlan(currentPlan);

        if (currentPlan.planType === PLAN_TYPES.LITE) {
          userUnauthorized({ email: user.email, reason: 'User is on LITE plan' });
          return;
        }

        const featureLimit = await Api.getPaymentPlan();
        saveFeatureLimits(featureLimit);
        const customerPlan = await Api.getCustomerPlan(company.id);
        const extendedTrialStart = await Api.fetchExtendedTrialDuration(company.id);

        const onExtendedTrial =
          extendedTrialStart &&
          moment(extendedTrialStart)
            .add(14, 'days')
            .isAfter(moment());

        if (customerPlan === 'PLATINUM' || onExtendedTrial) {
          closeLoginLoader();
          navigateToPath('/catalogues');
          showSideBars();
          attachDefaultListeners({
            companyId: company.id,
            userId: user.id
          });
          generateUniqueDeviceIdIfNotExists({ companyId: company.id });
        } else {
          closeLoginLoader();
          userUnauthorized({ email });
        }
      } else {
        userUnauthorized({ email, reason: 'Company.currency code does not exists' });
      }
    } else {
      IS_LOGGING_IN.isLoggingIn = false;
      eventbus.publish(IS_LOGGING_IN.eventbusKey, {
        isNewUser: true,
        isLoggingIn: false,
        didError: false,
        message: ''
      });
    }
  } catch (err) {
    console.error('onLogin:', err);
    closeLoginLoader();

    if (
      err &&
      (err.code === FIREBASE_ERROR_CODES.CANCELLED_POPUP ||
        err.code === FIREBASE_ERROR_CODES.CLOSED_BY_USER ||
        err.code === FIREBASE_ERROR_CODES.NETWORK_REQ_FAILED)
    ) {
      return;
    }

    eventbus.publish(IS_LOGGING_IN.eventbusKey, {
      message: err,
      didError: true
    });
    await firebase.auth().signOut();
    reportError(err);
  }
};

const showLoginLoader = () => {
  IS_LOGGING_IN.isLoggingIn = true;
  eventbus.publish(IS_LOGGING_IN.eventbusKey, {
    isLoggingIn: true
  });
};

const closeLoginLoader = () => {
  IS_LOGGING_IN.isLoggingIn = false;
  eventbus.publish(IS_LOGGING_IN.eventbusKey, {
    isLoggingIn: false
  });
};

export const userUnauthorized = ({ email, reason = 'UNAUTHORIZED' }) => {
  console.error(`userUnauthorized: ${reason}`);
  eventbus.publish(IS_LOGGING_IN.eventbusKey, {
    isLoggingIn: false,
    notAllowedToUse: true,
    userEmail: email,
    didError: false,
    message: ''
  });
  onLogout();
};

export const onLogout = ({ shouldNavigate = true } = {}) => {
  try {
    hideSideBars();

    if (shouldNavigate) {
      try {
        navigateToPath('/login');
      } catch (err) {
        console.error(`navigateToPath: Could not navigate user to path "/login"`, err);
        window.location.href = formWindowEndpointForLogin();
      }
    }

    firebase.auth().signOut();
    resetSentryUser();
    imageUploadProcessor.processUserChange();
  } catch (err) {
    console.error('onLogout: Could not log user out', err);
    reportError(err);
  }
};
