import React, { Component } from 'react';
import { ReactComponent as RefreshIcon } from 'qs-assets/Media/refresh.svg';
import eventbus from 'eventing-bus';
import './RefreshButton.css';
import OrderLib from 'qs-data-manager/Order';
import * as Sentry from "@sentry/browser";

class RefreshButton extends Component {
  state = {
    showRefreshButton: false,
    numberOfUnreadInquries: 0
  };

  componentDidMount() {
    eventbus.on(
      OrderLib.UNREAD_INQUIRIES_COUNT.EVENT_BUS_KEY,
      ({ unreadInquiryCount, showRefreshButton }) => {
        this.setState({
          showRefreshButton,
          numberOfUnreadInquries: unreadInquiryCount
        });
      }
    );
  }

  refreshClicked = () => {
    try {
      const { refreshClicked } = this.props;
      const ts = Date.now();
      this.setState({ showRefreshButton: false });
      OrderLib.setLastInquiryFetchTimestamp(ts);
      refreshClicked(true);
    } catch (err) {
      console.error('refreshClicked: Could not refresh', err);
      Sentry.captureException(err);
    }
  };

  render() {
    const { showRefreshButton, numberOfUnreadInquries } = this.state;

    return showRefreshButton && numberOfUnreadInquries ? (
      <div className="newInquriesAvailableHeader">
        <div onClick={this.refreshClicked} className="newInquriesContainer">
          <div className="textContainer">New inquries available</div>

          <div className="refreshButton">
            <RefreshIcon className="refreshIcon" />
            <div>Refresh</div>
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default RefreshButton;
