import { compressImageUsingWorker } from './Helpers/index';

//const compressImageUsingWorker = async() => {}

export default async images => {
  const compressedImages = [],
    compressFailedImages = [];

  const compressedImagePromises = images.map(async imageMeta => {
    try {
      const compressedImage = await compressImageUsingWorker({imageId: imageMeta.pictureId, imagePath: imageMeta.path});
      compressedImages.push({
        image: compressedImage[0].data,
        imageMeta
      });
    } catch (compressionError) {

      //Image could not be compressed
      compressFailedImages.push({
        ...imageMeta,
        isPrepared: false,
        error: 'COMPRESSION'
      });
    }
  });

  await Promise.all(compressedImagePromises);

  return { compressedImages, compressFailedImages };
};
