import React, { useState, useCallback, useEffect } from 'react';

import './styles.scss';
import eventbus from 'eventing-bus';
import {
  SAVE_BUTTON_META,
  getDiscountInitDependencies,
  getProductPriceFromCache,
  getProductDiscountFromCache,
  bulkSaveDiscount
} from 'qs-data-manager/ProductDetails';
import { deleteDiscountFromProducts } from 'qs-data-manager/Products';
import BulkDiscount from './BulkEditDiscount/BulkEditDiscount.js';
import { ReactComponent as CloseIcon } from 'qs-assets/Media/close.svg';

export default ({
  discount,
  originalPrice,
  modifiedPrice,
  activeProductId,
  activeProductIds,
  isBulkEditing,
  currencySymbol
} = {}) => {
  const [discountState, setDiscountState] = useState(() => {
    const canApplyDiscount = typeof originalPrice === 'number';
    const shouldShowDiscountButton = typeof discount !== 'number';
    const modifiedDiscount = discount ? `${discount}` : '';
    let discountInPercentage =
      typeof originalPrice === 'number' ? ((discount || 0) / originalPrice) * 100 : 0;

    discountInPercentage = 100 - discountInPercentage;
    discountInPercentage = Math.round(discountInPercentage * 100) / 100;

    return {
      canApplyDiscount,
      shouldShowDiscountButton,
      modifiedDiscount,
      discountInPercentage,
      modalVisible: false
    };
  });
  const [showBulkDiscountModal, setShowBulkDiscountModal] = useState({
    isVisible: false
  });

  useEffect(() => {
    const { originalPrice, discount } = getDiscountInitDependencies(activeProductId);
    const canApplyDiscount = typeof originalPrice === 'number';
    const shouldShowDiscountButton = typeof discount !== 'number';
    const modifiedDiscount = discount ? `${discount}` : '';
    let discountInPercentage =
      typeof originalPrice === 'number' ? ((discount || 0) / originalPrice) * 100 : 0;

    discountInPercentage = 100 - discountInPercentage;
    discountInPercentage = Math.round(discountInPercentage * 100) / 100;

    setDiscountState(prevState => ({
      ...prevState,
      canApplyDiscount,
      shouldShowDiscountButton,
      modifiedDiscount,
      discountInPercentage
    }));
  }, [activeProductId]);

  // Effect for when price changes
  useEffect(() => {
    const canApplyDiscount = typeof modifiedPrice === 'number';
    if (!canApplyDiscount) {
      const discount = getProductDiscountFromCache({ productId: activeProductId });
      const newDiscount = null;

      setDiscountState(prevState => ({
        ...prevState,
        modifiedDiscount: newDiscount,
        shouldShowDiscountButton: !canApplyDiscount,
        canApplyDiscount
      }));

      const hasChanged = newDiscount !== discount;

      eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
        id: SAVE_BUTTON_META.PRODUCT_DISCOUNT.id,
        hasChanged,
        data: newDiscount,
        eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
      });
    } else {
      setDiscountState(prevState => ({
        ...prevState,
        canApplyDiscount
      }));
      if (discountState.modifiedDiscount) {
        const originalPrice = getProductPriceFromCache({ productId: activeProductId });
        const discount = getProductDiscountFromCache({ productId: activeProductId });
        const percentage =
          typeof originalPrice !== 'number' || typeof discount !== 'number'
            ? null
            : (discount / originalPrice) * 100;

        let newDiscount =
          typeof percentage === 'number' ? (percentage * modifiedPrice) / 100 : null;
        const canApplyDiscount = typeof modifiedPrice === 'number';

        newDiscount = typeof newDiscount === 'number' ? Math.round(newDiscount * 100) / 100 : null;
        const hasChanged = newDiscount !== discount;

        setDiscountState(prevState => ({
          ...prevState,
          canApplyDiscount,
          modifiedDiscount: `${newDiscount}`
        }));

        eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
          id: SAVE_BUTTON_META.PRODUCT_DISCOUNT.id,
          hasChanged,
          data: newDiscount,
          eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
        });
      }
    }
  }, [modifiedPrice]);
  // }, [modifiedPrice, discount, originalPrice]);

  const offerDiscount = useCallback(e => {
    e.stopPropagation();
    setDiscountState(prevState => ({
      ...prevState,
      shouldShowDiscountButton: false
    }));
  }, []);

  const onDiscountChange = useCallback(
    e => {
      e.stopPropagation();
      const value = e.target.value;

      if (typeof Number(value) !== 'number' || isNaN(Number(value))) {
        return;
      }

      let price = modifiedPrice !== originalPrice ? modifiedPrice : originalPrice;

      if (value > price) {
        alert('Discount cannot be greater than price');
        return;
      }

      const hasChanged = (discount ? `${discount}` : '') !== value;
      let discountInPercentage = 100 - (value / price) * 100;
      discountInPercentage = Math.round(discountInPercentage * 100) / 100;

      setDiscountState(prevState => ({
        ...prevState,
        modifiedDiscount: value,
        discountInPercentage
      }));

      eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
        id: SAVE_BUTTON_META.PRODUCT_DISCOUNT.id,
        hasChanged,
        data: Number(value),
        eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
      });
    },
    [originalPrice, discount, modifiedPrice]
  );

  const onBulkDiscount = useCallback(() => {
    setShowBulkDiscountModal(prevState => ({
      ...prevState,
      isVisible: true
    }));
  }, []);

  const closeModal = useCallback(() => {
    setShowBulkDiscountModal(prevState => ({
      ...prevState,
      isVisible: false
    }));
  }, []);

  const onSetPercentClick = useCallback(
    discount => {
      if (discount === 0) {
        closeModal();
        return;
      }

      bulkSaveDiscount({ productIds: activeProductIds, discountInPercent: Number(discount) });
      closeModal();
    },
    [activeProductIds, closeModal]
  );

  const showModalFromSingleDiscount = useCallback(() => {
    setDiscountState(prevState => ({
      ...prevState,
      modalVisible: true
    }));
  }, []);

  const onDeleteDiscount = useCallback(() => {
    if (!activeProductId) {
      console.error(`onDeleteDiscount: activeProductId is not defined ${activeProductId}`);
      return;
    }

    const shouldRemove = window.confirm('Are you sure you want remove this discount?');
    if (!shouldRemove) {
      return;
    }

    setDiscountState(prevState => ({
      ...prevState,
      modifiedDiscount: '',
      discountInPercentage: '',
      shouldShowDiscountButton: true
    }));
    const productIds = [activeProductId];
    deleteDiscountFromProducts({ productIds });
  }, [activeProductId]);

  const closeSingeDiscountModal = useCallback(() => {
    setDiscountState(prevState => ({
      ...prevState,
      modalVisible: false
    }));
  }, []);

  const setPercentageSingleDiscount = useCallback(
    rawDiscount => {
      const closeModal = () => {
        setDiscountState(prevState => ({
          ...prevState,
          modalVisible: false
        }));
      };

      if (rawDiscount === 0) {
        closeModal();
        return;
      }
      const discount = Math.round(rawDiscount * 100) / 100;
      if (discount === discountState.discountInPercentage) {
        closeModal();
        return;
      }

      let discountGiven = (discount / 100) * modifiedPrice;
      let newDiscount = modifiedPrice - discountGiven;
      newDiscount = newDiscount > -1 ? newDiscount : 0;
      newDiscount = Math.round(newDiscount * 100) / 100;

      setDiscountState(prevState => ({
        ...prevState,
        modalVisible: false,
        modifiedDiscount: `${newDiscount}`,
        discountInPercentage: discount
      }));

      eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
        id: SAVE_BUTTON_META.PRODUCT_DISCOUNT.id,
        hasChanged: true,
        data: Number(newDiscount),
        eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
      });
    },
    [discountState.discountInPercentage, modifiedPrice]
  );

  if (isBulkEditing) {
    return (
      <div id={'ProductDiscount'}>
        <div className={'discountButtonContainer'}>
          <button onClick={onBulkDiscount} className={`discountButton`}>
            Offer discount
          </button>
        </div>
        <BulkDiscount
          visible={showBulkDiscountModal.isVisible}
          initialPercentage={''}
          closeModal={closeModal}
          onSetPercentClick={onSetPercentClick}
        />
      </div>
    );
  }

  return (
    <div id={'ProductDiscount'}>
      {discountState.shouldShowDiscountButton ? (
        <div className={'discountButtonContainer'}>
          <button
            onClick={offerDiscount}
            disabled={!discountState.canApplyDiscount}
            className={`discountButton ${
              discountState.canApplyDiscount ? '' : 'disableDiscountButton'
            }`}
          >
            Offer discount
          </button>
        </div>
      ) : (
        <div className={'discountInputContainer'}>
          <div className={'rowContainer'}>
            <div className={'rowHeading'}>After discount</div>
            <div className={'inputContainer discountContainer'}>
              <div className={'symbolContainer'}>{currencySymbol}</div>
              <input
                autoFocus={true}
                className={'input'}
                value={discountState.modifiedDiscount}
                onChange={onDiscountChange}
              />
              {!!discountState.modifiedDiscount && (
                <div onClick={onDeleteDiscount} className={'deleteDiscountContainer'}>
                  <CloseIcon className={'deleteDiscountIcon'} />
                </div>
              )}
              {!!discountState.modifiedDiscount ? (
                <div onClick={showModalFromSingleDiscount} className={'discountInPercent'}>
                  {discountState.discountInPercentage}%
                </div>
              ) : null}
            </div>
          </div>
          <BulkDiscount
            visible={discountState.modalVisible}
            initialPercentage={discountState.discountInPercentage}
            closeModal={closeSingeDiscountModal}
            onSetPercentClick={setPercentageSingleDiscount}
          />
        </div>
      )}
    </div>
  );
};
