import React, { useState, useEffect, useRef } from 'react';
import SettingsRow from '../../../Common/SettingsRow/SettingsRow';
import {
  getCompanyMetaFromCache,
  attachCompanyMetaListener,
  removeCompanyMetaListener,
  getCompanyIdFromCache
} from 'qs-data-manager/Company';
import Loader from '../../../../Common/Loader';
import {
  getUserMetaFromCache,
  attachUserMetaListener,
  removeUserMetaListener
} from 'qs-data-manager/User';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import './SettingsLeftCompanyView.scss';
import toastr from 'toastr';
import ClickHandlerWithLoaderContainer from '../../../../Common/ClickHandlerWithLoader/ClickHandlerWithLoaderContainer';

const ProfileLogoComponent = () => {
  const companyProfile = useRef(getCompanyMetaFromCache());
  if (!companyProfile.current.logoExists) {
    const firstCompanyChar = companyProfile.current.name.search(/\w+/);
    return (
      <div className="default-profile-logo-container">
        {companyProfile.current.name[firstCompanyChar]}
      </div>
    );
  }

  const { logoVersion } = companyProfile.current;
  const companyId = getCompanyIdFromCache();
  return (
    <img
      className="profile-logo"
      src={`https://s3.ap-south-1.amazonaws.com/quicksell-logos/${companyId}.jpg?v=${logoVersion}`}
      alt="Your company profile image"
    />
  );
};

export default ({ onSelected, settingComponentId, classForContainer }) => {
  const [companyProfile, setCompanyProfile] = useState(getCompanyMetaFromCache());
  const [userProfile, setUserProfile] = useState(getUserMetaFromCache());

  useEffect(() => {
    const companyListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);
      if (loading || refreshing) {
        return;
      }

      if (err) {
        toastr.error('Something went wrong while fetching the company name');
        return;
      }
      setCompanyProfile(data);
    };

    attachCompanyMetaListener(companyListener);
    return () => removeCompanyMetaListener(companyListener);
  }, []);

  useEffect(() => {
    const userListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (loading || refreshing) {
        return;
      }

      if (err) {
        toastr.error('Something went wrong while fetching the user email and phone');
        return;
      }
      setUserProfile(data);
    };

    attachUserMetaListener(userListener);
    return () => removeUserMetaListener(userListener);
  }, []);

  let companyProfileViewComponent;
  if (companyProfile && userProfile) {
    companyProfileViewComponent = (
      <ClickHandlerWithLoaderContainer
        clickProps={{clickHandler: onSelected, clickParams: settingComponentId }}
        type="button"
        classForContainer={classForContainer}
      >
        <SettingsRow
          Icon={ProfileLogoComponent}
          title={companyProfile.name}
        >
          <div className="profile--details">{userProfile.phone}</div>
          <div className="profile--details">
            {userProfile.accountEmail ? userProfile.accountEmail : userProfile.email}
          </div>
        </SettingsRow>
      </ClickHandlerWithLoaderContainer>
    );
  } else {
    companyProfileViewComponent = (
      <SettingsRow>
        <Loader size="large" />
      </SettingsRow>
    );
  }
  return companyProfileViewComponent;
};
