import React, { useEffect, useCallback } from 'react';
import GenerateLink from './GenerateLink';
import SharingOptions from './SharingOptions';
import { CATALOGUE_SHARE_TYPES } from 'qs-services/DataManager/Catalogues';
import { getElementParents } from 'qs-helpers';

import './styles.scss';


export default ({ catalogueIds = [], isVisible, closePopover } = {}) => {
  useEffect(() => {
    const handleBodyClick = e => {
      const parents = getElementParents(e.target);
      const parentsClass = parents.map(parent => parent.className);

      if (
        !parentsClass.includes('react-tiny-popover-container') &&
        !parentsClass.includes('iconContainer shareDialogueContainer')
      ) {
        closePopover();
      }
    };

    document.querySelector('body').addEventListener('click', handleBodyClick);

    return () => document.querySelector('body').removeEventListener('click', handleBodyClick);
  }, [isVisible, closePopover]);

  return (
    <div id={'ShareDialogue'}>
      <GenerateLink catalogueId={catalogueIds[0]} isVisible={isVisible} />
      <SharingOptions catalogueId={catalogueIds[0]} shareType={CATALOGUE_SHARE_TYPES.SINGLE} />
    </div>
  );
};
