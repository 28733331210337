import Dexie from 'dexie';
// import { rehydrationService } from './Rehydration';

export const NATIVE_STORAGE_EVENT_BUS_KEY = 'NATIVE_STORAGE_EVENT_BUS_KEY';
export const nativeDB = new Dexie('qsDesktop');

const nativeDbIndexingConfig = {
  cataloguesListMeta: 'catalogueId',
  catalogueRowMeta: '',
  productListMeta: '',
  productRowMeta: 'productId',
  catalogueTags: ''
};

nativeDB.version(1).stores(nativeDbIndexingConfig);

/*
  Making any change to an existing indexed db requires upgrading
  the version and only specifying the change is sufficient
*/
nativeDB
  .version(2)
  .stores({ pictureUploadsMeta: 'pictureId, timestamp, state' });
