import { connector } from './ApiAndCacheConnector';
import CacheRequest from './CacheRequest';
import Api from 'qs-services/Api';
import { getCurrencySymbol, getPictureIdFromImageUrl, toggleGlobalLoader } from '../Helpers';
import {
  saveProductMetaChangesInNative,
  updateExistingProductsInNative
} from 'qs-data-manager/Dexie/ProductDexieHelpers';
import eventbus from 'eventing-bus';
import { getActiveProductId, setLastFetchDate, PRODUCT_POSITION_MAP } from './Products';
import { getCompanyCurrencyCode } from './Company';
import { getActiveCatalogueId } from './Catalogues';
import * as Sentry from '@sentry/browser';

const BOTTOM_SHEET_TABS = {
  BASIC_INFO: 'BASIC_INFO',
  INVENTORY: 'INVENTORY',
  PRIVATE_NOTES: 'PRIVATE_NOTES'
};

const PRODUCT_SELECTION_TYPE = {
  SINGLE: 'SINGLE',
  MULTI: 'MULTI'
};

const SAVE_BUTTON_META = {
  PRIVATE_NOTES: {
    id: 'PRIVATE_NOTES'
  },
  PRODUCT_TITLE: {
    id: 'PRODUCT_TITLE'
  },
  PRODUCT_PRICE: {
    id: 'PRODUCT_PRICE'
  },
  PRODUCT_DISCOUNT: {
    id: 'PRODUCT_DISCOUNT'
  },
  PRODUCT_DESCRIPTION: {
    id: 'PRODUCT_DESCRIPTION'
  },
  eventbusKey: 'SAVE_BUTTON_EVENTBUS_KEY',
  eventType: {
    AUTO_SAVE: {
      id: 'AUTO_SAVE'
    },
    PRODUCT_META: {
      id: 'PRODUCT_META'
    }
  }
};

let RENDERED_PRODUCT_IMAGE_META = {
  eventbusKey: 'RENDERED_PRODUCT_IMAGE_META',
  meta: {
    imageUrl: null,
    defaultImageUrl: null
  }
};

// PRIVATE NOTES api call
const attachPrivateNotesListener = (listener, productId) => {
  const key = `${connector.PRIVATE_NOTES.cacheKey}${productId}`;
  CacheRequest.attachListener(key, listener);
};

const removePrivateNotesListener = (listener, productId) => {
  const key = `${connector.PRIVATE_NOTES.cacheKey}${productId}`;
  CacheRequest.removeListener(key, listener);
};

const getPrivateNotes = productId => {
  const key = `${connector.PRIVATE_NOTES.cacheKey}${productId}`;
  const apiName = connector.PRIVATE_NOTES.apiFunction;
  CacheRequest.makeRequest(key, apiName, {
    params: [productId],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

// Inventory listener
const attachInventoryListener = ({ listener, productId }) => {
  const key = `${connector.INVENTORY.cacheKey}${productId}`;
  CacheRequest.attachListener(key, listener);
};

const removeInventoryListener = ({ listener, productId }) => {
  const key = `${connector.INVENTORY.cacheKey}${productId}`;
  CacheRequest.removeListener(key, listener);
};

const getInventory = ({ productId }) => {
  const key = `${connector.INVENTORY.cacheKey}${productId}`;
  const apiName = connector.INVENTORY.apiFunction;
  CacheRequest.makeRequest(key, apiName, {
    params: [productId],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

// Basic info api call
const attachBasicInfoListener = ({ listener, productId }) => {
  const key = `${connector.BASIC_INFO.cacheKey}${productId}`;
  CacheRequest.attachListener(key, listener);
};

const removeBasicInfoListener = ({ listeners, productId }) => {
  const key = `${connector.BASIC_INFO.cacheKey}${productId}`;
  CacheRequest.removeListener(key, listeners);
};

const getBasicInfoFromRemote = ({ productId }) => {
  const key = `${connector.BASIC_INFO.cacheKey}${productId}`;
  const apiName = connector.BASIC_INFO.apiFunction;
  CacheRequest.makeRequest(key, apiName, {
    params: [productId],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

// Gets initial state of product details screen
const productDetailsInitialState = (productIds = []) => {
  if (productIds.length > 1) {
    return {
      error: null,
      loading: false,
      refreshing: false,
      privateNotes: '',
      changedPrivateNotes: '',
      showSaveButton: false
    };
  }
  const productId = productIds[0];
  const key = `${connector.PRIVATE_NOTES.cacheKey}${productId}`;
  const cache = CacheRequest.getCacheForKey(key);
  const loading = !cache;
  const privateNotes = loading ? '' : cache.privateNote;

  return {
    error: null,
    loading,
    refreshing: true,
    privateNotes,
    changedPrivateNotes: privateNotes,
    showSaveButton: false
  };
};

// Save product details changes
const saveBulkProductChanges = async (data = {}, productIds = []) => {};

const saveSingleProductChanges = async ({ data, productId }) => {
  const loaderKey = `saveSingleProductChanges${Date.now()}`;
  toggleGlobalLoader(loaderKey, true);
  const catalogueId = getActiveCatalogueId();
  const promises = [];
  const changes = {};

  if (data && typeof data[SAVE_BUTTON_META.PRIVATE_NOTES.id] !== 'undefined') {
    const privateNotes = data[SAVE_BUTTON_META.PRIVATE_NOTES.id];
    const key = `${connector.PRIVATE_NOTES.cacheKey}${productId}`;
    CacheRequest.setCacheForKey(key, { privateNotes });
    const apicall = Api.setPrivateNotes([productId], privateNotes);
    promises.push(apicall);
  }

  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_TITLE.id] !== 'undefined') {
    changes.name = data[SAVE_BUTTON_META.PRODUCT_TITLE.id];
  }

  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_PRICE.id] !== 'undefined') {
    changes.price = data[SAVE_BUTTON_META.PRODUCT_PRICE.id];
  }

  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_DISCOUNT.id] !== 'undefined') {
    changes.discount = data[SAVE_BUTTON_META.PRODUCT_DISCOUNT.id];
  }

  if (data && typeof data[SAVE_BUTTON_META.PRODUCT_DESCRIPTION.id] !== 'undefined') {
    changes.description = data[SAVE_BUTTON_META.PRODUCT_DESCRIPTION.id];
  }

  const modifications = [{ productId, changes }];
  const productMetaPromise = changeProductMeta(modifications);
  promises.push(productMetaPromise);

  await Promise.all(promises);
  const date = new Date().toISOString();
  setLastFetchDate({ date, catalogueId });
  toggleGlobalLoader(loaderKey, false);
};

const saveProductDetailsChanges = ({ data = {} }) => {
  const productId = getActiveProductId();
  if (!productId) {
    return;
  }
  saveSingleProductChanges({ data, productId });
};

const computeBasicInfoFromProductRow = productRowMetaCache => {
  const basicInfo = {};

  basicInfo.title =
    productRowMetaCache && productRowMetaCache.name ? productRowMetaCache.name : null;

  basicInfo.price =
    productRowMetaCache && typeof productRowMetaCache.price === 'number'
      ? productRowMetaCache.price
      : null;

  basicInfo.discount =
    productRowMetaCache && typeof productRowMetaCache.discount === 'number'
      ? productRowMetaCache.discount
      : null;

  basicInfo.description =
    productRowMetaCache && productRowMetaCache.description ? productRowMetaCache.description : null;

  const currencyCode = getCompanyCurrencyCode();
  basicInfo.currencySymbol = getCurrencySymbol({ currencyCode });

  return basicInfo;
};

const getBasicInfo = ({ activeProductId = '', isBulkEditing = '' }) => {
  if (isBulkEditing) {
    return {
      title: null,
      currencyCode: null,
      price: null,
      discount: null,
      description: null
    };
  }

  const productRowMetaCache = CacheRequest.getCacheForKey(
    `${connector.PRODUCT_ROW_META.cacheKey}${activeProductId}`
  );
  return computeBasicInfoFromProductRow(productRowMetaCache);
};

// Change product title
// Structure of meta: Array of these objects { productId, changes: { name?, price?, discount?, description? }  }

const changeProductMeta = async (meta = []) => {
  const loaderkey = `changeProductMeta${Date.now()}`;
  toggleGlobalLoader(loaderkey, true);
  const sharedCacheKey = connector.PRODUCT_ROW_META.cacheKey;
  const modifications = [];

  meta.forEach(({ productId, changes } = {}) => {
    if (!productId || !Object.keys(changes).length) {
      return;
    }

    const key = `${sharedCacheKey}${productId}`;
    const cache = CacheRequest.getCacheForKey(key);
    const newCache = { ...cache, ...changes };

    modifications.push(newCache);
    CacheRequest.setCacheForKey(key, newCache);
  });

  const promise = saveProductMetaChangesInNative({ modifications });
  Api.updateProduct({ productId: meta[0].productId, updates: meta[0].changes });

  toggleGlobalLoader(loaderkey, false);
  return promise;
};

const getDiscountInitDependencies = productId => {
  const key = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
  const { price, discount } = CacheRequest.getCacheForKey(key) || {};

  return {
    originalPrice: price,
    discount
  };
};

const getImageUrls = ({ isBulkEditing, activeProductId, activeProductIds }) => {
  if (isBulkEditing) {
    const ids = activeProductIds.slice(0, 12);
    return ids.map(id => {
      const key = `${connector.PRODUCT_ROW_META.cacheKey}${id}`;
      const { pictureUrl } = CacheRequest.getCacheForKey(key) || {};
      return pictureUrl;
    });
  } else {
    const key = `${connector.PRODUCT_ROW_META.cacheKey}${activeProductId}`;
    const cache = CacheRequest.getCacheForKey(key);
    return cache ? [cache.pictureUrl] : [];
  }
};

const setDefaultImageUrl = ({ imageUrl }) => {
  RENDERED_PRODUCT_IMAGE_META.meta.defaultImageUrl = imageUrl;
  RENDERED_PRODUCT_IMAGE_META.meta.imageUrl = imageUrl;
};

const isCurrentImageDefault = () =>
  RENDERED_PRODUCT_IMAGE_META.meta.imageUrl === RENDERED_PRODUCT_IMAGE_META.meta.defaultImageUrl;

const renderSelectedProductImage = ({ imageUrl }) => {
  RENDERED_PRODUCT_IMAGE_META.meta.imageUrl = imageUrl;
  eventbus.publish(RENDERED_PRODUCT_IMAGE_META.eventbusKey, { imageUrl });
};

const deleteCurrentImage = async productId => {
  const loaderKey = `deleteCurrentImage${productId}`;
  toggleGlobalLoader(loaderKey, true);

  const currentImageUrl = RENDERED_PRODUCT_IMAGE_META.meta.imageUrl;
  const pictureId = getPictureIdFromImageUrl({ url: currentImageUrl });
  const cacheKey = `${connector.BASIC_INFO.cacheKey}${productId}`;
  const basicInfo = CacheRequest.getCacheForKey(cacheKey);
  let index = -1;
  const picturesArray = Object.values(basicInfo.pictures);
  picturesArray.forEach((image, i) => {
    if (image.url === currentImageUrl) {
      index = i;
    }
  });

  if (index === -1) {
    return;
  }

  const nextImage = picturesArray[index + 1] ? picturesArray[index + 1] : picturesArray[index - 1];

  if (nextImage) {
    renderSelectedProductImage({
      imageUrl: nextImage.url
    });
  }

  picturesArray.splice(index, 1);
  const pictures = {};
  picturesArray.forEach(image => {
    pictures[image.id] = image;
  });

  CacheRequest.setCacheForKey(cacheKey, { ...basicInfo, pictures });

  await Api.deleteProductPicture({ productId, pictureIds: [pictureId] });
  toggleGlobalLoader(loaderKey, false);
};

// Save private notes
const savePrivateNotesInLocal = ({ notes, productIds }) => {
  const sharedCacheKey = connector.PRIVATE_NOTES.cacheKey;
  productIds.forEach(id => {
    const key = `${sharedCacheKey}${id}`;
    CacheRequest.setCacheForKey(key, notes);
  });
};

const savePrivateNotesInRemote = ({ notes, productIds }) => {
  return Api.setPrivateNotes(productIds, notes);
};

const savePrivateNotes = async ({ notes, productIds }) => {
  const loaderKey = `savePrivateNotes${Date.now()}`;
  try {
    toggleGlobalLoader(loaderKey, true);

    savePrivateNotesInLocal({ notes, productIds });
    await savePrivateNotesInRemote({ notes, productIds });

    toggleGlobalLoader(loaderKey, false);
  } catch (err) {
    console.log('savePrivateNotes Api: Could not update private notes in remote api', err);
    toggleGlobalLoader(loaderKey, false);
    Sentry.captureException(err);
  }
};

const getInventoryFromCache = ({ productId, isBulkEditing, catalogueId }) => {
  const defaultObject = {
    stockCount: 1,
    trackInventory: null,
    autoReduce: null,
    showOutOfStockProduct: null,
    stockManagedFrom: null,
    allowOrderOutOfStock: null,
    orderOnOOSControlledFrom: null,
    availableInCache: false,
    catalogueOOSProduct: null,
    catalogueOOSProductVisibility: false
  };

  if (!productId || isBulkEditing) {
    return defaultObject;
  }

  const cacheKey = `${connector.INVENTORY.cacheKey}${productId}`;
  const cache = CacheRequest.getCacheForKey(cacheKey);
  if (!cache) {
    return defaultObject;
  }

  const catalogueOOSCache = CacheRequest.getCacheForKey(
    `${connector.CATALOGUE_SETTINGS.cacheKey}${catalogueId}`
  );
  let catalogueOOSProductAvailable = false;
  let catalogueOOSProduct = null;

  if (
    catalogueOOSCache &&
    catalogueOOSCache.experiments &&
    typeof catalogueOOSCache.experiments.stockVisibility !== 'undefined'
  ) {
    catalogueOOSProductAvailable = true;
    catalogueOOSProduct = catalogueOOSCache.experiments.stockVisibility;
  }

  return {
    trackInventory: cache.trackInventory,
    stockCount: typeof cache.count === 'number' ? cache.count : 1,
    autoReduce: !!cache.autoReduceInventory === 'VISITOR',
    showOutOfStockProduct: cache.showOutOfStockProduct,
    stockManagedFrom: typeof cache.showOutOfStockProduct === 'boolean' ? 'PRODUCT' : 'CATALOGUE',
    allowOrderOutOfStock: cache.allowOrdersOnOutOfStock,
    orderOnOOSControlledFrom:
      typeof cache.allowOrdersOnOutOfStock === 'boolean' ? 'PRODUCT' : 'CATALOGUE',
    availableInCache: true,
    catalogueOOSProduct,
    catalogueOOSProductAvailable
  };
};

const getProductPriceFromCache = ({ productId }) => {
  const key = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
  const cache = CacheRequest.getCacheForKey(key) || {};
  return cache.price || null;
};

const getProductDiscountFromCache = ({ productId }) => {
  const key = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
  const cache = CacheRequest.getCacheForKey(key) || {};
  return cache.discount || null;
};

const bulkSaveTitle = async ({ productIds, title }) => {
  const loaderKey = `bulkSaveTitle${Date.now()}`;
  toggleGlobalLoader(loaderKey, true);
  try {
    const updates = { name: title };
    const nativeStorageUpdates = [];

    productIds.forEach(id => {
      const key = `${connector.PRODUCT_ROW_META.cacheKey}${id}`;
      const cache = CacheRequest.getCacheForKey(key);
      const newCache = {
        ...cache,
        ...updates
      };
      nativeStorageUpdates.push(newCache);
      CacheRequest.setCacheForKey(key, newCache);
    });

    await Promise.all([
      Api.bulkUpdateProducts({
        productIds,
        updates
      }),
      updateExistingProductsInNative(nativeStorageUpdates)
    ]);
    toggleGlobalLoader(loaderKey, false);
  } catch (e) {
    console.error('bulkSaveTitle: Error while saving bulk title', e);
    toggleGlobalLoader(loaderKey, false);
    Sentry.captureException(e);
  }
};

const bulkSavePrice = async ({ productIds, price }) => {
  const loaderKey = `bulkSavePrice${Date.now()}`;
  toggleGlobalLoader(loaderKey, true);
  const updates = { price: price };
  const nativeStorageUpdates = [];

  try {
    productIds.forEach(id => {
      const key = `${connector.PRODUCT_ROW_META.cacheKey}${id}`;
      const cache = CacheRequest.getCacheForKey(key);
      const newCache = {
        ...cache,
        ...updates
      };
      nativeStorageUpdates.push(newCache);
      CacheRequest.setCacheForKey(key, newCache);
    });

    await Promise.all([
      Api.bulkUpdateProducts({
        productIds,
        updates
      }),
      updateExistingProductsInNative(nativeStorageUpdates)
    ]);

    toggleGlobalLoader(loaderKey, false);
  } catch (err) {
    console.error('bulkSavePrice: Could not save price', err);
    toggleGlobalLoader(loaderKey, false);
    Sentry.captureException(err);
  }
};

const bulkSaveDiscount = async ({ productIds, discountInPercent }) => {
  const loaderKey = `bulkSaveDiscount${Date.now()}`;
  toggleGlobalLoader(loaderKey, true);
  const nativeStorageUpdates = [];
  const apiMeta = [];

  try {
    productIds.forEach(id => {
      const key = `${connector.PRODUCT_ROW_META.cacheKey}${id}`;
      const cache = CacheRequest.getCacheForKey(key);
      if (cache && typeof cache.price === 'number') {
        let discount = (discountInPercent * cache.price) / 100;
        discount = cache.price - discount;
        const apiUpdates = { id, price: cache.price, discounted_price: discount };
        const newCache = {
          ...cache,
          discount
        };

        apiMeta.push(apiUpdates);
        nativeStorageUpdates.push(newCache);
        CacheRequest.setCacheForKey(key, newCache);
      }
    });

    await Promise.all([
      Api.bulkUpdatePriceAndDiscount(apiMeta),
      updateExistingProductsInNative(nativeStorageUpdates)
    ]);
    toggleGlobalLoader(loaderKey, false);
  } catch (err) {
    console.error('bulkSaveDiscount: Could not save discount', err);
    toggleGlobalLoader(loaderKey, false);
    Sentry.captureException(err);
  }
};

const bulkSaveDescription = async ({ productIds, description }) => {
  const loaderKey = `bulkSaveDescription${Date.now()}`;
  toggleGlobalLoader(loaderKey, true);
  const updates = {
    description
  };
  const nativeStorageUpdates = [];

  try {
    productIds.forEach(id => {
      const key = `${connector.PRODUCT_ROW_META.cacheKey}${id}`;
      const cache = CacheRequest.getCacheForKey(key);
      const newCache = {
        ...cache,
        ...updates
      };
      nativeStorageUpdates.push(newCache);
      CacheRequest.setCacheForKey(key, newCache);
    });

    await Promise.all([
      Api.bulkUpdateProducts({ productIds, updates }),
      updateExistingProductsInNative(nativeStorageUpdates)
    ]);
  } catch (err) {
    console.error('bulkSaveDescription: could not save description', err);
    Sentry.captureException(err);
  }

  toggleGlobalLoader(loaderKey, false);
};

const setPictureAsDefault = async ({ pictureUrl, pictureId }) => {
  const loaderKey = `setPictureAsDefault${Date.now()}`;
  toggleGlobalLoader(loaderKey, true);

  RENDERED_PRODUCT_IMAGE_META.meta.defaultImageUrl = pictureUrl;
  const productId = getActiveProductId();
  const catalogueId = getActiveCatalogueId();

  const key = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
  const productRowCache = CacheRequest.getCacheForKey(key);
  const newCache = {
    ...productRowCache,
    pictureId,
    pictureUrl: RENDERED_PRODUCT_IMAGE_META.meta.imageUrl
  };
  CacheRequest.setCacheForKey(key, newCache);

  const position = (PRODUCT_POSITION_MAP[catalogueId][productId] || {}).position;
  if (position < 4) {
    const key = `${connector.CATALOGUE_ROW_META.cacheKey}${catalogueId}`;
    const cache = CacheRequest.getCacheForKey(key);

    const newPictureIds = [...cache.pictureIds];
    newPictureIds[position] = {
      pictureId,
      prepared: true
    };

    const newCache = {
      ...cache,
      pictureIds: newPictureIds
    };

    CacheRequest.setCacheForKey(key, newCache);
  }

  const updates = {
    default_picture_id: pictureId,
    pictureUrl: pictureUrl
  };

  await Promise.all([
    Api.updateProduct({ productId, updates }),
    updateExistingProductsInNative([newCache])
  ]);

  toggleGlobalLoader(loaderKey, false);
};

const autoSaveProductDetails = () => {
  eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
    eventType: SAVE_BUTTON_META.eventType.AUTO_SAVE.id
  });
};

export {
  BOTTOM_SHEET_TABS,
  SAVE_BUTTON_META,
  RENDERED_PRODUCT_IMAGE_META,
  PRODUCT_SELECTION_TYPE,
  attachBasicInfoListener,
  removeBasicInfoListener,
  getBasicInfoFromRemote,
  attachPrivateNotesListener,
  removePrivateNotesListener,
  getPrivateNotes,
  attachInventoryListener,
  removeInventoryListener,
  getInventory,
  productDetailsInitialState,
  saveProductDetailsChanges,
  getBasicInfo,
  computeBasicInfoFromProductRow,
  getDiscountInitDependencies,
  getImageUrls,
  renderSelectedProductImage,
  setDefaultImageUrl,
  isCurrentImageDefault,
  deleteCurrentImage,
  savePrivateNotes,
  getInventoryFromCache,
  getProductPriceFromCache,
  getProductDiscountFromCache,
  bulkSaveTitle,
  bulkSavePrice,
  bulkSaveDiscount,
  bulkSaveDescription,
  setPictureAsDefault,
  autoSaveProductDetails
};
