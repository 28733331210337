class Selected {
  constructor() {
    this.active = false;
    this.selected = {};
    this.listeners = [];
    this.activeListeners = [];
    this.allItems = [];
  }

  // is selection state active
  isActive() {
    return this.active;
  }

  isSelected(key) {
    return !!this.selected[key];
  }

  isAnySelected() {
    return !!Object.keys(this.selected).length;
  }

  getCount() {
    return Object.keys(this.selected).length;
  }

  // get all selected key
  getAll() {
    return this.selected;
  }

  // checks if all items of that list are selected
  areAllSelected() {
    return this.getCount() === this.allItems.length && this.allItems.length !== 0;
  }

  // takes passed array and considers it as the list on which actions will be performed
  setAllItems(items) {
    this.allItems = items;
  }

  getAllItemsCount() {
    return this.allItems.length;
  }

  // activates the selection state and notifies everybody that is subscribed
  activate() {
    if (this.active) {
      return;
    }
    this.active = true;
    this.notifyActive();
  }

  // deactivates the selection state and notifies everybody that is subscribed and removes all selected items
  deactivate() {
    if (!this.active) {
      return;
    }

    this.active = false;
    this.notifyActive();

    this.removeAll();
  }

  // deactivates the selection state and removes all items from the selected list
  reset() {
    this.deactivate();
    this.setAllItems([]);
  }

  // notifies all subscribed listeners of the selected list
  notify() {
    this.listeners.forEach(listener => {
      listener(this.getAll());
    });
  }

  //
  notifyActive() {
    this.activeListeners.forEach(listener => {
      listener(this.isActive());
    });
  }

  toggle(key) {
    if (this.selected[key]) {
      this.remove(key);
    } else {
      this.add(key);
    }
  }

  set(selected) {
    this.selected = selected;
    this.notify();
  }

  setAll() {
    const selected = {};
    this.allItems.forEach(item => {
      selected[item] = true;
    });

    this.set(selected);
  }

  add(key) {
    this.selected[key] = true;
    this.notify();
  }

  remove(key) {
    delete this.selected[key];
    this.notify();
  }

  // removes all items from the selection state
  removeAll() {
    this.selected = {};
    this.notify();
  }

  addListener(listener) {
    this.listeners.push(listener);

    listener(this.getAll());
  }

  removeListener(listener) {
    const pos = this.listeners.indexOf(listener);

    if (pos !== -1) {
      this.listeners.splice(pos, 1);
    }
  }

  addActiveListener(listener) {
    this.activeListeners.push(listener);

    listener(this.isActive());
  }

  removeActiveListener(listener) {
    const pos = this.activeListeners.indexOf(listener);

    if (pos !== -1) {
      this.activeListeners.splice(pos, 1);
    }
  }
}

class SelectedCatalogues extends Selected {}
export const selectedCatalogue = new SelectedCatalogues();

class SelectedProducts extends Selected {}
export const selectedProducts = new SelectedProducts();

// Put this in services/helpers
