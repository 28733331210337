import React, { Component } from 'react';

import './InquiryMultiSelectHeader.scss';
import CheckBox from 'qs-components/Common/CheckBox';
import MultiSelectInquiry from 'qs-helpers/MultiSelectInquiry';

import { ReactComponent as DeleteIcon } from 'qs-assets/Media/delete-icon.svg';

class InquiryMultiSelectHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCheckboxSelected: false
    };
  }

  componentDidMount() {
    MultiSelectInquiry.attachSelectAllListener(this.areAllSelectedListener);
  }

  areAllSelectedListener = areAllSelected => {
    if (this.state.isCheckboxSelected !== areAllSelected) {
      this.setState({ isCheckboxSelected: areAllSelected });
    }
  };

  onCheckboxClick = () => {
    const { toggleSelectAll } = MultiSelectInquiry;

    this.setState(prevState => {
      const currenState = !prevState.isCheckboxSelected;
      toggleSelectAll(currenState);
      return { isCheckboxSelected: currenState };
    });
  };

  render() {
    const { isCheckboxSelected } = this.state;
    const { onDeleteSelectedOrder } = this.props;

    return (
      <div className="inquiryMultiSelectHeaderContainer">
        <div className="leftContainer">
          <CheckBox checked={isCheckboxSelected} onCheckChanged={this.onCheckboxClick} />
          <div className="selcectAll">{isCheckboxSelected ? 'Deselect all' : 'Select all'}</div>
        </div>
        <div className="rightContainer">
          <div onClick={onDeleteSelectedOrder} className="deleteIconContainer">
            <DeleteIcon className="deleteIcon" />
          </div>
        </div>
      </div>
    );
  }
}

export default InquiryMultiSelectHeader;
