import React, { useEffect, useState } from 'react';
import ProductDetailsBottomSheet from './ProductDetailsBottomSheet';
import eventbus from 'eventing-bus';
import { ACTIVE_PRODUCT_ID_META } from 'qs-data-manager/Products';
import EmptyProductDetailsScreen from './EmptyProductDetailsScreen';
import ProductHeader from './ProductHeader';
import ProductImage from './ProductImage';
import { selectedProducts } from 'qs-data-manager/Selected';
import { resetActiveProductId } from 'qs-data-manager/Products';
import UploadImagesHeader from './UploadImagesHeader';

import './styles.scss';
import { ACTIVE_CATALOGUE_META } from 'qs-data-manager/Catalogues';

export default () => {
  const [activeProductIdMeta, setActiveProductIdMeta] = useState({
    activeProductId: null,
    activeProductIds: null,
    isBulkEditing: null
  });

  // Effect for checking product id
  useEffect(() => {
    const removeListener = eventbus.on(ACTIVE_PRODUCT_ID_META.eventbusKey, ({ productId } = {}) => {
      setActiveProductIdMeta(prevState => ({
        ...prevState,
        activeProductId: productId
      }));
    });

    return removeListener;
  }, []);

  // Effect for checking catalogue id
  useEffect(() => {
    const removeListener = eventbus.on(ACTIVE_CATALOGUE_META.eventbusKey, () => {
      setActiveProductIdMeta(prevState => ({
        ...prevState,
        activeProductId: null,
        activeProductIds: null,
        isBulkEditing: null
      }));
    });

    return removeListener;
  }, []);

  useEffect(() => {
    const onSelectionStateChange = value => {
      setActiveProductIdMeta(prevState => ({
        ...prevState,
        isBulkEditing: value ? value : null,
        activeProductId: null
      }));

      if (value) {
        resetActiveProductId();
      }
    };

    const onSelectedValueChange = all => {
      const productIds = Object.keys(all || {});
      setActiveProductIdMeta(prevState => ({
        ...prevState,
        activeProductIds: productIds.length ? productIds : null
      }));
    };

    selectedProducts.addActiveListener(onSelectionStateChange);
    selectedProducts.addListener(onSelectedValueChange);

    return () => {
      selectedProducts.removeActiveListener(onSelectionStateChange);
      selectedProducts.removeListener(onSelectedValueChange);
    };
  }, []);

  return (
    <div id={'ProductDetailsScreen'}>
      {activeProductIdMeta.activeProductId || activeProductIdMeta.activeProductIds ? (
        <>
          <ProductImage
            isBulkEditing={activeProductIdMeta.isBulkEditing}
            activeProductId={activeProductIdMeta.activeProductId}
            activeProductIds={activeProductIdMeta.activeProductIds}
          />
          <ProductDetailsBottomSheet
            isBulkEditing={activeProductIdMeta.isBulkEditing}
            activeProductId={activeProductIdMeta.activeProductId}
            activeProductIds={activeProductIdMeta.activeProductIds}
          />
          {activeProductIdMeta.isBulkEditing ? null : (
            <ProductHeader
              activeProductId={activeProductIdMeta.activeProductId}
              activeProductIds={activeProductIdMeta.activeProductIds}
            />
          )}
          {activeProductIdMeta.isBulkEditing ? null : (
            <UploadImagesHeader activeProductId={activeProductIdMeta.activeProductId} />
          )}
        </>
      ) : (
        <EmptyProductDetailsScreen />
      )}
    </div>
  );
};

// TODO: reset active product id when product list goes into selection mode
