const { ipcRenderer } = window.require('electron');

const cachedPromises = {};

export const compressImageUsingWorker = ({ imageId, imagePath }) => {
  if (cachedPromises[imageId]) {
    return cachedPromises[imageId];
  }

  return new Promise((resolve, reject) => {
    cachedPromises[imageId] = {
      success: resolve,
      error: reject
    };

    ipcRenderer.send('compress-images', { imagePath, imageId });
  });
};

ipcRenderer.on('compress-images-complete', (_, { result, imageId }) => {
  /*
    For an image there are no resolvers, this process may have restarted
    where as the worker must not, do not process anything
  */
  if (!cachedPromises[imageId]) {
    return;
  }

  const { success, error } = cachedPromises[imageId];
  delete cachedPromises[imageId];
  if (result.error) {
    error(result.error);
    return;
  }

  success(result.data);
});
