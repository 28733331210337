import React, { useState, useEffect } from 'react';
import './PlansBilling.scss';
import {
  attachCompanyPlanListener,
  getCompanyPlan,
  removeCompanyPlanListener,
  getCompanyPlanFromCache
} from 'qs-data-manager/Company';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import Loader from 'qs-components/Common/Loader';
import moment from 'moment-timezone';
import toastr from 'toastr';

export default () => {
  //TODO add usage statistics view
  //const [showStatistics, setStatistics] = useState(false);
  const [planDetails, setPlanDetails] = useState(getCompanyPlanFromCache());

  useEffect(() => {
    const plansListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (loading || refreshing) {
        return;
      }

      if (err) {
        toastr.error('Something went wrong while fetching the plan details');
        return;
      }

      setPlanDetails(data);
    };

    attachCompanyPlanListener(plansListener);
    getCompanyPlan();

    return () => removeCompanyPlanListener(plansListener);
  }, []);

  /*const handleUsageClick = function(event) {
    event.preventDefault();
    setStatistics(true);
  };

  //TODO add usage statisitcs view
  const closeStatisticsView = function() {
    setStatistics(false);
  };*/

  let planSettingsComponent;
  if (planDetails) {
    planSettingsComponent = (
      <div className="plans-billing-container">
        <img src={'https://web.quicksell.co/static/media/ic_plans.png'} alt="" />
        <h3>Plans and Billing</h3>
        <section className="plans-billing-section">
          <h3 className="plans-billing-section-title">Current Plan</h3>
          <div className="plans-billing-data-container">
            <span className="plans-billing-data-title">Plan Name</span>
            <span className="plans-billing-data-value">{planDetails.planName}</span>
          </div>
          <div className="plans-billing-data-container">
            <span className="plans-billing-data-title">
              {planDetails.autoRenewing ? 'Renews on' : 'Expires on'}
            </span>
            <span className="plans-billing-data-value">
              {moment(planDetails.expiryTime).format('Do MMM YYYY')}
            </span>
          </div>
          {/*
            TODO to be added in the enxt release, hence leaving this commented because the view is correct
            <div className="plans-billing-data-container">
              <span className="plans-billing-data-title">Usage Details</span>
              <button onClick={handleUsageClick} className="plans-billing-data-usage">
                View Usage Statistics
              </button>
            </div>
            */}
        </section>
      </div>
    );
  } else {
    planSettingsComponent = <Loader size="large" />;
  }

  return <div className="plans-billing-container">{planSettingsComponent}</div>;
};
