import React, { Component } from 'react';
import isEqual from 'lodash.isequal';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import Modal from 'react-responsive-modal';
import phone from 'qs-assets/Media/phone.png';
import whatsapp from 'qs-assets/Media/whatsappOutline.png';
import camera from 'qs-assets/Media/camera.png';
import excel from 'qs-assets/Media/excel.png';
import pdf from 'qs-assets/Media/pdf.png';
import zip from 'qs-assets/Media/zip.png';
import { ReactComponent as ContactIcon } from 'qs-assets/Media/contact.svg';
import { ReactComponent as SendIcon } from 'qs-assets/Media/send.svg';
import { ReactComponent as DeleteIcon } from 'qs-assets/Media/delete.svg';
import { ReactComponent as SendToVendor } from 'qs-assets/Media/sendToVendor.svg';
import PropTypes from 'prop-types';
import 'toastr/build/toastr.min.css';
import './Header.css';
import toastr from 'toastr';
import api from 'qs-services/Api';
import { toggleGlobalLoader } from 'qs-helpers';
import { getCompanyDomain } from 'qs-data-manager/Company';
import * as Sentry from "@sentry/browser";

const downloadPdfPromise = {};
const downloadExcelPromise = {};
const downloadZipPromise = {};

const ERROR_MESSAGE = 'Something went wrong. Please try again later.';
const modalStyles = {
  backgroundColor: 'white',
  borderRadius: 5,
  padding: '20px'
};

class Header extends Component {
  state = {
    buyerPopup: false,
    sendPopup: false,
    deleteModal: false,
    isPopoverOpen: false
  };

  static propTypes = {
    contact: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.callAnchor = document.createElement('a');
    this.chatAnchor = document.createElement('a');

    toastr.options = {
      positionClass: 'toast-bottom-right',
      showDuration: 300,
      hideDuration: 1000,
      timeOut: 2000
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.contact !== this.props.contact || !isEqual(nextState, this.state);
  }

  toggleContactPopup = () => {
    this.setState(() => ({
      buyerPopup: !this.state.buyerPopup,
      sendPopup: false,
      deletePopup: false
    }));
  };

  toggleSendPopup = () => {
    this.setState(() => ({
      sendPopup: !this.state.sendPopup,
      buyerPopup: false,
      deletePopup: false
    }));
  };

  downloadZip = async () => {
    // const { toggleGlobalLoading } = this.props;
    this.setState({ sendPopup: false });
    const key = `downloadZip${Date.now()}`;
    const { orderId } = this.props;

    if (downloadZipPromise[orderId]) {
      return;
    }

    toggleGlobalLoader(key, true);

    const promise = new Promise(async (resolve, reject) => {
      try {
        const response = await api.downloadZip(orderId);
        resolve(response);
      } catch (err) {
        reject(err);
        delete downloadZipPromise[orderId];
        toggleGlobalLoader(key, false);
        toastr.error(ERROR_MESSAGE);
        console.log('err in download zip', err);
        Sentry.captureException(err);
      }
    });

    downloadZipPromise[orderId] = promise;

    const { zipLink } = await promise;

    delete downloadZipPromise[orderId];

    const a = document.createElement('a');
    a.href = zipLink;
    a.click();
    toggleGlobalLoader(key, false);
  };

  downloadPDF = async () => {
    const key = `downloadPDF${Date.now()}`;
    const { orderId } = this.props;

    this.setState({ sendPopup: false });

    if (downloadPdfPromise[orderId]) {
      return;
    }

    toggleGlobalLoader(key, true);

    const promise = new Promise(async (resolve, reject) => {
      try {
        const { pdfLink } = await api.downloadPDF(orderId);
        resolve(pdfLink);
      } catch (err) {
        reject(err);
        delete downloadPdfPromise[orderId];
        toggleGlobalLoader(key, false);
        toastr.error(ERROR_MESSAGE);
        console.log('err in download pdf', err);
        Sentry.captureException(err);
      }
    });

    downloadPdfPromise[orderId] = promise;

    const pdfLink = await promise;
    delete downloadPdfPromise[orderId];

    if (pdfLink) {
      const a = document.createElement('a');
      a.href = pdfLink;
      a.click();
      toggleGlobalLoader(key, false);
    }
  };

  downloadExcel = async () => {
    const key = `downloadExcel${Date.now()}`;
    this.setState({ sendPopup: false });
    const { orderId } = this.props;

    if (downloadExcelPromise[orderId]) {
      return;
    }

    toggleGlobalLoader(key, true);

    const promise = new Promise(async (resolve, reject) => {
      try {
        const response = await api.downloadExcel(orderId);
        resolve(response);
      } catch (err) {
        reject(err);
        delete downloadExcelPromise[orderId];
        toggleGlobalLoader(key, false);
        toastr.error(ERROR_MESSAGE);
        console.log('err in download excel', err);
        Sentry.captureException(err);
      }
    });

    downloadExcelPromise[orderId] = promise;

    const { excelLink } = await promise;
    delete downloadExcelPromise[orderId];

    const a = document.createElement('a');
    a.href = excelLink;
    a.click();
    toggleGlobalLoader(key, false);
  };

  sendOrderToVendor = async () => {
    const { orderId } = this.props;
    const key = `sendOrderToVendor${Date.now()}`;
    this.setState({ sendPopup: false });

    try {
      toggleGlobalLoader(key, true);
      await api.sendOrderToVendor(orderId);
      toggleGlobalLoader(key, false);
    } catch (err) {
      toggleGlobalLoader(key, false);
      toastr.error(ERROR_MESSAGE);
      console.log('err while sending order to vendor', err);
      Sentry.captureException(err);
    }
  };

  shareLink = () => {
    const { contact, orderId } = this.props;
    this.setState({ sendPopup: false });

    let domain = getCompanyDomain() || 'quicksell.co';
    let protocol = 'http://';

    if (domain.indexOf('.catalog.to') !== -1 || domain === 'quicksell.co') {
      protocol = 'https://';
    }

    const link = `${protocol}${domain}/order/${orderId}`;

    this.openWhatsApp(contact, link);
    // this.openWhatsApp(contact,'I would like to send you this catalogue.');
  };

  onCallClick = () => {
    const { contact } = this.props;
    this.callAnchor.href = `tel:${contact}`;
    this.callAnchor.click();
    this.setState({ buyerPopup: false });
  };

  onChatClick = () => {
    const { contact } = this.props;
    this.setState({ buyerPopup: false });
    const text = 'Hello';
    this.openWhatsApp(contact, text);
  };

  openWhatsApp = (contact, text) => {
    let whatsappNumber = contact;
    if (contact.charAt(0) === '+') {
      whatsappNumber = whatsappNumber.slice(1);
    }

    const href = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${text}`;
    window.open(href, '_blank');
  };

  showDeleteModal = () => {
    this.setState({ deleteModal: true });
  };

  closeModal = () => {
    this.setState({ deleteModal: false });
  };

  onDelteInquiry = () => {
    const { deleteOrderClicked } = this.props;
    deleteOrderClicked();
    this.closeModal();
  };

  renderDeleteModal = () => {
    const { deleteModal } = this.state;

    if (deleteModal) {
      return (
        <Modal
          open={deleteModal}
          onClose={this.closeModal}
          center
          styles={{ modal: modalStyles }}
          showCloseIcon={false}
        >
          <div className="inquiryDeleteModal">
            <div className="header">Delete order</div>
            <div className="title">Are you sure you want to delete this order?</div>
            <div className="buttonContainer">
              <div onClick={this.closeModal} className="cancelButton">
                Cancel
              </div>
              <div onClick={this.onDelteInquiry} className="deleteButton">
                Delete order
              </div>
            </div>
          </div>
        </Modal>
      );
    }

    return null;
  };

  closeBuyerPopup = () => this.setState({ buyerPopup: false });
  closeSendPopup = () => this.setState({ sendPopup: false });

  render() {
    const { buyerPopup, sendPopup } = this.state;
    const { isOrderDeleted, isInheritedCatalogue } = this.props;

    const rowStyles = isOrderDeleted ? 'disabledRow' : 'singleRow';

    return (
      <div className="InquriesHeadaerContainer">
        <div className="extraOptionsContainer">
          <div className="optionContainer">
            <Popover
              isOpen={buyerPopup}
              position={['bottom']}
              padding={10}
              onClickOutside={this.closeBuyerPopup}
              containerStyle={{ overflow: 'visible' }}
              content={({ position, targetRect, popoverRect }) => (
                <ArrowContainer
                  position={position}
                  targetRect={targetRect}
                  popoverRect={popoverRect}
                  arrowColor={'white'}
                  arrowSize={10}
                  arrowStyle={{ opacity: 1, top: '10px' }}
                  style={{ top: '10px' }}
                >
                  <div className="verticalFlex popupContainer">
                    <button onClick={this.onCallClick} className="singleRow">
                      <div className="iconContainer">
                        <img src={phone} className="popupIcon" alt="" />
                      </div>
                      Call
                    </button>
                    <button onClick={this.onChatClick} className="singleRow">
                      <div className="iconContainer">
                        <img src={whatsapp} className="popupIcon" alt="" />
                      </div>
                      Chat
                    </button>
                  </div>
                </ArrowContainer>
              )}
            >
              <button onClick={this.toggleContactPopup} className="optionsButtonContainer">
                <div className="iconContainer">
                  <ContactIcon className="icon contactIcon" />
                </div>
                <div className="optionsTitleContainer contactTitle">Contact buyer</div>
              </button>
            </Popover>
          </div>

          <div className="optionContainer">
            <Popover
              isOpen={sendPopup}
              position={['bottom']}
              padding={10}
              onClickOutside={this.closeSendPopup}
              containerStyle={{ overflow: 'visible' }}
              content={({ position, targetRect, popoverRect }) => (
                <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                  position={position}
                  targetRect={targetRect}
                  popoverRect={popoverRect}
                  arrowColor={'white'}
                  arrowSize={10}
                  arrowStyle={{ opacity: 1, top: '10px' }}
                  style={{ top: '10px' }}
                >
                  <div className="verticalFlex popupContainer">
                    <button
                      disabled={isOrderDeleted}
                      onClick={this.shareLink}
                      className="singleRow"
                    >
                      <div className="iconContainer">
                        <img src={camera} className="popupIcon" alt="" />
                      </div>
                      <div className="popupTitle">Send order link</div>
                    </button>
                    <button
                      disabled={isOrderDeleted}
                      onClick={this.downloadExcel}
                      className={rowStyles}
                    >
                      <div className="iconContainer">
                        <img src={excel} className="popupIcon" alt="" />
                      </div>
                      <div className="popupTitle">Download as excel</div>
                    </button>
                    <button
                      disabled={isOrderDeleted}
                      onClick={this.downloadZip}
                      className={rowStyles}
                    >
                      <div className="iconContainer">
                        <img src={zip} className="popupIcon" alt="" />
                      </div>
                      <div className="popupTitle">Download as zip</div>
                    </button>
                    <button
                      disabled={isOrderDeleted}
                      onClick={this.downloadPDF}
                      className={rowStyles}
                    >
                      <div className="iconContainer">
                        <img src={pdf} className="popupIcon" alt="" />
                      </div>
                      <div className="popupTitle">Download pdf</div>
                    </button>
                    {!!isInheritedCatalogue && (
                      <button
                        disabled={isOrderDeleted}
                        onClick={this.sendOrderToVendor}
                        className={rowStyles}
                      >
                        <div className="iconContainer">
                          <SendToVendor className="popupIcon" />
                        </div>
                        <div className="popupTitle">Send to vendor</div>
                      </button>
                    )}
                  </div>
                </ArrowContainer>
              )}
            >
              <button onClick={this.toggleSendPopup} className="optionsButtonContainer">
                <div className="iconContainer">
                  <SendIcon className="icon sendIcon" />
                </div>
                <div className="optionsTitleContainer sendTitle">Send order</div>
              </button>
            </Popover>
          </div>

          <div className="optionContainer">
            <button onClick={this.showDeleteModal} className="optionsButtonContainer">
              <div className="iconContainer">
                <DeleteIcon className="icon deleteIcon" />
              </div>
              <div className="optionsTitleContainer deleteTitle">Delete</div>
            </button>
          </div>
          {this.renderDeleteModal()}
        </div>
      </div>
    );
  }
}

export default Header;
