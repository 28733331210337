import React, { useState, useEffect } from 'react';
import ProductDemo from '../../ProductDemo/ProductDemo';
import SettingsRow from '../../../Common/SettingsRow/SettingsRow';
import {
  getCompanyProductDemoFromCache,
  attachCompanyProductDemoListener,
  getCompanyProductDemo,
  removeCompanyProductDemoListener
} from 'qs-data-manager/Company';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import toastr from 'toastr';

export default ({ classForContainer }) => {
  const [showProductDemo, toggleProductDemo] = useState(() => {
    const productDemo = getCompanyProductDemoFromCache();
    if (productDemo) {
      return productDemo.status;
    }
    return false;
  });

  // Attach product demo listener to determine whether to show the product demo link or not
  useEffect(() => {
    const productDemoListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (loading || refreshing) {
        return;
      }

      if (err) {
        toastr.error('Something went wrong while fetching the info for the demo option');
        return;
      }

      if (data && typeof data.status === 'boolean') {
        toggleProductDemo(data.status);
      }
    };

    attachCompanyProductDemoListener(productDemoListener);
    getCompanyProductDemo();

    return () => removeCompanyProductDemoListener(productDemoListener);
  }, []);

  if (!showProductDemo) {
    return null;
  }

  return (
    <div className={classForContainer}>
      <SettingsRow
        imageSrc={'https://web.quicksell.co/static/media/ic_speak_to_expert.png'}
        title="Product Demo"
      >
        <ProductDemo />
      </SettingsRow>
    </div>
  );
};
