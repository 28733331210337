import React, { useCallback, useState, useEffect } from 'react';
import './styles.scss';
import { PRODUCT_SEARCH, setProductPriceFilter } from 'qs-data-manager/Products';
import eventbus from 'eventing-bus';

export default ({ result = '', shouldShowClearFilter = true }) => {
  // const [resultValue, setResultValue] = useState(result);

  // useEffect(() => {
  //   setResultValue(result);
  // }, [result]);

  const clearResult = useCallback(() => {
    // setResultValue(null);
    setProductPriceFilter(null);
    eventbus.publish(PRODUCT_SEARCH.PRODUCT_SEARCH_EB_KEY, {
      type: PRODUCT_SEARCH.FILTER_PRODUCT_STATE,
      value: null
    });
  }, []);

  if (!result) {
    return null;
  }

  return (
    <div id={'catalogueSearchResult'}>
      <div className={'result'}>{result}</div>
      {!!shouldShowClearFilter && (
        <div onClick={clearResult} className={'clearFilterContainer'}>
          Clear filter
        </div>
      )}
    </div>
  );
};
