import { connector } from './ApiAndCacheConnector';
import CacheRequest from './CacheRequest';
import { COMPANY_LOCAL_STORAGE_KEYS } from 'qs-data-manager/Company';
import Api from '../Api';

const attachUserMetaListener = listener => {
  const key = connector.USER_META.cacheKey;
  CacheRequest.attachListener(key, listener);
};

const removeUserMetaListener = listener => {
  const key = connector.USER_META.cacheKey;
  CacheRequest.removeListener(key, listener);
};

/*
 * @description Sets the user object in RAM and localstorage and notifies all listeners of this change
 * @params {Object} data - Entire user object
 * @params {Object} data.email - Email id of the user
 * @params {Object} data.id - Unique id of the user
 * @params {Object} data.name - Name of the user
 * @params {Object} data.phone - Phone number of the user
 * @params {Object} data.profileImageUrl - User's image's url
 * @params {Object} data.representingCompanyId - User's company id
 * @params {Object} data.timestamp_created - Timestamp the user was created in database
 * @params {Object} data.timestamp_last_visited_app - Timestamp the user last visited the app
 */

const setUserMetaInCache = ({data, error = null} = {}) => {
  const key = connector.USER_META.cacheKey;
  CacheRequest.setCacheForKey(key, data, {error});
  localStorage.setItem(COMPANY_LOCAL_STORAGE_KEYS.USER_META, JSON.stringify(data));
};

const getUserMetaFromCache = () => {
  const key = connector.USER_META.cacheKey;
  const cache = CacheRequest.getCacheForKey(key);
  return cache;
};

const getUserMetaForUserDetails = () => {
  const key = connector.USER_META.cacheKey;
  const user = CacheRequest.getCacheForKey(key);
  if (!user) {
    return {
      userName: '',
      phone: ''
    };
  }

  return {
    userName: user.name,
    phone: user.phone
  };
};

const getUserIdFromCache = () => {
  const key = connector.USER_META.cacheKey;
  const user = CacheRequest.getCacheForKey(key);

  if (!user) {
    const parseData = JSON.parse(localStorage.getItem(COMPANY_LOCAL_STORAGE_KEYS.USER_META));
    return parseData && parseData.id ? parseData.id : null;
  }
  return user.id || null;
};

const getUserId = () => {
  const cache = CacheRequest.getCacheForKey(connector.USER_META.cacheKey);
  return cache.id;
};

const getUserEmail = () => {
  const cache = CacheRequest.getCacheForKey(connector.USER_META.cacheKey);
  return cache.email || '';
};

const updateUser = (value) => {
  Api.updateUserMetaData(value, getUserIdFromCache()).catch(error => error)
}

const updateUserName = (userName) => {
  if(typeof userName !== 'string') {
    return;
  }
  updateUser({name: userName})
}

export {
  attachUserMetaListener,
  removeUserMetaListener,
  getUserMetaForUserDetails,
  setUserMetaInCache,
  getUserIdFromCache,
  getUserMetaFromCache,
  getUserId,
  getUserEmail,
  updateUserName
};
