import { connector } from './ApiAndCacheConnector';
import CacheRequest from './CacheRequest';
import Api from 'qs-services/Api';
import CategoryMapper from 'qs-helpers/CategoryMapper';
import CurrencyMapper from 'qs-helpers/CurrencyMapper';
import { PLAN_TYPES, saveCurrentPlan } from './FeatureUsage';
import { userUnauthorized } from './Authentication';
import { getUserEmail, getUserMetaFromCache } from './User';
//TODO convert to qs imports
import {
  attachCompanySettingsFirebaseListener,
  removeCompanySettingsFirebaseListener,
  attachCompanyShareTextFirebaseListener,
  removeCompanyShareTextFirebaseListener
} from '../Firebase/Company';
import * as Sentry from "@sentry/browser";

// TODO: move this to more common location with a generic name like
const COMPANY_LOCAL_STORAGE_KEYS = {
  COMPANY_META: 'COMPANY_META',
  USER_META: 'USER_META',
  AUTH_UUID: 'AUTH_UUID'
};

const COMPANY_LANGUAGUES = new Map();
COMPANY_LANGUAGUES.set('en_US', 'English');
COMPANY_LANGUAGUES.set('es_ES', 'Español');
COMPANY_LANGUAGUES.set('pt_PT', 'Português');
COMPANY_LANGUAGUES.set('hi_IN', 'हिंदी');
COMPANY_LANGUAGUES.set('fr_FR', 'French');
COMPANY_LANGUAGUES.set('id_ID', 'Indonesian');
COMPANY_LANGUAGUES.set('ar_EG', 'Arabic');

const CUSTOM_SHARE_TEXT_APPEND =
  '\nCheckout the *{catalogue_name}* catalog here - \n{showcase_link}\n\n\n\nSent via QuickSell App';

// Company settings
const attachCompanySettingsListener = listener => {
  const key = `${connector.COMPANY_SETTINGS.cacheKey}`;
  CacheRequest.attachListener(key, listener);
};

const removeCompanySettingsListener = listener => {
  const key = `${connector.COMPANY_SETTINGS.cacheKey}`;
  CacheRequest.removeListener(key, listener);
};

const getCompanySettings = () => {
  const key = `${connector.COMPANY_SETTINGS.cacheKey}`;
  const apiName = connector.COMPANY_SETTINGS.apiFunction;
  return CacheRequest.makeRequest(key, apiName, {
    params: [],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

// Company meta
const attachCompanyMetaListener = listener => {
  const key = connector.COMPANY_META.cacheKey;
  CacheRequest.attachListener(key, listener);
};

const removeCompanyMetaListener = listener => {
  const key = connector.COMPANY_META.cacheKey;
  CacheRequest.removeListener(key, listener);
};

/*
 * @description Sets the company object in RAM and localstorage and notifies all listeners of this change
 * @params {Object} data - Entire company object
 * @params {Object} data.address - address of the company
 * @params {Object} data.catalogues - all catalogue ids of the company
 * @params {Object} data.categories - predefined category the company belongs to
 * @params {string} data.companyRepUserId - user id of the company
 * @params {string} data.currencyCode - currency code of that company
 * @params {string} data.logoExists - Company has logo or not
 * @params {string} data.logoVersion - Version of the logo
 * @params {string} data.name - Name of the company
 * @params {Object} data.products - All existing productIds of the company
 * @params {Object} data.showcase - All existing showcaseIds of the company
 */

const setCompanyMetaInCache = ({data, error = null } = {}) => {
  const key = connector.COMPANY_META.cacheKey;
  CacheRequest.setCacheForKey(key, data, { error });
  const localStorageData = JSON.stringify(data);
  localStorage.setItem(COMPANY_LOCAL_STORAGE_KEYS.COMPANY_META, localStorageData);
};

const getCompanyMetaFromCache = () => {
  const key = connector.COMPANY_META.cacheKey;
  const cache = CacheRequest.getCacheForKey(key);
  return cache;
};

const rehydrateCompanyMeta = data => {
  const companyMeta = JSON.parse(data);
  const key = connector.COMPANY_META.cacheKey;
  CacheRequest.setCacheForKey(key, companyMeta);
};

const updateCompanyMeta = value => {
  Api.updateCompanyMetaData(value, getCompanyIdFromCache()).catch(error =>
    setCompanyMetaInCache({ error })
  );
};

const updateCompanyAddress = addressObj => {
  //Default value can be null but undefined is not allowed
  updateCompanyMeta({
    address: {
      addressLineOne: addressObj.addressLineOne || null,
      addressLineTwo: addressObj.addressLineTwo || null,
      city: addressObj.city || null,
      country: addressObj.country || null,
      pincode: addressObj.pincode || null,
      state: addressObj.state || null
    }
  });
};

const updateCompanyNameAndCurrency = (companyName, currency) => {
  if (typeof companyName !== 'string' || typeof currency !== 'string') {
    return;
  }

  //Default value can be null but undefined is not allowed
  updateCompanyMeta({ name: companyName, currencyCode: currency });
};

const rehydrateUserMeta = data => {
  const userMeta = JSON.parse(data);
  const key = connector.USER_META.cacheKey;
  CacheRequest.setCacheForKey(key, userMeta);
};

const getCompanySettingsFromCache = () => {
  const key = `${connector.COMPANY_SETTINGS.cacheKey}`;
  const cache = CacheRequest.getCacheForKey(key);

  return cache
    ? {
        stockVisibility: cache.showOutOfStockProduct,
        orderOnOOS: cache.allowOrdersOnOutOfStock,
        trackInventory: cache.trackInventory,
        autoReduce: cache.autoReduceInventory,
        loading: false,
        error: null,
        refreshing: false
      }
    : {
        stockVisibility: false,
        orderOnOOS: false,
        trackInventory: false,
        autoReduce: false,
        loading: true,
        error: null,
        refreshing: false
      };
};

// Helper functions
const setCompanySettingsInCache = ({ data }) => {
  const key = connector.COMPANY_SETTINGS.cacheKey;
  CacheRequest.setCacheForKey(key, data);
};

const changeCompanyOutOfStockVisibility = ({ value }) => {
  return Api.changeCompanyOutOfStockVisibility({
    value
  });
};

const changeCompanyAllowOrderOnOutOfStock = ({ value }) => {
  return Api.changeCompanyAllowOrderOnOutOfStock({
    value
  });
};

const changeCompanyTrackInventory = ({ value }) => {
  return Api.changeCompanyTrackInventory({
    value
  });
};

const changeCompanyAutoReduceInventory = ({ value }) => {
  return Api.changeCompanyAutoReduceInventory({
    value
  });
};

const getCompanyCurrencyCode = () => {
  const key = connector.COMPANY_META.cacheKey;
  const cache = CacheRequest.getCacheForKey(key);
  if (!cache) {
    return null;
  }
  return cache.currencyCode;
};

const getCompanyMetaForUserDetails = () => {
  const key = connector.COMPANY_META.cacheKey;
  const cache = CacheRequest.getCacheForKey(key);
  if (!cache) {
    return {
      name: '',
      currency: '',
      category: ''
    };
  }
  const categoryCode = Object.keys(cache.categories || {})[0];
  const category = CategoryMapper[categoryCode];

  return cache
    ? {
        name: cache.name,
        currency: cache.currencyCode,
        category
      }
    : {};
};

const getCompanyIdFromCache = () => {
  const key = connector.COMPANY_META.cacheKey;
  const cache = CacheRequest.getCacheForKey(key);

  if (!cache) {
    const parsedCompanyObject = JSON.parse(
      localStorage.getItem(COMPANY_LOCAL_STORAGE_KEYS.COMPANY_META)
    );
    return parsedCompanyObject && parsedCompanyObject.id ? parsedCompanyObject.id : null;
  }

  return cache.id || null;
};

const getCompanyCurrencySymbol = () => {
  const key = connector.COMPANY_META.cacheKey;
  const cache = CacheRequest.getCacheForKey(key);
  let currencyCode = null;
  if (cache && cache.currencyCode) {
    currencyCode = cache.currencyCode;
  }

  if (!currencyCode) {
    return '';
  }

  return CurrencyMapper[currencyCode] || '';
};

const getCompanyMetaForUserBar = () => {
  const key = connector.COMPANY_META.cacheKey;
  const cache = CacheRequest.getCacheForKey(key);

  if (!cache) {
    return {
      name: ''
    };
  }

  return {
    name: cache.name
  };
};

const getAllCatalogueCount = () => {
  const key = connector.COMPANY_META.cacheKey;
  const cache = CacheRequest.getCacheForKey(key);

  return cache && cache.catalogues ? Object.keys(cache.catalogues || {}).length : null;
};

const getAllProductCount = () => {
  const key = connector.COMPANY_META.cacheKey;
  const cache = CacheRequest.getCacheForKey(key);

  return cache && cache.products ? Object.keys(cache.products || {}).length : null;
};

const getCompanyDomain = () => {
  const key = connector.COMPANY_META.products;
  const cache = CacheRequest.getCacheForKey(key);

  if (!cache) {
    return null;
  }

  return cache && cache.companyDomain ? cache.companyDomain : null;
};

const getCompanyStockSetting = async () => {
  const key = `${connector.COMPANY_SETTINGS.cacheKey}`;

  const data = await Api.getCompanySettings();
  CacheRequest.setCacheForKey(key, data);

  return data.settings.showOutOfStockProduct;
};

const getCompanyOrderOnOOS = async () => {
  const key = `${connector.COMPANY_SETTINGS.cacheKey}`;

  const data = await Api.getCompanySettings();
  CacheRequest.setCacheForKey(key, data);

  return data.settings.allowOrdersOnOutOfStock;
};

const getCompanyCategory = () => {
  const key = connector.COMPANY_META.cacheKey;
  const cache = CacheRequest.getCacheForKey(key);
  return cache.categories || {};
};

const validateCompanyPaymentPlan = async () => {
  const currentPlan = await Api.getCurrentPlan();
  saveCurrentPlan(currentPlan);

  if (currentPlan.planType === PLAN_TYPES.LITE) {
    const email = getUserEmail();
    userUnauthorized({ email, reason: 'User is on LITE plan' });
    return;
  }
};

//Company settings plan
const attachCompanyPlanListener = listener => {
  const key = connector.COMPANY_SETTINGS_PLAN.cacheKey;
  CacheRequest.attachListener(key, listener);
};

const removeCompanyPlanListener = listener => {
  const key = connector.COMPANY_SETTINGS_PLAN.cacheKey;
  CacheRequest.removeListener(key, listener);
};

const getCompanyPlan = () => {
  const key = `${connector.COMPANY_SETTINGS_PLAN.cacheKey}`;
  const apiName = connector.COMPANY_SETTINGS_PLAN.apiFunction;
  return CacheRequest.makeRequest(key, apiName, {
    params: [],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

const getCompanyPlanFromCache = () => {
  const key = `${connector.COMPANY_SETTINGS_PLAN.cacheKey}`;
  return CacheRequest.getCacheForKey(key);
};

//Company request product demo
const attachCompanyProductDemoListener = listener => {
  const key = connector.COMPANY_SETTINGS_PRODUCT_DEMO.cacheKey;
  CacheRequest.attachListener(key, listener);
};

const removeCompanyProductDemoListener = listener => {
  const key = connector.COMPANY_SETTINGS_PRODUCT_DEMO.cacheKey;
  CacheRequest.removeListener(key, listener);
};

const getCompanyProductDemo = () => {
  const key = connector.COMPANY_SETTINGS_PRODUCT_DEMO.cacheKey;
  const apiName = connector.COMPANY_SETTINGS_PRODUCT_DEMO.apiFunction;
  return CacheRequest.makeRequest(key, apiName, {
    params: [],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

const getCompanyProductDemoFromCache = () => {
  const key = connector.COMPANY_SETTINGS_PRODUCT_DEMO.cacheKey;
  return CacheRequest.getCacheForKey(key);
};

//Company Settings Frirebase
const setupCompanySettingsListener = () => {
  attachCompanySettingsFirebaseListener(getCompanyIdFromCache());
};

const tearDownCompanySettingsListener = () => {
  removeCompanySettingsFirebaseListener(getCompanyIdFromCache());
};

const setCompanySettingsFirebaseInCache = ({data, error = null}) => {
  const key = connector.COMPANY_SETTINGS_FIREBASE.cacheKey;
  CacheRequest.setCacheForKey(key, data, {error});
};

const getCompanySettingsFirebaseFromCache = () => {
  const key = connector.COMPANY_SETTINGS_FIREBASE.cacheKey;
  return CacheRequest.getCacheForKey(key);
};

const attachCompanySettingsListenerFirebase = listener => {
  const key = connector.COMPANY_SETTINGS_FIREBASE.cacheKey;
  CacheRequest.attachListener(key, listener);
};

const removeCompanySettingsListenerFirebase = listener => {
  const key = connector.COMPANY_SETTINGS_FIREBASE.cacheKey;
  CacheRequest.removeListener(key, listener);
};

const updateCompanySettingsFirebase = updateData =>
  Api.updateCompanySettings(updateData, getCompanyIdFromCache()).catch(error => setCompanySettingsFirebaseInCache({error}));

const extractCompanyLanguage = companySettingsData => {
  if (companySettingsData) {
    return COMPANY_LANGUAGUES.get(companySettingsData.i18n || 'en_US');
  }
  return null;
};

const setCompanyLanguage = language => {
  if (!language) {
    return;
  }
  updateCompanySettingsFirebase({ i18n: language });
};

const extractCompanyPaymentsSettings = companySettingsData => {
  if (companySettingsData) {
    return companySettingsData.acceptPaymentsOnOrderConfirmation || false;
  }
  return null;
};

const setCompanyPaymentsSettings = paymentSetting => {
  if (typeof paymentSetting !== 'boolean') {
    return;
  }
  updateCompanySettingsFirebase({ acceptPaymentsOnOrderConfirmation: paymentSetting });
};

const extractCompanyCataloguePdfSettings = companySettingsData => {
  if (companySettingsData) {
    return companySettingsData.pdfDownloadFromWeb || false;
  }
  return null;
};

const setCompanyCataloguePdfSettings = pdfSetting => {
  if (typeof pdfSetting !== 'boolean') {
    return;
  }
  updateCompanySettingsFirebase({ pdfDownloadFromWeb: pdfSetting });
};

const extractCompanyCatalogueProductSettings = companySettingsData => {
  if (companySettingsData) {
    return companySettingsData.enableProductDownload || false;
  }
  return null;
};

const setCompanyCatalogueProductSettings = productSetting => {
  if (typeof productSetting !== 'boolean') {
    return;
  }

  updateCompanySettingsFirebase({ enableProductDownload: productSetting });
};

const extractCompanyCheckoutSettings = companySettingsData => {
  if (companySettingsData) {
    return companySettingsData.captureShippingAddress || false;
  }
  return null;
};

const setCompanyCheckoutSettings = checkoutSetting => {
  if (typeof checkoutSetting !== 'boolean') {
    return;
  }

  updateCompanySettingsFirebase({ captureShippingAddress: checkoutSetting });
};

const extractCompanyCheckoutMessageSettings = companySettingsData => {
  if (companySettingsData) {
    return companySettingsData.orderConfirmationCustomMessage || '';
  }
  return null;
};

const setCompanyCheckoutMessageSettings = checkoutMessageSetting => {
  if (typeof checkoutMessageSetting !== 'string') {
    return;
  }

  updateCompanySettingsFirebase({ orderConfirmationCustomMessage: checkoutMessageSetting });
};

const extractPaymentGatewayOption = data => {
  if (data) {
    return data.showPaymentGateway;
  }
  return null;
};

const submitFeedback = feedbackData => {
  const userObject = getUserMetaFromCache();
  const companyObject = getCompanyMetaFromCache();

  return Api.submitFeedback({
    feedbackData,
    companyId: companyObject.id,
    userId: userObject.id,
    userName: userObject.name,
    phoneNumber: userObject.phone,
    companyName: companyObject.name
  }).catch(error => {
    Sentry.captureException(error)

    //ensure that the client receives the error
    Promise.reject(error)
  });
};

//Comapny Share Text
const setupCompanyShareTextListener = () => {
  attachCompanyShareTextFirebaseListener(getCompanyIdFromCache());
};

const tearDownCompanyShareTextListener = () => {
  removeCompanyShareTextFirebaseListener(getCompanyIdFromCache());
};

const setCompanyShareTextFirebaseInCache = ({ data, error = null } = {}) => {
  const key = connector.COMPANY_SHARE_TEXT_FIREBASE.cacheKey;
  CacheRequest.setCacheForKey(key, data, { error });
};

const getCompanyShareTextFirebaseFromCache = () => {
  const key = connector.COMPANY_SHARE_TEXT_FIREBASE.cacheKey;
  return CacheRequest.getCacheForKey(key);
};

const attachCompanyShareTextListenerFirebase = listener => {
  const key = connector.COMPANY_SHARE_TEXT_FIREBASE.cacheKey;
  CacheRequest.attachListener(key, listener);
};

const removeCompanyShareTextListenerFirebase = listener => {
  const key = connector.COMPANY_SHARE_TEXT_FIREBASE.cacheKey;
  CacheRequest.removeListener(key, listener);
};

const updateCompanyShareTextFirebase = updateData => {
  if (typeof updateData !== 'string') {
    return;
  }

  updateData = `${updateData}${CUSTOM_SHARE_TEXT_APPEND}`;
  Api.updateCompanyShareText({ shareTemplate: updateData }, getCompanyIdFromCache()).catch(error =>
    setCompanyShareTextFirebaseInCache(error)
  );
};

const extractCompanyShareTextProperty = shareTextTemplate => {
  if (shareTextTemplate) {
    const indexOfCustomText = shareTextTemplate.shareTemplate.indexOf(CUSTOM_SHARE_TEXT_APPEND);
    return shareTextTemplate.shareTemplate.substring(0, indexOfCustomText);
  }

  return '';
};

export {
  COMPANY_LOCAL_STORAGE_KEYS,
  attachCompanySettingsListener,
  removeCompanySettingsListener,
  getCompanySettings,
  attachCompanyMetaListener,
  removeCompanyMetaListener,
  setCompanyMetaInCache,
  updateCompanyAddress,
  updateCompanyNameAndCurrency,
  setCompanySettingsInCache,
  getCompanySettingsFromCache,
  changeCompanyOutOfStockVisibility,
  changeCompanyAllowOrderOnOutOfStock,
  changeCompanyTrackInventory,
  changeCompanyAutoReduceInventory,
  getCompanyCurrencyCode,
  getCompanyMetaForUserDetails,
  getCompanyIdFromCache,
  getCompanyCurrencySymbol,
  getCompanyMetaForUserBar,
  getAllCatalogueCount,
  getAllProductCount,
  getCompanyDomain,
  rehydrateCompanyMeta,
  rehydrateUserMeta,
  getCompanyStockSetting,
  getCompanyOrderOnOOS,
  getCompanyMetaFromCache,
  getCompanyCategory,
  validateCompanyPaymentPlan,
  attachCompanyPlanListener,
  removeCompanyPlanListener,
  getCompanyPlan,
  getCompanyPlanFromCache,
  attachCompanyProductDemoListener,
  removeCompanyProductDemoListener,
  getCompanyProductDemo,
  getCompanyProductDemoFromCache,
  COMPANY_LANGUAGUES,
  setupCompanySettingsListener,
  tearDownCompanySettingsListener,
  setCompanySettingsFirebaseInCache,
  getCompanySettingsFirebaseFromCache,
  attachCompanySettingsListenerFirebase,
  removeCompanySettingsListenerFirebase,
  updateCompanySettingsFirebase,
  extractCompanyLanguage,
  setCompanyLanguage,
  extractCompanyPaymentsSettings,
  setCompanyPaymentsSettings,
  extractCompanyCataloguePdfSettings,
  setCompanyCataloguePdfSettings,
  extractCompanyCatalogueProductSettings,
  setCompanyCatalogueProductSettings,
  extractCompanyCheckoutSettings,
  setCompanyCheckoutSettings,
  extractCompanyCheckoutMessageSettings,
  setCompanyCheckoutMessageSettings,
  extractPaymentGatewayOption,
  submitFeedback,
  //Company share text
  setupCompanyShareTextListener,
  tearDownCompanyShareTextListener,
  setCompanyShareTextFirebaseInCache,
  getCompanyShareTextFirebaseFromCache,
  attachCompanyShareTextListenerFirebase,
  removeCompanyShareTextListenerFirebase,
  updateCompanyShareTextFirebase,
  extractCompanyShareTextProperty
};
