import React, { Component } from 'react';

import Header from './Header/Header';
import InquiryMultiSelectHeader from './InquiryMultiSelectHeader/InquiryMultiSelectHeader';
import './InquiriesScreen.css';
import List from './List/List';
import MultiSelectInquiry from 'qs-helpers/MultiSelectInquiry';

class InquiriesScreen extends Component {
  state = {
    onDeleteOrderClicked: false,
    isSomethingSelected: false,
    isEditing: false,
    onDeleteSelectedOrder: false
  };

  UNSAFE_componentWillMount() {
    const { contact, orderId } = this.props;
    if (contact && orderId) {
      this.setState({ isSomethingSelected: true });
    }
  }

  componentDidMount() {
    const { attachEditingListener } = MultiSelectInquiry;
    attachEditingListener('mainScreen', this.editingListener);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { contact, orderId } = nextProps;
    const { contact: prevContact, orderId: prevOrderId } = this.props;

    if (contact === prevContact && orderId !== prevOrderId) {
      this.setState({ isSomethingSelected: true });
    }

    if (contact !== prevContact) {
      this.setState({ isSomethingSelected: false, isEditing: false });
      MultiSelectInquiry.resetEditing();
      MultiSelectInquiry.resetSelectedInquries();
    }
  }

  componentWillUnmount() {
    this.setState({
      onDeleteOrderClicked: false,
      isSomethingSelected: false
    });
  }

  editingListener = isEditing => {
    if (this.state.isEditing !== isEditing) {
      this.setState({ isEditing });
    }
  };

  onDeleteOrder = () => {
    this.setState({ onDeleteOrderClicked: true });
  };

  resetDeleteOrderClicked = () => {
    this.setState({ onDeleteOrderClicked: false });
    this.props.resetSelectedOrders();
  };

  nothingSelected = () => {
    return <div className="nothingSelectedContainer">Nothing selected</div>;
  };

  resetSelectedOrderId = () => {
    this.setState({ isSomethingSelected: false });
  };

  onDeleteSelectedOrder = () => {
    this.setState({ onDeleteSelectedOrder: true });
  };

  onResetDeleteSelectedOrder = () => {
    this.setState({ onDeleteSelectedOrder: false });
  };

  render() {
    const { contact, orderId, isOrderDeleted, isInheritedCatalogue } = this.props;

    const {
      onDeleteOrderClicked,
      isSomethingSelected,
      isEditing,
      onDeleteSelectedOrder
    } = this.state;

    return isSomethingSelected ? (
      <div className="InquiriesScreenWrapper">
        {!isEditing && (
          <Header
            orderId={orderId}
            contact={contact}
            deleteOrderClicked={this.onDeleteOrder}
            isOrderDeleted={isOrderDeleted}
            isInheritedCatalogue={isInheritedCatalogue}
          />
        )}

        {!!isEditing && (
          <InquiryMultiSelectHeader onDeleteSelectedOrder={this.onDeleteSelectedOrder} />
        )}

        {/*
          Force react to clean up the existing view so that
          the scroll position on click of a new order is not retained
        */}
        <List
          key={orderId}
          contact={contact}
          orderId={orderId}
          deleteOrderClicked={onDeleteOrderClicked}
          resetDeleteOrderClicked={this.resetDeleteOrderClicked}
          resetSelectedOrderId={this.resetSelectedOrderId}
          onDeleteSelectedOrder={onDeleteSelectedOrder}
          onResetDeleteSelectedOrder={this.onResetDeleteSelectedOrder}
        />
      </div>
    ) : (
      this.nothingSelected()
    );
  }
}

export default InquiriesScreen;
