import React, { Fragment, useEffect, useState } from 'react';
import './ProductDemo.scss';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import Loader from '../../../Common/Loader';
import {
  attachConfigDemoLinkListener,
  removeConfigDemoLinkListener,
  getConfigDemoLink,
  getConfigDemoLinkFromCache
} from 'qs-data-manager/Config';
import toastr from 'toastr';

export default () => {
  const [productLink, setProductLink] = useState(() => {
    const demoLink = getConfigDemoLinkFromCache();
    if (demoLink) {
      return demoLink.requestDemoLink;
    }
    return '';
  });

  useEffect(() => {
    const productDemoListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (loading || refreshing) {
        return;
      }

      if (err) {
        toastr.error(
          'Something went wrong while fetching the config for the product demo. The option may not be clickable'
        );
        return;
      }

      if (data) {
        setProductLink(data.requestDemoLink);
      }
    };

    attachConfigDemoLinkListener(productDemoListener);
    getConfigDemoLink();

    return () => removeConfigDemoLinkListener(productDemoListener);
  }, []);

  const handleProductDemoClick = function(event) {
    event.preventDefault();
    window.open(productLink, '_blank', 'noopener=true');
  };

  let demoComponent;
  if (productLink) {
    demoComponent = (
      <button className={'product-demo--button'} onClick={handleProductDemoClick}>
        Request a demo
      </button>
    );
  } else {
    demoComponent = <Loader size="small" />;
  }

  return (
    <Fragment>
      <div className="product-demo--text">
        Get a complete walkthrough of how you can use the QuickSell App for your business{' '}
      </div>
      {demoComponent}
    </Fragment>
  );
};
