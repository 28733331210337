import React, { Component } from 'react';
import Api from 'qs-services/Api';
import Modal from 'react-responsive-modal';
import PropTypes from 'prop-types';
import './BulkInventory.scss';
import { toggleGlobalLoader } from 'qs-helpers';
import { getCompanyIdFromCache } from 'qs-data-manager/Company';
import { setProductStockCount } from 'qs-data-manager/Products';
import CatalogueLib, { getActiveCatalogueId } from 'qs-data-manager/Catalogues';
import * as Sentry from '@sentry/browser';

class BulkInventory extends Component {
  constructor(props) {
    super(props);

    this.companyId = getCompanyIdFromCache();

    this.state = {
      stockAvailability: null,
      trackInventory: null,
      quantity: 1,
      autoReduce: null,
      stockVisibility: null,
      orderOnOOS: null,
      resetModalToggledFrom: null
    };
  }

  static propTypes = {
    productIds: PropTypes.array,
    toggleGlobalLoading: PropTypes.func
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { productIds } = nextProps;
    let updates = {};

    if (productIds.length !== this.props.productIds.length) {
      updates = {
        stockAvailability: null,
        trackInventory: null,
        quantity: 1,
        autoReduce: null,
        stockVisibility: null,
        orderOnOOS: null,
        resetModalToggledFrom: null
      };
    }

    if (Object.keys(updates).length) {
      this.setState(updates);
    }
  }

  changeStockAvailability = async value => {
    const { productIds } = this.props;
    const key = `changeStockAvailability${Date.now()}`;
    this.setState({ stockAvailability: !!value });

    try {
      const catalogueId = getActiveCatalogueId();
      toggleGlobalLoader(key, true);
      setProductStockCount({ stock: value, productIds });
      await Api.setBulkStockCount({ productIds, stock: value });
      CatalogueLib.getCatalogueTags(catalogueId);
      toggleGlobalLoader(key, false);
    } catch (err) {
      console.log('err', err);
      toggleGlobalLoader(key, false);
      Sentry.captureException(err);
    }
  };

  toggleQuantityModal = modalState => {
    this.setState({ quantityModal: modalState, quantity: 1 });
  };

  increaseInventory = () => {
    this.setState({ quantity: Number(this.state.quantity) + 1 });
  };

  onInventoryFieldChange = e => {
    const inventory = e.target.value;
    const containsDecimal = inventory.indexOf('.') >= 0;
    if (isNaN(Number(inventory)) || containsDecimal) {
      return;
    }

    this.setState({ quantity: Number(inventory) });
  };

  reduceInventory = () => {
    const currentInventory = this.state.quantity;
    if (currentInventory === 0) {
      return;
    }

    this.setState({ quantity: Number(currentInventory) - 1 });
  };

  changeTrackInventory = async trackInventory => {
    const { productIds } = this.props;
    const loaderKey = `changeTrackInventory${Date.now()}`;
    toggleGlobalLoader(loaderKey, true);

    if (trackInventory) {
      const { quantity } = this.state;
      this.toggleQuantityModal(false);
      this.changeStockAvailability(quantity);
    }
    this.setState({ trackInventory });
    await Api.setInventoryTracking({ productIds, value: trackInventory });
    toggleGlobalLoader(loaderKey, false);
  };

  changeAutoReduce = async autoReduce => {
    const { productIds } = this.props;
    const loader = `changeAutoReduce${Date.now()}`;

    toggleGlobalLoader(loader, true);
    this.setState({ autoReduce });
    await Api.autoReduceQuantity({
      productIds,
      value: autoReduce
    });
    toggleGlobalLoader(loader, false);
  };

  changeStockVisibility = async stockVisibility => {
    const { productIds } = this.props;

    const loaderKey = `changeStockVisibility${Date.now()}`;
    toggleGlobalLoader(loaderKey, true);

    this.setState({ stockVisibility: stockVisibility });

    let value = null;
    if (stockVisibility === 'show') {
      value = true;
    } else if (stockVisibility === 'hide') {
      value = false;
    } else {
      value = null;
    }

    await Api.setOutOfStockVisibility({
      productIds,
      value
    });

    toggleGlobalLoader(loaderKey, false);
  };

  changeOrderOnOOS = async orderOnOOS => {
    const { productIds } = this.props;

    const loaderKey = `changeStockVisibility${Date.now()}`;
    toggleGlobalLoader(loaderKey, true);
    this.setState({ orderOnOOS });

    let value = '';
    if (orderOnOOS === 'yes') {
      value = true;
    } else if (orderOnOOS === 'no') {
      value = false;
    } else {
      value = null;
    }

    await Api.setAllowOrderOnOutOfStock({
      productIds,
      value
    });

    toggleGlobalLoader(loaderKey, false);
  };

  toggleResetModal = ({ modalState, toggledFrom }) => {
    this.setState({ showResetModal: modalState, resetModalToggledFrom: toggledFrom });
  };

  resetModal = () => {
    const { resetModalToggledFrom, showResetModal } = this.state;
    const closeModal = () => {
      this.toggleResetModal({ modalState: false, toggledFrom: null });
    };

    const reset = () => {
      if (resetModalToggledFrom === 'OrderOnOOS') {
        this.changeOrderOnOOS('default');
      } else if (resetModalToggledFrom === 'stockVisibility') {
        this.changeStockVisibility('default');
      }

      this.toggleResetModal({ modalState: false, resetModalToggledFrom: null });
    };

    return (
      <Modal
        open={showResetModal}
        onClose={closeModal}
        center
        styles={{ modal: { backgroundColor: 'white', borderRadius: 10 } }}
        showCloseIcon={false}
      >
        <div id="bulkInventoryResetModal" className="resetModalContainer">
          <div className="resetHeader">Reset to default</div>
          <div className="description">
            This setting will be automatically turned on or off based on your catalogue setting
          </div>

          <div className="actionButton">
            <div onClick={closeModal} className="buttonStyle">
              Cancel
            </div>
            <div onClick={reset} className="buttonStyle">
              Reset
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  renderQuantityModal = () => {
    const { quantity, quantityModal } = this.state;

    const closeModal = () => {
      this.toggleQuantityModal(false);
    };

    return (
      <Modal
        open={quantityModal}
        onClose={closeModal}
        center
        styles={{ modal: { backgroundColor: 'white', borderRadius: 10 } }}
        showCloseIcon={false}
      >
        <div id="BulkInventoryQuantityModal">
          <div className="header">Set inventory</div>
          <div className="quantityWrapper">
            <div onClick={this.reduceInventory} className="minusContainer noselect">
              -
            </div>
            <div className="quantityInputContainer">
              <input
                type="number"
                value={quantity}
                className="quantityContainer"
                onChange={this.onInventoryFieldChange}
              />
            </div>
            <div onClick={this.increaseInventory} className="plusContainer noselect">
              +
            </div>
          </div>
          <div className="buttonCotnainer">
            <div onClick={closeModal} className="cancelButton">
              Cancel
            </div>
            <div onClick={() => this.changeTrackInventory(true)} className="acceptButton">
              Apply
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  render() {
    const {
      quantityModal,
      stockAvailability,
      trackInventory,
      showResetModal,
      autoReduce,
      orderOnOOS,
      stockVisibility
    } = this.state;

    return (
      <div className="bulkInventoryContainer">
        <div className="stockaAvailabilityContainer">
          <div className="rowHeading header">Stock availability</div>
          <div className="options">
            <div onClick={() => this.changeStockAvailability(0)} className="outOfStockWrapper">
              <div
                className={
                  stockAvailability === false
                    ? 'outOfStockContainer selectedOOS'
                    : 'outOfStockContainer'
                }
              >
                out of stock
              </div>
            </div>

            <div onClick={() => this.changeStockAvailability(1)} className="availableContainer">
              <div
                className={
                  stockAvailability === true
                    ? 'stockAvailableContainer availableSelected'
                    : 'stockAvailableContainer'
                }
              >
                available
              </div>
            </div>
          </div>
        </div>

        {!!stockAvailability && (
          <div className="availableQuantityContainer">
            <div className="rowHeading header">Available quantity</div>

            <div className="actionButtons">
              <div onClick={() => this.toggleQuantityModal(true)} className="buttonStyle">
                <div
                  className={
                    trackInventory === true
                      ? 'inventoryContainer selectedInventory'
                      : 'inventoryContainer'
                  }
                >
                  Track available quantity
                </div>
              </div>
              <div onClick={() => this.changeTrackInventory(false)} className="buttonStyle">
                <div
                  className={
                    trackInventory === false
                      ? 'inventoryContainer dontTrackSelected'
                      : 'inventoryContainer'
                  }
                >
                  Do not track quantity
                </div>
              </div>
            </div>
          </div>
        )}

        {!!trackInventory && !!stockAvailability && (
          <div className="autoReduceInventory">
            <div className="rowHeading header">Auto reduce quantity when visitor confirms</div>
            <div className="actionButtons">
              <div onClick={() => this.changeAutoReduce(true)} className="buttonStyle">
                <div className={autoReduce === true ? 'autoReduce selected' : 'autoReduce'}>
                  YES
                </div>
              </div>
              <div onClick={() => this.changeAutoReduce(false)} className="buttonStyle">
                <div className={autoReduce === false ? 'autoReduce selected' : 'autoReduce'}>
                  NO
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="OOSVisibilityContainer">
          <div className="rowHeading header">Out of stock visibility</div>

          <div className="actionButtons">
            <div onClick={() => this.changeStockVisibility('show')} className="buttonStyle">
              <div
                className={
                  stockVisibility === 'show' ? 'stockVisibility selected' : 'stockVisibility'
                }
              >
                SHOW
              </div>
            </div>
            <div onClick={() => this.changeStockVisibility('hide')} className="buttonStyle">
              <div
                className={
                  stockVisibility === 'hide' ? 'stockVisibility selected' : 'stockVisibility'
                }
              >
                HIDE
              </div>
            </div>
            <div
              onClick={() =>
                this.toggleResetModal({ toggledFrom: 'stockVisibility', modalState: true })
              }
              className="buttonStyle"
            >
              <div
                className={
                  stockVisibility === 'default' ? 'stockVisibility selected' : 'stockVisibility'
                }
              >
                DEFAULT
              </div>
            </div>
          </div>
        </div>

        <div className="OOSVisibilityContainer orderOnOOSContainer">
          <div className="rowHeading header">Allow orders on out of stock</div>

          <div className="actionButtons">
            <div onClick={() => this.changeOrderOnOOS('yes')} className="buttonStyle">
              <div className={orderOnOOS === 'yes' ? 'orderOnOOS selected' : 'orderOnOOS'}>YES</div>
            </div>
            <div onClick={() => this.changeOrderOnOOS('no')} className="buttonStyle">
              <div className={orderOnOOS === 'no' ? 'orderOnOOS selected' : 'orderOnOOS'}>NO</div>
            </div>
            <div
              onClick={() => this.toggleResetModal({ toggledFrom: 'OrderOnOOS', modalState: true })}
              className="buttonStyle"
            >
              <div className={orderOnOOS === 'default' ? 'orderOnOOS selected' : 'orderOnOOS'}>
                DEFAULT
              </div>
            </div>
          </div>
        </div>

        {quantityModal && this.renderQuantityModal()}
        {showResetModal && this.resetModal()}
      </div>
    );
  }
}

export default BulkInventory;
