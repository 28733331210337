import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SingleInquiryRow from '../SingleInquieryRow/SingleInquiryRow';
import ReactList from 'react-list';
import Order from 'qs-data-manager/Order';
import './List.css';
import toastr from 'toastr';
import MultiSelectInquiry from 'qs-helpers/MultiSelectInquiry';
import { toggleGlobalLoader, roundNumberToGivenDecimals } from 'qs-helpers';
import Mixpanel from 'qs-data-manager/Mixpanel';
import * as Sentry from "@sentry/browser";
import { getCompanyCurrencySymbol } from 'qs-data-manager/Company';
import Loader from 'qs-components/Common/Loader';

const SCREEN_NAME = 'INQURIES_SCREEN';
const ERROR_MESSAGE = 'Something went wrong. Please try again later.';

class InquriesList extends Component {
  static propTypes = {
    catalogueId: PropTypes.string,
    orderId: PropTypes.string,
    contact: PropTypes.string,
    onResetDeleteSelectedOrder: PropTypes.func,
    onDeleteSelectedOrder: PropTypes.func,
    deleteOrderClicked: PropTypes.bool,
    resetSelectedOrderId: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.didReadInquiries = false;
    this.erroredRequests = [];
    const inquiriesData = Order.getOrderInquiriesIfOrderExists(props.orderId)
    let inquiriesLoading = true, displayOrderId, inquiries = []
    if(inquiriesData) {
      inquiriesLoading = false;
      ({displayOrderId, inquiries} = inquiriesData)
    }

    this.state = {
      inquiriesLoading,
      displayOrderId,
      metaLoading: null,
      visitorName: '',
      visitorPhone: '',
      inquiries,
      deletingOrder: false,
      showBigImage: false
    };
    this.currencySymbol = getCompanyCurrencySymbol();
  }

  componentDidMount() {
    const { catalogueId, orderId, contact } = this.props;
    const { inquiries } = this.state;

    Order.attachOrderInquiriesListener({ orderId }, this.onInquiriesChange);
    Order.attachContactMetaListener({ catalogueId, contact }, this.onMetaChange);
    MultiSelectInquiry.attachFetchInquries(this.fetchInquires);

    const unreadInquiryIds = inquiries
      .filter(inquiry => !inquiry.read)
      .map(inquiry => inquiry.inquiryId);

    if (unreadInquiryIds.length) {
      Order.readInquiries({ orderId, inquiryIds: unreadInquiryIds, catalogueId, contact });
    }

    if (orderId) {
      this.getInquiries({ orderId });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      orderId,
      deleteOrderClicked,
      catalogueId,
      contact,
      onDeleteSelectedOrder,
      onResetDeleteSelectedOrder
    } = nextProps;

    const {
      orderId: prevOrderId,
      deleteOrderClicked: prevDeleteOrderClicked,
      catalogueId: prevCatalogueId,
      contact: prevContact
    } = this.props;

    const { inquiries = [] } = this.state;

    if (orderId !== prevOrderId) {
      Order.removeContactMetaListener({ catalogueId, contact }, this.onMetaChange);
      Order.removeOrderInquiriesListener({ orderId }, this.onInquiriesChange);
      MultiSelectInquiry.resetSelectedInquries();
      MultiSelectInquiry.resetEditing();

      if (this.listRef) {
        this.listRef.scrollTo(0);
      }
      if (!this.didReadInquiries) {
        const unreadInquiryIds = inquiries
          .filter(inquiry => !inquiry.read)
          .map(inquiry => inquiry.inquiryId);

        if (unreadInquiryIds.length) {
          Order.readInquiries({
            orderId: prevOrderId,
            inquiryIds: unreadInquiryIds,
            catalogueId: prevCatalogueId,
            contact: prevContact
          });
        }
      }

      new Promise(() => {
        this.setState({ inquiries: [] });
      });
      Order.attachOrderInquiriesListener({ orderId }, this.onInquiriesChange);
      Order.attachContactMetaListener({ catalogueId, contact }, this.onMetaChange);

      this.getInquiries({ orderId });
    }

    if (onDeleteSelectedOrder !== this.props.onDeleteSelectedOrder && onDeleteSelectedOrder) {
      this.onDeleteInquiry();
      onResetDeleteSelectedOrder();
    }

    if (deleteOrderClicked !== prevDeleteOrderClicked && deleteOrderClicked) {
      this.deleteOrder(nextProps);
    }
  }

  fetchInquires = () => this.state.inquiries;

  deleteOrder = async ({
    orderId,
    contact,
    catalogueId,
    resetDeleteOrderClicked,
    resetSelectedOrderId
  }) => {
    const { inquiries } = this.state;
    const key = `deleteOrder${Date.now()}`;
    toggleGlobalLoader(key, true);

    Mixpanel.sendEvent('orders_delete_order', { catalogueId, orderId, contact }, SCREEN_NAME);

    try {
      const unreadInquiryIds = inquiries
        .filter(inquiry => !inquiry.read)
        .map(inquiry => inquiry.inquiryId);

      if (unreadInquiryIds.length) {
        Order.readInquiries({ orderId, inquiryIds: unreadInquiryIds, catalogueId, contact });
      }
      this.didReadInquiries = true;

      await Order.deleteOrder({ catalogueId, orderId, contact });
      toggleGlobalLoader(key, false);
    } catch (ex) {
      this.setState({ deletingOrder: false, error: ex });
      Sentry.captureException(ex);
    }
    resetDeleteOrderClicked();
    resetSelectedOrderId();
  };

  getInquiries = ({ orderId }) => {
    Order.getOrderInquiries({ orderId });
  };

  onMetaChange = meta => {
    //callback
    if (!meta) {
      return;
    }

    this.setState({
      metaLoading: false,
      visitorName: meta.name,
      visitorPhone: meta.phone
    });
  };

  onInquiriesChange = (err, { status, data:{inquiries, displayOrderId} = {} } = {}) => {
    //callback
    const { orderId, catalogueId, contact, resetSelectedOrderId } = this.props;

    const stateUpdates = {};

    if (err) {
      this.erroredRequests.push({
        type: 'GET_INQUIRIES',
        params: { orderId }
      });

      stateUpdates.inquiriesLoading = false;
      stateUpdates.refreshing = false;
      stateUpdates.didErrored = true;
      stateUpdates.error = err;
      toastr.error(stateUpdates.error.message);
    } else {
      switch (status) {
        case Order.OPERATION_STATUS.LOADING: {
          stateUpdates.inquiriesLoading = true;
          stateUpdates.refreshing = false;
          break;
        }
        case Order.OPERATION_STATUS.REFRESHING: {
          stateUpdates.inquiriesLoading = false;
          stateUpdates.refreshing = true;
          stateUpdates.inquiries = inquiries;
          stateUpdates.displayOrderId = displayOrderId;
          break;
        }
        case Order.OPERATION_STATUS.SUCCESS: {
          stateUpdates.inquiriesLoading = false;
          stateUpdates.refreshing = false;
          stateUpdates.inquiries = inquiries;
          stateUpdates.displayOrderId = displayOrderId;
          break;
        }
        case Order.OPERATION_STATUS.UPDATE: {
          stateUpdates.inquiries = inquiries;
          stateUpdates.displayOrderId = displayOrderId;
          if(!stateUpdates.inquiries || (stateUpdates.inquiries && !stateUpdates.inquiries.length)) {
            resetSelectedOrderId();
          }
          break;
        }
        default:
      }
    }

    /*
      All inquiries deleted, call the callback to clear out the data
      Check if the inquiries data is empty or if the inquiries are non-existant
    */
    if (stateUpdates.inquiries) {
      if (!stateUpdates.inquiries.length) {
        resetSelectedOrderId();
      }

      const unreadInquiryIds = stateUpdates.inquiries
        .filter(inquiry => !inquiry.read)
        .map(inquiry => inquiry.inquiryId);

      if (unreadInquiryIds.length) {
        Order.readInquiries({ orderId, inquiryIds: unreadInquiryIds, catalogueId, contact });
      }
    }

    this.setState(stateUpdates);
  };

  stockToggled = (err, inquiry) => {
    if (err) {
      this.setState({ error: err });
      return;
    }

    const inquiries = this.state.inquiries.map(inq =>
      inq.inquiryId === inquiry.inquiryId ? { ...inq, stockCount: inquiry.stockCount ? 0 : 1 } : inq
    );

    this.setState({ inquiries });
  };

  onDeleteInquiry = async () => {
    const { contact, orderId } = this.props;
    const loaderKey = `onDeleteInquiry${Date.now()}`;

    const { getAllSelectedInquries, resetSelectedInquries, resetEditing } = MultiSelectInquiry;

    const inquiryIds = getAllSelectedInquries();
    resetSelectedInquries();
    resetEditing();

    try {
      toggleGlobalLoader(loaderKey, true);
      Mixpanel.sendEvent('multiselect_order_delete_inquiry');
      await Order.deleteInquiries({ catalogueId: '', contact, orderId, inquiryIds });
      toggleGlobalLoader(loaderKey, false);
    } catch (err) {
      toggleGlobalLoader(loaderKey, false);
      toastr.error(ERROR_MESSAGE);
      Sentry.captureException(err);
    }
  };

  rowRenderer = (index) => {
    const { orderId, contact, catalogueId } = this.props;
    const { inquiries } = this.state;
    const inquiry = inquiries[index];

    return (
      <SingleInquiryRow
        orderId={orderId}
        contact={contact}
        catalogueId={catalogueId}
        stockToggled={err => this.stockToggled(err, inquiry)}
        inquiry={inquiry}
        renderBigImage={this.showBigImage}
        key={inquiry.inquiryId}
      />
    );
  };

  getMinimumPriceForAllInquiries(inquiries) {
    const totalOrderPrice = inquiries.reduce((accumalatedVal, currVal) => {
      const {product: {discounted_price, price}} = currVal
      let minPrice = 0;
      if(typeof discounted_price === 'number') {
        minPrice = discounted_price
      } else if(typeof price === 'number') {
        minPrice = price
      }

      accumalatedVal += minPrice
      return accumalatedVal
    }, 0)

    return roundNumberToGivenDecimals(totalOrderPrice, 2);
  }

  renderLoader() {
    return (
      <div className="loaderContainer">
        <Loader size="large" />
      </div>
    );
  }

  renderInquiriesList() {
    const { inquiries = [], displayOrderId } = this.state;
    let itemString, inquiryComponent;
    if(inquiries.length) {
      itemString = `${inquiries.length} item`
      if(inquiries.length > 1) {
        itemString = `${inquiries.length} items`
      }

      inquiryComponent = (
        <div className="inquiry-order-info">
          <div className="inquiry-order-seperator inquiry-order--text">
            <span className="order-text-color">Order ID</span>
            <span className="order-id">{displayOrderId}</span>
          </div>
          <div className="inquiry-order-seperator">
            {this.currencySymbol} {this.getMinimumPriceForAllInquiries(inquiries)}
          </div>
          <div className="inquiry-order--text">{itemString}</div>
        </div>
      )
    }

    return (
      <div className="inquriesListContainer">
        <div className="inquiry-column-container">
          {inquiryComponent}
          <ReactList
            itemRenderer={this.rowRenderer}
            length={inquiries.length}
            type="simple"
            ref={ref => (this.listRef = ref)}
          />
        </div>
      </div>
    );
  }

  render() {
    const { inquiriesLoading } = this.state;
    return inquiriesLoading ? this.renderLoader() : this.renderInquiriesList()
  }
}

export default InquriesList;
