import React, { useCallback, useState } from 'react';
import CatalogueProducts from '../CatalogueProducts';
import CatalogueLib, { CREATING_NEW_CATALOGUE } from 'qs-data-manager/Catalogues';
import eventbus from 'eventing-bus';
import { isEnterKey } from 'qs-helpers';

import './styles.scss';

export default () => {
  const [title, setTitle] = useState('');

  const onSaveClick = useCallback(() => {
    const meta = {
      title
    };
    eventbus.publish(
      CREATING_NEW_CATALOGUE.NEW_CATALOGUE_EB_KEY,
      CREATING_NEW_CATALOGUE.SAVE_CATALOGUE
    );
    CatalogueLib.createNewCatalogue(meta);
  }, [title]);

  const setInput = useCallback(e => {
    e.stopPropagation();
    const value = e.target.value;
    setTitle(value);
  }, []);

  const onKeyPress = useCallback(
    e => {
      e.stopPropagation();
      const isEnter = isEnterKey(e);
      if (isEnter) {
        onSaveClick(e);
      }
    },
    [onSaveClick]
  );

  return (
    <div id={'SkeletonCatalogueContainer'}>
      <div id={'SkeletonCatalogue'}>
        <div className={'catalogueProductsContainer'}>
          <CatalogueProducts pictureUrls={['', '', '', '']} />
        </div>
        <div className={'titleContainer'}>
          <input
            autoFocus
            value={title}
            onChange={setInput}
            className={'title'}
            placeholder={'Type title'}
            onKeyPress={onKeyPress}
          />
        </div>

        {!!title.length ? (
          <div onClick={onSaveClick} className={'saveContainer'}>
            save
          </div>
        ) : null}
      </div>
    </div>
  );
};
