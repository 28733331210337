import React, { useEffect, useState, useCallback } from 'react';
import Modal from 'react-responsive-modal';
import Loader from 'qs-components/Common/Loader';
import CatalogueLib from 'qs-services/DataManager/Catalogues';
import { openWhatsApp, openMail, openFacebook } from 'qs-services/Helpers/index';

import './styles.scss';
import WhatsappIcon from 'qs-assets/Media/whatsapp.png';
import FacebookIcon from 'qs-assets/Media/facebook-bg.png';
import MailIcon from 'qs-assets/Media/mail.png';
import * as Sentry from "@sentry/browser";

export default ({ isVisible, catalogueIds, onModalClose }) => {
  const [link, setLink] = useState(null);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    const generateLink = async catalogueIds => {
      try {
        const resp = await CatalogueLib.createCataloguesLink(catalogueIds);
        const { link } = resp;
        setLink(link);
      } catch (err) {
        console.error('generateLink: Could not generate catalogue link', err);
        onModalClose();
        Sentry.captureException(err);
      }
    };
    generateLink(catalogueIds);
  }, [isVisible]);

  const modalClose = useCallback(() => {
    onModalClose();
  }, [onModalClose]);

  const shareCatalogue = useCallback(
    shareType => {
      switch (shareType) {
        case 'whatsapp':
          openWhatsApp({ text: link });
          break;
        case 'email':
          openMail({ text: link });
          break;
        case 'fb':
          openFacebook({ text: link });
          break;
        default:
        //
      }
    },
    [link]
  );

  const shareCatalogueEmail = useCallback(
    e => {
      e.stopPropagation();
      shareCatalogue('email');
    },
    [shareCatalogue]
  );

  const shareCatalogueFb = useCallback(
    e => {
      e.stopPropagation();
      shareCatalogue('fb');
    },
    [shareCatalogue]
  );

  const shareCatalogueWhatsapp = useCallback(
    e => {
      e.stopPropagation();
      shareCatalogue('whatsapp');
    },
    [shareCatalogue]
  );

  const handleTapToCopy = useCallback(
    e => {
      e.stopPropagation();
      const el = document.createElement('textarea');
      el.value = link;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      setIsCopied(true);
    },
    [link]
  );

  return (
    <Modal
      open={isVisible}
      onClose={modalClose}
      center
      styles={{
        modal: { backgroundColor: 'white', borderRadius: 10, padding: '23px 25px 20px 25px' }
      }}
      showCloseIcon={false}
    >
      {link ? (
        <div>
          <div className="ShareCataloguePopupBody" onClick={handleTapToCopy}>
            <a className="CatalogueLinkText" href={link} target="_blank" rel="noopener noreferrer">
              {link}
            </a>
            <div className="TapToCopyBtn">{isCopied ? 'Copied' : 'Click to copy'}</div>
          </div>
          <div className="ShareCataloguePopupFooter">
            <div className="shareType" onClick={shareCatalogueWhatsapp}>
              <img src={WhatsappIcon} alt="" />
              <span>WhatsApp</span>
            </div>
            <div className="shareType" onClick={shareCatalogueFb}>
              <img src={FacebookIcon} alt="" />
              <span>Facebook</span>
            </div>
            <div className="shareType" onClick={shareCatalogueEmail}>
              <img src={MailIcon} alt="" />
              <span>Email</span>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Loader size="large" color="black" />
        </div>
      )}
    </Modal>
  );
};
