import React, { useState, useEffect } from 'react';
import './ClickHandlerWithLoaderContainer.scss';
import Loader from '../Loader';

export default ({ linkProps = {}, clickProps = {}, type, classForContainer = '', children }) => {
  const [showLoader, toggleLoader] = useState(false);

  const linkUrlExists = function() {
    if (linkProps.linkUrl !== undefined && linkProps.linkUrl !== null) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (showLoader && linkUrlExists()) {
      toggleLoader(false);
      window.open(linkProps.linkUrl, '_blank', 'noopener=true');
    }
  }, [showLoader, linkProps]);

  const handleRowClick = function(event) {
    event.preventDefault();

    //Callback is passed, call it irrespective of any type
    const { clickParams, clickHandler } = clickProps;
    if (clickHandler) {
      clickHandler(clickParams);
    }

    if (type === 'link') {
      if (!linkUrlExists()) {
        toggleLoader(true);
        return;
      }

      window.open(linkProps.linkUrl, '_blank', 'noopener=true');
    }
  };

  return (
    <div className={`qs-click-handler--container ${classForContainer}`} onClick={handleRowClick}>
      <div className="qs-click-handler__children--container">{children}</div>
      {showLoader && (
        <div className="qs-click-handler__loader-container">
          <Loader size="small" />
        </div>
      )}
    </div>
  );
};
