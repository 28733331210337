import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { sortableHandle } from 'react-sortable-hoc';

import './ProductTagRow.scss';
import { ReactComponent as TimesIcon } from 'qs-assets/Media/close.svg';
import { ReactComponent as ReorderIcon } from 'qs-assets/Media/drag-reorder.svg';

const DragHandle = sortableHandle(() => (
  <div className="reorderIconContainer">
    <ReorderIcon fill="#c3dac8" className="reorderIcon" />
  </div>
));

class ProductTagRow extends PureComponent {
  static propTypes = {
    tag: PropTypes.string,
    isBulkEdit: PropTypes.bool,
    onDeletePress: PropTypes.func
  };

  static defaultProps = {
    tag: '',
    onVisiblePress: () => {}
  };

  render() {
    const { tag, isBulkEdit, onDeletePress } = this.props;

    return (
      <div className="productTagRow">
        <div className="tagContainer">
          <div className="tag">
            <span className="tagText">{tag}</span>
          </div>
        </div>

        <div className="deleteTagIconContainer" onClick={onDeletePress}>
          <TimesIcon fill="#c3dac8" className="deleteIcon" />
        </div>

        {!isBulkEdit && <DragHandle />}
      </div>
    );
  }
}

export default ProductTagRow;
