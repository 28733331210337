import React, { useState, useEffect } from 'react';
import './styles.scss';
import GlobalLoader from '../GlobalLoader';
import {
  getCompanyMetaForUserBar,
  attachCompanyMetaListener,
  removeCompanyMetaListener
} from 'qs-data-manager/Company';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';

export default () => {
  const [companyMeta, setCompanyMeta] = useState(() => {
    return getCompanyMetaForUserBar();
  });

  // Effect to keep company data in real time
  useEffect(() => {
    const listener = (error, payload) => {
      const { err, loading, data } = CacheListenerCallback(error, payload);
      if (err || loading) {
        return;
      }

      const companyMeta = {
        name: data.name
      };

      setCompanyMeta(companyMeta);
    };

    attachCompanyMetaListener(listener);

    return () => {
      removeCompanyMetaListener(listener);
    };
  });

  return (
    <div id={'NavBarContainer'}>
      <div id={'NavBar'}>
        <div id={'ourCompanyName'}>QuickSell</div>
        <div className={'companyName'}>{companyMeta.name}</div>
      </div>
      <GlobalLoader />
    </div>
  );
};
