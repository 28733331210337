import React, { Component } from 'react';

import Header from './Header/Header';
import RenderList from './RenderList/RenderList';

import './ContactOrdersScreen.css';

class ContactOrdersScreen extends Component {
  state = {
    noOfBaskets: null,
    isContactSelected: false,
    isRefreshing: false
  };

  componentWillMount() {
    const { contact } = this.props;
    if (contact) {
      this.setState({ isContactSelected: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { contact } = nextProps;
    const { contact: prevContact } = this.props;
    if (contact !== prevContact) {
      this.setState({ isContactSelected: !!contact });
    }
  }

  componentWillUnmount() {
    this.setState({
      noOfBaskets: null,
      isContactSelected: false,
      isRefreshing: false
    });
  }

  setNoOfBaskets = number => {
    this.setState({ noOfBaskets: number });
  };

  setIsRefreshing = isRefreshing => {
    this.setState({ isRefreshing });
  };

  noOrderContactSelected = () => {
    this.setState({isContactSelected: false})
  }

  render() {
    const { noOfBaskets, isContactSelected, isRefreshing } = this.state;

    const { contact, seeContactInquiry, selectedOrderId } = this.props;

    return isContactSelected ? (
      <div className="ContactOrdersWrapper">
        <Header noOfBaskets={noOfBaskets} isRefreshing={isRefreshing} />
        <RenderList
          seeContactInquiry={seeContactInquiry}
          contact={contact}
          setNoOfBaskets={this.setNoOfBaskets}
          isRefreshing={this.setIsRefreshing}
          selectedOrderId={selectedOrderId}
          noOrderContactSelected={this.noOrderContactSelected}
        />
      </div>
    ) : (
      <div className="noContactSelected">Nothing selected</div>
    );
  }
}

export default ContactOrdersScreen;
