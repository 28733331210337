import React, { useCallback, useState, useEffect, Component } from 'react';
import { VariableSizeList as List } from 'react-window';
import ProductRow from './ProductRow';
import {
  OPERATION_STATUS,
  PRODUCT_ROW_TYPES,
  PRODUCT_SEARCH,
  attachProductListListener,
  removeProductListListener,
  getProductList,
  getProductMeta,
  getProductListFromCache,
  getProductSearchTerm,
  setProductSearchTerm,
  reorderProduct,
  getProductListRef,
  setProductListRef,
  updateProductListHeight,
  setProductPositionMap,
  getProductRows,
  setProductPriceFilter,
  getProductPriceFilter,
  setLastFetchDate,
  uploadFromFilePicker
} from 'qs-data-manager/Products';
import { getActiveCatalogueId } from 'qs-data-manager/Catalogues';
import AutoSizer from 'react-virtualized-auto-sizer';
import CatalogueTags from './CatalogueTags';
import EmptyProductList from './EmptyProductList';
import { selectedProducts } from 'qs-data-manager/Selected';
import eventbus from 'eventing-bus';
import Api from 'qs-services/Api';

import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import CatalogueSearchResult from './CatalogueSearchResult';
import { modifySearchResult } from 'qs-helpers';
import CacheCallback from 'qs-helpers/CacheListenerCallback';
import Tags from 'qs-data-manager/Tags';
import ProductsFilePicker from '../ProductsFilePicker';

import './styles.scss';
import Loader from 'qs-components/Common/Loader';

// handles user searching but has only two characters
let SHOULD_REFRESH_SEARCH = null;
let IS_SEARCHING = false;
let IS_FILTERING = false;
let IS_PRICE_FILTERING = false;
let SEARCH_RESULT_MAINTAINER = null;
let FILTER_RESULT_MAINTAINER = null;
let TAGS_RESULT_MAINTAINER = null;

const resetVariables = () => {
  SHOULD_REFRESH_SEARCH = null;
  IS_SEARCHING = false;
  IS_FILTERING = false;
  IS_PRICE_FILTERING = false;
};

const SortableItem = sortableElement(({ rowData, style, key, productIndex }) => {
  const productId = rowData;

  return (
    <div key={key} style={style}>
      <ProductRow productId={productId} productIndex={productIndex} />
    </div>
  );
});

class VirtualList extends Component {
  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.activeCatalogueId !== this.props.activeCatalogueId) {
      this.scrollToTop();
    }
  }

  scrollToTop = () => {
    const ref = getProductListRef();
    if (ref) {
      ref.scrollToItem(0, 'start');
    } else {
      console.log('scrollToTop: Could not scroll to top');
    }
  };

  renderRow = ({ index, style }) => {
    const { items } = this.props;
    const rowData = items[index].productId;

    return (
      <SortableItem
        key={`${index}`}
        index={index}
        productIndex={index}
        style={style}
        rowData={rowData}
        updateListHeight={this.updateListHeight}
      />
    );
  };

  getRowHeight = index => {
    const { items } = this.props;
    const product = items[index];

    if (typeof product.stock === 'undefined' || typeof product.stock === 'object') {
      console.error('getRowHeight: Could not get product row height for row', items);
      return PRODUCT_ROW_TYPES.PRODUCT_ROW.outOfStockHeight;
    }

    return product.stock
      ? PRODUCT_ROW_TYPES.PRODUCT_ROW.height
      : PRODUCT_ROW_TYPES.PRODUCT_ROW.outOfStockHeight;
  };

  onItemsRendered = ({ visibleStartIndex, visibleStopIndex }) => {
    const { items } = this.props;
    const visibleProductIds = items.slice(visibleStartIndex, visibleStopIndex + 2);
    const productIds = visibleProductIds.map(({ productId }) => productId);

    getProductMeta({ productIds });
  };

  itemKey = index => `${index}`;

  render() {
    const { items, height, width } = this.props;

    return (
      <List
        ref={setProductListRef}
        height={height}
        width={width}
        itemData={items}
        itemCount={items.length}
        itemKey={this.itemKey}
        itemSize={this.getRowHeight}
        overscanCount={PRODUCT_ROW_TYPES.overscanCount}
        onItemsRendered={this.onItemsRendered}
      >
        {this.renderRow}
      </List>
    );
  }
}

const SortableVirtualList = sortableContainer(VirtualList);

export default ({ activeCatalogueId }) => {
  const [productListState, setProductListState] = useState(() => {
    const productList = getProductListFromCache(activeCatalogueId);
    return {
      error: null,
      loading: !productList,
      refreshing: false,
      success: false,
      productList,
      searchResult: '',
      shouldShowClearFilter: false
    };
  });
  const [reduceHeight, setReduceHeight] = useState(0);
  const [showLoader, setShowLoader] = useState(false);

  // Effect of product search. This handles all the list manipulations due to the search in header
  useEffect(() => {
    const clearSearch = () => {
      SHOULD_REFRESH_SEARCH = false;
      const catalogueId = getActiveCatalogueId();
      setProductSearchTerm('');

      if (IS_FILTERING) {
        const tagId = (Tags.getAllSelectedTags() || [])[0];
        Tags.onTagClick(tagId, catalogueId, false);
        setProductListState(prevState => ({ ...prevState, searchResult: '' }));
      } else {
        const productList = getProductListFromCache(catalogueId);
        setShowLoader(false);
        setProductListState(prevState => ({ ...prevState, productList, searchResult: '' }));
        updateProductListHeight({ index: 0 });
      }
    };

    const clearPriceFilter = () => {
      IS_PRICE_FILTERING = false;
      setProductPriceFilter(null);
      const catalogueId = getActiveCatalogueId();

      if (IS_SEARCHING || IS_FILTERING) {
        const searchTerm = getProductSearchTerm() || null;
        eventbus.publish(PRODUCT_SEARCH.PRODUCT_SEARCH_EB_KEY, {
          type: PRODUCT_SEARCH.PRODUCT_SEARCH_TERM,
          value: searchTerm
        });
      } else {
        const productList = getProductListFromCache(catalogueId);
        setShowLoader(false);
        setProductListState(prevState => ({ ...prevState, productList, searchResult: '' }));
        updateProductListHeight({ index: 0 });
      }
    };

    const removeListener = eventbus.on(
      PRODUCT_SEARCH.PRODUCT_SEARCH_EB_KEY,
      async ({ type, value }) => {
        if (type === PRODUCT_SEARCH.PRODUCT_SEARCH_STATE) {
          IS_SEARCHING = value;
          if (!value) {
            clearSearch();
          }
        } else if (type === PRODUCT_SEARCH.PRODUCT_SEARCH_TERM) {
          if (!value || value.length < 2) {
            if (IS_PRICE_FILTERING) {
              setProductSearchTerm(value);
              const filterValue = getProductPriceFilter();
              eventbus.publish(PRODUCT_SEARCH.PRODUCT_SEARCH_EB_KEY, {
                type: PRODUCT_SEARCH.FILTER_PRODUCT_ON,
                value: filterValue
              });
            } else {
              clearSearch();
            }
            return;
          }

          setProductSearchTerm(value);

          let products = [];
          let result = '';
          const catalogueId = getActiveCatalogueId();
          const tags = Tags.getAllSelectedTags() || [];
          SEARCH_RESULT_MAINTAINER = Date.now();
          const reqSendTime = SEARCH_RESULT_MAINTAINER;
          if (IS_PRICE_FILTERING) {
            const filterValue = getProductPriceFilter();

            setShowLoader(true);

            ({ products, result } = await Api.filterProducts({
              searchTerm: value,
              catalogueId,
              tags,
              ...filterValue
            }));

            setShowLoader(false);
          } else {
            setShowLoader(true);
            ({ products, result } = await Api.searchProducts(value, catalogueId, tags));
            SHOULD_REFRESH_SEARCH = true;
            setShowLoader(false);
          }
          const isResultValid = (() => SEARCH_RESULT_MAINTAINER === reqSendTime)();

          if (!isResultValid) {
            return;
          }

          let searchResult = result;
          if (IS_PRICE_FILTERING) {
            const value = getProductPriceFilter();
            searchResult = modifySearchResult({
              result,
              type: value.type,
              price: value.price,
              minPrice: value.minPrice,
              maxPrice: value.maxPrice,
              numberOfProduct: (products || []).length
            });
          }

          const productsArray = products.map(({ product_id }) => product_id);
          let productList = getProductRows({ productIds: productsArray, catalogueId }).sort(
            (row1, row2) => row1.position - row2.position
          );

          setProductListState(prevState => {
            return {
              ...prevState,
              productList,
              searchResult,
              shouldShowClearFilter: !!IS_PRICE_FILTERING
            };
          });
          updateProductListHeight({ index: 0 });
        } else if (type === PRODUCT_SEARCH.FILTER_PRODUCT_STATE) {
          if (!value) {
            clearPriceFilter();
          }
        } else if (type === PRODUCT_SEARCH.FILTER_PRODUCT_ON) {
          const catalogueId = getActiveCatalogueId();
          const searchTerm = getProductSearchTerm() || '';

          IS_PRICE_FILTERING = true;
          setProductPriceFilter(value);

          const tags = Tags.getAllSelectedTags();
          FILTER_RESULT_MAINTAINER = Date.now();
          const reqSendTime = FILTER_RESULT_MAINTAINER;
          setShowLoader(true);
          const { products, result } = await Api.filterProducts({
            searchTerm,
            catalogueId,
            tags,
            ...value
          });
          setShowLoader(false);
          const isResultValid = (() => FILTER_RESULT_MAINTAINER === reqSendTime)();

          if (!isResultValid) {
            return;
          }

          const productsArray = products.map(({ product_id }) => product_id);
          let productList = getProductRows({ productIds: productsArray, catalogueId }).sort(
            (row1, row2) => row1.position - row2.position
          );

          const modifiedResult = modifySearchResult({
            result,
            type: value.type,
            price: value.price,
            minPrice: value.minPrice,
            maxPrice: value.maxPrice,
            numberOfProduct: (products || []).length
          });

          setProductListState(prevState => ({
            ...prevState,
            productList,
            searchResult: modifiedResult,
            shouldShowClearFilter: true
          }));
          updateProductListHeight({ index: 0 });
        }
      }
    );

    return () => removeListener();
  }, []);

  // Effect of tag filtering. This handles all the list manipulation due to tag filtering
  useEffect(() => {
    const clearFiltering = () => {
      const catalogueId = getActiveCatalogueId();
      IS_FILTERING = false;

      if (IS_SEARCHING) {
        const searchTerm = getProductSearchTerm() || null;
        eventbus.publish(PRODUCT_SEARCH.PRODUCT_SEARCH_EB_KEY, {
          type: PRODUCT_SEARCH.PRODUCT_SEARCH_TERM,
          value: searchTerm
        });
      } else {
        const productList = getProductListFromCache(catalogueId);
        setProductListState(prevState => ({ ...prevState, productList }));
        updateProductListHeight({ index: 0 });
      }
    };

    const removeListener = eventbus.on(PRODUCT_SEARCH.TAG_FILTERING_EB_KEY, ({ type, value }) => {
      if (type === PRODUCT_SEARCH.TAG_API_RESP) {
        setShowLoader(value);
        return;
      }

      if (type === PRODUCT_SEARCH.TAG_FILTERING_STATE) {
        // this will fail if reps has no prdouctIds for the selected tags
        if (!value) {
          clearFiltering();
          return;
        }
        return;
      }

      if (type === PRODUCT_SEARCH.FILTERED_PRODUCTS_VIA_TAGS) {
        IS_FILTERING = true;
        const catalogueId = getActiveCatalogueId();
        let productList = getProductRows({ productIds: value, catalogueId }).sort(
          (row1, row2) => row1.position - row2.position
        );
        setProductListState(prevState => ({
          ...prevState,
          productList
        }));

        updateProductListHeight({ index: 0 });

        return;
      }
    });

    return () => removeListener();
  }, []);

  // makes sure that the list is not overwritten by external factors and still is updated
  const updateSearchedList = useCallback(({ data }) => {
    if (!data || !data.productsList) {
      return;
    }

    const productListMap = {};
    data.productsList.forEach(row => {
      productListMap[row.productId] = row;
    });

    setProductListState(prevState => {
      let newProductList = [];
      prevState.productList.forEach(prevData => {
        if (productListMap[prevData.productId]) {
          const newData = productListMap[prevData.productId];
          const row = { ...prevData, stock: newData.stock };
          newProductList.push(row);
        }
      });

      newProductList = newProductList.sort((row1, row2) => row1.position - row2.position);

      return {
        ...prevState,
        productList: newProductList
      };
    });
    updateProductListHeight({ index: 0 });
  }, []);

  // Callback when cache is manipulated
  const productListListener = useCallback(
    (error, payload) => {
      const { err, loading, refreshing, data } = CacheCallback(error, payload);
      if ((IS_SEARCHING && SHOULD_REFRESH_SEARCH) || IS_FILTERING) {
        updateSearchedList({ data });
        return;
      }

      if (err) {
        setProductListState(prevState => ({
          ...prevState,
          error: !!err,
          loading
        }));
        return;
      }

      if (loading) {
        setProductListState(prevState => ({
          ...prevState,
          loading,
          refreshing,
          error: !!err
        }));
        return;
      }

      if (payload.status === OPERATION_STATUS.SUCCESS) {
        const date = new Date().toISOString();
        const catalogueId = getActiveCatalogueId();
        setLastFetchDate({ date, catalogueId });
      }

      const productMeta = data.productsList || [];
      let productList = [];
      if (productMeta) {
        productList = productMeta.sort((p1, p2) => p1.position - p2.position);

        const allItems = productMeta.map(product => product.productId);
        selectedProducts.setAllItems(allItems);
      }

      setProductListState(prevState => ({
        ...prevState,
        refreshing,
        productList,
        error: !!err,
        loading: false
      }));
      updateProductListHeight({ index: 0 });

      const catalogueId = getActiveCatalogueId();
      setProductPositionMap({ productList, catalogueId });
    },
    [updateSearchedList]
  );

  // Effect of product list listeners attached to cache manipulation
  useEffect(() => {
    attachProductListListener({ listener: productListListener, catalogueId: activeCatalogueId });
    getProductList({ catalogueId: activeCatalogueId });

    const currentCataloguesProductList = getProductListFromCache(activeCatalogueId);
    setProductListState(prevState => ({
      ...prevState,
      productList: currentCataloguesProductList
    }));
    updateProductListHeight({ index: 0 });
    resetVariables();

    return () =>
      removeProductListListener({ listener: productListListener, catalogueId: activeCatalogueId });
  }, [productListListener, activeCatalogueId]);

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex) {
        return;
      }

      const newList = arrayMove(productListState.productList, oldIndex, newIndex).map(
        (row, index) => ({
          ...row,
          position: index
        })
      );

      setProductListState(prevState => ({
        ...prevState,
        productList: [...newList]
      }));
      const index = oldIndex < newIndex ? oldIndex : newIndex;
      updateProductListHeight({ index });
      const catalogueId = getActiveCatalogueId();
      reorderProduct({ newProductList: newList, catalogueId, oldIndex, newIndex });
    },
    [productListState.productList]
  );

  const changeReduceHeight = useCallback(val => {
    setReduceHeight(val);
  }, []);

  const uploadFromPicker = useCallback(e => {
    e.stopPropagation();
    const catalogueId = getActiveCatalogueId();
    uploadFromFilePicker({ e, catalogueId });
  }, []);

  if (productListState.loading) {
    return (
      <div id={'productListLoaderContainer'}>
        <Loader size={'large'} color={'white'} />
      </div>
    );
  }

  const renderList = () => {
    if (showLoader) {
      return (
        <div id={'productListLoaderContainer'}>
          <Loader size={'large'} color={'white'} />
        </div>
      );
    }

    if (!productListState.productList || !productListState.productList.length) {
      if (IS_SEARCHING || IS_FILTERING || IS_PRICE_FILTERING) {
        return <EmptyProductList.SEARCH />;
      }

      return <EmptyProductList.NORMAL uploadFromPicker={uploadFromPicker} />;
    }

    return (
      <div className={'AutoSizerContainer'}>
        <AutoSizer>
          {({ width, height }) => (
            <SortableVirtualList
              items={productListState.productList}
              onSortEnd={onSortEnd}
              width={width}
              height={height - reduceHeight}
              pressDelay={150}
              lockAxis={'y'}
              activeCatalogueId={activeCatalogueId}
            />
          )}
        </AutoSizer>
      </div>
    );
  };

  return (
    <>
      <CatalogueSearchResult
        result={productListState.searchResult}
        shouldShowClearFilter={productListState.shouldShowClearFilter}
      />
      <CatalogueTags activeCatalogueId={activeCatalogueId} />
      {renderList()}
      <ProductsFilePicker changeReduceHeight={changeReduceHeight} />
    </>
  );
};
