import React, { useState, useEffect, useCallback } from 'react';
import ProductRowShimmer from './ProductRowShimmer';
import {
  attachProductMetaListener,
  removeProductMetaListener,
  getProductMetaFromCache,
  OPERATION_STATUS,
  deleteProducts,
  setActiveProductId,
  isProductSelected,
  ACTIVE_PRODUCT_ID_META
} from 'qs-data-manager/Products';
import { selectedProducts } from 'qs-data-manager/Selected';
import CheckBox from 'qs-components/Common/CheckBox';
import { setDefaultImageUrl } from 'qs-data-manager/ProductDetails';
import { getActiveCatalogueId } from 'qs-data-manager/Catalogues';
import eventbus from 'eventing-bus';
import SmallImageLoader from 'qs-components/Common/SmallImageLoader';
import { getCurrencySymbol } from 'qs-helpers';
import { getCompanyCurrencyCode } from 'qs-data-manager/Company';

import './styles.scss';
import { ReactComponent as DeleteIcon } from 'qs-assets/Media/trash.svg';

const NO_CATALOGUE_TITLE_PLACEHOLDER = 'No product title';
const NO_CATALOGUE_DESCRIPTION_PLACEHOLDER = 'No product description';

export default ({ productId, productIndex } = {}) => {
  const [checkboxValue, setCheckboxValue] = useState(() => selectedProducts.isSelected(productId));
  const [editMode, setEditMode] = useState(() => selectedProducts.isActive());
  const [productMetaState, setProductMetaState] = useState({
    loading: false,
    refreshing: false,
    success: false,
    error: null,
    productMeta: getProductMetaFromCache(productId)
  });
  const [isSelected, setIsSelected] = useState(() => isProductSelected(productId));

  useEffect(() => {
    const removeListener = eventbus.on(ACTIVE_PRODUCT_ID_META.eventbusKey, productIds => {
      const id = productIds[0];
      setIsSelected(id === productId);
    });

    return () => removeListener();
  }, [productId]);

  useEffect(() => {
    const isSelected = isProductSelected(productId);
    setIsSelected(isSelected);
  }, [productId, productIndex]);

  const productMetaListener = useCallback((err, { status, data }) => {
    const updates = {};
    if (err) {
      updates.loading = false;
      updates.refreshing = false;
      updates.success = false;
      updates.error = err;
    } else {
      switch (status) {
        case OPERATION_STATUS.LOADING: {
          updates.loading = true;
          updates.refreshing = false;
          updates.success = false;
          updates.error = null;
          break;
        }
        case OPERATION_STATUS.REFRESHING: {
          updates.loading = false;
          updates.refreshing = true;
          updates.success = false;
          updates.error = null;
          updates.productMeta = data || {};
          break;
        }
        case OPERATION_STATUS.SUCCESS: {
          updates.loading = false;
          updates.refreshing = false;
          updates.success = true;
          updates.error = null;
          updates.productMeta = data || {};
          break;
        }
        case OPERATION_STATUS.UPDATE: {
          updates.loading = false;
          updates.refreshing = false;
          updates.success = false;
          updates.error = null;
          updates.productMeta = data || {};
          break;
        }
        default:
          break;
      }

      if (updates.productMeta) {
        const currencyCode = getCompanyCurrencyCode();
        updates.productMeta.currencySymbol = getCurrencySymbol({ currencyCode });
      }

      setProductMetaState(updates);
    }
  }, []);

  const onSelectedValueChange = useCallback(
    (all = {}) => {
      const selectionState = !!all[productId];
      setCheckboxValue(selectionState);
    },
    [productId]
  );

  const onSelectionStateChange = useCallback(isActive => {
    setEditMode(isActive);
  }, []);

  useEffect(() => {
    attachProductMetaListener({ listener: productMetaListener, productId });

    const productMeta = getProductMetaFromCache(productId);
    setProductMetaState(prevState => ({
      ...prevState,
      productMeta
    }));

    selectedProducts.addActiveListener(onSelectionStateChange);
    selectedProducts.addListener(onSelectedValueChange);

    return () => {
      removeProductMetaListener({ listener: productMetaListener, productId });
      selectedProducts.removeActiveListener(onSelectionStateChange);
      selectedProducts.removeListener(onSelectedValueChange);
    };
  }, [productId, productMetaListener, onSelectionStateChange, onSelectedValueChange]);

  const toggleCheckboxValue = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      const newValue = !checkboxValue;

      setCheckboxValue(newValue);

      if (newValue) {
        selectedProducts.add(productId);
      } else {
        selectedProducts.remove(productId);
      }
    },
    [checkboxValue, productId]
  );

  const deleteProduct = useCallback(
    e => {
      e.stopPropagation();

      const removeProduct = window.confirm(
        'Are you sure you want to delete this product from catalogue ?'
      );

      if (removeProduct) {
        const catalogueId = getActiveCatalogueId();
        deleteProducts([productId], catalogueId, {
          showLoader: true,
          makeRemoteChanges: true
        });
      }
    },
    [productId]
  );

  const onProductRowClick = useCallback(
    e => {
      if (editMode) {
        toggleCheckboxValue(e);
      } else {
        e.stopPropagation();
        e.preventDefault();
        setActiveProductId(productId);
        setDefaultImageUrl({
          imageUrl: productMetaState.productMeta.pictureUrl
        });
        setIsSelected(true);
      }
    },
    [productId, productMetaState.productMeta, editMode, toggleCheckboxValue]
  );

  if (!productMetaState.productMeta || !productMetaState.productMeta.productId) {
    return <ProductRowShimmer />;
  }

  return (
    <div
      onClick={onProductRowClick}
      className={`ProductRow noselect ${isSelected ? 'productRowSelected' : ''}`}
    >
      <div className={'leftContainer'}>
        {editMode && (
          <div onClick={toggleCheckboxValue} className={'checkbox'}>
            <CheckBox checked={checkboxValue} onCheckChanged={toggleCheckboxValue} />
          </div>
        )}

        <div className={'productImageContainer'}>
          <SmallImageLoader
            pictureId={productMetaState.productMeta.pictureId}
            isPrepared={productMetaState.productMeta.isPrepared}
          />
        </div>

        <div className={'titleContainer'}>
          <div className={'title ellipsis'}>
            {!!productMetaState.productMeta.name
              ? productMetaState.productMeta.name
              : NO_CATALOGUE_TITLE_PLACEHOLDER}
          </div>
          <div className={'description ellipsis'}>
            {!!productMetaState.productMeta.description
              ? productMetaState.productMeta.description
              : NO_CATALOGUE_DESCRIPTION_PLACEHOLDER}
          </div>
          {productMetaState.productMeta.stock == 0 && (
            <div className={'outOfStock'}>out of stock</div>
          )}
        </div>
      </div>
      <div className={'rightContainer'}>
        <div
          className={`priceAndDiscountContainer ${editMode ? 'editModeWidth' : 'nonEditModeWidth'}`}
        >
          <div
            className={`price ${
              !!productMetaState.productMeta.discount ? 'discountAvailable' : ''
            }`}
          >
            {!!productMetaState.productMeta.price
              ? `${productMetaState.productMeta.currencySymbol} ${productMetaState.productMeta.price}`
              : '-'}
          </div>
          {!!productMetaState.productMeta.discount && (
            <div className={'discount'}>
              {productMetaState.productMeta.currencySymbol} {productMetaState.productMeta.discount}
            </div>
          )}
        </div>

        {!!editMode ? (
          <div onClick={deleteProduct} className={'deleteContainer'}>
            <DeleteIcon className={'deleteIcon'} />
          </div>
        ) : null}
      </div>
    </div>
  );
};
