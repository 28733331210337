import React, { useCallback, useEffect, useState } from 'react';
import './styles.scss';
import Loader from '../Loader';
import uploadImage from 'qs-assets/Media/upload.png';
import '../GlobalLoader/styles.css';
import { generateImageUrls } from 'qs-helpers/index';
import eventbus from 'eventing-bus';
import webworkerConfig from 'qs-master-worker/config';
import { ReactComponent as ErrorIcon } from 'qs-assets/Media/image-error.svg';

export default ({
  pictureId,
  imageContainerClassName = '',
  imageClassName = '',
  isPrepared = true,
  localImageError = false
}) => {
  // { imageUrls: Array, error: bool }
  const [imageMeta, setImageMeta] = useState(() => generateImageUrls(pictureId));
  const [sourceIndex, setSourceIndex] = useState(0);
  const [isUploading, setIsUploading] = useState(!isPrepared);

  // Effect to show loader if image is uploading
  useEffect(() => {
    let removeEventbus = () => {};

    if (!isPrepared) {
      const ebKey = webworkerConfig.UPLOAD_IMAGES.eventbusKey(pictureId);
      removeEventbus = eventbus.on(ebKey, ({ pictureId, error, isPrepared }) => {
        if (error) {
          console.log('error in comp', error);
          setImageMeta({
            imageUrls: [],
            error: true
          });
          setIsUploading(isPrepared);
        } else {
          const data = generateImageUrls(pictureId);
          setImageMeta(data);
          setIsUploading(false);
        }
      });
    }

    return removeEventbus;
  }, [pictureId, isPrepared]);

  useEffect(() => {
    const imageMeta = generateImageUrls(pictureId);
    setImageMeta(imageMeta);
    setSourceIndex(0);
    setIsUploading(!isPrepared);
  }, [pictureId, isPrepared]);

  const onError = useCallback(
    e => {
      e.stopPropagation();
      if (sourceIndex < imageMeta.imageUrls.length - 1) {
        setSourceIndex(sourceIndex + 1);
      }
    },
    [sourceIndex, imageMeta.imageUrls.length]
  );

  const errorUI = useCallback(() => {
    return (
      <div className={'errorIconContainer'}>
        <ErrorIcon className={'errorIcon'} />
      </div>
    );
  }, []);

  const renderImageOrUploader = useCallback(
    ({ imageMeta, imageClassName = '', isUploading }) => {
      if (imageMeta.error || localImageError) {
        return errorUI();
      }

      if (isUploading) {
        return (
          <div className={'absolutePosition uploadingImageContainer'}>
            <img src={uploadImage} alt="" className={'uploadingImage'} />
            <div className="progress">
              <div className="indeterminate" />
            </div>
          </div>
        );
      }

      return (
        <>
          <div className={'absolutePosition'}>
            <Loader size={'small'} className={'loader'} />
          </div>
          <div className={`imageContainer ${imageClassName}`}>
            {!!imageMeta.imageUrls[sourceIndex] ? (
              <>
                <div
                  className={'smallBg'}
                  style={{ backgroundImage: `url(${imageMeta.imageUrls[sourceIndex]})` }}
                />
                <img
                  onError={onError}
                  src={`${imageMeta.imageUrls[sourceIndex]}`}
                  className={'smallImage'}
                  alt={''}
                />
              </>
            ) : (
              errorUI()
            )}
          </div>
        </>
      );
    },
    [sourceIndex, onError, localImageError, errorUI]
  );

  return (
    <div id={'smallImageLoader'} className={imageContainerClassName ? imageContainerClassName : ''}>
      {renderImageOrUploader({ imageMeta, imageClassName, isUploading })}
    </div>
  );
};
