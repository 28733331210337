import React, { Component } from 'react';
import WithNavRef from 'qs-helpers/WithNavRef';
import OrdersContactsScreen from './OrdersContactsScreen/OrdersContactsScreen';
import ContactOrdersScreen from './ContactOrdersScreen/ContactOrdersScreen.js';
import InquiriesScreen from './InquiriesScreen/InquiriesScreen.js';
import './styles.scss';

class Inquiries extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedContact: null,
      selectedOrderId: '',
      isSelectedOrderDeleted: false,
      isInheritedCatalogue: null
    };
  }

  seeContactsOrder = number => {
    if (this.state.selectedContact !== number) {
      this.setState({ selectedContact: number, selectedOrderId: '' });
    }
  };

  clearScreenState = () => {
    this.setState({ selectedContact: null, selectedOrderId: '' });
  };

  clearSelectionState = () => {
    this.setState({ selectedOrderId: '' });
  };

  seeContactInquiry = ({ contact, orderId, isDeletedByVisitor, isInheritedCatalogue }) => {
    this.setState({
      inquiryContact: contact,
      selectedOrderId: orderId,
      isSelectedOrderDeleted: isDeletedByVisitor,
      isInheritedCatalogue
    });
  };

  setIsInquiryDeleted = () => {
    // commented in production as well
    // if(this.state.orderId === orderId) {
    //     this.setState({isInquiryDeleted});
    // }
  };

  render() {
    const {
      selectedContact,
      selectedOrderId,
      isSelectedOrderDeleted,
      isInheritedCatalogue
    } = this.state;

    return (
      <WithNavRef navigationRef={this.props}>
        <div className="screenContainer" id={'InquiriesPage'}>
          <OrdersContactsScreen
            onContactClick={this.seeContactsOrder}
            clearScreenState={this.clearScreenState}
            selectedContact={selectedContact}
          />
          <ContactOrdersScreen
            seeContactInquiry={this.seeContactInquiry}
            setIsInquiryDeleted={this.setIsInquiryDeleted}
            contact={selectedContact}
            selectedOrderId={selectedOrderId}
          />
          <InquiriesScreen
            contact={selectedContact}
            orderId={selectedOrderId}
            isOrderDeleted={isSelectedOrderDeleted}
            isInheritedCatalogue={isInheritedCatalogue}
            resetSelectedOrders={this.clearSelectionState}
          />
        </div>
      </WithNavRef>
    );
  }
}

export default Inquiries;
