import React from 'react';
import { ReactComponent as CloseIcon } from 'qs-assets/Media/close.svg';
import './styles.scss';

export default ({ didError, closeErrorModal }) => {
  if (!didError) {
    return null;
  }

  return (
    <div id={'LoginFailedContainer'}>
      <div className={'LoginFailed'}>
        <div className={'title'}>Login Failed</div>
        <div className={'description'}>
          Something went wrong. Please try again later or contact customer support
        </div>

        <a className={'contactSupport'} href="mailto:support@quicksell.co?subject=Login Failed">
          Contact support
        </a>
        <div onClick={closeErrorModal} className={'closeIconContainer'}>
          <CloseIcon className={'closeIcon'} />
        </div>
      </div>
    </div>
  );
};
