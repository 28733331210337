export default {
  INR: '\u20B9', // Indian Rupee
  AED: 'د.إ', // UAE Dirham
  AFN: 'Af', // Afghani
  ALL: 'L', // Lek
  AMD: 'Դ', // Armenian Dram
  AOA: 'Kz', // Kwanza
  ARS: '$', // Argentine Peso
  AUD: '$', // Australian Dollar
  AWG: 'ƒ', // Aruban Guilder/Florin
  AZN: 'ман', // Azerbaijanian Manat
  BAM: 'КМ', // Konvertibilna Marka
  BBD: '$', // Barbados Dollar
  BDT: '৳', // Taka
  BGN: 'лв', // Bulgarian Lev
  BHD: '.د.ب', // Bahraini Dinar
  BIF: '₣', // Burundi Franc
  BMD: '$', // Bermudian Dollar
  BND: '$', // Brunei Dollar
  BOB: 'Bs.', // Boliviano
  BRL: 'R$', // Brazilian Real
  BSD: '$', // Bahamian Dollar
  BWP: 'P', // Pula
  BYR: 'Br', // Belarussian Ruble
  BZD: '$', // Belize Dollar
  CAD: '$', // Canadian Dollar
  CDF: '₣', // Congolese Franc
  CHF: '₣', // Swiss Franc
  CLP: '$', // Chilean Peso
  CNY: '¥', // Yuan
  COP: '$', // Colombian Peso
  CRC: '₡', // Costa Rican Colon
  CUP: '$', // Cuban Peso
  CVE: '$', // Cape Verde Escudo
  CZK: 'Kč', // Czech Koruna
  DJF: '₣', // Djibouti Franc
  DKK: 'kr', // Danish Krone
  DOP: '$', // Dominican Peso
  DZD: 'دج', // Algerian Dinar
  EGP: '£', // Egyptian Pound
  ERN: 'Nfk', // Nakfa
  EUR: '€', // Euro
  FJD: '$', // Fiji Dollar
  FKP: '£', // Falkland Islands Pound
  GBP: '£', // Pound Sterling
  GEL: 'ლ', // Lari
  GHS: '₵', // Cedi
  GIP: '£', // Gibraltar Pound
  GMD: 'D', // Dalasi
  GNF: '₣', // Guinea Franc
  GTQ: 'Q', // Quetzal
  GYD: '$', // Guyana Dollar
  HKD: '$', // Hong Kong Dollar
  HNL: 'L', // Lempira
  HRK: 'Kn', // Croatian Kuna
  HTG: 'G', // Gourde
  HUF: 'Ft', // Forint
  IDR: 'Rp', // Rupiah
  ILS: '₪', // New Israeli Shekel
  IQD: 'د.ع', // Iraqi Dinar
  IRR: '﷼', // Iranian Rial
  ISK: 'Kr', // Iceland Krona
  JMD: '$', // Jamaican Dollar
  JOD: 'دينار', // Jordanian Dinar
  JPY: '¥', // Yen
  KES: 'Sh', // Kenyan Shilling
  KHR: '៛', // Riel
  KPW: '₩', // North Korean Won
  KRW: '₩', // South Korean Won
  KWD: 'د.ك', // Kuwaiti Dinar
  KYD: '$', // Cayman Islands Dollar
  KZT: '〒', // Tenge
  LAK: '₭', // Kip
  LBP: 'ل.ل.‎', // Lebanese Pound
  LKR: 'Rs', // Sri Lanka Rupee
  LRD: '$', // Liberian Dollar
  LSL: 'L', // Loti
  LYD: 'ل.د', // Libyan Dinar
  MAD: '.د.م', // Moroccan Dirham
  MDL: 'L', // Moldavian Leu
  MKD: 'ден', // Denar
  MMK: 'K', // Kyat
  MNT: '₮', // Tugrik
  MOP: 'P', // Pataca
  MRO: 'UM', // Ouguiya
  MUR: '₨', // Mauritius Rupee
  MVR: 'ރ.', // Rufiyaa
  MWK: 'MK', // Kwacha
  MXN: '$', // Mexican Peso
  MYR: 'RM', // Malaysian Ringgit
  MZN: 'MTn', // Metical
  NAD: '$', // Namibia Dollar
  NGN: '₦', // Naira
  NIO: 'C$', // Cordoba Oro
  NOK: 'kr', // Norwegian Krone
  NPR: '₨', // Nepalese Rupee
  NZD: '$', // New Zealand Dollar
  OMR: 'ر.ع.', // Rial Omani
  PAB: 'B/.', // Balboa
  PEN: 'S/.', // Nuevo Sol
  PGK: 'K', // Kina
  PHP: '₱', // Philippine Peso
  PKR: '₨', // Pakistan Rupee
  PLN: 'zł', // PZloty
  PYG: '₲', // Guarani
  QAR: 'ر.ق', // Qatari Rial
  RON: 'L', // Leu
  RSD: 'din', // Serbian Dinar
  RUB: 'р. ', // Russian Ruble
  RWF: '₣', // Rwanda Franc
  SAR: 'ر.س', // Saudi Riyal
  SBD: '$', // Solomon Islands Dollar
  SCR: '₨', // Seychelles Rupee
  SDG: '£', // Sudanese Pound
  SEK: 'kr', // Swedish Krona
  SGD: '$', // Singapore Dollar
  SHP: '£', // Saint Helena Pound
  SLL: 'Le', // Leone
  SOS: 'Sh', // Somali Shilling
  SRD: '$', // Suriname Dollar
  STD: 'Db', // Dobra
  SYP: 'ل.س', // Syrian Pound
  SZL: 'L', // Lilangeni
  THB: '฿', // Baht
  TJS: 'ЅМ', // Somoni
  TMT: 'm', // Manat
  TND: 'د.ت', // Tunisian Dinar
  TOP: 'T$', // Pa’anga
  TRY: '₤', // Turkish Lira
  TTD: '$', // Trinidad and Tobago Dollar
  TWD: '$', // Taiwan Dollar
  TZS: 'Sh', // Tanzanian Shilling
  UAH: '₴', // Hryvnia
  UGX: 'Sh', // Uganda Shilling
  USD: '$', // US Dollar
  UYU: '$', // Peso Uruguayo
  VEF: 'Bs F', // Bolivar Fuerte
  VND: '₫', // Dong
  VUV: 'Vt', // Vatu
  WST: 'T', // Tala
  XAF: '₣', // CFA Franc BCEAO
  XCD: '$', // East Caribbean Dollar
  XPF: '₣', // CFP Franc
  YER: '﷼', // Yemeni Rial
  ZAR: 'R', // Rand
  ZMW: 'ZK', // Zambian Kwacha
  ZWL: '$' // Zimbabwe Dollar
};
