import eventbus from 'eventing-bus';
import { NATIVE_STORAGE_EVENT_BUS_KEY } from 'qs-services/DexieInit';
import { saveCatalogueListMeta, saveCatalogueRowMeta, saveCatalogueTags } from './Catalogue';
import { saveProductList, saveProductRowMeta } from './Product';
import { saveSinglePrivateNote } from './ProductDetails';
import { connector } from '../../ApiAndCacheConnector';

// Config for storing api response in native storage
const config = {
  [connector.CATALOGUE_LIST_META.nativeStorageKey]: {
    storeDataToNative: saveCatalogueListMeta
  },
  [connector.CATALOGUE_ROW_META.nativeStorageKey]: {
    storeDataToNative: saveCatalogueRowMeta
  },
  [connector.PRODUCT_LIST_META.nativeStorageKey]: {
    storeDataToNative: saveProductList
  },
  [connector.PRODUCT_ROW_META.nativeStorageKey]: {
    storeDataToNative: saveProductRowMeta
  },
  [connector.CATALOGUE_TAGS.nativeStorageKey]: {
    storeDataToNative: saveCatalogueTags
  }
};

// populating native storage as a side effect to api calls
eventbus.on(NATIVE_STORAGE_EVENT_BUS_KEY, (key, apiResp, extraData) => {
  if (config && config[key]) {
    config[key].storeDataToNative(apiResp, extraData);
  }
});
