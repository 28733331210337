import { nativeDB } from '../../DexieInit';
import * as Sentry from '@sentry/browser';

export const createCatalogueInNative = async (meta, catalogueId) => {
  try {
    const listData = {
      sortTimestamp: Date.now(),
      catalogueId: catalogueId
    };
    const rowMeta = {
      title: meta.title,
      productCount: 0,
      catalogueId
    };

    await Promise.all([
      nativeDB.cataloguesListMeta.put(listData),
      nativeDB.catalogueRowMeta.put(rowMeta, catalogueId)
    ]);
  } catch (err) {
    console.log('createCatalogueInNative: Could not store into native', err);
    Sentry.captureException(err);
  }
};

export const updateCatalogueTitleInNative = async ({ title, catalogueId }) => {
  try {
    await nativeDB.catalogueRowMeta.update(catalogueId, { title });
  } catch (err) {
    console.log('updateCatalogueTitleInNative: Could not store into native', err);
    Sentry.captureException(err);
  }
};

export const deleteCataloguesFromNative = async catalogueIds => {
  await Promise.all([
    nativeDB.catalogueRowMeta.bulkDelete(catalogueIds),
    nativeDB.cataloguesListMeta.bulkDelete(catalogueIds)
  ]);
};

export const upsertCatalogueRowInNative = async (changes, newCataloguesAdded) => {
  const catalogueIds = Object.keys(changes);
  const newMeta = Object.keys(changes).map(id => changes[id]);
  const promises = [nativeDB.catalogueRowMeta.bulkPut(newMeta, catalogueIds)];

  if (newCataloguesAdded) {
    promises.push(nativeDB.cataloguesListMeta.bulkPut(newCataloguesAdded));
  }

  await Promise.all(promises);
};

// CATALOGUE TAGS methods:
export const setCatalogueTagVisibilityInNative = async ({ catalogueId, changes }) => {
  const { newTagsCache } = changes;
  await nativeDB.catalogueTags.put(newTagsCache, catalogueId);
};

export const saveSortedTagsInNative = async ({ catalogueId, changes }) => {
  const { newTagsCache } = changes;
  await nativeDB.catalogueTags.put(newTagsCache, catalogueId);
};

export const saveTagsChangesInNative = async ({ catalogueId, tags }) => {
  nativeDB.catalogueTags.put(tags, catalogueId);
};

export const getAllCataloguePictureMeta = async () => {
  const catalogueData = [];
  try {
    await nativeDB.catalogueRowMeta.each((data, cursor) => {
      if (data.totalImagesToProcess > 0) {
        catalogueData.push({
          catalogueId: data.catalogueId,
          uploaded: data.uploaded,
          totalImages: data.totalImagesToProcess
        });
      }
    });
    return catalogueData;
  } catch (catalogueFetchError) {
    console.error('getCataloguePictureMeta: could not fetch the uploaded image meta');
    Sentry.captureException(catalogueFetchError);
    return [];
  }
};

export const updateCataloguePictureMeta = async ({
  catalogueId,
  uploaded,
  totalImagesToProcess
}) => {
  try {
    const catalogueRowData = await nativeDB.catalogueRowMeta.get(catalogueId);
    await nativeDB.catalogueRowMeta.put(
      { ...catalogueRowData, uploaded, totalImagesToProcess },
      catalogueId
    );
  } catch (updateError) {
    console.error('updateCataloguePictureMeta: could not fetch the uploaded image meta');
    Sentry.captureException(updateError);
  }
};

export const clearCataloguePictureMetaCounts = async () => {
  const catalogueData = [];
  const catalogueDataIds = [];
  try {
    await nativeDB.catalogueRowMeta.each((data) => {
      if (data.totalImagesToProcess > 0) {
        catalogueData.push({
          ...data,
          uploaded: 0,
          totalImages: 0
        });
        catalogueDataIds.push(data.catalogueId);
      }
    });
    await nativeDB.catalogueRowMeta.bulkPut(catalogueData, catalogueDataIds);
  } catch (countClearError) {
    console.error('clearCataloguePictureMetaCounts: could not fetch the uploaded image meta');
    Sentry.captureException(countClearError);
  }
};
