import Listener from 'qs-helpers/Listener';
import { handleProductChangeListener } from 'qs-data-manager/Products';
import { getCompanyIdFromCache } from 'qs-data-manager/Company';
import { rehydrateProductLastFetch } from 'qs-helpers';

const listener = new Listener();
const END_POINT = {
  PRODUCT_CHANGE_TIMESTAMP: ({ companyId, catalogueId }) =>
    `screen-update-timestamps/${companyId}/catalogue-detail-screen/${catalogueId}`
};

const attachProductChangesListener = ({ catalogueId }) => {
  const companyId = getCompanyIdFromCache();

  if (!companyId || !catalogueId) {
    return;
  }

  const key = END_POINT.PRODUCT_CHANGE_TIMESTAMP({ companyId, catalogueId });

  rehydrateProductLastFetch({ catalogueId });
  listener.add({ key, type: 'value' }, snapshot => {
    const value = snapshot.val();
    handleProductChangeListener({ timestamp: value, catalogueId });
  });
};

const removeProductChangeListener = ({ catalogueId }) => {
  const companyId = getCompanyIdFromCache();
  if (!companyId || !catalogueId) {
    return;
  }

  const key = END_POINT.PRODUCT_CHANGE_TIMESTAMP({ companyId, catalogueId });
  listener.remove({ key, type: 'value' });
};

export { attachProductChangesListener, removeProductChangeListener };
