import React, { useCallback, useState } from 'react';

import WhatsappImage from 'qs-assets/Media/whatsapp.png';
import BrochureImage from 'qs-assets/Media/brochure.png';
import PdfImage from 'qs-assets/Media/colouredPdf.png';
import FacebookImage from 'qs-assets/Media/facebook-bg.png';
import EmailImage from 'qs-assets/Media/mail.png';
import DownloadBlack from 'qs-assets/Media/downloadBlack.png';
import ExcelImage from 'qs-assets/Media/excel.png';
import './styles.scss';
import {
  openWhatsApp,
  downloadBrochure,
  downloadPdf,
  openFacebook,
  openMail,
  downloadCatalogueImages,
  downloadCatalogueExcel
} from 'qs-helpers';
import CatalogueLib from 'qs-data-manager/Catalogues';
import GrtIcon from 'qs-assets/Media/ic-grt-logo.png';
import { GRT_PM_META } from 'qs-data-manager';
import eventbus from 'eventing-bus';
import Mixpanel from 'qs-data-manager/Mixpanel';

const SHARE_OPTIONS = {
  GRT_PM: 'GRT_PM',
  WHATSAPP: 'WHATSAPP',
  BROCHURE: 'BROCHURE',
  PDF: 'PDF',
  FB: 'FB',
  EMAIL: 'EMAIL',
  DOWNLOAD_IMAGES: 'DOWNLOAD_IMAGES',
  DOWNLOAD_EXCEL: 'DOWNLOAD_EXCEL'
};

export default ({ catalogueId } = {}) => {
  const [isGrtPM] = useState(GRT_PM_META.isGrtPM);

  const onOptionClick = useCallback(
    option => {
      let eventName = '';
      switch (option) {
        case SHARE_OPTIONS.WHATSAPP: {
          const link = CatalogueLib.getCatalogueLinkFromCache(catalogueId);
          openWhatsApp({ text: link });
          eventName = 'shared_via_whatsapp';
          break;
        }

        case SHARE_OPTIONS.BROCHURE: {
          downloadBrochure({ catalogueId });
          eventName = 'brochure_downloaded';
          break;
        }

        case SHARE_OPTIONS.PDF: {
          const link = CatalogueLib.getCatalogueLinkFromCache(catalogueId);
          downloadPdf({ catalogueId, link });
          eventName = 'pdf_downloaded';
          break;
        }

        case SHARE_OPTIONS.FB: {
          const link = CatalogueLib.getCatalogueLinkFromCache(catalogueId);
          openFacebook({ text: link });
          eventName = 'shared_via_downloaded';
          break;
        }

        case SHARE_OPTIONS.EMAIL: {
          const link = CatalogueLib.getCatalogueLinkFromCache(catalogueId);
          openMail({ text: link });
          eventName = 'shared_via_email';
          break;
        }

        case SHARE_OPTIONS.DOWNLOAD_IMAGES: {
          downloadCatalogueImages({ catalogueId });
          eventName = 'catalogue_images_downloaded';
          break;
        }

        case SHARE_OPTIONS.DOWNLOAD_EXCEL: {
          downloadCatalogueExcel({ catalogueId });
          eventName = 'catalogue_excel_downloaded';
          break;
        }

        default:
          break;
      }

      Mixpanel.sendEvent({
        eventName,
        props: {
          catalogueId
        }
      });
    },
    [catalogueId]
  );

  const openGrtModal = useCallback(() => {
    const catalogueName = CatalogueLib.getCatalogueTitle({ catalogueId });

    const link = CatalogueLib.getCatalogueLinkFromCache();

    if (typeof link !== 'string') {
      return;
    }

    const data = {
      showModal: true,
      catalogueLink: link,
      catalogueName
    };

    eventbus.publish(GRT_PM_META.EVENT_BUS_KEY, data);
  }, [catalogueId]);

  return (
    <div id={'shareOptions'}>
      {isGrtPM && (
        <div className={`option ${isGrtPM ? 'firstOption' : ''}`}>
          <div className={'iconContainer'} onClick={openGrtModal}>
            <img src={GrtIcon} alt="GRT icon" className={'icon'} />
            <div>Showrooms</div>
          </div>
        </div>
      )}
      <div
        onClick={() => onOptionClick(SHARE_OPTIONS.WHATSAPP)}
        className={`option ${isGrtPM ? '' : 'firstOption'}`}
      >
        <div className={'iconContainer'}>
          <img src={WhatsappImage} alt="" className={'icon'} />
        </div>
        <div className={'optionName'}>WhatsApp</div>
      </div>
      <div onClick={() => onOptionClick(SHARE_OPTIONS.BROCHURE)} className={'option'}>
        <div className={'iconContainer'}>
          <img src={BrochureImage} alt="" className={'icon'} />
        </div>
        <div className={'optionName'}>Brochure</div>
      </div>
      <div onClick={() => onOptionClick(SHARE_OPTIONS.PDF)} className={'option'}>
        <div className={'iconContainer'}>
          <img src={PdfImage} className={'icon pdfIcon'} />
        </div>
        <div className={'optionName'}>PDF</div>
      </div>
      <div onClick={() => onOptionClick(SHARE_OPTIONS.FB)} className={'option'}>
        <div className={'iconContainer'}>
          <img src={FacebookImage} className={'icon facebook'} />
        </div>
        <div className={'optionName'}>Facebook</div>
      </div>
      <div onClick={() => onOptionClick(SHARE_OPTIONS.EMAIL)} className={'option'}>
        <div className={'iconContainer'}>
          <img src={EmailImage} className={'icon emailIcon'} />
        </div>
        <div className={'optionName'}>Email</div>
      </div>
      <div onClick={() => onOptionClick(SHARE_OPTIONS.DOWNLOAD_IMAGES)} className={'option'}>
        <div className={'iconContainer'}>
          <img src={DownloadBlack} className={'icon'} />
        </div>
        <div className={'optionName'}>Download</div>
      </div>
      <div
        onClick={() => onOptionClick(SHARE_OPTIONS.DOWNLOAD_EXCEL)}
        className={'option lastOption'}
      >
        <div className={'iconContainer'}>
          <img src={ExcelImage} className={'icon'} />
        </div>
        <div className={'optionName'}>Excel</div>
      </div>
    </div>
  );
};
