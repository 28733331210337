import React, { useState, useRef, Fragment, useEffect } from 'react';
import ReactSwitch from 'react-switch';
import Modal from 'react-responsive-modal';
import SettingsDataRow from '../../Common/SettingsDataRow/SettingsDataRow';
import './CatalogueSettings.scss';
import {
  extractCompanyCataloguePdfSettings,
  attachCompanySettingsListenerFirebase,
  removeCompanySettingsListenerFirebase,
  getCompanySettingsFirebaseFromCache,
  extractCompanyCatalogueProductSettings,
  setCompanyCataloguePdfSettings,
  setCompanyCatalogueProductSettings,
  attachCompanyShareTextListenerFirebase,
  removeCompanyShareTextListenerFirebase,
  getCompanyShareTextFirebaseFromCache,
  extractCompanyShareTextProperty,
  updateCompanyShareTextFirebase
} from 'qs-data-manager/Company';
import Loader from '../../../Common/Loader';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import toastr from 'toastr';

export default () => {
  const [cataloguePdf, setCataloguePdf] = useState(
    extractCompanyCataloguePdfSettings(getCompanySettingsFirebaseFromCache())
  );
  const [catalogueProduct, setCatalogueProduct] = useState(
    extractCompanyCatalogueProductSettings(getCompanySettingsFirebaseFromCache())
  );
  const [showTextModal, toggleTextModal] = useState(false);
  const [displayTemplateText, setTemplateText] = useState(() =>
    extractCompanyShareTextProperty(getCompanyShareTextFirebaseFromCache())
  );
  const [textAreaVal, setTextAreaVal] = useState(displayTemplateText);
  const modalStyles = useRef({
    backgroundColor: 'white',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    width: '400px'
  });

  useEffect(() => {
    const catalogueListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (loading || refreshing) {
        return;
      }

      if (err) {
        toastr.error('Something went wrong while fetching the catalogue information');
        return;
      }

      setCatalogueProduct(extractCompanyCatalogueProductSettings(data));
      setCataloguePdf(extractCompanyCataloguePdfSettings(data));
    };

    attachCompanySettingsListenerFirebase(catalogueListener);

    return () => removeCompanySettingsListenerFirebase(catalogueListener);
  }, []);

  useEffect(() => {
    const shareTextListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (loading || refreshing) {
        return;
      }

      if (err) {
        toastr.error('Something went wrong while fetching the set share text information');
        return;
      }

      setTemplateText(extractCompanyShareTextProperty(data));
      setTextAreaVal(extractCompanyShareTextProperty(data));
    };

    attachCompanyShareTextListenerFirebase(shareTextListener);

    return () => removeCompanyShareTextListenerFirebase(shareTextListener);
  }, []);

  const handleRowClick = function(from) {
    return (_, event) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      switch (from) {
        case 'pdfDownload':
          setCompanyCataloguePdfSettings(!cataloguePdf);
          break;
        case 'productDownload':
          setCompanyCatalogueProductSettings(!catalogueProduct);
          break;
        default:
          break;
      }
    };
  };

  const iconComponent = (switchChecked, from) => (
    <ReactSwitch
      checked={switchChecked}
      onChange={handleRowClick(from)}
      onColor="#C0DACE"
      onHandleColor="#4DA47A"
      handleDiameter={25}
      uncheckedIcon={false}
      checkedIcon={false}
      height={17}
      width={40}
    />
  );

  const handleTextTemplateChange = function(event) {
    event.preventDefault();
    /*
      Since this handler is being used on both the button and the row,
      prevent this from being called twice
    */
    event.stopPropagation();

    setTextAreaVal(displayTemplateText);
    toggleTextModal(!showTextModal);
  };

  const closeTextModal = function() {
    toggleTextModal(false);
  };

  const handleReset = function(event) {
    event.preventDefault();
    setTextAreaVal('');
  };

  const submitText = function(event) {
    event.preventDefault();
    closeTextModal();
    updateCompanyShareTextFirebase(textAreaVal);
  };

  const renderTextModal = () => (
    <Modal
      open={true}
      center={true}
      onClose={closeTextModal}
      showCloseIcon={false}
      styles={{ modal: modalStyles.current }}
    >
      <h3 className="catalogue-section-modal catalogue-share-text--title">Change Share Text</h3>
      <p className="catalogue-section-modal catalogue-share-text-description">
        {' '}
        Type a custom message that you want to send to visitors while sharing your catalogues
      </p>
      <textarea
        className="catalogue-section-modal catalogue-text-area"
        value={textAreaVal}
        onChange={event => setTextAreaVal(event.target.value)}
        placeholder={'Type your customized sharing message here'}
      />
      <div className="catalogue-section-modal button-container">
        <button type="reset" className="button-plain button-reset" onClick={handleReset}>
          {' '}
          RESET{' '}
        </button>
        <button type="submit" className="button-plain button-submit" onClick={submitText}>
          {' '}
          SET SHARE TEXT{' '}
        </button>
      </div>
    </Modal>
  );

  let catalogueSettingsComponent;
  if (typeof cataloguePdf === 'boolean' && typeof catalogueProduct === 'boolean') {
    catalogueSettingsComponent = (
      <Fragment>
        <SettingsDataRow
          icon={
            <button className="text-template-share" onClick={handleTextTemplateChange}>
              {' '}
              Change{' '}
            </button>
          }
          title={'Sharing text template'}
          onSectionClick={handleTextTemplateChange}
        >
          <div className="text-template-share-container">{displayTemplateText.trim()}</div>
        </SettingsDataRow>
        <SettingsDataRow
          onSectionClick={handleRowClick('pdfDownload')}
          icon={iconComponent(cataloguePdf, 'pdfDownload')}
          title={'Enable PDF download'}
        >
          <span>Allow your customers to download the pdf of your catalogue</span>
        </SettingsDataRow>
        <SettingsDataRow
          onSectionClick={handleRowClick('productDownload')}
          icon={iconComponent(catalogueProduct, 'productDownload')}
          title={'Enable product download'}
        >
          <span>Allow customers to download your product images</span>
        </SettingsDataRow>
        {showTextModal && renderTextModal()}
      </Fragment>
    );
  } else {
    catalogueSettingsComponent = <Loader size="large" />;
  }

  return <div className="catalogue-section">{catalogueSettingsComponent}</div>;
};
