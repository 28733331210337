import React, { useState, useCallback, useEffect } from 'react';

import ProductHeaderOptions from './ProductHeaderOptions';
import EditProductsHeader from './EditProductsHeader';
import SearchProductHeader from './SearchProductHeader';

import './styles.scss';

const PRODUCT_HEADER = 'PRODUCT_HEADER';
const EDIT_PRODUCTS_HEADER = 'EDIT_PRODUCTS_HEADER';
const SEARCH_PRODUCTS_HEADER = 'SEARCH_PRODUCTS_HEADER';

export default ({ activeCatalogueId } = {}) => {
  const [productHeader, setProductHeader] = useState(PRODUCT_HEADER);

  useEffect(() => {
    setProductHeader(PRODUCT_HEADER);
  }, [activeCatalogueId]);

  const onEditIconClick = useCallback(() => {
    setProductHeader(EDIT_PRODUCTS_HEADER);
  }, []);

  const onSearchIconClick = useCallback(() => {
    setProductHeader(SEARCH_PRODUCTS_HEADER);
  }, []);

  const setHeaderAsProductHeader = useCallback(() => {
    setProductHeader(PRODUCT_HEADER);
  }, []);

  if (productHeader === PRODUCT_HEADER) {
    return (
      <ProductHeaderOptions
        onEditIconClick={onEditIconClick}
        onSearchIconClick={onSearchIconClick}
        activeCatalogueId={activeCatalogueId}
      />
    );
  }

  if (productHeader === EDIT_PRODUCTS_HEADER) {
    return <EditProductsHeader goBack={setHeaderAsProductHeader} />;
  }

  if (productHeader === SEARCH_PRODUCTS_HEADER) {
    return <SearchProductHeader goBack={setHeaderAsProductHeader} />;
  }

  return null;
};
