/* global mixpanel */

import AppConfig from 'qs-config/AppConfig';
import { getCompanyMetaFromCache, getCompanyCategory } from 'qs-data-manager/Company';
import { getUserMetaFromCache, getUserId } from 'qs-data-manager/User';
import { db } from 'qs-config/FirebaseConfig';
import CatalogueLib from 'qs-data-manager/Catalogues';
import { getProductPropsForMixpanel } from 'qs-data-manager/Products';
import * as Sentry from '@sentry/browser';
import { getAppVersion } from 'qs-helpers';

mixpanel.init(AppConfig.mixpanelToken);

let identifyPromise = new Promise(resolve => resolve());

const setSuperProps = () => {
  const company = getCompanyMetaFromCache();
  const user = getUserMetaFromCache();

  if (!user || !company) {
    return;
  }

  const categoryMap = getCompanyCategory();

  const superProps = {
    user_id: user.id,
    company_id: company.id,
    user_name: user.name,
    company_name: company.name,
    currency: company.currencyCode,
    products: Object.keys(company.products || {}).length,
    catalogues: Object.keys(company.catalogues || {}).length,
    showcases: Object.keys(company.showcases || {}).length,
    web: true
  };

  const peopleProps = {
    $email: user.email,
    $name: user.name,
    $phone: user.phone,
    ...categoryMap
  };

  mixpanel.people.set({ ...peopleProps, ...superProps });
  mixpanel.register(superProps);
};

const setSessionAttributes = () => {
  try {
    const userId = getUserId();

    if (!userId) {
      throw new Error('User not found');
    }

    identifyPromise = new Promise(resolve => {
      db.ref('analytics')
        .child('identified')
        .child(userId)
        .child('mixpanel')
        .on('value', snapshot => {
          db.ref('analytics')
            .child('identified')
            .child(userId)
            .child('mixpanel')
            .off('value');

          const isIdentified = snapshot.val();

          if (!isIdentified) {
            mixpanel.alias(userId);

            db.ref('analytics')
              .child('identified')
              .child(userId)
              .child('mixpanel')
              .set(true);
          }

          mixpanel.identify(userId);

          setTimeout(function() {
            setSuperProps();

            resolve();
          }, 5000);
        });
    });
  } catch (err) {
    console.log('setSessionAttributes: mixpanel error', err);
    Sentry.captureException(err);
  }
};

const sendEvent = ({ eventName, props = {} }) => {
  const appVersion = getAppVersion();
  const extraData = { appVersion };

  const mergedProps = { ...props, ...extraData };

  identifyPromise.then(() => {
    setSuperProps();
    mixpanel.track(eventName, mergedProps);
  });
};

const getCatalogueProps = catalogueId => {
  if (!catalogueId) {
    return {};
  }

  const props = CatalogueLib.getMixpanelCatalogueProps({ catalogueId });
  return props;
};

const getProductProps = productId => {
  if (!productId) {
    return {};
  }

  const props = getProductPropsForMixpanel({ productId });
  return props;
};

export default {
  setSessionAttributes,
  sendEvent,
  timeEvent: eventName => identifyPromise.then(() => mixpanel.time_event(eventName)),
  track: eventName => identifyPromise.then(() => mixpanel.track(eventName)),
  reset: () => {
    identifyPromise = new Promise(resolve => resolve());
    // Mixpanel.clearPushRegistrationId();
    mixpanel.reset();
  },
  getCatalogueProps,
  getProductProps
  // getOrderProps,
  // getVisitorOpensProps,
  // addPushDeviceToken: token => Mixpanel.addPushDeviceToken(token)
};
