import React, { useState, useCallback, useEffect } from 'react';
import { SAVE_BUTTON_META } from 'qs-data-manager/ProductDetails';
import eventbus from 'eventing-bus';
import {
  attachPrivateNotesListener,
  removePrivateNotesListener,
  getPrivateNotes,
  productDetailsInitialState,
  savePrivateNotes,
  autoSaveProductDetails
} from 'qs-data-manager/ProductDetails';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import Loader from 'qs-components/Common/Loader';

import './styles.scss';
import '../BasicInfo/styles.scss';
import Mixpanel from 'qs-data-manager/Mixpanel';

export default ({ activeProductIds, isBulkEditing, activeProductId } = {}) => {
  const [privateNotesState, setPrivateNotesState] = useState(() =>
    productDetailsInitialState(isBulkEditing ? activeProductIds : [activeProductId])
  );

  // Effect for attaching private notes changes listener
  useEffect(() => {
    const privateNoteListener = (error, payload) => {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (err) {
        setPrivateNotesState(prevState => ({
          ...prevState,
          error: err,
          loading
        }));
        return;
      }

      if (loading) {
        setPrivateNotesState(prevState => ({
          ...prevState,
          error: null,
          loading
        }));
        return;
      }

      setPrivateNotesState(prevState => ({
        ...prevState,
        error: null,
        loading,
        refreshing,
        privateNotes: data.privateNotes || '',
        changedPrivateNotes: data.privateNotes || ''
      }));
    };

    if (!isBulkEditing) {
      attachPrivateNotesListener(privateNoteListener, activeProductId);
      getPrivateNotes(activeProductId);
    }

    return isBulkEditing
      ? () => {}
      : () => {
          removePrivateNotesListener(privateNoteListener, activeProductId);
          // autoSaveProductDetails();
        };
  }, [isBulkEditing, activeProductId]);

  useEffect(() => {
    setPrivateNotesState(prevState => ({
      ...prevState,
      privateNotes: '',
      changedPrivateNotes: '',
      showSaveButton: false
    }));
  }, [(activeProductIds || []).length]);

  const changePrivateNotes = useCallback(
    e => {
      e.stopPropagation();
      const value = e.target.value;
      const hasChanged = privateNotesState.privateNotes !== value;

      const showSaveButton = isBulkEditing && hasChanged;

      setPrivateNotesState(prevState => ({
        ...prevState,
        changedPrivateNotes: value,
        showSaveButton
      }));

      if (!isBulkEditing) {
        eventbus.publish(SAVE_BUTTON_META.eventbusKey, {
          id: SAVE_BUTTON_META.PRIVATE_NOTES.id,
          hasChanged,
          data: value,
          eventType: SAVE_BUTTON_META.eventType.PRODUCT_META.id
        });

        Mixpanel.sendEvent({
          eventName: 'private_notes_saved',
          prop: {
            private_notes: value
          }
        });
      }
    },
    [privateNotesState.privateNotes, isBulkEditing]
  );

  const onSaveClick = useCallback(() => {
    setPrivateNotesState(prevState => ({
      ...prevState,
      showSaveButton: false
    }));

    savePrivateNotes({
      notes: privateNotesState.changedPrivateNotes,
      productIds: activeProductIds
    });
  }, [privateNotesState.changedPrivateNotes, activeProductIds]);

  return (
    <div id={'PrivateNoteContainer'}>
      {privateNotesState.loading ? (
        <div className={'loaderContainer'}>
          <Loader size={'small'} />
        </div>
      ) : (
        <div className={'rowContainer'}>
          <div className={'rowHeading privateNoteHeading'}>Private Notes</div>
          <textarea
            rows="5"
            cols="20"
            type="text"
            onChange={changePrivateNotes}
            value={privateNotesState.changedPrivateNotes}
            className={`privateNotes ${privateNotesState.showSaveButton && 'marginRight'}`}
          />
          {privateNotesState.showSaveButton ? (
            <div className="privateNoteBulkSaveButton" onClick={onSaveClick}>
              Save
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};
