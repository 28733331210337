import {
  getAllCataloguePictureMeta,
  clearCataloguePictureMetaCounts
} from 'qs-data-manager/Dexie/CatalogueDexieHelpers';
import { showProductHeaderProgressbar } from 'qs-helpers/ProcessUploadedImage';

export const restartProgressBar = async () => {
  const allCataloguePictureMeta = await getAllCataloguePictureMeta();
  allCataloguePictureMeta.forEach(catalogueData => {
    showProductHeaderProgressbar(catalogueData);
  });
};

export const clearProgressBarInfo = async () => {
  await clearCataloguePictureMetaCounts();
};
