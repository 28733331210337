import * as Sentry from '@sentry/browser';
import { getAppVersion } from 'qs-helpers';

export const setSentryContext = ({ userId, email, companyId }) => {
  const appVersion = getAppVersion();
  Sentry.configureScope(scope => {
    scope.setUser({ id: userId, email, companyId, appVersion });
  });
};

export const resetSentryUser = () => {
  Sentry.configureScope(scope => scope.setUser(null));
};

export const reportError = (error, options = {}) => {
  Sentry.captureException(error);
};
