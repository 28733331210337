import React, { useState, useEffect } from 'react';
import './styles.scss';
import {
  getImageUploadingMeta,
  getProductPicturesUploadingMeta
} from 'qs-data-manager/Products';
import eventbus from 'eventing-bus';
import { UPLOAD_PRODUCT_PICURES_HEADER } from 'qs-helpers/ProcessUploadedImage';

export default ({ activeProductId }) => {
  const [uploadMeta, setUploadMeta] = useState(() =>
    getProductPicturesUploadingMeta({ productId: activeProductId })
  );

  useEffect(() => {
    const meta = getImageUploadingMeta({ productId: activeProductId });
    setUploadMeta(meta);

    const key = UPLOAD_PRODUCT_PICURES_HEADER.eventbusKey(activeProductId);
    const removeListener = eventbus.on(key, ({ remaining, percent } = {}) => {
      const shouldShow = !!remaining;
      setUploadMeta({
        shouldShow,
        percent,
        remaining
      });
    });

    return () => {
      setUploadMeta({
        shouldShow: false,
        percent: 0,
        remaining: 0
      });
      removeListener();
    };
  }, [activeProductId]);

  if (!uploadMeta.shouldShow) {
    return null;
  }

  return (
    <div id={'UploadImagesHeader'}>
      <div className={'uploadingPictures'}>Uploading your pictures ({uploadMeta.percent}%) </div>
      <div className={'progressBarContainer'}>
        <div className={'progressBar'} style={{ width: `${uploadMeta.percent}%` }} />
      </div>
      <div className={'picturesRemaining'}>{uploadMeta.remaining} pictures remaining</div>
    </div>
  );
};
