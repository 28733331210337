import React from 'react';
import './index.scss';

export default ({ userEmail, notAllowedToUse, redirectToPayment, handleLogout }) => {
  if (!notAllowedToUse) {
    return null;
  }

  return (
    <div id="NonPlatinumUserDailog">
      <p className="title">
        You tried to access QuickSell with <b>{userEmail}</b>, which is on Lite plan.
        <span className={'pricingRedirectionForLite'} onClick={redirectToPayment}>
          Upgrade your account
        </span>{' '}
        to Premium or Platinum plan to use QuickSell Web
      </p>
      <p className={'description'}>Please upgrade to platinum plan to use QuickSell Web</p>
      <div className="buttonContainer">
        <a
          className={'contactSupportLiteDialogue'}
          href="mailto:support@quicksell.co?subject=I'm interested in using QuickSell Web"
        >
          Contact support
        </a>
        <button className={'contactSupportLiteDialogue'} onClick={handleLogout}>
          Ok
        </button>
      </div>
    </div>
  );
};
