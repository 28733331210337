import React, { Component } from 'react';

import Loader from 'qs-components/Common/Loader';
import Order from 'qs-data-manager/Order';
import MultiSelectOrder from 'qs-helpers/MultiSelectOrder';

import moment from 'moment';

import CheckBox from 'qs-components/Common/CheckBox';

import './RenderSingleOrder.css';
import PropTypes from 'prop-types';

class RenderSingleOrder extends Component {
  static propTypes = {
    contact: PropTypes.string,
    fetchingRowData: PropTypes.bool,
    catalogueId: PropTypes.string,
    selectedContact: PropTypes.string,
    isEditing: PropTypes.bool,
    onContactSelect: PropTypes.func
  };

  constructor(props) {
    super(props);
    const { selectedContact, contact } = this.props;
    const { isContactSelected } = MultiSelectOrder;

    const isSelected = selectedContact === contact;
    const contactSelected = isContactSelected(contact);

    this.state = {
      loading: true,
      itemCount: 0,
      latestDate: null,
      name: null,
      phone: contact,
      unreadItemCount: 0,
      addressBookName: null,
      fetchingRowData: false,
      isSelected: isSelected,
      isContactSelected: contactSelected
    };
  }

  componentDidMount() {
    this.setState({ fetchingRowData: true });
    const { catalogueId, contact } = this.props;
    Order.attachContactMetaListener({ catalogueId, contact }, this.onMetaChange);
  }

  componentWillReceiveProps(nextProps) {
    const { contact, catalogueId, selectedContact, areAllSelected, isEditing } = nextProps;
    const {
      contact: prevContact,
      catalogueId: prevCatalogueId,
      selectedContact: prevSelectedContact
    } = this.props;
    const { isContactSelected } = MultiSelectOrder;
    let updates = {};

    if (contact !== prevContact) {
      const contactSelected = isContactSelected(contact);

      updates = {
        itemCount: 0,
        latestDate: null,
        name: null,
        phone: contact,
        unreadItemCount: 0,
        addressBookName: null,
        isContactSelected: contactSelected
      };
    }

    if (selectedContact !== prevSelectedContact) {
      updates.isSelected = selectedContact === contact;
    }

    if (isEditing !== this.props.isEditing) {
      updates.isContactSelected = isContactSelected(contact);
    }

    if (areAllSelected !== this.props.areAllSelected) {
      updates.isContactSelected = isContactSelected(contact);
    }

    this.setState(updates, () => {
      Order.removeContactMetaListener(
        { catalogueId: prevCatalogueId, contact: prevContact },
        this.onMetaChange
      );
      Order.attachContactMetaListener({ catalogueId, contact }, this.onMetaChange);
    });
  }

  componentWillUnmount() {
    const { catalogueId, contact } = this.props;
    Order.removeContactMetaListener({ catalogueId, contact }, this.onMetaChange);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.phone !== this.state.phone ||
      nextState.latestDate !== this.state.latestDate ||
      nextState.unreadItemCount !== this.state.unreadItemCount ||
      nextState.itemCount !== this.state.itemCount ||
      nextState.isSelected !== this.state.isSelected ||
      nextState.isEditing !== this.props.isEditing ||
      nextState.isContactSelected !== this.state.isContactSelected
    );
  }

  onMetaChange = meta => {
    if (!meta) {
      return;
    }

    this.setState({
      loading: false,
      itemCount: meta.itemCount,
      latestDate: meta.latestDate,
      name: meta.name,
      phone: meta.phone,
      unreadItemCount: meta.unreadItemCount,
      fetchingRowData: false
    });
  };

  onContactClick = async e => {
    const { onContactClick, isEditing } = this.props;
    const { phone } = this.state;

    if (isEditing) {
      this.onContactSelect(e);
    } else {
      this.setState({ isSelected: true });
      onContactClick(phone);
    }
  };

  onCheckClicked = e => {
    e.stopPropagation();
  };

  onContactSelect = e => {
    const { phone } = this.state;
    const { onContactSelect } = this.props;

    this.setState(prevState => {
      const currenState = !prevState.isContactSelected;
      MultiSelectOrder.onContactSelect(phone, currenState);
      onContactSelect();
      return { isContactSelected: currenState };
    });
  };

  render() {
    const {
      unreadItemCount,
      itemCount,
      name,
      phone,
      fetchingRowData,
      latestDate,
      isSelected,
      isContactSelected
    } = this.state;
    const { isEditing } = this.props;

    const latestDateMoment = moment(latestDate || new Date());

    const latestTime = latestDateMoment.format('h:mm a');
    const latestAgo = latestDateMoment.fromNow();
    const bgColorFirstScreen = isSelected
      ? 'renderSingleOrder bgColorOrderContactScreen'
      : 'renderSingleOrder';
    const showAllCheckbox = isEditing ? ' visibleCheckboxContainer' : ' invisibleCheckboxContainer';

    return (
      <div onClick={this.onContactClick} className={bgColorFirstScreen}>
        {/* <div className={bgColorFirstScreen}> */}
        <div className="spacer">
          <div className="leftContainer">
            <div className={'checkboxContainer' + showAllCheckbox}>
              <CheckBox
                id="contactOrderRowSelect"
                checked={isContactSelected}
                onCheckChanged={this.onContactSelect}
                onCheckClicked={this.onCheckClicked}
              />
            </div>
            <div className="detailsContainer">
              <div className="pNumberContainer">
                <div className={'nameContainer'}>
                  <div className="name noselect">{!!name ? name : phone}</div>
                  {fetchingRowData && (
                    <div className="rowLoaderContainer">
                      <Loader size={'extraSmall'} />
                    </div>
                  )}
                </div>
                { !!name && (<div className="phoneNumber noselect">{phone}</div>)}
              </div>

              <div className="dateTimeContainer">
                <div className="time noselect">{latestTime}</div>
                <div className="separator" />
                <div className="date noselect">{latestAgo}</div>
              </div>
            </div>
          </div>

          <div className="rightContainer">
            {!!unreadItemCount && unreadItemCount > 0 && (
              <div className="noOfNew noselect">{unreadItemCount} new</div>
            )}
            {itemCount !== null && <div className="itemNumber noselect">{itemCount} items</div>}
          </div>
        </div>
      </div>
    );
  }
}

export default RenderSingleOrder;
