import {
  getPicturesFromNative,
  removeExistingPicturesMetaInNative,
  updateExistingPicturesMetaInNative,
  setPicturesMetaInNative,
  getPicturesInUploadingStateFromNative,
  clearPicturesMetaFromNative
} from 'qs-data-manager/Dexie/ProductDexieHelpers';
import { onImageUploadDone } from 'qs-helpers/ProcessUploadedImage';

export const batchNumberToRead = 5;

/*
  On startup change all data that was uploading to pending.
  This state might be left if the app was closed while
  the images were uploading
*/
export const prepareQueueDataForRestarting = async () => {
  try {
    const picturesMeta = await getPicturesInUploadingStateFromNative();

    if (picturesMeta.length) {
      picturesMeta.forEach(pictureMetaData => {
        pictureMetaData.state = 'pending';
      });
      await updateExistingPicturesMetaInNative(picturesMeta);
    }
  } catch (setupError) {
    console.error('Could not process the indexed db items on startup');
  }
};

export const setQueueData = newPicturesToUpload => {
  //Pick only those parameters that are needed
  //Since this is a shared interface, many more parameters could be present
  const pictureMetaToSave = newPicturesToUpload.map(pictureData => {
    const { pictureId, path, prepared, productId, catalogueId, timestamp, extraData } = pictureData;
    const retryCount = 0;
    return {
      pictureId,
      path,
      prepared,
      productId,
      catalogueId,
      timestamp,
      state: 'pending',
      retryCount,
      extraData
    };
  });
  return setPicturesMetaInNative(pictureMetaToSave);
};

export const getImagesFromQueue = async numbersToRetrieve => {
  try {
    const picturesMeta = await getPicturesFromNative(numbersToRetrieve);
    picturesMeta.forEach(pictureMetaData => {
      pictureMetaData.state = 'uploading';
    });
    await updateExistingPicturesMetaInNative(picturesMeta);
    return picturesMeta;
  } catch (retirievePictureError) {
    console.log('failed to fetch images from the native queue');
    // Make API call to fetch data from the remote database system and persist in the native db
    return [];
  }
};

export const updateImageQueue = async imageMeta => {
  const { error, pictureId, path, prepared, productId, catalogueId, extraData } = imageMeta;
  let {retryCount} = imageMeta;
  //0 based index, retry if any error apart from compression is found
  if (error && error !== 'COMPRESSION' && retryCount < 3) {
    retryCount += 1;
    return await updateExistingPicturesMetaInNative([{
      pictureId,
      path,
      prepared,
      productId,
      catalogueId,
      state: 'pending',
      //Push to the end of the queue
      timestamp: new Date().getTime(),
      retryCount,
      extraData
    }]);
  }

  onImageUploadDone(imageMeta);
  return await removeExistingPicturesMetaInNative(pictureId);
};

export const clearQueueData = async () => {
  await clearPicturesMetaFromNative()
};