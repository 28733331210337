import CacheRequest from 'qs-data-manager/CacheRequest';

export default (err, { status, data } = {}) => {
  const updates = {};
  if (err) {
    updates.loading = false;
    updates.err = err;
    console.log('error in cache listener:', err);
  } else {
    switch (status) {
      case CacheRequest.OPERATION_STATUS.LOADING: {
        updates.loading = true;
        updates.refreshing = false;
        updates.err = null;
        break;
      }
      case CacheRequest.OPERATION_STATUS.REFRESHING: {
        updates.loading = false;
        updates.refreshing = true;
        updates.err = null;
        updates.data = data;
        break;
      }
      case CacheRequest.OPERATION_STATUS.SUCCESS: {
        updates.loading = false;
        updates.refreshing = false;
        updates.err = null;
        updates.data = data;
        break;
      }
      case CacheRequest.OPERATION_STATUS.UPDATE: {
        updates.loading = false;
        updates.refreshing = false;
        updates.err = null;
        updates.data = data;
        break;
      }
      default:
    }
  }

  return updates;
};
