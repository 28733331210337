import React, { useState, useRef } from 'react';
import Modal from 'react-responsive-modal';
import './EditCompanyAddress.scss';
import { updateCompanyAddress } from 'qs-data-manager/Company';
import { isInteger } from 'qs-helpers';

const AddressFormField = ({ error, label, inputId, value = '', ...restInputProps }) => {
  return (
    <div className={`company-address--container ${error ? 'error' : ''}`}>
      <label htmlFor={inputId} className="company-address--label">
        {label}
      </label>
      <input className="company-address--input" value={value} id={inputId} {...restInputProps} />
      {error && <span className="error-container">{error}</span>}
    </div>
  );
};

export default ({ onClose, defaultAddress }) => {
  const modalStyles = useRef({
    backgroundColor: 'white',
    borderRadius: 10,
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    width: '400px'
  });

  const [addressObj, setAddressObj] = useState(defaultAddress);
  const [addressError, setAddressError] = useState({});
  const handleAddressChange = function(event) {
    const inputId = event.target.id;
    const value = event.target.value;
    const newAddressObj = { ...addressObj };
    const newErrorObj = { ...addressError };
    switch (inputId) {
      case 'addressOne':
        newAddressObj.addressLineOne = value;
        delete newErrorObj.addressOne
        break;
      case 'addressTwo':
        newAddressObj.addressLineTwo = value;
        break;
      case 'zip':
        newAddressObj.pincode = value;
        delete newErrorObj.zip
        break;
      case 'city':
        newAddressObj.city = value;
        delete newErrorObj.city
        break;
      case 'state':
        newAddressObj.state = value;
        delete newErrorObj.state
        break;
      case 'country':
        newAddressObj.country = value;
        delete newErrorObj.country
        break;
      default:
        break;
    }

    setAddressObj(newAddressObj);
    setAddressError(newErrorObj);
  };

  const validateAddress = function() {
    const newAddressError = {};
    if(!addressObj.addressLineOne) {
      newAddressError.addressOne = 'Please enter the address line 1';
    }
    if(!addressObj.pincode) {
      newAddressError.zip = 'Please enter the pincode/zip';
    } else if(!isInteger(addressObj.pincode)) {
      newAddressError.zip = 'pincode/zip must be a number';
    }
    if(!addressObj.city) {
      newAddressError.city = 'Please enter the city';
    }
    if(!addressObj.state) {
      newAddressError.state = 'Please enter the state';
    }
    if(!addressObj.country) {
      newAddressError.addressOne = 'Please enter the country';
    }

    return newAddressError;
  };

  const handleAddressSave = function(event) {
    event.preventDefault();
    const updateError = validateAddress();
    if(Object.keys(updateError).length > 0) {
      setAddressError(updateError)
      return
    }

    updateCompanyAddress(addressObj);
    onClose();
  };

  return (
    <Modal
      open={true}
      center={true}
      onClose={onClose}
      showCloseIcon={false}
      styles={{ modal: modalStyles.current }}
    >
      <h3 className="edit-address title">Address</h3>
      <form>
        <AddressFormField
          error={addressError.addressOne}
          label={'Address line 1'}
          inputId={'addressOne'}
          onChange={handleAddressChange}
          type="text"
          value={addressObj.addressLineOne}
        />
        <AddressFormField
          label={'Address line 2'}
          inputId={'addressTwo'}
          onChange={handleAddressChange}
          type="text"
          value={addressObj.addressLineTwo}
        />
        <AddressFormField
          error={addressError.zip}
          label={'Pincode/Zipcode'}
          inputId={'zip'}
          onChange={handleAddressChange}
          type="tel"
          value={addressObj.pincode}
        />
        <AddressFormField
          error={addressError.city}
          label={'City'}
          inputId={'city'}
          onChange={handleAddressChange}
          type="text"
          value={addressObj.city}
        />
        <AddressFormField
          error={addressError.state}
          label={'State'}
          inputId={'state'}
          onChange={handleAddressChange}
          type="text"
          value={addressObj.state}
        />
        <AddressFormField
          error={addressError.country}
          label={'Country'}
          inputId={'country'}
          onChange={handleAddressChange}
          type="text"
          value={addressObj.country}
        />
        <div className="edit-address button-container">
          <button type="submit" className="button-plain" onClick={handleAddressSave}>
            {' '}
            SAVE{' '}
          </button>
        </div>
      </form>
    </Modal>
  );
};
