import React, { Component } from 'react';

import Modal from 'react-responsive-modal';

import './BulkEditDiscount.css';
import PropTypes from 'prop-types';
import Mixpanel from 'qs-data-manager/Mixpanel';

const modalStyles = {
  backgroundColor: 'white',
  borderRadius: 10,
  padding: '23px 35px 20px 35px'
};

class BulkEditDiscount extends Component {
  static propTypes = {
    initialPercentage: PropTypes.string,
    visible: PropTypes.bool,
    closeModal: PropTypes.func,
    onSetPercentClick: PropTypes.func
  };

  state = { discountValue: '', error: false };

  componentWillMount() {
    const { initialPercentage } = this.props;
    this.setState({ discountValue: '' + initialPercentage, error: false });
  }

  componentDidMount() {
    Mixpanel.sendEvent({ eventName: 'dialog_discount_percent_shown' });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ discountValue: nextProps.initialPercentage, error: false });
  }

  handleSetPercent = () => {
    if (!this.state.discountValue) {
      this.setState({ error: true });
      return;
    }

    const roundOffValue = Math.round(Number(this.state.discountValue) * 100) / 100;
    this.setState({ discountValue: '', error: false });
    this.props.onSetPercentClick(roundOffValue);
    Mixpanel.sendEvent({ eventName: 'product_offer_discount_clicked' });
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.handleSetPercent();
    }
  };

  handleValueChange = e => {
    const { value } = e.target;

    if (isNaN(value)) {
      e.preventDefault();
      window.alert('Discount should be a number');
      return;
    } else if (value > 100) {
      e.preventDefault();
      window.alert('You can offer maximum 100% discount');
      return;
    } else if (value < 0) {
      e.preventDefault();
      window.alert('Discount cannot be less than 0%');
      return;
    }

    this.setState({ discountValue: '' + e.target.value, error: false });
  };

  render() {
    const { visible, closeModal } = this.props;
    const { discountValue, error } = this.state;

    return (
      <Modal
        open={visible}
        onClose={closeModal}
        center={true}
        styles={{ modal: modalStyles }}
        showCloseIcon={false}
      >
        <div className="bulkDiscountModalHeader">Set discount by percentage</div>
        <div className={`bulkDiscountInputbox ${!!error ? 'bulkDiscountInputboxError' : ''}`}>
          <input
            autoFocus
            onChange={this.handleValueChange}
            value={discountValue}
            className="inputbox"
            onKeyPress={this.handleKeyPress}
            placeholder="Enter percentage"
            min={0}
          />
          <div className="percentSymbol">%</div>
        </div>
        <div className="bulkDiscoutSetPercentage">
          <button onClick={this.handleSetPercent} className="setPercentage">
            SET PERCENTAGE
          </button>
        </div>
      </Modal>
    );
  }
}

export default BulkEditDiscount;
