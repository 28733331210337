import React, { useState, useRef, useEffect, Fragment } from 'react';
import SettingsDataRow from '../../Common/SettingsDataRow/SettingsDataRow';
import './LanguageSettings.scss';
import Modal from 'react-responsive-modal';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import {
  extractCompanyLanguage,
  COMPANY_LANGUAGUES,
  getCompanySettingsFirebaseFromCache,
  attachCompanySettingsListenerFirebase,
  removeCompanySettingsListenerFirebase,
  setCompanyLanguage
} from 'qs-data-manager/Company';
import Loader from '../../../Common/Loader';
import { getConfigFromCache, attachConfigsListener, removeConfigsListener } from 'qs-data-manager/Config';
import toastr from 'toastr';
import ClickHandlerWithLoaderContainer from '../../../Common/ClickHandlerWithLoader/ClickHandlerWithLoaderContainer';
import Mixpanel from 'qs-data-manager/Mixpanel';

export default () => {
  const currentlySetLang = useRef(extractCompanyLanguage(getCompanySettingsFirebaseFromCache()));
  const [displayLang, setDisplayLang] = useState(currentlySetLang.current);
  const [currentLanguage, setCurrentLanguage] = useState(currentlySetLang.current);
  const [helpTranslateLink, setHelpTranslateLink] = useState(getConfigFromCache() || {});
  const [showLanguageModal, toggleLanguageModal] = useState(false);

  const modalStyles = useRef({
    backgroundColor: 'white',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    width: '400px'
  });

  useEffect(() => {
    const languageListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (loading || refreshing) {
        return;
      }

      if (err) {
        toastr.error('Something went wrong while fetching the current set language');
        return;
      }

      const languageData = extractCompanyLanguage(data);
      setCurrentLanguage(languageData);
      setDisplayLang(languageData);
    };

    attachCompanySettingsListenerFirebase(languageListener);

    return () => removeCompanySettingsListenerFirebase(languageListener);
  }, []);

  useEffect(() => {
    const configListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (loading || refreshing || err) {
        return;
      }

      setHelpTranslateLink(data);
    };

    attachConfigsListener(configListener);

    return () => removeConfigsListener(configListener);
  }, []);

  const handleAppLanguageClick = function(event) {
    event.preventDefault();
    /*
      Since this handler is being used on both the button and the row,
      prevent this from being called twice
    */
    event.stopPropagation();

    toggleLanguageModal(!showLanguageModal);
  };

  const closeLanguageModal = function() {
    toggleLanguageModal(false);
    setCurrentLanguage(displayLang);
  };

  const handleCancel = function(event) {
    event.preventDefault();
    closeLanguageModal();
  };

  const submitLanguage = function(event) {
    event.preventDefault();
    closeLanguageModal();
    let languageToSend;
    for (const langProps of COMPANY_LANGUAGUES) {
      if (langProps[1] === currentLanguage) {
        languageToSend = langProps[0];
        break;
      }
    }
    setCompanyLanguage(languageToSend);
  };

  const handleLanguageChange = function(event) {
    event.preventDefault();
    setCurrentLanguage(COMPANY_LANGUAGUES.get(event.currentTarget.dataset.lang));
  };

  const handleHelpTranslateClick = function() {
    Mixpanel.sendEvent({
      eventName: 'help_us_translate_clicked',
      props: {
        from: 'Language Settings'
      }
    });
  }

  const renderLanguages = function() {
    const languageComponents = [];
    COMPANY_LANGUAGUES.forEach((languageVal, languageKey) => {
      const currentLangSelected = languageVal === currentLanguage;
      let selectedValClass, selectedComponent;
      if (currentLangSelected) {
        selectedValClass = 'language-text--selected';
        selectedComponent = <span className="selected-text">SELECTED</span>;
      }
      languageComponents.push(
        <button className="language-text" data-lang={languageKey} onClick={handleLanguageChange}>
          <span className={selectedValClass}>{languageVal}</span>
          {selectedComponent}
        </button>
      );
    });
    return languageComponents;
  };

  const renderLanguageSelectionModal = () => (
    <Modal
      open={true}
      center={true}
      onClose={closeLanguageModal}
      showCloseIcon={false}
      styles={{ modal: modalStyles.current }}
    >
      <h3 className="language-section-modal language-modal-title">Select catalogue language</h3>
      <div className="language-section-modal language-container">{renderLanguages()}</div>
      <div className="language-section-modal button-container">
        <button type="button" className="button-plain" onClick={handleCancel}>
          {' '}
          CANCEL{' '}
        </button>
        <button type="submit" className="button-plain button-submit" onClick={submitLanguage}>
          {' '}
          SET LANGUAGE{' '}
        </button>
      </div>
    </Modal>
  );

  let languageComponent;
  if (displayLang) {
    languageComponent = (
      <Fragment>
        <SettingsDataRow
          icon={
            <button onClick={handleAppLanguageClick} className="text-template-share">
              {displayLang}{' '}
            </button>
          }
          title={'Catalogue Language'}
          onSectionClick={handleAppLanguageClick}
        >
          <span>Select the default language for your catalogue</span>
        </SettingsDataRow>

        <ClickHandlerWithLoaderContainer
          linkProps={{ linkUrl: helpTranslateLink.translationInviteLink }}
          clickProps={{
            clickHandler: handleHelpTranslateClick
          }}
          type='link'
          classForContainer={'language-section-link'}
        >
          <SettingsDataRow title={'Help us translate'}>
            <span>Help us translate QuickSell app in your native language</span>
          </SettingsDataRow>
        </ClickHandlerWithLoaderContainer>
        {showLanguageModal && renderLanguageSelectionModal()}
      </Fragment>
    );
  } else {
    languageComponent = <Loader size="large" />;
  }

  return <div className="language-section">{languageComponent}</div>;
};
