import { connector } from './ApiAndCacheConnector';
import CacheRequest from './CacheRequest';
import { toggleGlobalLoader } from 'qs-helpers/index';
import {
  setCatalogueTagVisibilityInNative,
  saveSortedTagsInNative,
  saveTagsChangesInNative
} from 'qs-data-manager/Dexie/CatalogueDexieHelpers';
import Api from 'qs-services/Api';
import eventbus from 'eventing-bus';
import { getProductPriceFilter, PRODUCT_SEARCH, setLastFetchDate } from './Products';
import { getProductSearchTerm } from 'qs-data-manager/Products';
import CatalogueLib, { getActiveCatalogueId } from 'qs-data-manager/Catalogues';
import Mixpanel from './Mixpanel';
import * as Sentry from '@sentry/browser';

let selectedTags = {};
let bulkTagsResp = null;
let TAGS_RESP_MAINTAINER = null;

const setCatalogueTagVisibilityInLocal = ({ catalogueId, tag, v }) => {
  const key = `${connector.CATALOGUE_TAGS.cacheKey}${catalogueId}`;
  const { tags: tagsCache } = CacheRequest.getCacheForKey(key);
  tagsCache[tag] = { ...tagsCache[tag], v };
  const newTagsCache = { ...tagsCache };

  CacheRequest.setCacheForKey(key, { tags: newTagsCache });
  return {
    newTagsCache
  };
};

const setCatalogueTagVisibilityInRemote = async ({ catalogueId, tag, v }) => {
  return Api.setCatalogueTagVisibility({ catalogueId, tagId: tag, visibility: v });
};

const setCatalogueTagVisibility = async ({ catalogueId, tag, v }) => {
  const loaderId = `${catalogueId}${tag}`;
  try {
    toggleGlobalLoader(loaderId, true);
    const changes = setCatalogueTagVisibilityInLocal({ catalogueId, tag, v });
    await Promise.all([
      setCatalogueTagVisibilityInRemote({ catalogueId, tag, v }),
      setCatalogueTagVisibilityInNative({ catalogueId, changes })
    ]);
    const date = new Date().toISOString();
    setLastFetchDate({ date, catalogueId });
    toggleGlobalLoader(loaderId, false);
  } catch (err) {
    console.error(
      `setCatalogueTagVisiblity: Could not set catalogue tags visibility for catalogueId: ${catalogueId} tag: ${tag} v: ${v}`
    );
    toggleGlobalLoader(loaderId, false);
    Sentry.captureException(err);
  }
};

const sortTagsInLocal = ({ catalogueId, modifiedTags }) => {
  const key = `${connector.CATALOGUE_TAGS.cacheKey}${catalogueId}`;
  const oldCache = CacheRequest.getCacheForKey(key) || {};

  const userTags = {};
  modifiedTags.forEach((tag, index) => {
    userTags[tag.title] = { ...tag, p: index };
  });

  const newTagsCache = { ...(oldCache.tags || {}), ...userTags };

  CacheRequest.setCacheForKey(key, { tags: newTagsCache });
  return {
    newTagsCache
  };
};

const sortTagsInRemote = async ({ catalogueId, tags }) => {
  const reorderedTags = tags.map((tag, index) => ({ tagId: tag.title, position: index }));
  await Api.reorderCatalogueTags({ catalogueId, reorderedTags });
};

const filterStockTags = tags => {
  const filteredTags = [];
  const tagsRemoved = [];

  tags.forEach(tag => {
    if (tag.title !== 'Out of stock' && tag.title !== 'In stock') {
      filteredTags.push(tag);
    } else {
      tagsRemoved.push(tag);
    }
  });

  return {
    filteredTags,
    tagsRemoved
  };
};

const sortCatalogueTags = async ({ catalogueId, tags }) => {
  const key = `sortCatalogueTags${catalogueId}${Date.now()}`;
  try {
    toggleGlobalLoader(key, true);

    const { filteredTags } = filterStockTags(tags);

    const changes = sortTagsInLocal({ catalogueId, modifiedTags: filteredTags });

    await Promise.all([
      sortTagsInRemote({ catalogueId, tags: filteredTags }),
      saveSortedTagsInNative({ catalogueId, changes })
    ]);
    const date = new Date().toISOString();
    setLastFetchDate({ date, catalogueId });

    Mixpanel.sendEvent({
      eventName: 'catalogue_tags_reordered',
      props: {
        catalogueId
      }
    });

    toggleGlobalLoader(key, false);
  } catch (err) {
    console.error(
      `sortCatalogueTags: Could not sort catalogue tags for catalogueId ${catalogueId}`
    );
    Sentry.captureException(err);
  }
};

const getAllSelectedTags = () => Object.keys(selectedTags);
const toggleTagSelection = tagId => {
  if (selectedTags[tagId]) {
    delete selectedTags[tagId];
    return;
  }

  selectedTags[tagId] = true;
};
const isTagSelected = tagId => selectedTags[tagId];

const clearSelectedTags = () => {
  selectedTags = {};
  eventbus.publish(PRODUCT_SEARCH.TAG_FILTERING_EB_KEY, {
    type: PRODUCT_SEARCH.TAG_FILTERING_STATE,
    value: false
  });
};

// Filter via tags
const onTagClick = async (tagId, catalogueId, shouldToggleTag = true) => {
  if (shouldToggleTag) {
    toggleTagSelection(tagId);
  }

  let tagIds = getAllSelectedTags();

  if (!tagIds.length) {
    eventbus.publish(PRODUCT_SEARCH.TAG_FILTERING_EB_KEY, {
      type: PRODUCT_SEARCH.TAG_FILTERING_STATE,
      value: false
    });
    return false;
  }

  TAGS_RESP_MAINTAINER = `${Date.now()}`;
  const currentValue = TAGS_RESP_MAINTAINER;
  const searchTerm = getProductSearchTerm() || '';
  let data = {};

  const outOfStock = tagIds.indexOf('Out of stock') > -1;
  const inStock = tagIds.indexOf('In stock') > -1;
  let isRespValid = false;

  eventbus.publish(PRODUCT_SEARCH.TAG_FILTERING_EB_KEY, {
    type: PRODUCT_SEARCH.TAG_API_RESP,
    value: true
  });
  if (outOfStock || inStock) {
    const filteredTagIds = tagIds.filter(tag => ['In stock', 'Out of stock'].indexOf(tag) === -1);
    data = await Api.searchWithStock({
      catalogueId,
      inStock,
      outOfStock,
      tags: filteredTagIds,
      query: searchTerm
    });
    isRespValid = (() => TAGS_RESP_MAINTAINER === currentValue)(); // Ensures that prev resp does not overwrite current request
  } else {
    const value = getProductPriceFilter();
    if (value) {
      data = await Api.filterProducts({
        searchTerm,
        catalogueId,
        tags: tagIds,
        ...value
      });
    } else {
      data = await Api.searchProducts(searchTerm, catalogueId, tagIds);
    }
    isRespValid = (() => TAGS_RESP_MAINTAINER === currentValue)();
  }

  if (!isRespValid) {
    return null;
  }

  eventbus.publish(PRODUCT_SEARCH.TAG_FILTERING_EB_KEY, {
    type: PRODUCT_SEARCH.TAG_API_RESP,
    value: false
  });

  if (!data.products) {
    console.error('onTagClick: Api does not has the products key', data);
    clearSelectedTags();
    return null;
  }

  const { products } = data;
  const productIds = products.map(({ product_id }) => product_id);

  eventbus.publish(PRODUCT_SEARCH.TAG_FILTERING_EB_KEY, {
    type: PRODUCT_SEARCH.FILTERED_PRODUCTS_VIA_TAGS,
    value: productIds
  });

  return true;
};

const onChangeCatalogueTagsFromRemote = ({ tags, catalogueId }) => {
  const key = `${connector.CATALOGUE_TAGS.cacheKey}${catalogueId}`;
  CacheRequest.setCacheForKey(key, { tags });
  saveTagsChangesInNative({ catalogueId, tags });
};

const isTagValid = tag => !/.*[/.#$[\]].*/.test(tag);

const addProductTag = async ({ productId, tagId, position, catalogueId }) => {
  const loaderKey = `addProductTag${Date.now()}`;
  toggleGlobalLoader(loaderKey, true);

  const basicInfoKey = `${connector.BASIC_INFO.cacheKey}${productId}`;
  const catalogueTagKey = `${connector.CATALOGUE_TAGS.cacheKey}${catalogueId}`;

  const basicInfo = CacheRequest.getCacheForKey(basicInfoKey);
  const catalogueTags = CacheRequest.getCacheForKey(catalogueTagKey) || {};

  const catalogueTagsLength = Object.keys(catalogueTags.tags || {}).length;

  const productTag = {
    title: tagId,
    v: true,
    p: position,
    t: Date.now()
  };

  const newTags = { ...(basicInfo.tags || []), [tagId]: productTag };
  const newCache = { ...basicInfo, tags: newTags };

  if (!catalogueTags || !catalogueTags.tags || !catalogueTags.tags[tagId]) {
    const newCatalogueTag = {
      title: tagId,
      v: true,
      p: catalogueTagsLength,
      t: Date.now()
    };

    const newCatalogueTagsCache = { ...(catalogueTags.tags || {}), [tagId]: newCatalogueTag };
    CacheRequest.setCacheForKey(catalogueTagKey, { tags: newCatalogueTagsCache });
  }

  CacheRequest.setCacheForKey(basicInfoKey, newCache);
  await Api.createTagInProduct(catalogueId, [productId], tagId);
  const date = new Date().toISOString();
  setLastFetchDate({ date, catalogueId });

  Mixpanel.sendEvent({
    eventName: 'product_tag_added',
    props: {
      tagTitle: tagId,
      productId
    }
  });
  toggleGlobalLoader(loaderKey, false);
};

const addProductsTag = async ({ catalogueId, productIds, tag }) => {
  const loaderKey = `addProductsTag${Date.now()}`;
  try {
    toggleGlobalLoader(loaderKey, true);

    const basicInfoShareKey = connector.BASIC_INFO.cacheKey;
    const catalogueTagsKey = `${connector.CATALOGUE_TAGS.cacheKey}${catalogueId}`;

    const newTag = {
      title: tag,
      v: true,
      t: Date.now()
    };

    productIds.forEach(id => {
      const key = `${basicInfoShareKey}${id}`;
      const basicInfo = CacheRequest.getCacheForKey(key) || {};
      const largestPosition = Object.values(basicInfo.tags || {}).reduce(
        (acc, val) => (val.p > acc ? val.p : acc),
        -1
      );
      const localTag = { ...newTag, p: largestPosition + 1 };
      const newBasicInfo = { ...basicInfo, tags: { ...basicInfo.tags, [tag]: localTag } };
      CacheRequest.setCacheForKey(key, newBasicInfo);
    });

    const catalogueTags = CacheRequest.getCacheForKey(catalogueTagsKey) || {};

    if (!catalogueTags || !catalogueTags.tags || !catalogueTags.tags[tag]) {
      const catalogueTag = Object.values(catalogueTags.tags || {}).reduce(
        (acc, val) => (val.p > acc.p ? val : acc),
        {}
      );
      const position = (catalogueTag.p || 0) + 1;
      const localTag = {
        title: tag,
        v: true,
        t: Date.now(),
        p: position
      };
      CacheRequest.setCacheForKey(catalogueTagsKey, {
        tags: { [tag]: localTag, ...(catalogueTags.tags || {}) }
      });
    }
    await Api.createTagInProduct(catalogueId, productIds, tag);
    const date = new Date().toISOString();
    setLastFetchDate({ date, catalogueId });
    Mixpanel.sendEvent({
      eventName: 'bulk_product_tag_added',
      props: {
        tagTitle: tag,
        total_product_count: productIds.length
      }
    });
    toggleGlobalLoader(loaderKey, false);
  } catch (err) {
    console.error('addProductsTag: Could not add tags to product', err);
    toggleGlobalLoader(loaderKey, false);
    Sentry.captureException(err);
  }
};

// Does not delete tag from remote, a separate function does that
const deleteProductTag = ({ catalogueId, productId, tag, unselectedTags }) => {
  const loaderKey = `deleteProductTag${Date.now()}`;
  try {
    toggleGlobalLoader(loaderKey, true);

    const selectedTagsKey = `${connector.BASIC_INFO.cacheKey}${productId}`;
    const newUnselectedTags = [...unselectedTags];

    const basicInfo = CacheRequest.getCacheForKey(selectedTagsKey);

    if (basicInfo && basicInfo.tags && basicInfo.tags[tag]) {
      delete basicInfo.tags[tag];
    }

    const newSelectedTags = Object.values(basicInfo.tags)
      .sort((t1, t2) => t1.p - t2.p)
      .map(tag => tag.title);

    newUnselectedTags.push(tag);
    CacheRequest.setCacheForKey(selectedTagsKey, basicInfo);

    toggleGlobalLoader(loaderKey, false);
    return {
      selectedTags: newSelectedTags,
      newUnselectedTags
    };
  } catch (err) {
    console.log('deleteProductTag: Could not delete product tag', err);
    toggleGlobalLoader(loaderKey, false);
    Sentry.captureException(err);
  }
};

const deleteProductTagInRemote = async ({ catalogueId, productIds, tagId }) => {
  const loaderKey = `deleteProductTagInRemote${Date.now()}`;
  try {
    toggleGlobalLoader(loaderKey, true);

    await Api.deleteProductTag({ productIds, tagId });
    CatalogueLib.getCatalogueTags(catalogueId);
    const date = new Date().toISOString();
    setLastFetchDate({ date, catalogueId });

    if (productIds.length === 1) {
      Mixpanel.sendEvent({
        eventName: 'product_tag_deleted',
        props: {
          productId: productIds[0],
          tagTitle: tagId
        }
      });
    } else {
      Mixpanel.sendEvent({
        eventName: 'bulk_product_tag_deleted',
        props: {
          productIds,
          tagTitle: tagId
        }
      });
    }
    toggleGlobalLoader(loaderKey, false);
  } catch (err) {
    console.error('deleteProductTagInRemote: Could not delete product tags in remote', err);
    toggleGlobalLoader(loaderKey, false);
    Sentry.captureException(err);
  }
};

const sortProductTags = async ({ productId, tagsIds }) => {
  const loaderKey = `sortProductTags${Date.now()}`;
  try {
    toggleGlobalLoader(loaderKey, true);
    const catalogueId = getActiveCatalogueId();

    const key = `${connector.BASIC_INFO.cacheKey}${productId}`;
    const basicInfo = CacheRequest.getCacheForKey(key);
    const reorderedTags = {};
    const apiData = [];

    tagsIds.forEach((tagId, index) => {
      const tag = basicInfo.tags[tagId];
      reorderedTags[tag.title] = { ...tag, p: index };
      apiData.push({ tagId, position: index });
    });

    CacheRequest.setCacheForKey(key, { ...basicInfo, tags: reorderedTags });
    await Api.reorderTags({
      productId,
      reorderedTags: apiData
    });
    const date = new Date().toISOString();
    setLastFetchDate({ date, catalogueId });

    Mixpanel.sendEvent({
      eventName: 'product_tags_reordered',
      props: {
        productId
      }
    });

    toggleGlobalLoader(loaderKey, false);
  } catch (err) {
    console.log('sortProductTags: Could not reorder product tags', err);
    toggleGlobalLoader(loaderKey, false);
    Sentry.captureException(err);
  }
};

const deleteProductsTag = ({ catalogueId, productIds, tag }) => {
  const loaderKey = `deleteProductsTag${Date.now()}`;

  try {
    toggleGlobalLoader(loaderKey, true);
    productIds.forEach(id => {
      const key = `${connector.BASIC_INFO.cacheKey}${id}`;
      const basicInfo = CacheRequest.getCacheForKey(key);

      if (basicInfo && basicInfo.tags && basicInfo.tags[tag]) {
        delete basicInfo.tags[tag];
      }

      CacheRequest.setCacheForKey(key, basicInfo);
    });

    setTimeout(() => {
      Api.getCatalogueTags(catalogueId);
    }, 1500);
  } catch (err) {
    console.error('deleteProductsTag: Could not delete tags', err);
    Sentry.captureException(err);
  }

  toggleGlobalLoader(loaderKey, false);
};

const fetchCommonBulkTags = async ({ productIds }) => {
  try {
    const key = `${JSON.stringify(productIds)}`;
    bulkTagsResp = key;

    const { tags } = await Api.getBulkProductTags(productIds);
    const exists = (() => bulkTagsResp === key)(); // Ensures that prev resp does not overwrite the new resp

    if (!exists) {
      return;
    }

    const selectedCommonTags = Object.values(tags).map(tag => tag.title);
    bulkTagsResp = null;

    return selectedCommonTags;
  } catch (err) {
    console.error('ProductTagsSelector: Could not fetch bulk product tags');
    bulkTagsResp = null;
    Sentry.captureException(err);
  }
};

const computeUnSelectedFromSelected = (catalogueId, selectedCommonTags = []) => {
  const key = `${connector.CATALOGUE_TAGS.cacheKey}${catalogueId}`;
  const { tags } = CacheRequest.getCacheForKey(key);
  const allTags = Object.keys(tags);

  const unselected = [];

  allTags.forEach(tag => {
    if (selectedCommonTags.indexOf(tag) < 0 && tag !== 'Out of stock' && tag !== 'In stock') {
      unselected.push(tag);
    }
  });

  return unselected;
};

export default {
  setCatalogueTagVisibility,
  sortCatalogueTags,
  getAllSelectedTags,
  toggleTagSelection,
  isTagSelected,
  clearSelectedTags,
  onTagClick,
  onChangeCatalogueTagsFromRemote,
  isTagValid,
  addProductTag,
  addProductsTag,
  deleteProductTag,
  deleteProductsTag,
  sortProductTags,
  deleteProductTagInRemote,
  fetchCommonBulkTags,
  computeUnSelectedFromSelected,
  filterStockTags
};
