import React, { useState, useCallback } from 'react';
import {
  FILTER_OPTIONS,
  PRODUCT_SEARCH,
  getPriceFilterSelectedOption,
  getPriceFilterInputValues
} from 'qs-data-manager/Products';
import eventbus from 'eventing-bus';
import { getCompanyCurrencySymbol } from 'qs-data-manager/Company';

import './styles.scss';
import Mixpanel from 'qs-data-manager/Mixpanel';

export default ({ closePopover } = {}) => {
  const [selectedOption, setSelectedOption] = useState(() => {
    return getPriceFilterSelectedOption();
  });
  const [filterValue, setFilterValue] = useState(() => {
    return getPriceFilterInputValues();
  });
  const [companyCurrency] = useState(() => {
    return getCompanyCurrencySymbol();
  });

  const onFilterOptionSelected = optionId => {
    setSelectedOption(optionId);
    setFilterValue({
      price: '',
      minPrice: '',
      maxPrice: ''
    });
  };

  const applyPriceFilter = useCallback(() => {
    let data = {
      type: FILTER_OPTIONS[selectedOption].title
    };

    if (selectedOption === FILTER_OPTIONS.RANGE.id) {
      if (!filterValue.maxPrice.length || !filterValue.minPrice.length) {
        console.error('applyPriceFilter: Cannot click apply button');
        return;
      }
      data.minPrice = Number(filterValue.minPrice);
      data.maxPrice = Number(filterValue.maxPrice);
    } else {
      if (!filterValue.price.length) {
        console.error('applyPriceFilter: Cannot click apply button');
        return;
      }
      data.price = Number(filterValue.price);
    }

    eventbus.publish(PRODUCT_SEARCH.PRODUCT_SEARCH_EB_KEY, {
      type: PRODUCT_SEARCH.FILTER_PRODUCT_ON,
      value: data
    });
    Mixpanel.sendEvent({
      eventName: 'filter_applied',
      props: {
        price_filter_applied: typeof data.price === 'number',
        filter_min_price: typeof data.minPrice === 'number',
        filter_max_price: typeof data.maxPrice === 'number',
      }
    });
    closePopover();
  }, [filterValue, selectedOption, closePopover]);

  const renderFilterOptions = optionId => {
    const applyThisStyle = selectedOption === optionId ? 'option selectedOption' : 'option';

    if (!FILTER_OPTIONS[optionId] || !FILTER_OPTIONS[optionId].title) {
      return null;
    }

    return (
      <div
        key={optionId}
        className={applyThisStyle}
        onClick={() => onFilterOptionSelected(optionId)}
      >
        {FILTER_OPTIONS[optionId].title}
      </div>
    );
  };

  const setMinPrice = useCallback(e => {
    e.stopPropagation();
    const value = e.target.value;
    if (isNaN(Number(value))) {
      console.error('setMinPrice: Value is not a number');
      return;
    }
    setFilterValue(prevState => ({ ...prevState, minPrice: value }));
  }, []);

  const setMaxPrice = useCallback(e => {
    e.stopPropagation();
    const value = e.target.value;
    if (isNaN(Number(value))) {
      console.error('setMinPrice: Value is not a number');
      return;
    }
    setFilterValue(prevState => ({ ...prevState, maxPrice: value }));
  }, []);

  const setPrice = useCallback(e => {
    e.stopPropagation();
    const value = e.target.value;
    if (typeof Number(value) !== 'number' || isNaN(Number(value))) {
      console.error('setPrice: Value is not a number');
      return;
    }
    setFilterValue(prevState => ({ ...prevState, price: value }));
  }, []);

  const renderPriceInput = () => {
    return (
      <div className="priceContainer">
        <div className="currencyContainer">
          <div className="WebRupee">{companyCurrency}</div>
        </div>
        <input
          autoFocus
          className="inputPrice"
          value={filterValue.price}
          onChange={setPrice}
          placeholder="Enter price to filter"
          // onKeyPress={this.handlePriceEnterKeyPress}
        />
      </div>
    );
  };

  const renderRangeInput = () => {
    return (
      <div className="rangeWrapper">
        <div className="rangeContainer">
          <div className="currencyContainer">
            <div className="WebRupee">{companyCurrency}</div>
          </div>
          <input
            autoFocus
            className="rangeInput"
            placeholder="min price"
            value={filterValue.minPrice}
            onChange={setMinPrice}
          />
        </div>

        <span className="rangeTo">to</span>

        <div className="rangeContainer">
          <div className="currencyContainer">
            <div className="WebRupee">{companyCurrency}</div>
          </div>
          <input
            className="rangeInput"
            placeholder="max price"
            value={filterValue.maxPrice}
            onChange={setMaxPrice}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="productPriceModalContainer">
      <div className="titleContainer">Filter by price</div>
      <div className="optionsContainer">
        {Object.keys(FILTER_OPTIONS).map(id => renderFilterOptions(id))}
      </div>

      {selectedOption === FILTER_OPTIONS.RANGE.id ? renderRangeInput() : renderPriceInput()}

      <div className={`applyFilterContainer`} onClick={applyPriceFilter}>
        <span className="applyFilter">APPLY FILTER</span>
      </div>
    </div>
  );
};
