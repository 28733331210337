import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import './index.css';
import App from './Components';
import * as serviceWorker from './serviceWorker';
import AppConfig from 'qs-config/AppConfig';
import { setScrollStyleIfRequired } from 'qs-helpers';

Sentry.init({ ...AppConfig.sentry });

/*
    Attach the scrollbar style if needed before the Browser spends time rendering
    all the components. This will ensure that the scrollbar style will be available
    before the components are rendered.
*/
setScrollStyleIfRequired()

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
