export const showPaymentSettingsOption = (companyVal, globalVal, countryVal) => {
  /*
    If the company level value is not set, then return the global val.
    If it is set then companyVal takes precedence over the global val
  */
  const paymentEnabledCompanyLevel = companyVal === null ? globalVal : companyVal;

  return paymentEnabledCompanyLevel || countryVal;
};

export const displayCompanyAddress = addressObj => {
  if (!addressObj) {
    return '';
  }

  return (
    addressObj.addressLineOne +
    (addressObj.addressLineTwo ? ', ' + addressObj.addressLineTwo : '') +
    '\n' +
    addressObj.city +
    ' - ' +
    addressObj.pincode +
    '\n' +
    addressObj.state +
    ', ' +
    addressObj.country
  );
};
