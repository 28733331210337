import React, { useState, useCallback, useEffect } from 'react';
import ProductPrice from './ProductPrice';
import ProductDiscount from './ProductDiscount';

import './styles.scss';

export default ({
  price,
  discount,
  activeProductIds,
  isBulkEditing,
  activeProductId,
  currencySymbol
}) => {
  const [modifiedPrice, setModifiedPrice] = useState(price);

  const onPriceChange = useCallback(({ newPrice }) => {
    setModifiedPrice(newPrice);
  }, []);

  useEffect(() => {
    setModifiedPrice(price);
  }, [price]);

  return (
    <div id={'ProductPriceAndDiscount'}>
      <ProductPrice
        price={price}
        informDiscountOfPriceChange={onPriceChange}
        activeProductId={activeProductId}
        activeProductIds={activeProductIds}
        isBulkEditing={isBulkEditing}
        currencySymbol={currencySymbol}
      />
      <ProductDiscount
        discount={discount}
        originalPrice={price}
        modifiedPrice={modifiedPrice}
        activeProductId={activeProductId}
        activeProductIds={activeProductIds}
        isBulkEditing={isBulkEditing}
        currencySymbol={currencySymbol}
      />
    </div>
  );
};
