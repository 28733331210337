import { connector } from './ApiAndCacheConnector';
import CacheRequest from './CacheRequest';
import { attachCountryFirebaseOnceListener, attachExperimentsOnceListenerFirebase } from '../Firebase/Config';

const attachConfigsListener = listener => {
  const key = connector.CONFIG_DATA.cacheKey;
  CacheRequest.attachListener(key, listener);
};

const removeConfigsListener = listener => {
  const key = connector.CONFIG_DATA.cacheKey;
  CacheRequest.removeListener(key, listener);
};

const setConfigInCache = data => {
  const key = connector.CONFIG_DATA.cacheKey;
  CacheRequest.setCacheForKey(key, data);
};

const getConfigFromCache = () => {
  const key = connector.CONFIG_DATA.cacheKey;
  return CacheRequest.getCacheForKey(key);
};

//Company request product demo
const attachConfigDemoLinkListener = listener => {
  const key = connector.CONFIG_PRODUCT_DEMO.cacheKey;
  CacheRequest.attachListener(key, listener);
}

const removeConfigDemoLinkListener = listener => {
  const key = connector.CONFIG_PRODUCT_DEMO.cacheKey;
  CacheRequest.removeListener(key, listener);
}

const getConfigDemoLink = () => {
  const key = connector.CONFIG_PRODUCT_DEMO.cacheKey;
  const apiName = connector.CONFIG_PRODUCT_DEMO.apiFunction;
  return CacheRequest.makeRequest(key, apiName, {
    params: [],
    options: {
      shouldNotStoreInNative: true
    }
  });
};

const getConfigDemoLinkFromCache = () => {
  const key = connector.CONFIG_PRODUCT_DEMO.cacheKey;
  return CacheRequest.getCacheForKey(key);
};


//Country code data

const attachCountryCodeConfigListener = listener => {
  const key = connector.CONFIG_COUNTRY_CODE.cacheKey;
  CacheRequest.attachListener(key, listener)
}

const removeCountryCodeConfigListener = listener => {
  const key = connector.CONFIG_COUNTRY_CODE.cacheKey;
  CacheRequest.removeListener(key, listener)
}

const getCountryCodeConfigFromCache = () => {
  const key = connector.CONFIG_COUNTRY_CODE.cacheKey;
  return CacheRequest.getCacheForKey(key);
}

const getCountryCodeConfig = () => {
  const key = connector.CONFIG_COUNTRY_CODE.cacheKey;
  const apiName = connector.CONFIG_COUNTRY_CODE.apiFunction;
  return CacheRequest.makeRequest(key, apiName, {
    params: [],
    shouldNotStoreInNative: true
  });
}

//Country code config firebase
const attachCountryConfigListenerFirebase = (countryCode) => {
  attachCountryFirebaseOnceListener({countryCode})
}

const attachCountryConfigListener = listener => {
  const key = connector.CONFIG_COUNTRY.cacheKey;
  CacheRequest.attachListener(key, listener);
}

const removeCountryConfigListener = listener => {
  const key = connector.CONFIG_COUNTRY.cacheKey;
  CacheRequest.removeListener(key, listener);
}

const setCountryConfigInCache = ({data, error = null} = {}) => {
  const key = connector.CONFIG_COUNTRY.cacheKey;
  CacheRequest.setCacheForKey(key, data, {error});
}

const getCountryConfigFromCache = () => {
  const key = connector.CONFIG_COUNTRY.cacheKey;
  return CacheRequest.getCacheForKey(key);
}

//Experiments data
const setupExperimentsOnceListener = () => {
  attachExperimentsOnceListenerFirebase()
}

const attachExperimentsListener = listener => {
  const key = connector.CONFIG_EXPERIMENTS.cacheKey;
  CacheRequest.attachListener(key, listener);
}

const removeExperimentsListener = listener => {
  const key = connector.CONFIG_EXPERIMENTS.cacheKey;
  CacheRequest.removeListener(key, listener);
}

const setExperimentsInCache = ({data, error = null } = {}) => {
  const key = connector.CONFIG_EXPERIMENTS.cacheKey;
  CacheRequest.setCacheForKey(key, data, {error});
}

const getExperimentsFromCache = () => {
  const key = connector.CONFIG_EXPERIMENTS.cacheKey;
  return CacheRequest.getCacheForKey(key);
}

export {
  attachConfigsListener,
  removeConfigsListener,
  setConfigInCache,
  getConfigFromCache,
  attachConfigDemoLinkListener,
  removeConfigDemoLinkListener,
  getConfigDemoLink,
  getConfigDemoLinkFromCache,
  //External service to fetch country code
  attachCountryCodeConfigListener,
  removeCountryCodeConfigListener,
  getCountryCodeConfigFromCache,
  getCountryCodeConfig,

  //Coonect to firebase and listen for updates
  attachCountryConfigListenerFirebase,
  attachCountryConfigListener,
  removeCountryConfigListener,
  setCountryConfigInCache,
  getCountryConfigFromCache,

  //Experiments
  setupExperimentsOnceListener,
  attachExperimentsListener,
  removeExperimentsListener,
  setExperimentsInCache,
  getExperimentsFromCache
}
