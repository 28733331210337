import React, { Component } from 'react';

import Loader from 'qs-components/Common/Loader';

import PropTypes from 'prop-types';
import './Header.css';

class Header extends Component {
  static propTypes = {
    noOfBaskets: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    isRefreshing: PropTypes.bool
  };

  state = {};

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.noOfBaskets !== this.props.noOfBaskets ||
      nextProps.isRefreshing !== this.props.isRefreshing
    );
  }

  render() {
    const { noOfBaskets, isRefreshing } = this.props;

    return !!noOfBaskets ? (
      <div className="contactOrderHeaderContainer">
        <div className="contactOrderHeader">
          {typeof noOfBaskets === 'number' && (
            <div className="noOfBaskets">{noOfBaskets} baskets</div>
          )}
        </div>

        {isRefreshing && (
          <div className="contactScreenLoader">
            <Loader size="extraSmall" />
          </div>
        )}
      </div>
    ) : null;
  }
}

export default Header;
