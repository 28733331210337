import imageCompressor from './ImageCompressors/ImageCompressor_APP_RUN_ENV';
import {
  setQueueData,
  getImagesFromQueue,
  updateImageQueue,
  batchNumberToRead,
  prepareQueueDataForRestarting,
  clearQueueData
} from './ImageQueues/ImageQueue_APP_RUN_ENV';
import { uploadImagesUsingWorker } from 'qs-master-worker/master.worker.js';
import { getUserIdFromCache } from 'qs-data-manager/User';
import {
  restartProgressBar,
  clearProgressBarInfo
} from './RestartProgressBar/RestartProgressBar_APP_RUN_ENV';
import { attachConnectivityChangeListener, isAppOffline } from '../Firebase/Connectivity';
import { clearCatalogueImageMeta, clearProductImageMeta } from 'qs-helpers/ProcessUploadedImage';

class ImageUploadProcessor {
  constructor() {
    this.imageUploadingStatus = 'IDLE';
    this.totalImagesUploading = 0;
    const appOffline = isAppOffline();
    if (appOffline) {
      this.imageUploadingStatus = 'PAUSED';
    }

    /*
      Since this instance lives throughout the applications lifecycle,
      no need to remove the listener
    */
    attachConnectivityChangeListener(this.connectivityListener);
  }

  connectivityListener = offlineStatus => {
    //Process states once internet is back
    if (!offlineStatus) {
      this.updateUploadingStatusOnceOnline();
      this.startImageProcessing();
      return;
    }

    this.imageUploadingStatus = 'PAUSED';
  };

  /**
    As long as the totalImagesUploading is not 0, there are still
    a few images whose status has not been received. So before the last image
    has been processed, if another upload operation is performed then a new batch will be started.
    This can create multiple batches and can hence increase the load.
    So to prevent multiple batches from being started, maintain a count
    for the total number of images that are being uploaded.
    If it is 0, then set image uploading status to IDLE.
  */
  updateUploadingStatusOnceOnline() {
    //No images were being processed, safely set to IDLE
    if (this.totalImagesUploading === 0) {
      this.imageUploadingStatus = 'IDLE';
      return;
    }

    //Images are being processed, app is back online set it to running state
    if(this.imageUploadingStatus === 'PAUSED') {
      this.imageUploadingStatus = 'RUNNING';
    }
  }

  /**
   * If all the pending images have been processed then the current state
   * must be checked. If the app is offline then set it to PAUSED. However,
   * for any other status set it back to IDLE to enable processing the
   * images again
   */
  toggleStatePostProcessingImages() {
    if (this.totalImagesUploading === 0) {
      console.log('all images processed');
      if (isAppOffline()) {
        console.log('app offline setting to paused');
        this.imageUploadingStatus = 'PAUSED';
        return;
      }

      /*
        The previous upload process was killed, and hence processing
        any new images was not done. Now that all previous have been
        processed and the app is online proceed to check the queue
        again if new images are ready to be processed
      */
      if(this.imageUploadingStatus === 'KILLED') {
        console.log('app status was killed, setting to idle and checking if anything is present in new queue');
        this.imageUploadingStatus = 'IDLE';
        this.startImageProcessing();
        return;
      }

      this.imageUploadingStatus = 'IDLE';
    }
  }

  async setImageData(imageData) {
    if (!imageData) {
      return;
    }

    return await setQueueData(imageData);
  }

  async processNextImages(batchNumberToRead) {
    /*
      No point reading further from the queue if the
      operation is no longer running
    */
    if (this.imageUploadingStatus !== 'RUNNING') {
      this.toggleStatePostProcessingImages();
      return;
    }

    const imagesToProcess = await getImagesFromQueue(batchNumberToRead);

    this.totalImagesUploading += imagesToProcess.length;

    // No images present in queue, stop the processing
    if (imagesToProcess.length === 0) {
      this.toggleStatePostProcessingImages();
      return;
    }

    const { compressedImages, compressFailedImages } = await imageCompressor(imagesToProcess);

    //Process the compressed failed images and add it to the pending queue
    compressFailedImages.forEach(compressedFailedImage => {
      this.handleProcessedImages(compressedFailedImage);
    });

    // All the fetched images, can't be compressed, remove these images from the queue
    if (compressedImages.length === 0) {
      return;
    }

    uploadImagesUsingWorker({ userId: getUserIdFromCache(), images: compressedImages });
  }

  async startImageProcessing() {
    if (this.imageUploadingStatus !== 'IDLE') {
      return;
    }

    this.imageUploadingStatus = 'RUNNING';
    this.processNextImages(batchNumberToRead);
  }

  async handleProcessedImages(processedImage) {
    this.totalImagesUploading -= 1;

    // The uploader process has to be killed, don't process any updates
    if (this.imageUploadingStatus !== 'KILLED') {
      await updateImageQueue(processedImage);
    }

    // 1 processed, read the next
    this.processNextImages(1);
  }

  async uploadImages(imageData) {
    await this.setImageData(imageData);
    this.startImageProcessing();
  }

  async restartUploadingImages() {
    //On startup, prepare the upload process for restart
    await Promise.all([restartProgressBar(), prepareQueueDataForRestarting()]);
    this.startImageProcessing();
  }

  processUserChange() {

    // User logout kill processing any pending item
    if(this.totalImagesUploading !== 0) {
      this.imageUploadingStatus = 'KILLED';
    }
    clearQueueData();
    clearProgressBarInfo();

    //Clear the in-memory progress bar data
    clearCatalogueImageMeta();
    clearProductImageMeta();
  }
}

export default new ImageUploadProcessor();
