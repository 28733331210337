import React, { Component } from 'react';
import './styles.css';
import Switch from 'react-switch';
import Loader from 'qs-components/Common/Loader';

class CatalogueSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.value,
      loader: false
    };

    setTimeout(() => {
      this.setState({
        loader: true
      });
    }, 3000);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      checked: props.value
    });
  }

  handleChange = checked => {
    this.toggleSwitch();
  };

  toggleSwitch = () => {
    let newState = !this.state.checked;
    this.setState({
      checked: newState
    });

    this.props.onSettingChanged(newState, this.props.text);
  };

  renderCatalogueVisibility = () => {
    const { stockManagedFrom, resetToCompanyDefault, text } = this.props;

    if (stockManagedFrom === 'catalogue') {
      return (
        <div
          className="inventoryManagedFrom reset"
          onClick={() => resetToCompanyDefault({ toggleValue: true, text })}
        >
          Reset to company default
        </div>
      );
    }

    if (stockManagedFrom === 'company') {
      return <div className="inventoryManagedFrom">Currently managed by company settings</div>;
    }

    return null;
  };

  render() {
    const { text, shouldShowLoader } = this.props;
    return (
      <div className="CatalogueSettingWrapper">
        <div className="CatalogueSettingContainer">
          <div className="CatalogueSettingMeta" onClick={this.toggleSwitch}>
            <label className="CatalogueSettingTitle">{this.props.settingTitle}</label>
            <div className="CatalogueSettingDescription">{this.props.settingDescription}</div>
          </div>
          <div className="CatalogueSwitch">
            {shouldShowLoader ? (
              <div className={'loaderContainer'}>
                <Loader size={'small'} />
              </div>
            ) : (
              <Switch
                onChange={this.handleChange}
                checked={this.state.checked}
                onColor="#C0DACE"
                onHandleColor="#4DA47A"
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow={
                  this.state.checked ? '0px 1px 5px #4DA47A' : '0px 1px 5px rgba(0, 0, 0, 0.6)'
                }
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                id="normal-switch"
              />
            )}
          </div>
        </div>
        {(text === 'catalogueStockVisibility' || text === 'orderOnOOS') &&
          this.renderCatalogueVisibility()}
      </div>
    );
  }
}

export default CatalogueSetting;
