import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

export const apiKey = 'AIzaSyD1U5aoVoFVxs2GhJ7Q0mpHwL25fJwks2U';
export const authDomain = 'sx-app-a04c1.firebaseapp.com';
export const databaseURL = 'https://sx-app-a04c1.firebaseio.com';
export const projectId = 'sx-app-a04c1';
export const storageBucket = 'sx-app-a04c1.appspot.com';
export const messagingSenderId = '223646330354';

const config = {
  apiKey,
  authDomain,
  databaseURL,
  projectId,
  storageBucket,
  messagingSenderId
};

firebase.initializeApp(config);

export const db = firebase.database();

export default firebase;
