import React from 'react';
import Popover from 'react-tiny-popover';
import FilterProducts from '../FilterProducts';

export default ({ children, showPopover, onClosePopover }) => {

  return (
    <Popover
      isOpen={showPopover}
      position={['bottom']}
      padding={20}
      onClickOutside={onClosePopover}
      containerStyle={{ zIndex: 11 }}
      content={<FilterProducts closePopover={onClosePopover} />}
    >
      {children}
    </Popover>
  );
};
