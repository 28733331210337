import React, { useState, useCallback, useEffect } from 'react';
import CatalogueLib from 'qs-services/DataManager/Catalogues';
import { isEnterKey } from 'qs-services/Helpers/index';

import './styles.scss';
import { ReactComponent as EditIcon } from 'qs-assets/Media/pencil.svg';
import { ReactComponent as CloseIcon } from 'qs-assets/Media/close.svg';

export default ({ title: catalogueTitle, catalogueEditMode, id }) => {
  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState(catalogueTitle);
  const [editModeTitle, setEditModeTitle] = useState(catalogueTitle);
  const [saveButtonVisibility, setSaveButtonVisibility] = useState(false);

  useEffect(() => {
    setTitle(catalogueTitle);
  }, [catalogueTitle]);

  if (catalogueEditMode && editMode) {
    setEditMode(false);
  }

  const closeEditMode = useCallback(
    e => {
      e.stopPropagation();
      setEditMode(false);
      setSaveButtonVisibility(false);
      setEditModeTitle(title);
    },
    [title]
  );

  const openEditMode = useCallback(
    e => {
      e.stopPropagation();
      setEditMode(true);
      setEditModeTitle(title);
    },
    [title]
  );

  const saveTitleChange = useCallback(
    e => {
      if (e) {
        e.stopPropagation();
      }

      if (editModeTitle === '') {
        console.error('Cannot set catalogue to an empty string');
        return;
      }

      setEditMode(false);
      setSaveButtonVisibility(false);
      setTitle(editModeTitle);
      CatalogueLib.changeCatalogueTitle({
        title: editModeTitle,
        id
      });
    },
    [editModeTitle, id]
  );

  const changeEditModeTitle = e => {
    e.stopPropagation();
    const value = e.target.value;
    if (value === catalogueTitle) {
      setSaveButtonVisibility(false);
    }

    setEditModeTitle(value);
    if (!saveButtonVisibility) {
      setSaveButtonVisibility(true);
    }
  };

  const onKeyPress = useCallback(
    e => {
      e.stopPropagation();
      const isEnter = isEnterKey(e);
      if (isEnter) {
        saveTitleChange();
      }
    },
    [saveTitleChange]
  );

  if (editMode) {
    return (
      <div className={'commonTitleContainer catalogueEditTitle'}>
        <input
          autoFocus
          value={editModeTitle}
          onChange={changeEditModeTitle}
          placeholder="Enter catalogue title"
          onKeyPress={onKeyPress}
        />
        <div className={'closeIconContainer'}>
          <CloseIcon className={'closeIcon'} onClick={closeEditMode} />
        </div>
        {saveButtonVisibility && !!editModeTitle.length && (
          <div className={'saveButton'} onClick={saveTitleChange}>
            save
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={'commonTitleContainer titleContainer'}>
      <div className={'title catalogueTitle'}>{title}</div>
      {!catalogueEditMode && <EditIcon onClick={openEditMode} className={'titleEditIcon'} />}
    </div>
  );
};
