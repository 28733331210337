import { useEffect } from "react";
import { rehydrationService } from "qs-services/Rehydration";
import { validateCompanyPaymentPlan } from "qs-data-manager/Company";
import Mixpanel from "qs-data-manager/Mixpanel";
import imageUploadProcessor from "qs-services/ImageUpload/ImageUploadProcessor";

export default loginState => {
  useEffect(() => {
    if (loginState.isLoggedIn && !loginState.loggingIn) {
      rehydrationService();
      validateCompanyPaymentPlan();
      Mixpanel.setSessionAttributes();
      imageUploadProcessor.restartUploadingImages()
    }
    return () => {};
  }, [loginState]);
};
