import eventbus from 'eventing-bus';
import { GLOBAL_LOADER_KEY } from 'qs-components/Common/GlobalLoader';
import Api from '../Api';
import config from 'qs-config/AppConfig';
import currencyMapper from 'qs-helpers/CurrencyMapper';
import { getUserIdFromCache } from 'qs-data-manager/User';
import { getCompanyCurrencySymbol, COMPANY_LOCAL_STORAGE_KEYS } from 'qs-data-manager/Company';
import { LOCAL_STORAGE_KEYS } from '../Rehydration';
import * as Sentry from '@sentry/browser';

const CURRENT_VERSION = '1.0.16';

const debouncerMeta = {};
const downloadBrochurePromise = {};
const downloadPdfPromise = {};
const downloadImagePromise = {};
const downloadExcelPromise = {};

const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

// Should have been in data-manager/catalogue but that causes a bad circular dependency
export const CATALOGUES_LAST_FETCH_TS = {
  localstorageKey: 'CATALOGUES_LAST_FETCH_TS',
  ts: null
};

export const rehydrateCatalogueLastFetch = date => {
  CATALOGUES_LAST_FETCH_TS.ts = date;
};

export const PRODUCT_LAST_FETCH_TS = {
  localstorageKey: catalogueId => `PRODUCT_LAST_FETCH_TS${catalogueId}`,
  ts: {}
};

export const rehydrateProductLastFetch = ({ catalogueId }) => {
  const key = PRODUCT_LAST_FETCH_TS.localstorageKey(catalogueId);
  let date = localStorage.getItem(key);
  date = JSON.parse(date);
  PRODUCT_LAST_FETCH_TS.ts[catalogueId] = date;
};

export const NAVIGATION = {
  openDrawerWidth: 195,
  closedDrawerWidth: 51,
  screens: {
    catalogueScreen: 'CATALOGUES_SCREEN',
    inquiriesScreen: 'INQUIRIES_SCREEN',
    accountSettingsScreen: 'ACCOUNT_SETTINGS_SCREEN'
  },
  animationDuration: {
    TRANSITION_DURATION: '0.3s',
    OPEN_DRAWER_WIDTH: '200px',
    CLOSE_DRAWER_WIDTH: '51px'
  }
};

export const DEBOUNCER_TYPE = {
  ADD: 'ADD',
  OVER_WRITE: 'OVER_WRITE'
};

export const debouncer = ({ data, key } = {}, options = {}, callback) => {
  const { time, type } = options;
  if (!data || !key) {
    console.warn('Debouncer called with no data or key');
    return;
  }

  if (type === DEBOUNCER_TYPE.ADD) {
    if (!debouncerMeta[key]) {
      debouncerMeta[key] = [];
    }

    if (debouncerMeta[`${key}_timeout`]) {
      clearTimeout(debouncerMeta[`${key}_timeout`]);
    }

    debouncerMeta[key].push(data);
    debouncerMeta[`${key}_timeout`] = setTimeout(() => {
      callback(debouncerMeta[key]);
      delete debouncerMeta[key];
      delete debouncerMeta[`${key}_timeout`];
    }, time);
  } else if (type === DEBOUNCER_TYPE.OVER_WRITE) {
    debouncerMeta[key] = [data];
  }
};

export const openWhatsApp = ({ text = '', contact = '' } = {}) => {
  window.open(`https://api.whatsapp.com/send?text=${text}&phone=${contact}`, '_blank');
};

export const openMail = ({ text = '' }) => {
  window.open(
    'https://mail.google.com/mail/u/0/?view=cm&fs=1&&su=QUICKSELL&body=' + text,
    '_blank'
  );
};

export const openFacebook = ({ text = '' }) => {
  window.open('https://www.facebook.com/sharer/sharer.php?u=' + text, '_blank');
};

export const isFileCSV = fileType =>
  [
    'text/csv',
    'application/vnd.ms-excel',
    'application/octet-stream',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ].includes(fileType);

export const toggleGlobalLoader = (uniqueKey, value) => {
  eventbus.publish(GLOBAL_LOADER_KEY, uniqueKey, value);
};

export const isEnterKey = e => e.key === 'Enter';

export const dataFromProfiler = (
  id, // the "id" prop of the Profiler tree that has just committed
  phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
  actualDuration, // time spent rendering the committed update
  baseDuration, // estimated time to render the entire subtree without memoization
  startTime, // when React began rendering this update
  commitTime, // when React committed this update
  interactions // the Set of interactions belonging to this update
) => {
  console.log(
    JSON.stringify({
      id,
      phase,
      actualDuration,
      baseDuration,
      interactions
    })
  );
};

export const isWebkitBrowser = () => {
  return isChrome || isSafari;
};

export const getImageWidthHeight = file => {
  return new Promise((resolve, reject) => {
    window.URL = window.URL || window.webkitURL;
    var img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      window.URL.revokeObjectURL(img.src);
      img = null;
      resolve({ width, height });
    };
    img.onerror = () => {
      img = null;
      const error = new Error('Could not compute image width and height');
      reject(error);
    };
  });
};

export const modifySearchResult = ({
  result,
  type,
  price,
  minPrice,
  maxPrice,
  numberOfProduct
}) => {
  const currencySymbol = getCompanyCurrencySymbol();
  const resultArray = result.split(' ');
  const unit = resultArray[1] === 'result' ? 'product' : 'products';
  const productCount = numberOfProduct === 0 ? 'no' : numberOfProduct;

  if (type === 'equals') {
    return `Found ${productCount} ${unit} worth ${currencySymbol} ${price}`;
  } else if (type === 'more than') {
    return `Found ${productCount} ${unit} more than ${currencySymbol} ${price}`;
  } else if (type === 'less than') {
    return `Found ${productCount} ${unit} less than ${currencySymbol} ${price}`;
  } else if (type === 'range') {
    return `Found ${productCount} ${unit} in the range of ${currencySymbol} ${minPrice} to ${currencySymbol} ${maxPrice}`;
  }
};

// Code has been copy pasted from prev prod version
export const getElementParents = (el, parentSelector /* optional */) => {
  // If no parentSelector defined will bubble up all the way to *document*
  if (parentSelector === undefined) {
    parentSelector = document;
  }

  var parents = [];
  var p = el.parentNode;

  while (p !== parentSelector) {
    var o = p;
    parents.push(o);
    p = o.parentNode;
  }
  parents.push(parentSelector); // Push that parentSelector you wanted to stop at

  return parents;
};

export const downloadFileFromLink = link => {
  const a = document.createElement('a');
  a.href = link;
  a.click();
};

export const downloadBrochure = async ({ catalogueId }) => {
  const loaderKey = `downloadBrochure${catalogueId}`;
  try {
    if (downloadBrochurePromise[catalogueId]) {
      return;
    }

    toggleGlobalLoader(loaderKey, true);

    const promise = Api.downloadCatalogueBrochure(catalogueId);

    downloadBrochurePromise[catalogueId] = promise;
    const { brochureLink } = await promise;

    delete downloadBrochurePromise[catalogueId];
    downloadFileFromLink(brochureLink);

    toggleGlobalLoader(loaderKey, false);
  } catch (err) {
    console.log(`downloadPdf: Could not download pdf for catalogueId: ${catalogueId}`, err);
    delete downloadBrochurePromise[catalogueId];
    toggleGlobalLoader(loaderKey, false);
    Sentry.captureException(err);
  }
};

export const downloadPdf = async ({ catalogueId, link }) => {
  const loaderKey = `downloadPdf${catalogueId}`;
  try {
    if (downloadPdfPromise[catalogueId]) {
      return;
    }

    toggleGlobalLoader(loaderKey, true);

    const promise = Api.downloadCataloguePdf({ catalogueId, catalogueLink: link });
    downloadPdfPromise[catalogueId] = promise;
    const { pdfLink } = await promise;
    downloadFileFromLink(pdfLink);

    delete downloadPdfPromise[catalogueId];
    toggleGlobalLoader(loaderKey, false);
  } catch (err) {
    console.log(`downloadPdf: Could not download pdf for catalogueId: ${catalogueId}`, err);
    delete downloadPdfPromise[catalogueId];
    toggleGlobalLoader(loaderKey, false);
    Sentry.captureException(err);
  }
};

export const downloadCatalogueImages = async ({ catalogueId }) => {
  const loaderKey = `downloadCatalogueImages${catalogueId}`;

  try {
    if (downloadImagePromise[catalogueId]) {
      return;
    }

    toggleGlobalLoader(loaderKey, true);

    const promise = Api.downloadCatalogueImages({ catalogueId });
    downloadImagePromise[catalogueId] = promise;
    const { zipLink } = await promise;

    delete downloadImagePromise[catalogueId];
    downloadFileFromLink(zipLink);
    toggleGlobalLoader(loaderKey, false);
  } catch (err) {
    console.log(
      `downloadCatalogueImages: Could not download images for catalogueId: ${catalogueId}`,
      err
    );
    delete downloadImagePromise[catalogueId];
    toggleGlobalLoader(loaderKey, false);
    Sentry.captureException(err);
  }
};

export const downloadCatalogueExcel = async ({ catalogueId }) => {
  const loaderKey = `downloadCatalogueExcel${catalogueId}`;

  try {
    if (downloadExcelPromise[catalogueId]) {
      return;
    }
    toggleGlobalLoader(loaderKey, true);

    const promise = Api.downloadCatalogueAsExcel({ catalogueId });
    downloadExcelPromise[catalogueId] = promise;

    const { excelLink } = await promise;
    delete downloadExcelPromise[catalogueId];
    downloadFileFromLink(excelLink);
    toggleGlobalLoader(loaderKey, false);
  } catch (err) {
    console.log(
      `downloadCatalogueExcel: Could not download excel for catalogueId: ${catalogueId}`,
      err
    );
    delete downloadExcelPromise[catalogueId];
    toggleGlobalLoader(loaderKey, false);
    Sentry.captureException(err);
  }
};

export const generateImageUrls = pictureId => {
  const userId = getUserIdFromCache();

  if (!pictureId || !userId) {
    return {
      imageUrls: [],
      error: true
    };
  }

  const urls = [];

  config.imageSizes.forEach(size => {
    const connector = size === 'FULL' ? 'products' : `products_${size}`;
    const cloudFrontUrl = `${config.imageUrlSource.CLOUD_FRONT}/${userId}/${connector}/${pictureId}.jpg`;
    const s3Url = `${config.imageUrlSource.S3}/${userId}/${connector}${size}/${pictureId}.jpg`;
    urls.push(cloudFrontUrl, s3Url);
  });

  return {
    imageUrls: urls,
    error: false
  };
};

export const getPictureIdFromImageUrl = ({ url }) => {
  const urlArray = url.split('/');
  return urlArray[urlArray.length - 1].split('.')[0];
};

export const getImageUrlFromPictureId = ({ size = 'FULL', pictureId }) => {
  const userId = getUserIdFromCache();
  const sizeConnector = size === 'FULL' ? 'products' : `products_${size}`;
  return `${config.imageUrlSource.S3}/${userId}/${sizeConnector}/${pictureId}.jpg`;
};

export const convertImageUrlSize = ({ size, url }) => {
  if (!size || !url) {
    return url;
  }

  return url.replace('products', `products_${size}`);
};

export const getCurrencySymbol = ({ currencyCode = '' }) => {
  return currencyMapper[currencyCode] || '';
};

export const windowEndpointProtocolHostname = () => {
  let redirectionPath = window.location.protocol + '//' + window.location.hostname;
  if (window.location.port) {
    redirectionPath = redirectionPath + ':' + window.location.port;
  }
  return redirectionPath;
};

export const formWindowEndpointForCatalogue = () => {
  let redirectionPath = windowEndpointProtocolHostname();
  redirectionPath = redirectionPath + '/catalogues';
  return redirectionPath;
};

export const formWindowEndpointForLogin = () => {
  let redirectionPath = windowEndpointProtocolHostname();
  redirectionPath = redirectionPath + '/login';
  return redirectionPath;
};

export const supportPhoneNumber = () => {
  return '918104064645'; // was already hardcoded in prod in prev versions
};

export const getRenderableSize = ({ imageWidth, imageHeight }) => {
  if (!imageWidth || !imageHeight) {
    return { width: 0, height: 0 };
  }

  const WIDTH = window.innerWidth - 100;
  const HEIGHT = window.innerHeight - 100;

  const maxWidth = WIDTH;
  const maxHeight = HEIGHT;

  let renderableWidth = maxWidth;
  let renderableHeight = maxHeight;

  const aspectRatio = imageWidth / imageHeight;

  if (imageWidth >= imageHeight) {
    renderableHeight = renderableWidth / aspectRatio;

    if (renderableHeight > maxHeight) {
      renderableHeight = maxHeight;
      renderableWidth = renderableHeight * aspectRatio;
    }
  } else {
    renderableWidth = renderableHeight * aspectRatio;

    if (renderableWidth > maxWidth) {
      renderableWidth = maxWidth;
      renderableHeight = renderableWidth / aspectRatio;
    }
  }

  return { width: renderableWidth, height: renderableHeight };
};

const getPictureIdFromUrl = pictureUrl => {
  if (!pictureUrl) {
    return '';
  }
  let tokens = pictureUrl.split('/');
  let lastToken = tokens[tokens.length - 1];
  return lastToken;
};

export const getProductPictureUrl = options => {
  let { imageId, source, size, userId, pictureUrl } = options;

  if (!imageId) {
    imageId = getPictureIdFromUrl(pictureUrl);
  }

  if (!imageId) {
    return '';
  }

  return `${source}/${userId}/products_${size}/${imageId}`;
};

export const modifyCatalogueSearchResult = ({ searchedIdsLength }) => {
  return `${searchedIdsLength} result${searchedIdsLength > 1 ? 's' : ''} found`;
};

export const updateRemoteWithDeviceId = async ({companyId, uniqueId, osType, device}) => {
  const update = {};
  update[`/company-uuid/${companyId}/${uniqueId}`] = {
    timestamp_created: new Date().getTime(),
    os_type: osType,
    companyId,
    device
  };

  try {
    await Api.updateFirebase(update)
  }catch(deviceSendError) {
    console.error('updateRemoteWithDeviceId: could not update remote with the unique device ID')
    Sentry.captureException(deviceSendError)
  }
}

export const checkIfMobile = () => {
  var check = false;
  (function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const isRequestFromDesiredOs = (os) => {
  let requestFromDesiredOs = false;
  let osForNavigator;
  switch(os) {
    case 'WIN':
      osForNavigator = 'win';
      break;
    case 'MAC':
      osForNavigator = 'mac';
      break;
    default:
      osForNavigator = '';
      break;
  }

  const stringToTest = navigator.platform || navigator.appVersion || navigator.userAgent || ''
  if (stringToTest.toLowerCase().indexOf(osForNavigator) !== -1) {
    requestFromDesiredOs = true;
  }
  return requestFromDesiredOs;
};

export const getAppVersion = () => CURRENT_VERSION;

export const localstorageExists = () =>
  !!(JSON.parse(localStorage.getItem(COMPANY_LOCAL_STORAGE_KEYS.COMPANY_META)) || {}).id &&
  !!JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_PLAN) || null) &&
  !!JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.FEATURE_LIMIT) || null);

export const setScrollStyleIfRequired = function () {
  if (!isRequestFromDesiredOs('MAC')) {
    const styleForScrollbar = document.createElement('style')
    styleForScrollbar.type = "text/css";
    styleForScrollbar.id = "scrollbar-css"
    styleForScrollbar.appendChild(
      document.createTextNode('*::-webkit-scrollbar {width: 10px;background-color: transparent}')
    );
    styleForScrollbar.appendChild(
      document.createTextNode('*::-webkit-scrollbar-track {background-color: transparent;border-radius: 10px;}')
    );
    styleForScrollbar.appendChild(
      document.createTextNode('*::-webkit-scrollbar-thumb {background-color: #181e25;border-radius: 10px;border: 1px solid transparent}')
    );
    styleForScrollbar.appendChild(
      document.createTextNode('*::-webkit-scrollbar:vertical:hover {background-color: rgba(255,255,255,0.5);}')
    );
    styleForScrollbar.appendChild(
      document.createTextNode('*::-webkit-scrollbar:vertical:hover {background-color: rgba(255,255,255,0.5);}')
    );
    styleForScrollbar.appendChild(
      document.createTextNode('*::-webkit-scrollbar-thumb:vertical:hover {background-color: #0e1115;border: 2px solid #0e1115;}')
    );
    styleForScrollbar.appendChild(
      document.createTextNode('*::-webkit-scrollbar-thumb:vertical:active {background-color: #000000;border: 2px solid #000000;}')
    );
    styleForScrollbar.appendChild(
      document.createTextNode('* {scrollbar-width: auto;scrollbar-color: rgba(255,255,255, 0.4) transparent;}')
    );
    document.head.appendChild(styleForScrollbar)
  }
}

export const isInputNumber = function(numToValidate) {
  let numValid = false
  if (typeof numToValidate === 'string' || typeof numToValidate === 'number') {
    numValid = !Number.isNaN(Number(numToValidate))
  }

  // Check existance of e, because this will be converted by the Number constructor to a valid number
  if (numValid && typeof numToValidate === 'string') {
    numValid = numToValidate.toLowerCase().indexOf('e') === -1
  }

  return numValid
}

export const roundNumberToGivenDecimals = function(number, numDecimals = 2) {
  if(typeof number === 'number') {
    const numToDivide = Math.pow(10, numDecimals)
    return (Math.round(number * numToDivide) / numToDivide)
  }

  return number
}

export const isInteger = function(number) {
  if(typeof number === 'string') {
    const allDigits = /^-{0,1}[0-9]+$/.test(number)
    if(!allDigits) {
      return false;
    }

    //parse the input and convert it to a number
    number = parseInt(number, 10);
  }

  return Number.isInteger(number)
}
