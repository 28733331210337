import React, { Component } from 'react';
import moment from 'moment';

import cross from 'qs-assets/Media/close_red.png';
import PropTypes from 'prop-types';
import './RenderSingleOrder.css';

class RenderSingleOrder extends Component {
  static propTypes = {
    data: PropTypes.object
  };

  state = { isSelected: false };

  UNSAFE_componentWillMount() {
    const { selectedOrderId, data } = this.props;

    if (selectedOrderId === data.orderId) {
      this.setState({ isSelected: true });
    } else {
      this.setState({ isSelected: false });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectedOrderId, data } = nextProps;
    const updates = {};

    if (selectedOrderId !== this.props.selectedOrderId) {
      updates.isSelected = selectedOrderId === data.orderId;
    }

    if (Object.keys(updates)) {
      this.setState(updates);
    }
  }

  seeContactInquiry = () => {
    const {
      seeContactInquiry,
      contact,
      data: { orderId, isDeletedByVisitor, isInheritedCatalogue }
    } = this.props;

    this.setState({ isSelected: true });
    seeContactInquiry({
      contact: contact,
      orderId: orderId,
      isDeletedByVisitor: isDeletedByVisitor,
      isInheritedCatalogue
    }); // dont send contact form here
  };

  renderImages = pictureUrls => {
    const top = 0;
    let left = -15;
    // let left = 15*(pictureUrls.length);
    let zIndex = 1;
    return pictureUrls.map(url => {
      left += 15;
      zIndex += 1;
      return (
        <div key={url} style={{ width: '100%', height: '100%' }}>
          <div
            className="pictureImage"
            style={{
              top: `${top}px`,
              left: `${left}px`,
              zIndex: zIndex,
              backgroundColor: '#4e555e'
            }}
          />
          <div
            className="pictureImage"
            style={{
              backgroundImage: `url(${url})`,
              top: `${top}px`,
              left: `${left}px`,
              zIndex: zIndex
            }}
          />
        </div>
      );
    });
  };

  render() {
    const { isDeletedByVisitor, isFinalized, catalogueTitle, dateUpdated, inquiries } = this.props.data;
    const { isSelected } = this.state;

    const bgContactOrder = isSelected
      ? 'RenderSingleOrderContainer bgContactOrder'
      : 'RenderSingleOrderContainer';

    const dateUpdatedMoment = moment(dateUpdated || new Date());

    const updatedTime = dateUpdatedMoment.format('h:mm a');
    const updatedAgo = dateUpdatedMoment.fromNow();

    const itemCount = (inquiries || []).length;
    const unreadItemCount = (inquiries || []).filter(inquiry => !inquiry.read).length;

    const pictureUrls = (inquiries || [])
      .map(inquiry => inquiry.product && inquiry.product.pictureUrl)
      .filter(pictureUrl => !!pictureUrl);

    const renderableUrls = pictureUrls.slice(0, 4);
    const pictureLength = renderableUrls.length - 1;
    const width = 26 + 15 * pictureLength + 13; //single image width + overlap + padding

    return (
      <button onClick={this.seeContactInquiry} className={bgContactOrder}>
        <div className="order-details-container">
          <div className="leftContainer">
            {isDeletedByVisitor && (
              <div className="deletedVisitorContainer">
                <div>
                  <img src={cross} className="icon-image" alt="" />
                </div>
                <div className="deleted">Deleted by visitor</div>
              </div>
            )}

            <div className="firRowContainer">
              <div className="imageItems">
                <div className="imageContainer" style={{ width: width }}>
                  {this.renderImages(renderableUrls)}
                </div>
                <div className="item-details-container">
                  <div className="numberOfItems">
                    {itemCount} item{itemCount === 1 ? '' : 's'}
                  </div>
                  {!!unreadItemCount && <div className="numberOfNew">{unreadItemCount} new</div>}
                </div>
              </div>
            </div>

            <div className="secondRowContainer">
              <span className="time">{updatedTime}</span>
              <span className="daysAgo">{updatedAgo}</span>
              <span className="catalogueName">{catalogueTitle}</span>
            </div>
          </div>
          {isFinalized && (
            <div className="confirmed-container">
              CONFIRMED
            </div>
          )}
        </div>
      </button>
    );
  }
}

export default RenderSingleOrder;
