import React, { useCallback, useState } from 'react';
import { previewCatalogue } from 'qs-services/DataManager/Products';
import ShareDialoguePopover from './ShareDialoguePopover';
import CatalogueSettings from './CatalogueSettings';
import { getActiveCatalogueId } from 'qs-data-manager/Catalogues';
import UploadProductHeader from '../UploadProductHeader';

import { ReactComponent as EditIcon } from 'qs-assets/Media/pencil.svg';
import { ReactComponent as SearchIcon } from 'qs-assets/Media/magnifyingGlass.svg';
import { ReactComponent as ShareIcon } from 'qs-assets/Media/share.svg';
import { ReactComponent as PreviewIcon } from 'qs-assets/Media/play.svg';
import { ReactComponent as SettingsIcon } from 'qs-assets/Media/userDetailsGearFilled.svg';
import './styles.scss';
import Mixpanel from 'qs-data-manager/Mixpanel';

export default ({ onEditIconClick, onSearchIconClick, activeCatalogueId }) => {
  const [sharePopover, setSharePopover] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);

  const onPreviewClick = useCallback(() => {
    const catalogueId = getActiveCatalogueId();
    previewCatalogue({ catalogueId });
    Mixpanel.sendEvent({
      eventName: 'catalogue_previewed',
      props: {
        catalogueId
      }
    });
  }, []);

  const onShareClick = useCallback(() => {
    setSharePopover(prevState => !prevState);
  }, []);

  const onSettingsIconClick = useCallback(e => {
    e.stopPropagation();
    setSettingsModal(prevState => !prevState);
  }, []);

  const closeCatalogueSettings = useCallback(() => {
    setSettingsModal(false);
  }, []);

  const closeSharePopover = useCallback(() => {
    setSharePopover(false);
  }, []);

  return (
    <div id={'ProductHeaderWrapper'} className={'productListHeader'}>
      <div id={'ProductHeaderContainer'} className={'container'}>
        <div onClick={onEditIconClick} className={'iconContainer'}>
          <EditIcon className={'productHeaderIcon editIcon'} />
          <div className={'label'}>edit</div>
        </div>
        <div onClick={onSearchIconClick} className={'iconContainer'}>
          <SearchIcon className={'productHeaderIcon'} />
          <div className={'label'}>search</div>
        </div>
        <div onClick={onShareClick} className={'iconContainer shareDialogueContainer'}>
          <ShareDialoguePopover
            showPopover={sharePopover}
            catalogueId={activeCatalogueId}
            closePopover={closeSharePopover}
          >
            <div className={'productHeaderIconContainer'}>
              <ShareIcon className={'productHeaderIcon'} />
              <div className={'label'}>share</div>
            </div>
          </ShareDialoguePopover>
        </div>
        <div onClick={onPreviewClick} className={'iconContainer'}>
          <PreviewIcon className={'productHeaderIcon'} />
          <div className={'label'}>preview</div>
        </div>
        <div onClick={onSettingsIconClick} className={'iconContainer settingsIconContainer'}>
          <SettingsIcon className={'productHeaderIcon settingsIcon'} />
          <div className={'label'}>settings</div>
        </div>
        {settingsModal ? (
          <CatalogueSettings closeCatalogueSettings={closeCatalogueSettings} />
        ) : null}
      </div>
    </div>
  );
};
