import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';

import './ProductTagsSelector.scss';
import ProductTagsModal from '../ProductTagsModal/ProductTagsModal';
import NonVirtualisedList from 'qs-components/Common/NonVirtualisedList';
import { ReactComponent as EditIcon } from 'qs-assets/Media/pencil.svg';
// import { isWebkitBrowser } from 'qs-helpers/index';

class ProductTagsSelector extends Component {
  static propTypes = {
    isBulkEdit: PropTypes.bool,
    productId: PropTypes.string,
    productIds: PropTypes.array,
    catalogueId: PropTypes.string,
    selectedTags: PropTypes.array,
    unselectedTags: PropTypes.array,
    onUnselectedTagPress: PropTypes.func,
    refreshTags: PropTypes.func
  };

  static defaultProps = {
    selectedTags: [],
    unselectedTags: []
  };

  state = {
    editModalActive: false
  };

  onAddTagsPress = () => {
    this.setState({ editModalActive: true });
  };

  onEditTagsPress = () => {
    this.setState({ editModalActive: true });
  };

  onEditModalClose = () => {
    this.setState({ editModalActive: false });
    this.props.refreshTags();
  };

  render() {
    const { editModalActive } = this.state;
    const {
      selectedTags,
      unselectedTags,
      onUnselectedTagPress,
      productId,
      productIds,
      isBulkEdit,
      catalogueId,
      addNewTagFromModal,
      updateTagsFromModal,
    } = this.props;

    // const isWebkitBrowser = isWebkitBrowser();
    const isWebkitBrowser = true; // TODO: hardcoded here

    return (
      <div className="productTagsSelector">
        {!selectedTags.length && <div className="tagsTitle">Organize your products</div>}

        {!!unselectedTags.length && (
          <NonVirtualisedList
            data={unselectedTags}
            renderItem={({ item: tag }) => (
              <div key={tag} className="unselectedTag" onClick={() => onUnselectedTagPress(tag)}>
                <span className="unselectedTagText">{tag}</span>

                <div className="plus">+</div>
              </div>
            )}
            keyExtractor={item => item}
            contentContainerClassName="unselectedTagsScrollContainer"
            contentContainerStyle={{ overflow: isWebkitBrowser ? 'auto' : 'hidden' }}
            ItemSeparatorComponent={() => <div className="productTagsSelectorSeparator" />}
            ListHeaderComponent={() => <div className="productTagsSelectorHeader" />}
            ListFooterComponent={() => <div className="productTagsSelectorFooter" />}
          />
        )}

        {!!selectedTags.length && (
          <div className="selectedTagsContainer">
            <div
              className="selectedTagsScrollContainer"
              style={{ overflow: isWebkitBrowser ? 'auto' : 'hidden' }}
            >
              <NonVirtualisedList
                data={selectedTags}
                renderItem={({ item: tag }) => (
                  <div key={tag} className="selectedTag">
                    <span className="selectedTagText">{tag}</span>
                  </div>
                )}
                keyExtractor={item => item}
                contentContainerClassName="selectedTagsScrollContainer"
                ItemSeparatorComponent={() => <div className="productTagsSelectorSeparator" />}
                ListHeaderComponent={() => <div className="productTagsSelectorHeader" />}
                ListFooterComponent={() => <div className="productTagsSelectorFooter" />}
              />
            </div>

            <div className="tagButton" onClick={this.onEditTagsPress}>
              <EditIcon fill="#000" className="tagButtonIcon" />
              <span className="tagButtonText">Edit</span>
            </div>
          </div>
        )}

        {!selectedTags.length && (
          <div className={`addTagsContainer ${!selectedTags.length ? 'removeMargin' : ''} `}>
            <div className="tagButton" onClick={this.onAddTagsPress}>
              <EditIcon fill="#000" className="tagButtonIcon" />
              <span className="tagButtonText">Add tags</span>
            </div>
          </div>
        )}

        <Modal
          open={editModalActive}
          onClose={this.onEditModalClose}
          center
          styles={{
            modal: { backgroundColor: '#fff', borderRadius: 10, padding: 0 }
          }}
          showCloseIcon={false}
        >
          {editModalActive && (
            <ProductTagsModal
              productId={productId}
              productIds={productIds}
              isBulkEdit={isBulkEdit}
              catalogueId={catalogueId}
              selectedTags={selectedTags}
              unselectedTags={unselectedTags}
              onClose={this.onEditModalClose}
              addNewTagFromModal={addNewTagFromModal}
              updateTagsFromModal={updateTagsFromModal}
            />
          )}
        </Modal>
      </div>
    );
  }
}

export default ProductTagsSelector;
