import React, { Component } from 'react';
import './styles.scss';
import {
  setupCompanySettingsListener,
  tearDownCompanySettingsListener,
  setupCompanyShareTextListener,
  tearDownCompanyShareTextListener
} from 'qs-data-manager/Company';
import SettingsLeftPanel from './SettingsLeftPanel/SettingsLeftPanel';
import InventorySettings from './InventorySettings/InventorySettings';
import CatalogueSettings from './CatalogueSettings/CatalogueSettings';
import LanguageSettings from './LanguageSettings/LanguageSettings';
import PaymentSettings from './PaymentSettings/PaymentSettings';
import CheckoutSettings from './CheckoutSettings/CheckoutSettings';
import PlansBilling from './PlansBilling/PlansBilling';
import SuggestionFeedback from './SuggestionFeedback.js/SuggestionFeedback';
import { setupExperimentsOnceListener } from 'qs-data-manager/Config';
import CompanyProfile from './CompanyProfile/CompanyProfile';

class CompanySettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      settingsComponentId: 'COMPANY_NAME'
    };

    this.componentIdMapping = CompanySettings.createListOfSettingsOptions();
  }

  static createListOfSettingsOptions() {
    const componentIdMapping = {
      COMPANY_NAME: CompanyProfile,
      PLANS_BILLING: PlansBilling,
      INVENTORY_SETTINGS: InventorySettings,
      LANGUAGE_SETTINGS: LanguageSettings,
      CATALOGUE_SETTINGS: CatalogueSettings,
      CHECKOUT_SETTINGS: CheckoutSettings,
      PAYMENT_SETTINGS: PaymentSettings,
      SUGGESTION_FEEDBACKS: SuggestionFeedback
    };

    return componentIdMapping
  }

  componentDidMount() {
    setupCompanySettingsListener();
    setupCompanyShareTextListener();
    setupExperimentsOnceListener();
  }

  componentWillUnmount() {
    tearDownCompanySettingsListener();
    tearDownCompanyShareTextListener();
  }

  handleSettingsItemClick = settingsComponentId => {
    this.setState({ settingsComponentId });
  };

  render() {
    const { settingsComponentId } = this.state;
    const ComponentToRender = this.componentIdMapping[settingsComponentId];

    return (
      <div className="accountSettingsMainContainer">
        <SettingsLeftPanel
          handleSettingsItemClick={this.handleSettingsItemClick}
          selectedId={settingsComponentId}
        />
        <div className="settingsContainer">
          <ComponentToRender />
        </div>
      </div>
    );
  }
}

export default CompanySettings;
