import React, {Component} from 'react';
import './styles.css';

class Loader extends Component{
  render(){
    var extraSmallSize  = this.props.size === 'extraSmall' ? '23px' : false;
    var smallSize = this.props.size === "small" ? "30px" : false;
    var largeSize = this.props.size === "large" ? "60px" : false;

    var style = {
      width : extraSmallSize || smallSize || largeSize
    };

    return (
      <div className="loader" style={style}>
        <svg className="circular" viewBox="25 25 50 50">
          <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="4" strokeMiterlimit="10"/>
        </svg>
      </div>
    );
  }
}

export default Loader;
