import React from 'react';
import './styles.css';

export default ({ checked, onCheckChanged, id = '', onCheckClicked = () => {} }) => {
  return (
    <label className="CheckBoxContainer" onClick={onCheckClicked}>
      <input
        id={id}
        className="CheckBox"
        type="checkbox"
        checked={checked}
        onChange={onCheckChanged}
      />
      <span className="Checkmark" />
    </label>
  );
};
