import React from 'react';
import './SettingsDataRow.scss';

export default ({ icon, title, onSectionClick, children }) => (
  <section
    className={`settings-data-container ${onSectionClick ? 'settings-data--click' : ''}`}
    onClick={onSectionClick}
  >
    <div className="settings-data-row-container">
      <div className="settings-data-text-container">
        <h4 className="settings-data-text--title">{title}</h4>
        <div className="settings-data-text--description">{children}</div>
      </div>
      {icon ? <div className="icon-container">{icon}</div> : null}
    </div>
  </section>
);
