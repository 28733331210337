import React, { useEffect, useState, Fragment } from 'react';
import SettingsDataRow from '../../Common/SettingsDataRow/SettingsDataRow';
import {
  getCompanyMetaFromCache,
  attachCompanyMetaListener,
  removeCompanyMetaListener,
  getCompanyIdFromCache
} from 'qs-data-manager/Company';
import {
  getUserMetaFromCache,
  attachUserMetaListener,
  removeUserMetaListener
} from 'qs-data-manager/User';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import { displayCompanyAddress } from 'qs-helpers/CompanySettings';
import './CompanyProfile.scss';
import UserNameEdit from './UserNameEdit/UserNameEdit';
import EditCompanyAddress from './EditCompanyAddress/EditCompanyAddress';
import EditCompanyDetails from './EditCompanyDetails/EditCompanyDetails';
import toastr from 'toastr';
import Loader from '../../../Common/Loader';

export default () => {
  const [companyProfile, setCompanyProfile] = useState(getCompanyMetaFromCache());
  const [userProfile, setUserProfile] = useState(getUserMetaFromCache());
  const [showEditName, toggleEditName] = useState(false);
  const [showAddress, toggleAddress] = useState(false);
  const [showCompanyEdit, toggleCompanyEdit] = useState(false);

  useEffect(() => {
    const companyListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);
      if (loading || refreshing) {
        return;
      }

      if (err) {
        toastr.error('Something went wrong while fetching the updated company values');
        return;
      }
      setCompanyProfile(data);
    };

    attachCompanyMetaListener(companyListener);
    return () => removeCompanyMetaListener(companyListener);
  }, []);

  useEffect(() => {
    const userListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (loading || refreshing) {
        return;
      }

      if (err) {
        toastr.error('Something went wrong while fetching the updated user values');
        return;
      }
      setUserProfile(data);
    };

    attachUserMetaListener(userListener);
    return () => removeUserMetaListener(userListener);
  }, []);

  const getCompanyLogo = function() {
    if (!companyProfile) {
      return;
    }

    const { logoExists, logoVersion } = companyProfile;
    if (!logoExists) {
      return (
        <div className="placeholder-logo">
          <span>Logo</span>
        </div>
      );
    }

    const companyId = getCompanyIdFromCache();
    return (
      <div className="logo-container">
        <img
          src={`https://s3.ap-south-1.amazonaws.com/quicksell-logos/${companyId}.jpg?v=${logoVersion}`}
          alt="Your company profile image"
        />
      </div>
    );
  };

  const toggleEditNameView = function() {
    toggleEditName(!showEditName);
  };

  const toggleAddressView = function() {
    toggleAddress(!showAddress);
  };

  const toggleCompanyEditView = function() {
    toggleCompanyEdit(!showCompanyEdit);
  };

  //TODO will be added later, hence leaving it commented
  /*const handleOptionsClick = function(field) {
    return event => {
      event.preventDefault();
      switch (field) {
        case 'USER_NAME':
          toggleEditNameView();
          break;
        case 'COMPANY_POPUP':
          toggleCompanyEditView();
          break;
        case 'ADDRESS':
          toggleAddressView();
          break;
        default:
          break;
      }
    };
  };*/

  let companyDetailsComponent;
  if (companyProfile && userProfile) {
    companyDetailsComponent = (
      <Fragment>
        <div className="company-image-container">{getCompanyLogo(companyProfile)}</div>
        <SettingsDataRow
          title={'Name'}
        >
          <span>{userProfile.name}</span>
        </SettingsDataRow>
        <SettingsDataRow title={'Company name'}>
          <span>{companyProfile.name}</span>
        </SettingsDataRow>
        <SettingsDataRow icon={null} title={'Currency'}>
          <span>{companyProfile.currencyCode}</span>
        </SettingsDataRow>
        <SettingsDataRow icon={null} title={'Domain'}>
          <span>{companyProfile.domain || 'No domain set'}</span>
        </SettingsDataRow>
        <SettingsDataRow title={'Email'}>
          <span>{userProfile.accountEmail ? userProfile.accountEmail : userProfile.email}</span>
        </SettingsDataRow>
        <SettingsDataRow icon={null} title={'Phone'}>
          <span>{userProfile.phone}</span>
        </SettingsDataRow>
        <SettingsDataRow
          title={'Address'}
        >
          <pre className="address-container">{displayCompanyAddress(companyProfile.address)}</pre>
        </SettingsDataRow>
        {showEditName && (
          <UserNameEdit onClose={toggleEditNameView} defaultName={userProfile.name} />
        )}
        {showAddress && (
          <EditCompanyAddress onClose={toggleAddressView} defaultAddress={companyProfile.address} />
        )}
        {showCompanyEdit && (
          <EditCompanyDetails
            onClose={toggleCompanyEditView}
            defaultCompany={companyProfile}
            phone={userProfile.phone}
          />
        )}
      </Fragment>
    );
  } else {
    companyDetailsComponent = <Loader size="large" />;
  }

  return <div className="company-profile-container">{companyDetailsComponent}</div>;
};
