import React, { useEffect, useState } from 'react';
import ProductTitle from './ProductTitle';
import ProductPriceAndDiscount from './ProductPriceAndDiscount';
import ProductDescription from './ProductDescription';
import ProductTags from './ProductTags';
import ProductPictures from './ProductPictures';
import {
  getBasicInfo,
  computeBasicInfoFromProductRow,
  removeBasicInfoListener,
  attachBasicInfoListener,
  getBasicInfoFromRemote,
  autoSaveProductDetails
} from 'qs-data-manager/ProductDetails';
import { attachProductMetaListener, removeProductMetaListener } from 'qs-data-manager/Products';
import CacheCallback from 'qs-helpers/CacheListenerCallback';

import './styles.scss';

export default ({ activeProductIds = [], isBulkEditing = '', activeProductId = '' } = {}) => {
  const [productDetails, setProductDetails] = useState({
    err: null,
    loading: false,
    refreshing: false,
    basicInfo: {
      tags: [],
      pictures: []
    },
    productMetaFromRow: getBasicInfo({ activeProductId, isBulkEditing }) // for Title, price, discount, description
  });

  // Effect to compute tags and pictures from remote
  useEffect(() => {
    const listener = (error, payload) => {
      const { err, loading, refreshing, data } = CacheCallback(error, payload);
      const updates = {};

      if (err) {
        console.error('BasicInfo index.js: Could not fetch basic info from remote', err);
        updates.err = err;
        updates.loading = loading;
        return;
      }

      if (loading) {
        updates.loading = loading;
        updates.refreshing = refreshing;
        updates.err = err;
        setProductDetails(prevState => {
          return {
            ...prevState,
            ...updates
          };
        });
        return;
      }

      const { tags, pictures } = data;
      const pictureUrls = Object.values(pictures || {}).map(picture => ({
        url: picture.url,
        prepared: picture.prepared
      }));
      let sortedTags = Object.values(tags)
        .sort((t1, t2) => {
          if (t1.v === t2.v) {
            return t1.p - t2.p;
          }

          return t2.v ? 1 : -1;
        })
        .map(tag => tag.title);

      setProductDetails(prevState => ({
        ...prevState,
        loading: false,
        basicInfo: {
          ...prevState.basicInfo,
          tags: sortedTags,
          pictures: pictureUrls
        }
      }));
    };

    if (!isBulkEditing) {
      attachBasicInfoListener({ listener, productId: activeProductId });
      getBasicInfoFromRemote({ productId: activeProductId });
    }

    return isBulkEditing
      ? () => {}
      : () => {
          removeBasicInfoListener({ listener, productId: activeProductId });
          // autoSaveProductDetails();
        };
  }, [activeProductId, isBulkEditing]);

  // Effect to compute name, price, discount, description data from product row
  useEffect(() => {
    const listener = (error, payload) => {
      const { err, data } = CacheCallback(error, payload);
      if (err) {
        console.error('Basic Info useEffect error', err);
        return;
      }

      if (data) {
        const updates = computeBasicInfoFromProductRow(data);
        setProductDetails(prevState => ({
          ...prevState,
          productMetaFromRow: {
            ...prevState.productMetaFromRow,
            ...updates
          }
        }));
      }
    };

    if (!isBulkEditing) {
      attachProductMetaListener({ listener, productId: activeProductId });
      const updates = getBasicInfo({ activeProductId, isBulkEditing });
      setProductDetails(prevState => ({
        ...prevState,
        productMetaFromRow: {
          ...prevState.productMetaFromRow,
          ...updates
        }
      }));
    }

    return isBulkEditing
      ? () => {}
      : () => removeProductMetaListener({ listener, productId: activeProductId });
  }, [activeProductId, isBulkEditing]);

  const { productMetaFromRow } = productDetails;

  return (
    <div id={'BasicInfo'}>
      <ProductTitle
        title={productMetaFromRow.title}
        isBulkEditing={isBulkEditing}
        activeProductIds={activeProductIds}
      />
      <ProductPriceAndDiscount
        currencySymbol={productMetaFromRow.currencySymbol}
        price={productMetaFromRow.price}
        discount={productMetaFromRow.discount}
        activeProductId={activeProductId}
        activeProductIds={activeProductIds}
        isBulkEditing={isBulkEditing}
      />
      <ProductDescription
        description={productMetaFromRow.description}
        activeProductId={activeProductId}
        activeProductIds={activeProductIds}
        isBulkEditing={isBulkEditing}
      />
      <ProductTags
        loading={productDetails.loading}
        tags={productDetails.basicInfo.tags}
        activeProductId={activeProductId}
        activeProductIds={activeProductIds}
        isBulkEditing={isBulkEditing}
      />
      {isBulkEditing ? null : (
        <ProductPictures
          loading={productDetails.loading}
          pictures={productDetails.basicInfo.pictures}
          activeProductIds={activeProductIds}
        />
      )}
    </div>
  );
};
