import { LOCAL_STORAGE_KEYS } from 'qs-services/Rehydration';
import { getAllCatalogueCount, getAllProductCount } from 'qs-data-manager/Company';
import eventbus from 'eventing-bus';

let CURRENT_PLAN = null;
let FEATURE_LIMITS = {};

export const FEATURE_LIMITING_MODAL_META = {
  EVENTBUS_KEY: 'FEATURE_LIMITING_MODAL_META'
};

export const PLAN_TYPES = {
  LITE: 'LITE',
  UNLIMITED: 'UNLIMITED'
};

export const FEATURE_LIST = {
  CATALOGUES: {
    id: 'CATALOGUES',
    title: '50 catalogues limit reached',
    description: 'To create more catalogues contact us or delete existing catalogues',
    iconStyles: { width: 34, height: 42 }
  },
  PRODUCTS: {
    id: 'PRODUCTS',
    title: '2000 products limit reached',
    description: 'To create more products contact us or delete existing products',
    buttons: [{ title: 'Delete products', gradientColors: ['#FF7373', '#CE3737'] }]
  }
};

export const rehydrateCurrentPlan = currentPlan => {
  CURRENT_PLAN = currentPlan;
};

export const rehydrateFeatureLimit = featureLimits => {
  convertFeatureLimitMeta(featureLimits);
};

export const saveCurrentPlan = currentPlan => {
  CURRENT_PLAN = currentPlan;
  const data = JSON.stringify(currentPlan);
  localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_PLAN, data);
};

const convertFeatureLimitMeta = featureLimits => {
  featureLimits.plans.forEach(plan => {
    const features = {};
    plan.features.forEach(row => {
      features[row.feature] = row;
    });

    FEATURE_LIMITS[plan.planType] = {
      planMeta: plan,
      featureIdLimitMap: features
    };
  });
};

export const saveFeatureLimits = featureLimits => {
  const data = JSON.stringify(featureLimits);
  localStorage.setItem(LOCAL_STORAGE_KEYS.FEATURE_LIMIT, data);

  convertFeatureLimitMeta(featureLimits);
};

export const canUseFeature = (id, { extraProductCount } = {}) => {
  const isUnlimited = CURRENT_PLAN.planType === PLAN_TYPES.UNLIMITED;

  if (isUnlimited) {
    return true;
  }

  if (
    !FEATURE_LIMITS ||
    !CURRENT_PLAN.planType ||
    !FEATURE_LIMITS[CURRENT_PLAN.planType] ||
    !FEATURE_LIMITS[CURRENT_PLAN.planType].featureIdLimitMap ||
    !FEATURE_LIMITS[CURRENT_PLAN.planType].featureIdLimitMap[id]
  ) {
    return false;
  }

  const { limit, count } = FEATURE_LIMITS[CURRENT_PLAN.planType].featureIdLimitMap[id].options;

  if (!limit) {
    return true;
  }

  const canUse = usageStatistics(id, count, extraProductCount);

  if (canUse) {
    return true;
  }

  toggleFeatureLimitModal(true, id);
  return false;
};

const usageStatistics = (id, count, extraProductCount) => {
  switch (id) {
    case FEATURE_LIST.CATALOGUES.id:
      return canCreateCatalogue(count);

    case FEATURE_LIST.PRODUCTS.id:
      return canCreateProducts(count, extraProductCount);

    default:
      break;
  }
};

const canCreateCatalogue = count => {
  const userCatalogues = getAllCatalogueCount();

  if (typeof userCatalogues !== 'number') {
    return false;
  }

  const limit = userCatalogues + 1;
  return limit <= count;
};

const canCreateProducts = (count, extraProductCount) => {
  const userProducts = getAllProductCount();

  if (typeof userProducts !== 'number') {
    console.error(
      'canCreateProducts: typeof count of number of products is not a number',
      userProducts
    );
    return false;
  }

  const limit = userProducts + (extraProductCount || 0); // extraProductCount are the products that are being added from local

  return limit <= count;
};

const toggleFeatureLimitModal = (visibility, id) => {
  eventbus.publish(FEATURE_LIMITING_MODAL_META.EVENTBUS_KEY, {
    featureId: id,
    visibility
  });
};
