import React, { useState, useEffect, useCallback } from 'react';
import Loader from 'qs-components/Common/Loader';
import CatalogueLib from 'qs-data-manager/Catalogues';
import CacheCallback from 'qs-helpers/CacheListenerCallback';

import './styles.scss';
import Mixpanel from 'qs-data-manager/Mixpanel';

const {
  attachCatalogueLinkListener,
  removeCatalogueLinkListener,
  getAndCreateCatalogueLink
} = CatalogueLib;

export default ({ catalogueId, isVisible } = {}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [linkState, setLinkState] = useState(() => {
    const updates = {
      link: '',
      generatingLink: true
    };

    const link = getAndCreateCatalogueLink({
      catalogueId
    });

    if (typeof link === 'string') {
      updates.link = link;
      updates.generatingLink = false;
    } else if (typeof link === 'object') {
      link.then(resp => {
        const { link } = resp;
        setLinkState({
          link,
          generatingLink: false
        });
      });
    }

    return updates;
  });

  useEffect(() => {
    const listener = (error, payload) => {
      const { err, loading, refreshing, data } = CacheCallback(error, payload);

      if (err) {
        console.log('err', err);
        return;
      }

      if (loading) {
        setLinkState({
          generatingLink: true,
          link: ''
        });
        return;
      }

      const { linkDomain, companySlug, catalogueSlug, randomSlug } = data || {};
      const link = `${linkDomain}/${companySlug}/${catalogueSlug}/${randomSlug}`;
      setLinkState({
        link,
        generatingLink: false
      });
    };

    attachCatalogueLinkListener({ catalogueId, listener });

    return () => removeCatalogueLinkListener({ catalogueId, listener });
  }, [catalogueId]);

  const copyLink = useCallback(
    e => {
      e.stopPropagation();

      if (isCopied) {
        return;
      }

      const el = document.createElement('textarea');
      el.value = linkState.link;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      setIsCopied(prevState => !prevState);
      Mixpanel.sendEvent({
        eventName: 'link_share_option_clicked',
        props: {
          catalogueId
        }
      });
    },
    [linkState.link, isCopied, catalogueId]
  );

  if (linkState.generatingLink) {
    return (
      <div id={'generatingLink'}>
        <Loader size={'small'} />
      </div>
    );
  }

  return (
    <div onClick={copyLink} id={'linkWrapper'}>
      <div className={'linkContainer'}>{linkState.link}</div>

      <div className={`tapToCopy ${isCopied ? 'linkCopied' : ''}`}>
        {isCopied ? 'Link copied' : 'Tap to copy'}
      </div>
    </div>
  );
};
