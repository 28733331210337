import React, { useCallback } from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import ShareDialogue from 'qs-components/Common/ShareDialogue';
import CatalogueTagsPopover from '../../../ProductsList/CatalogueTags/CatalogueTagsPopover';

export default ({ children, showPopover, catalogueId, closePopover }) => {
  const content = useCallback(
    ({ position, targetRect, popoverRect }) => (
      <ArrowContainer
        position={position}
        targetRect={targetRect}
        popoverRect={popoverRect}
        arrowColor="white"
        arrowSize={10}
      >
        <ShareDialogue
          catalogueIds={[catalogueId]}
          isVisible={showPopover}
          closePopover={closePopover}
        />
      </ArrowContainer>
    ),
    [catalogueId, showPopover, closePopover]
  );

  return (
    <Popover
      isOpen={showPopover}
      position={['bottom']}
      padding={20}
      containerStyle={{ zIndex: 11, maxWidth: 340 }}
      content={content}
    >
      {children}
    </Popover>
  );
};

// this entire popover can be extracted into a common component
