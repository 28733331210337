import React, { Component } from 'react';

import RenderList from './RenderList/RenderList';
import RefreshButton from './RefreshButton/RefreshButton';
import './OrdersContactsScreen.css';
import PropTypes from 'prop-types';
import OrderLib from 'qs-data-manager/Order';

class OrdersContactsScreen extends Component {
  static propTypes = {
    onContactClick: PropTypes.func,
    selectedContact: PropTypes.string,
    clearScreenState: PropTypes.func
  };

  state = {
    refreshClicked: null,
    showRefreshButton: false
  };

  componentWillMount() {
    const ts = Date.now();
    OrderLib.setLastInquiryFetchTimestamp(ts);
  }

  componentWillUnmount() {
    this.props.clearScreenState();
  }

  refreshClicked = (value, clearState = true) => {
    if (clearState) {
      this.props.clearScreenState();
    }
    this.setState({ refreshClicked: value });
  };

  onContactClick = phone => {
    const { showRefreshButton, onContactClick } = this.props;

    if (showRefreshButton) {
      this.refreshClicked(true, false);
      const ts = new Date().getTime();
      OrderLib.setLastInquiryFetchTimestamp(ts);
    }

    onContactClick(phone);
  };

  render() {
    const { refreshClicked } = this.state;
    const { selectedContact, clearScreenState } = this.props;

    return (
      <div className="OrdersContactWrapper">
        <RefreshButton refreshClicked={this.refreshClicked} />
        <RenderList
          onContactClick={this.onContactClick}
          selectedContact={selectedContact}
          refreshClicked={refreshClicked}
          refreshCompleted={this.refreshClicked}
          clearScreenState={clearScreenState}
        />
      </div>
    );
  }
}

export default OrdersContactsScreen;
