import React from 'react';

import './styles.scss'

export default ({ excelColumnNames = [], selectedColumns = {}, onColumnNameClick } = {}) => {
  return (
    <div className={'columnNameContainer'}>
      {excelColumnNames.map(columnName => {
        const isSelected = !!selectedColumns[columnName];
        return (
          <div
            key={columnName}
            className={`columnName ${isSelected ? 'selectedColumnName' : ''}`}
            onClick={() => onColumnNameClick(columnName)}
          >
            {columnName}
          </div>
        );
      })}
    </div>
  );
};
