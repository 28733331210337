import React, { useState } from 'react';
import { BOTTOM_SHEET_TABS } from 'qs-data-manager/ProductDetails';
import BasicInfo from './BasicInfo';
import Inventory from './Inventory';
import PrivateNotes from './PrivateNotes';

import './styles.scss';

export default ({ activeTab, activeProductIds, isBulkEditing, activeProductId } = {}) => {
  if (activeTab === BOTTOM_SHEET_TABS.BASIC_INFO) {
    return (
      <BasicInfo
        activeProductIds={activeProductIds}
        isBulkEditing={isBulkEditing}
        activeProductId={activeProductId}
      />
    );
  }

  if (activeTab === BOTTOM_SHEET_TABS.INVENTORY) {
    return (
      <Inventory
        isBulkEditing={isBulkEditing}
        activeProductId={activeProductId}
        activeProductIds={activeProductIds}
      />
    );
  }

  if (activeTab === BOTTOM_SHEET_TABS.PRIVATE_NOTES) {
    return (
      <PrivateNotes
        isBulkEditing={isBulkEditing}
        activeProductId={activeProductId}
        activeProductIds={activeProductIds}
      />
    );
  }
};
