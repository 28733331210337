import React from 'react';

import './styles.scss'

export default () => {
  return (
    <div id={'ProductRowShimmer'}>
      <div id={'pictureUrl'}></div>
      <div id={'productName'}></div>
      <div id={'productDescription'}></div>
      <div id={'productPrice'}></div>
    </div>
  )
}
