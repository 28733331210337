import Listener from 'qs-helpers/Listener';
import { setUserMetaInCache } from 'qs-data-manager/User';

const listener = new Listener();
const LISTENER_ATTACHED = {};
const END_POINT = {
  USER_META: ({ userId }) => `users/${userId}`
};

const attachUserMetaListener = ({ userId }) => {
  if (!userId) {
    return;
  }

  const key = END_POINT.USER_META({ userId });

  if (LISTENER_ATTACHED[key]) {
    return;
  }

  LISTENER_ATTACHED[key] = true;
  listener.add({ key, type: 'value' }, snapshot => {
    const value = snapshot.val();
    setUserMetaInCache({data: value});
  });
};

const removeUserMetaListener = ({ userId }) => {
  if (!userId) {
    return;
  }

  const key = END_POINT.USER_META({ userId });
  listener.remove({ key, type: 'value' });
};

export { attachUserMetaListener, removeUserMetaListener };
