import React, { useCallback, useState, useEffect, Fragment } from 'react';
import './styles.scss';
import { getImageUrlFromPictureId, getPictureIdFromImageUrl } from 'qs-helpers';
import Loader from 'qs-components/Common/Loader';

export default ({ pictureIds = [] } = {}) => {
  const [imageError, setImageError] = useState(false);
  const [localSize, setLocalSize] = useState(100);

  const getPositionClass = useCallback(index => {
    if (index === 0) {
      return 'firstImageCatalogueRow';
    }

    if (index === 1) {
      return 'secondImageCatalogueRow';
    }

    if (index === 2) {
      return 'thirdImageCatalogueRow';
    }

    if (index === 3) {
      return 'fourthImageCatalogueRow';
    }

    return '';
  }, []);

  let ids = [...pictureIds];
  if (pictureIds.length !== 4) {
    for (let i = 0; i < 4 - pictureIds.length; i += 1) {
      ids.push({
        pictureId: '',
        prepared: true
      });
    }
  }

  const onError = useCallback(() => {
    localSize === 'FULL' ? setImageError(true) : setLocalSize('FULL');
  }, [localSize]);

  return (
    <div className={'catalogueProducts'}>
      {ids.map((pictureState, index) => {
        const key =
          pictureState && pictureState.pictureId
            ? `${pictureState.pictureId}`
            : `${index}${Date.now()}`;

        const positionClass = getPositionClass(index);

        const url = getImageUrlFromPictureId({
          size: localSize,
          pictureId: pictureState.pictureId
        });

        return (
          <Fragment key={key}>
            <div className={`imageContainer ${positionClass}`}>
              {!!pictureState && !!pictureState.pictureId ? (
                <div className={'loaderImageContainer'}>
                  <div className={'loaderContainer'}>
                    <Loader size={'extraSmall'} />
                  </div>
                  {!!pictureState.prepared ? (
                    <div className={'actualImageContainer'}>
                      <div className={'actualImage'} style={{ backgroundImage: `url(${url})` }} />
                      <img onError={onError} src={url} className={'image'} alt={''} />
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className={'loaderImageContainer dashed-border'}></div>
              )}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};
