import CacheRequest from './CacheRequest';
import { connector } from './ApiAndCacheConnector';
import {
  deleteProductsFromNative,
  createNewProductsInNative,
  updateExistingProductsInNative,
  addProductsInNative,
  changeProductsListInNative
} from './Dexie/ProductDexieHelpers';
import Api from 'qs-services/Api';
import { db } from 'qs-config/FirebaseConfig';
import {
  debouncer,
  DEBOUNCER_TYPE,
  isFileCSV,
  toggleGlobalLoader,
  getImageUrlFromPictureId,
  getCurrencySymbol,
  PRODUCT_LAST_FETCH_TS,
  getImageWidthHeight
} from 'qs-helpers';
import { CSV_UPLOADER_EB_KEY, getActiveCatalogueId, EXCEL_UPLOAD_META } from './Catalogues';
import eventbus from 'eventing-bus';
import Tags from 'qs-data-manager/Tags';
import CatalogueLib from 'qs-data-manager/Catalogues';
import { getCompanyCurrencyCode } from 'qs-data-manager/Company';
import {
  upsertCatalogueRowInNative,
  saveTagsChangesInNative
} from 'qs-data-manager/Dexie/CatalogueDexieHelpers';
import cloneDeep from 'lodash.clonedeep';
import { canUseFeature, FEATURE_LIST } from 'qs-data-manager/FeatureUsage';
import Mixpanel from 'qs-data-manager/Mixpanel';
import * as Sentry from '@sentry/browser';
import { reportError } from 'qs-helpers/ErrorReporting';
import imageUploadProcessor from 'qs-services/ImageUpload/ImageUploadProcessor';
import {
  IMAGE_UPLOAD_HELPER,
  CATALOGUE_PICTURE_PREPARED_MAP,
  UPLOAD_PRODUCT_HEADER,
  UPLOAD_PRODUCT_PICURES_HEADER,
  showProductHeaderProgressbar,
  showProductPicturesHeader
} from 'qs-helpers/ProcessUploadedImage';
import { getUniqueDeviceId } from 'qs-config/DeviceIdGenerator_APP_RUN_ENV';
import { getLocalElectronUrl } from 'qs-config/GeneralConfig_APP_RUN_ENV';

let PRODUCT_CHANGES_DEBOUNCER_ID = null;

const PRODUCT_ROW__META_DEBOUNCER = {
  key: 'PRODUCT_ROW__META_DEBOUNCER_KEY',
  timeInMs: 200,
  type: DEBOUNCER_TYPE.ADD
};

// { `catalogueId`: {} }
const PRODUCT_POSITION_MAP = {};

const PRODUCT_ROW_TYPES = {
  PRODUCT_ROW: {
    height: 80,
    outOfStockHeight: 101
  },
  overscanCount: 10
};

const PRODUCT_SEARCH = {
  PRODUCT_SEARCH_EB_KEY: 'PRODUCT_SEARCH_EB_KEY',
  PRODUCT_SEARCH_STATE: 'PRODUCT_SEARCH_STATE',
  PRODUCT_SEARCH_TERM: 'PRODUCT_SEARCH_TERM',
  FILTER_PRODUCT_STATE: 'FILTER_PRODUCT_STATE',
  FILTER_PRODUCT_ON: 'FILTER_PRODUCT_ON',

  TAG_FILTERING_EB_KEY: 'TAG_FILTERING_EB_KEY',
  TAG_FILTERING_STATE: 'TAG_FILTERING_STATE',
  TAG_API_RESP: 'TAG_API_RESP',
  FILTERED_PRODUCTS_VIA_TAGS: 'FILTERED_TAGS'
};
let productSearchTerm = null;
let priceFilterValues = null;

const FILTER_OPTIONS = {
  EQUALS: {
    title: 'equals',
    id: 'EQUALS'
  },
  RANGE: {
    title: 'range',
    id: 'RANGE'
  },
  LESS_THAN: {
    title: 'less than',
    id: 'LESS_THAN'
  },
  MORE_THAN: {
    title: 'more than',
    id: 'MORE_THAN'
  },

  // Reverse mapping to figure out id from title
  'more than': 'MORE_THAN',
  'less than': 'LESS_THAN',
  range: 'RANGE',
  equals: 'EQUALS'
};

const ACTIVE_PRODUCT_ID_META = {
  eventbusKey: 'ACTIVE_PRODUCT_ID_EB_KEY',
  productId: null
};

const UPLOAD_IMAGE_MODAL = {
  eventbusKey: 'UPLOAD_IMAGE_MODAL',
  meta: {}
};

const PRODUCT_META_REQUEST_SEND = {};

const PRODUCT_LIST_REF = {
  ref: null
};

const getProductListRef = () => PRODUCT_LIST_REF.ref;

const setProductListRef = ref => {
  PRODUCT_LIST_REF.ref = ref;
};

const OPERATION_STATUS = CacheRequest.OPERATION_STATUS;

const attachProductListListener = ({ listener, catalogueId }) => {
  const key = `${connector.PRODUCT_LIST_META.cacheKey}${catalogueId}`;
  CacheRequest.attachListener(key, listener);
};

const removeProductListListener = ({ listener, catalogueId }) => {
  const key = `${connector.PRODUCT_LIST_META.cacheKey}${catalogueId}`;
  CacheRequest.removeListener(key, listener);
};

const getProductList = ({ catalogueId }) => {
  const key = `${connector.PRODUCT_LIST_META.cacheKey}${catalogueId}`;
  const apiCall = connector.PRODUCT_LIST_META.apiFunction;
  CacheRequest.makeRequest(key, apiCall, {
    params: [catalogueId],
    options: {
      extraData: {
        catalogueId
      },
      nativeStorageKey: connector.PRODUCT_LIST_META.nativeStorageKey
    }
  });
};

const attachProductMetaListener = ({ listener, productId }) => {
  const key = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
  CacheRequest.attachListener(key, listener);
};

const removeProductMetaListener = ({ listener, productId }) => {
  const key = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
  CacheRequest.removeListener(key, listener);
};

const productMetaBatchCallback = (response, err) => {
  const sharedCacheKey = connector.PRODUCT_ROW_META.cacheKey;
  if (err) {
    console.error('ERROR while fetching batched catalogue meta', err);
    return;
  }

  if (!!response && !!response.productMeta && typeof response.productMeta === 'object') {
    Object.keys(response.productMeta).forEach(id => {
      const cache = response.productMeta[id];
      const key = `${sharedCacheKey}${id}`;
      PRODUCT_META_REQUEST_SEND[id] = true;
      CacheRequest.setCacheForKey(key, cache);
    });
  }
};

const productMetaDebounceCallback = (multipleBatchedProductIds = []) => {
  const allRenderedProductIds = {};
  const sharedCacheKey = connector.PRODUCT_ROW_META.cacheKey;

  multipleBatchedProductIds.forEach(batchedProductId => {
    batchedProductId.forEach(productId => {
      if (!productId) {
        console.error(
          'Product id not found while sending batch request for getting meta',
          productId
        );
        return;
      }

      const ifExists = PRODUCT_META_REQUEST_SEND[productId];
      if (!ifExists) {
        allRenderedProductIds[productId] = true;
      }
    });
  });

  const renderedProductIds = Object.keys(allRenderedProductIds);
  if (!renderedProductIds.length) {
    return;
  }
  const oneTimeUniqueKey = `PRODUCT_META_LISTENER_${new Date().getTime()}`;
  const apiCall = connector.PRODUCT_ROW_META.apiFunction;

  CacheRequest.makeRequest(oneTimeUniqueKey, apiCall, {
    params: [renderedProductIds],
    options: {
      isBatched: true,
      sharedCacheKey: sharedCacheKey,
      batchCallback: productMetaBatchCallback,
      nativeStorageKey: connector.PRODUCT_ROW_META.nativeStorageKey
    }
  });
};

const getProductMeta = ({ productIds } = {}) => {
  debouncer(
    { data: productIds, key: PRODUCT_ROW__META_DEBOUNCER.key },
    { time: PRODUCT_ROW__META_DEBOUNCER.timeInMs, type: PRODUCT_ROW__META_DEBOUNCER.type },
    productMetaDebounceCallback
  );
};

// HELPER FUNCTIONS

const previewCatalogue = async ({ catalogueId }) => {
  const loaderKey = `previewCatalogue${catalogueId}`;
  toggleGlobalLoader(loaderKey, true);

  const key = `${connector.CATALOGUE_LINK.cacheKey}${catalogueId}`;
  const linkMeta = CacheRequest.getCacheForKey(key);
  let link = '';
  if (linkMeta) {
    const { linkDomain, companySlug, catalogueSlug, randomSlug } = linkMeta;
    link = `${linkDomain}/${companySlug}/${catalogueSlug}/${randomSlug}`;
  } else {
    const resp = await CatalogueLib.createCataloguesLink([catalogueId]);
    ({ link } = resp);
  }

  if (!link) {
    console.log('Could not generate showcaseUrl for given catalogue', catalogueId);
    toggleGlobalLoader(loaderKey, false);
    return;
  }

  window.open(link, '_blank');
  toggleGlobalLoader(loaderKey, false);
};

const getProductListFromCache = catalogueId => {
  const key = `${connector.PRODUCT_LIST_META.cacheKey}${catalogueId}`;
  const meta = CacheRequest.getCacheForKey(key);
  if (!meta || !meta.productsList) {
    return null;
  }

  return meta.productsList
    .sort((p1, p2) => p1.position - p2.position)
    .map(product => ({
      productId: product.productId,
      stock: product.stock
    }));
};

const getProductMetaFromCache = productId => {
  const key = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
  const meta = CacheRequest.getCacheForKey(key);
  if (!meta) {
    return null;
  }

  const newMeta = { ...meta };
  const currencyCode = getCompanyCurrencyCode();
  newMeta.currencySymbol = getCurrencySymbol({ currencyCode });
  return newMeta;
};

const deleteProductsFromCache = (productIds, catalogueId, changeCatalogueRow) => {
  const productListCacheKey = connector.PRODUCT_LIST_META.cacheKey;
  const catalogueRowCacheKey = connector.CATALOGUE_ROW_META.cacheKey;
  const updates = {};

  const { productsList = [] } = CacheRequest.getCacheForKey(`${productListCacheKey}${catalogueId}`);
  const newProductList = productsList.filter(
    ({ productId } = {}) => productIds.indexOf(productId) < 0
  );
  updates.newProductList = newProductList;

  const top4ProductIds = newProductList.slice(0, 4);
  const top4PictureIds = top4ProductIds.map(({ productId } = {}) => {
    const key = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
    const product = CacheRequest.getCacheForKey(key);
    return product && product.pictureId
      ? {
          pictureId: product.pictureId,
          prepared: true
        }
      : {
          pictureId: '',
          prepared: false
        };
  });

  if (changeCatalogueRow) {
    const catalogueRow = CacheRequest.getCacheForKey(
      `${connector.CATALOGUE_ROW_META.cacheKey}${catalogueId}`
    );

    const newProductCount = catalogueRow.productCount - productIds.length;

    const newCatalogueRow = {
      ...catalogueRow,
      productCount: newProductCount,
      pictureIds: top4PictureIds
    };

    CacheRequest.setCacheForKey(`${catalogueRowCacheKey}${catalogueId}`, newCatalogueRow);
    updates.newProductCount = newProductCount;
    updates.newCatalogueRow = newCatalogueRow;
  }

  const productRowCacheKeys = productIds.map(id => `${connector.PRODUCT_ROW_META.cacheKey}${id}`);

  CacheRequest.setCacheForKey(`${productListCacheKey}${catalogueId}`, {
    productsList: newProductList
  });
  CacheRequest.deleteCacheForKeys(productRowCacheKeys);

  return updates;
};

const deleteProductsFromRemote = (productIds, catalogueId) => {
  return Api.deleteProducts(productIds, catalogueId);
};

const deleteProducts = async (productIds = [], id, extraData = {}) => {
  const loaderUniqueKey = `DELETE_PRODUCT_${productIds[0]}`;
  try {
    const { showLoader = true, makeRemoteChanges = true, changeCatalogueRow = true } = extraData;
    let catalogueId = id;
    if (!catalogueId) {
      catalogueId = getActiveCatalogueId();
    }

    if (!catalogueId) {
      console.log('deleteProducts: Catalogue id not found');
      return;
    }

    if (showLoader) {
      toggleGlobalLoader(loaderUniqueKey, true);
    }

    const changes = deleteProductsFromCache(productIds, catalogueId, changeCatalogueRow);

    const promises = [
      deleteProductsFromNative({ productIds, catalogueId, changeCatalogueRow }, changes)
    ];

    if (makeRemoteChanges) {
      promises.push(deleteProductsFromRemote(productIds, catalogueId));
    }

    await Promise.all(promises);
    CatalogueLib.getCatalogueTags(catalogueId);

    if (showLoader) {
      toggleGlobalLoader(loaderUniqueKey, false);
    }
  } catch (err) {
    toggleGlobalLoader(loaderUniqueKey, false);
    console.error('deleteProducts: Could not delete product', err);
    Sentry.captureException(err);
  }
};

// PRODUCT CREATION

// apiMeta  { productId: { product: product, pictureId } }
const createNewProductsInRemote = async ({ catalogueId, fileNameMappedTo }, changes) => {
  const productIds = Object.keys(changes.apiMeta || {});
  const products = [];

  const productChanges = [];
  const uuid = await getUniqueDeviceId();

  for (let i = 0; i < productIds.length; i += 1) {
    const productId = productIds[i];

    if (productId) {
      const { product, pictureId } = changes.apiMeta[productId];
      let width = 0;
      let height = 0;
      try {
        ({ width, height } = await getImageWidthHeight(product));
      } catch (err) {
        console.error('createNewProductsInRemote: Could not get width and height of image');
        reportError(err);
      }

      const updates = {};

      // Could have made this better
      if (fileNameMappedTo === 'title') {
        updates.name = changes.productMeta[productId].name;
      } else if (fileNameMappedTo === 'price') {
        updates.price = changes.productMeta[productId].price;
      } else if (fileNameMappedTo === 'description') {
        updates.description = changes.productMeta[productId].description;
      }

      productChanges.push({
        productId,
        updates
      });

      products.push({
        productId,
        default_picture_id: pictureId,
        pictures: [
          {
            pictureId,
            width,
            height,
            extension: 'jpg',
            localElectronUrl: getLocalElectronUrl(product),
            uuid
          }
        ]
      });
    } else {
      console.error(
        'createNewProductsInRemote: productId does not exist, could not create product'
      );
    }
  }

  await Api.createNewProducts(catalogueId, products);

  const promises = productChanges.map(data => {
    return Api.updateProduct(data);
  });

  await Promise.all(promises);
};

const createNewProductsInCache = (products, catalogueId, fileNameMappedTo, extraData) => {
  try {
    const productListCacheKey = `${connector.PRODUCT_LIST_META.cacheKey}${catalogueId}`;
    const productRowSharedCacheKey = `${connector.PRODUCT_ROW_META.cacheKey}`;
    const catalogueRowCacheKey = `${connector.CATALOGUE_ROW_META.cacheKey}${catalogueId}`;
    const apiMeta = {};

    const { productsList = [] } = CacheRequest.getCacheForKey(productListCacheKey) || {};
    const uploadedProductsListChanges = [];
    const uploadedProductsMetaChanges = {};
    const catalogueRowPictureUrls = [];
    const newPicturesToUpload = [];
    const prepared = false;

    const currencyCode = getCompanyCurrencyCode();

    for (let i = 0; i < products.length; i += 1) {
      const product = products[i];
      const productId = db.ref('products').push().key;
      const pictureId = db
        .ref('products')
        .child(productId)
        .child('pictures')
        .push().key;

      newPicturesToUpload.push({
        pictureId,
        product,
        path: product.path,
        prepared,
        productId,
        catalogueId,
        timestamp: new Date().getTime(),
        extraData
      });

      if (i < 4) {
        catalogueRowPictureUrls.push({
          pictureId,
          prepared: prepared
        });

        CATALOGUE_PICTURE_PREPARED_MAP[catalogueId] = {
          ...(CATALOGUE_PICTURE_PREPARED_MAP[catalogueId] || {}),
          [pictureId]: true
        };
      }

      let name = fileNameMappedTo === 'title' ? product.name : null;
      if (name) {
        name = name.split('.');
        name = name.slice(0, name.length - 1)[0];
      }
      const price = fileNameMappedTo === 'price' ? product.name : null;
      const description = fileNameMappedTo === 'description' ? product.name : null;
      const stock = 1;

      uploadedProductsMetaChanges[productId] = {
        productId,
        name,
        description,
        currencyCode,
        price,
        discount: null,
        stock,
        isPrepared: prepared,
        pictureId,
        pictureUrl: getImageUrlFromPictureId({ size: 'FULL', pictureId })
      };

      uploadedProductsListChanges.push({
        productId,
        position: i,
        stock
      });
      apiMeta[productId] = {
        product,
        pictureId
      };
      PRODUCT_META_REQUEST_SEND[productId] = true;
    }

    const modifiedExistingProductsList = productsList.map(row => {
      return {
        ...row,
        position: row.position + uploadedProductsListChanges.length
      };
    });
    const newProductList = [...uploadedProductsListChanges, ...modifiedExistingProductsList];

    let pictureIds = [...catalogueRowPictureUrls];

    const oldCataloguerRowCache = CacheRequest.getCacheForKey(catalogueRowCacheKey);

    if (catalogueRowPictureUrls.length < 4) {
      const prevIds = (oldCataloguerRowCache.pictureIds || []).slice(
        0,
        4 - catalogueRowPictureUrls.length
      );
      pictureIds = [...pictureIds, ...prevIds];
    }

    // Once image uploading starts, UPLOAD_PRODUCT_HEADER.meta is updated
    // with the correct count, hence read the value from it and persist in DB
    const catalogueImageMeta = UPLOAD_PRODUCT_HEADER.meta[catalogueId];
    const newCatalogueRowCache = {
      ...oldCataloguerRowCache,
      uploaded: catalogueImageMeta.uploaded,
      totalImagesToProcess: catalogueImageMeta.totalImages,
      productCount: newProductList.length,
      pictureIds
    };

    Object.keys(uploadedProductsMetaChanges).forEach(key => {
      const value = uploadedProductsMetaChanges[key];
      const cacheKey = `${productRowSharedCacheKey}${key}`;
      CacheRequest.setCacheForKey(cacheKey, value);
    });
    CacheRequest.setCacheForKey(productListCacheKey, { productsList: newProductList });
    CacheRequest.setCacheForKey(catalogueRowCacheKey, newCatalogueRowCache);

    return {
      newProductList,
      productMeta: uploadedProductsMetaChanges,
      newCatalogueRowCache,
      apiMeta,
      newPicturesToUpload
    };
  } catch (err) {
    console.log('createNewProductsInCache: Could not create new products in cache', err);
    Sentry.captureException(err);
  }
};

const createNewProductFromImages = async ({ images, catalogueId, fileNameMappedTo }) => {
  try {
    const extraData = {
      calledFrom: IMAGE_UPLOAD_HELPER.PRODUCT_UPLOAD.key
    };

    Mixpanel.sendEvent({ eventName: 'Product Uploaded' });
    const changes = createNewProductsInCache(images, catalogueId, fileNameMappedTo, extraData);

    try {
      await createNewProductsInRemote({ catalogueId, fileNameMappedTo }, changes);
    } catch (remoteError) {
      //Products could not be updated in remote at all. Don't persist anything. Fail the entire upload process
      console.error(
        'createNewProductsInRemote: Could not create new products in the remote',
        remoteError
      );
      Sentry.captureException(remoteError);
      return;
    }

    try {
      await createNewProductsInNative({ catalogueId }, changes);
    } catch (nativeStoreError) {
      console.error(
        'createNewProductsInNative: Could not create new products in the native storage',
        nativeStoreError
      );
      Sentry.captureException(nativeStoreError);
    }

    imageUploadProcessor.uploadImages(changes.newPicturesToUpload);
    const date = new Date().toISOString();
    setLastFetchDate({ date, catalogueId });
  } catch (err) {
    console.error('createNewProductFromImages: Could not create new products from images', err);
    Sentry.captureException(err);
  }
};

const uploadProducts = async ({ catalogueId, images, fileNameMappedTo = 'title' }) => {
  showProductHeaderProgressbar({ catalogueId, totalImages: images.length });
  await createNewProductFromImages({ images, catalogueId, fileNameMappedTo });
};

// PRODUCT CREATION HELPER

// File picker to uploadProducts connector
const uploadFromFilePicker = async ({ e, catalogueId }) => {
  try {
    const filesList = e.target.files;

    if (filesList) {
      const images = [];

      const isFirstFileCSV = isFileCSV((filesList[0] || {}).type);
      if (isFirstFileCSV) {
        const file = filesList[0];
        eventbus.publish(CSV_UPLOADER_EB_KEY, true, file);
        return;
      }

      // user can only upload png, jpg, csv, xlsx hence we only validate against csv
      Object.values(filesList).forEach(file => {
        const isCSV = isFileCSV(file.type);
        if (isCSV) {
          console.log('uploadFromFilePicker: cannot upload multiple CSVs');
        } else {
          images.push(file);
        }
      });

      const options = { extraProductCount: images.length };
      const canUse = canUseFeature(FEATURE_LIST.PRODUCTS.id, options);
      if (!canUse) {
        return;
      }

      openImageUploadModal({ files: images, catalogueId });
    }
  } catch (err) {
    console.log('uploadFromFilePicker: Could not upload imaegs', err);
    Sentry.captureException(err);
  }
};

// Drag and dropped images to uploadProducts connector
const handleDragAndDrop = async ({ images }) => {
  if (images && images.length) {
    const isFirstFileCSV = isFileCSV((images[0] || {}).type);
    if (isFirstFileCSV) {
      const file = images[0];
      eventbus.publish(CSV_UPLOADER_EB_KEY, true, file);
      return;
    }

    const options = { extraProductCount: images.length };
    const canUse = canUseFeature(FEATURE_LIST.PRODUCTS.id, options);
    if (!canUse) {
      return;
    }

    const catalogueId = getActiveCatalogueId();
    openImageUploadModal({ files: images, catalogueId });
  }
};

// On upload from CSV
const createProductsFromCSV = async ({
  headers,
  data,
  componentCSVMapping,
  shouldUpdateExisting = true
}) => {
  try {
    const headerPositions = {};
    const tempPictureKeyMap = {};
    const generatePictureKey = () => Date.now();
    const catalogueId = getActiveCatalogueId();
    const uuid = await getUniqueDeviceId();

    headers.forEach((headerValue, index) => {
      headerPositions[headerValue] = index;
    });

    const csvMeta = { headerPositions, componentCSVMapping };
    const products = [];

    data.forEach((row, index) => {
      // Excel headers
      if (index === 0) {
        return;
      }

      const meta = {};

      const imageMeta = getProductMetaFromCSV(csvMeta, row, {
        fieldId: EXCEL_UPLOAD_META.IMAGE.id,
        delimiter: ','
      });
      if (imageMeta.isMapped && imageMeta.data) {
        meta.imageUrl = imageMeta.data.split(',');
      }

      const nameMeta = getProductMetaFromCSV(csvMeta, row, { fieldId: EXCEL_UPLOAD_META.TITLE.id });
      if (nameMeta.isMapped) {
        meta.name = nameMeta.data;
      }

      let priceMeta = getProductMetaFromCSV(csvMeta, row, { fieldId: EXCEL_UPLOAD_META.PRICE.id });
      if (priceMeta.isMapped) {
        meta.price = parseStringToNumber(priceMeta.data);
      }

      let discountMeta = getProductMetaFromCSV(csvMeta, row, {
        fieldId: EXCEL_UPLOAD_META.DISCOUNT.id
      });
      if (discountMeta.isMapped) {
        meta.discount = parseStringToNumber(discountMeta.data);
      }

      const descriptionMeta = getProductMetaFromCSV(csvMeta, row, {
        fieldId: EXCEL_UPLOAD_META.DESCRIPTION.id
      });
      if (descriptionMeta.isMapped) {
        meta.description = descriptionMeta.data;
      }

      let tagsMeta = getProductMetaFromCSV(csvMeta, row, {
        fieldId: EXCEL_UPLOAD_META.TAGS.id,
        delimiter: ','
      });
      if (tagsMeta.data && tagsMeta.isMapped) {
        meta.tags = tagsMeta.data.split(',');
      }

      let inventoryMeta = getProductMetaFromCSV(csvMeta, row, {
        fieldId: EXCEL_UPLOAD_META.INVENTORY.id
      });
      if (inventoryMeta.isMapped) {
        meta.inventory = parseStringToNumber(inventoryMeta.data);
      }

      let notesMeta = getProductMetaFromCSV(csvMeta, row, {
        fieldId: EXCEL_UPLOAD_META.NOTES.id
      });

      if (notesMeta.isMapped) {
        meta.notes = notesMeta.data;
      }

      products.push(meta);
    });

    eventbus.publish(CSV_UPLOADER_EB_KEY, null, null, true);
    Mixpanel.sendEvent({ eventName: 'Excel uploaded' });
    const {
      updatedProducts,
      newProductList,
      productCount,
      top4PictureUrls,
      catalogueTags
    } = await Api.uploadExcel({
      productsMeta: products,
      shouldUpdateExisting,
      catalogueId,
      uuid
    });

    await updateLocalAfterUpload({
      updatedProducts,
      newProductList,
      productCount,
      top4PictureUrls,
      catalogueId,
      catalogueTags
    });

    const date = new Date().toISOString();
    setLastFetchDate({ date, catalogueId });
    eventbus.publish(CSV_UPLOADER_EB_KEY, false, null, false);
  } catch (err) {
    console.error('createProductsFromCSV: error', err);
    Sentry.captureException(err);
  }
};

const updateLocalAfterUpload = async ({
  updatedProducts,
  newProductList,
  productCount,
  top4PictureUrls,
  catalogueId,
  catalogueTags
}) => {
  try {
    const nativeChangePromise = [];
    const catalogueRowCacheKey = `${connector.CATALOGUE_ROW_META.cacheKey}${catalogueId}`;
    const catalogueRowCache = CacheRequest.getCacheForKey(catalogueRowCacheKey);
    const newCatalogueRowCache = cloneDeep(catalogueRowCache);
    newCatalogueRowCache.productCount = productCount;
    newCatalogueRowCache.pictureIds = top4PictureUrls;
    CacheRequest.setCacheForKey(catalogueRowCacheKey, newCatalogueRowCache);

    const productRowSharedKey = connector.PRODUCT_ROW_META.cacheKey;
    Object.keys(updatedProducts).forEach(productId => {
      const key = `${productRowSharedKey}${productId}`;
      const product = updatedProducts[productId];
      CacheRequest.setCacheForKey(key, product);
    });
    const productsNativeFormat = Object.values(updatedProducts);

    const productListCacheKey = `${connector.PRODUCT_LIST_META.cacheKey}${catalogueId}`;
    CacheRequest.setCacheForKey(productListCacheKey, { productsList: newProductList });
    updateProductListHeight({ index: 0 });

    if (catalogueTags) {
      const catalogueTagCacheKey = `${connector.CATALOGUE_TAGS.cacheKey}${catalogueId}`;
      CacheRequest.setCacheForKey(catalogueTagCacheKey, { tags: catalogueTags });
      nativeChangePromise.push(saveTagsChangesInNative({ catalogueId, tags: catalogueTags }));
    }

    await Promise.all([
      upsertCatalogueRowInNative({ [catalogueId]: catalogueRowCache }),
      updateExistingProductsInNative(productsNativeFormat),
      changeProductsListInNative({ newProductList, catalogueId }),
      ...nativeChangePromise
    ]);
  } catch (err) {
    console.log('err', err);
    reportError(err);
  }
};

const getProductMetaFromCSV = (
  { headerPositions, componentCSVMapping },
  row,
  { fieldId, delimiter = '' }
) => {
  let data = null;
  if (!componentCSVMapping[fieldId] || !Object.keys(componentCSVMapping[fieldId] || {}).length) {
    return {
      data,
      isMapped: false
    };
  }
  const mappedValues = Object.keys(componentCSVMapping[fieldId]);
  mappedValues.forEach(val => {
    const position = headerPositions[val];
    const value = row[position];

    if (
      fieldId === EXCEL_UPLOAD_META.INVENTORY.id ||
      fieldId === EXCEL_UPLOAD_META.PRICE.id ||
      fieldId === EXCEL_UPLOAD_META.DISCOUNT.id
    ) {
      if (typeof value !== 'number') {
        return;
      }
    } else {
      if (!value) {
        return;
      }
    }

    if (fieldId === EXCEL_UPLOAD_META.DESCRIPTION.id) {
      const excelColumnName =
        val.toLocaleLowerCase() === 'description' ||
        val.toLocaleLowerCase() === 'product description'
          ? ''
          : `${val}: `;
      data = data ? `${data}${excelColumnName}${value}\n` : `${excelColumnName}${value}\n`;
    } else {
      data = data ? `${data}${delimiter}${value}` : `${value}`;
    }
  });
  return {
    data,
    isMapped: true
  };
};

const parseStringToNumber = stringValue =>
  stringValue ? (isNaN(Number(stringValue)) ? null : Number(stringValue)) : stringValue;

// setter and getter for value in the product search textbox
const setProductSearchTerm = searchTerm => {
  productSearchTerm = searchTerm;
};
const getProductSearchTerm = () => productSearchTerm;
const resetProductSearchTerm = () => {
  setProductSearchTerm(null);
};

// setter and getter for price filter
const setProductPriceFilter = value => {
  priceFilterValues = value;
};
const getProductPriceFilter = () => priceFilterValues;

const resetProductPriceFilter = () => {
  setProductPriceFilter(null);
};

const getPriceFilterSelectedOption = () => {
  const value = getProductPriceFilter();
  return value && value.type ? FILTER_OPTIONS[value.type] : FILTER_OPTIONS.EQUALS.id;
};

const getPriceFilterInputValues = () => {
  const value = getProductPriceFilter();
  return value && value.type
    ? {
        price: `${value.price}`,
        minPrice: `${value.minPrice}`,
        maxPrice: `${value.maxPrice}`
      }
    : {
        price: '',
        minPrice: '',
        maxPrice: ''
      };
};

const changeProductListInLocal = ({ productsList, catalogueId }) => {
  const cacheKey = `${connector.PRODUCT_LIST_META.cacheKey}${catalogueId}`;
  CacheRequest.setCacheForKey(cacheKey, {
    productsList
  });
  updateProductListHeight({ index: 0 });
};

const changeProductsList = async ({ productsList }, extraData = {}) => {
  const catalogueId = getActiveCatalogueId();
  changeProductListInLocal({ productsList, catalogueId });
  changeProductsListInNative({ productsList, catalogueId });
};

// ACTIVE PRODUCT IDs helpers
const setActiveProductId = productId => {
  ACTIVE_PRODUCT_ID_META.productId = productId;
  eventbus.publish(ACTIVE_PRODUCT_ID_META.eventbusKey, {
    productId
  });
};

const getActiveProductId = () => ACTIVE_PRODUCT_ID_META.productId;

const resetActiveProductId = () => {
  ACTIVE_PRODUCT_ID_META.productId = null;
  eventbus.publish(ACTIVE_PRODUCT_ID_META.eventbusKey, {
    productId: null
  });
};

const isProductSelected = productId => {
  const activeProductId = ACTIVE_PRODUCT_ID_META.productId ? ACTIVE_PRODUCT_ID_META.productId : '';

  return productId === activeProductId;
};

const reorderProductInCache = ({ newProductList, catalogueId, oldIndex, newIndex }) => {
  const key = `${connector.PRODUCT_LIST_META.cacheKey}${catalogueId}`;
  const nativeListFormat = newProductList;
  const listApiFormat = [];

  newProductList.forEach((row, index) => {
    listApiFormat.push({ id: row.productId, position: index });
  });

  const newCache = { productsList: nativeListFormat };
  CacheRequest.setCacheForKey(key, newCache);

  if (oldIndex < 4 || newIndex < 4) {
    const top4Ids = nativeListFormat.slice(0, 4).map(({ productId }) => productId);
    const urls = [];
    const pictureStatus = top4Ids
      .map(id => {
        const key = `${connector.PRODUCT_ROW_META.cacheKey}${id}`;
        const cache = CacheRequest.getCacheForKey(key);
        urls.push(cache.pictureUrl);
        return {
          pictureId: cache.pictureId,
          prepared: true
        };
      })
      .filter(url => !!url);

    const catalogueRowKey = `${connector.CATALOGUE_ROW_META.cacheKey}${catalogueId}`;
    const catalogueRowCache = CacheRequest.getCacheForKey(catalogueRowKey);
    const catalogueRowNewCache = {
      ...catalogueRowCache,
      pictureUrls: urls,
      pictureIds: pictureStatus
    };

    CacheRequest.setCacheForKey(catalogueRowKey, catalogueRowNewCache);
  }

  return {
    newList: nativeListFormat,
    listApiFormat
  };
};

const changeProductListInRemote = ({ productsList, catalogueId }) => {
  Api.reorderProductInRemote({ productsList, catalogueId });
};

const reorderProduct = async ({ newProductList, catalogueId, oldIndex, newIndex }) => {
  const loaderKey = `reorderProduct${catalogueId}`;

  toggleGlobalLoader(loaderKey, true);
  const changes = reorderProductInCache({ newProductList, catalogueId, oldIndex, newIndex });
  setProductPositionMap({ productList: newProductList, catalogueId });

  await Promise.all([
    changeProductsListInNative({ productsList: changes.newList, catalogueId }),
    changeProductListInRemote({ productsList: changes.listApiFormat, catalogueId })
  ]);

  const date = new Date().toISOString();
  setLastFetchDate({ date, catalogueId });

  toggleGlobalLoader(loaderKey, false);
};

const getImageUploadingMeta = ({ catalogueId }) => {
  if (UPLOAD_PRODUCT_HEADER.meta[catalogueId]) {
    const meta = UPLOAD_PRODUCT_HEADER.meta[catalogueId];
    let percent = Math.round((meta.uploaded / meta.totalImages) * 100);
    percent = percent > 0 ? percent : 0;
    const remaining = meta.totalImages - meta.uploaded;

    return {
      shouldShow: true,
      percent,
      remaining
    };
  } else {
    return {
      shouldShow: false,
      percent: 0,
      remaining: 0
    };
  }
};

const openImageUploadModal = ({ files, catalogueId }) => {
  const file = files[0];
  const noOfFiles = (files || []).length;

  const canFileNameBePrice = files.filter(({ name }) => {
    const splitedName = name.split('.');
    const fileName = splitedName.slice(0, splitedName.length - 1).join('');
    return !isNaN(fileName);
  }).length;

  UPLOAD_IMAGE_MODAL.meta = {
    file,
    noOfFiles,
    canFileNameBePrice,
    shouldShow: true,
    catalogueId,
    allImages: files
  };
  eventbus.publish(UPLOAD_IMAGE_MODAL.eventbusKey, UPLOAD_IMAGE_MODAL.meta);
};

const closeImageUploadModal = () => {
  UPLOAD_IMAGE_MODAL.meta = {};
  eventbus.publish(UPLOAD_IMAGE_MODAL.eventbusKey, UPLOAD_IMAGE_MODAL.meta);
};

// Upload more product images

const uploadPicturesToProduct = async ({ e, productId }) => {
  try {
    const loaderKey = `uploadPicturesToProduct${Date.now()}`;

    toggleGlobalLoader(loaderKey, true);
    const images = [];
    const filesList = e.target.files;
    Object.values(filesList).forEach(file => {
      const isCSV = isFileCSV(file.type);
      if (isCSV) {
        console.log('uploadFromFilePicker: cannot upload multiple CSVs');
      } else {
        images.push(file);
      }
    });

    const extraData = {
      calledFrom: IMAGE_UPLOAD_HELPER.PRODUCT_EXTRA_PICTURE_UPLOAD.key
    };

    showProductPicturesHeader({ productId, totalImages: images.length });
    const changes = await changeProductPicturesInCache({ productId, images, extraData });

    try {
      await addImageToRemote({ apiMeta: changes.apiMeta, productId });
    } catch (remoteError) {
      //Products could not be updated in remote at all. Don't persist anything. Fail the entire upload process
      console.error('addImageToRemote: Could not create new products in the remote', remoteError);
      Sentry.captureException(remoteError);
      return;
    }

    imageUploadProcessor.uploadImages(changes.newPicturesToUpload);

    toggleGlobalLoader(loaderKey, false);
  } catch (err) {
    console.error('uploadPicturesToProduct: Could not upload pictures to product', e);
    Sentry.captureException(err);
  }
};

const addImageToRemote = ({ apiMeta, productId }) => {
  const pictures = apiMeta.pictureMeta;
  return Api.addNewPicture({ pictures, productId });
};

const changeProductPicturesInCache = async ({ productId, images, extraData }) => {
  const basicInfoCacheKey = `${connector.BASIC_INFO.cacheKey}${productId}`;

  const basicInfoCache = CacheRequest.getCacheForKey(basicInfoCacheKey);
  const newBasicInfoCache = cloneDeep(basicInfoCache);

  const cacheFormatOfPictures = {};
  const apiMeta = {
    pictureMeta: []
  };
  const newPicturesToUpload = [];

  for (let i = 0; i < images.length; i += 1) {
    const image = images[i];

    const pictureId = db
      .ref('products')
      .child(productId)
      .child('pictures')
      .push().key;

    const url = getImageUrlFromPictureId({ size: 'FULL', pictureId });
    const { width, height } = await getImageWidthHeight(image);
    const pictureMeta = {
      id: pictureId,
      url,
      extension: 'jpg',
      prepared: false,
      width,
      height
    };

    const apiData = {
      pictureId,
      url,
      extension: 'jpg',
      prepared: false,
      width,
      height
    };

    newPicturesToUpload.push({
      pictureId,
      product: image,
      path: image.path,
      prepared: false,
      productId,
      catalogueId: null,
      timestamp: new Date().getTime(),
      extraData
    });

    apiMeta.pictureMeta.push(apiData);
    cacheFormatOfPictures[pictureId] = pictureMeta;
  }

  newBasicInfoCache.pictures = {
    ...(newBasicInfoCache && newBasicInfoCache.pictures ? newBasicInfoCache.pictures : {}),
    ...cacheFormatOfPictures
  };

  CacheRequest.setCacheForKey(basicInfoCacheKey, newBasicInfoCache);

  return {
    apiMeta,
    newPicturesToUpload
  };
};

const getProductPicturesUploadingMeta = ({ productId }) => {
  if (UPLOAD_PRODUCT_PICURES_HEADER.meta[productId]) {
    const meta = UPLOAD_PRODUCT_PICURES_HEADER.meta[productId];
    let percent = Math.round((meta.uploaded / meta.totalImages) * 100);
    percent = percent > 0 ? percent : 0;
    const remaining = meta.totalImages - meta.uploaded;

    return {
      shouldShow: true,
      percent,
      remaining
    };
  } else {
    return {
      shouldShow: false,
      percent: 0,
      remaining: 0
    };
  }
};

// FIREBASE CHANGES LISTENER CALLBACK

const setLastFetchDate = ({ date, catalogueId }) => {
  PRODUCT_LAST_FETCH_TS.ts[catalogueId] = date;
  const key = PRODUCT_LAST_FETCH_TS.localstorageKey(catalogueId);
  const stringifiedDate = JSON.stringify(date);
  localStorage.setItem(key, stringifiedDate);
};

const getLastFetchDate = ({ catalogueId }) => {
  return PRODUCT_LAST_FETCH_TS.ts && PRODUCT_LAST_FETCH_TS.ts[catalogueId]
    ? PRODUCT_LAST_FETCH_TS.ts[catalogueId]
    : null;
};

const handleProductChangeListener = ({ timestamp, catalogueId }) => {
  const localTs = getLastFetchDate({ catalogueId });
  if (!localTs) {
    setLastFetchDate({ date: timestamp, catalogueId });
    return;
  }

  if (localTs < timestamp) {
    if (PRODUCT_CHANGES_DEBOUNCER_ID) {
      clearTimeout(PRODUCT_CHANGES_DEBOUNCER_ID);
    }

    PRODUCT_CHANGES_DEBOUNCER_ID = setTimeout(() => {
      onProductScreenChange({ newTimestamp: timestamp, catalogueId, prevTimestamp: localTs });
    }, 2000);
  }
};

const onProductScreenChange = async ({ newTimestamp, catalogueId, prevTimestamp }) => {
  try {
    PRODUCT_CHANGES_DEBOUNCER_ID = null;
    const { changes } = await Api.productsListScreenChanges(prevTimestamp, catalogueId);
    setLastFetchDate({ date: newTimestamp, catalogueId });
    let shouldChangeList = true;

    if (changes && changes.inserted && Object.keys(changes.inserted).length) {
      const products = Object.values(changes.inserted);
      shouldChangeList = false;
      addProductsFirebaseCallback({ products, catalogueId, productsList: changes.productList });
    }

    if (changes && changes.removed && Object.keys(changes.removed).length) {
      try {
        const deletedProducts = Object.keys(changes.removed);
        shouldChangeList = false;
        deleteProducts(deletedProducts, catalogueId, {
          showLoader: false,
          makeRemoteChanges: false,
          changeCatalogueRow: false
        });
      } catch (err) {
        console.error('onProductScreenChange: Could not delete products', err);
        Sentry.captureException(err);
      }
    }

    if (changes && shouldChangeList && changes.productList) {
      changeProductsList({ productsList: changes.productList });
    }

    if (changes && changes.updated && Object.keys(changes.updated).length) {
      const products = Object.values(changes.updated || {}) || [];
      updateExistingProducts({ products, catalogueId });
    }

    if (changes && changes.tags && Object.keys(changes.tags).length) {
      Tags.onChangeCatalogueTagsFromRemote({ tags: changes.tags, catalogueId });
    }

    if (changes && (changes.companySlug || changes.catalogueSlug || changes.randomSlug)) {
      updateSlug({
        companySlug: changes.companySlug,
        catalogueSlug: changes.catalogueSlug,
        randomSlug: changes.randomSlug,
        catalogueId
      });
    }
  } catch (err) {
    console.log('onProductScreenChange: Could not make remote changes', err);
    Sentry.captureException(err);
  }
};

const addProductsFirebaseCallback = ({ products, catalogueId, productsList }) => {
  try {
    const changes = addProductsInLocal({ products, catalogueId, productsList });
    addProductsInNative({
      products: changes.products,
      catalogueId,
      productsList: changes.productsList,
      catalogueMeta: changes.catalogueMeta
    });
  } catch (err) {
    console.log('addProductsFirebaseCallback: Could not add new products', err);
    Sentry.captureException(err);
  }
};

const addProductsInLocal = ({ products, catalogueId, productsList }) => {
  const productListSharedKey = connector.PRODUCT_LIST_META.cacheKey;
  const productMetaSharedKey = connector.PRODUCT_ROW_META.cacheKey;

  const productListCacheKey = `${productListSharedKey}${catalogueId}`;
  const catalogueRowMetaCacheKey = `${connector.CATALOGUE_ROW_META.cacheKey}${catalogueId}`;
  const productsForNative = [];

  (products || []).forEach(product => {
    const key = `${productMetaSharedKey}${product.productId}`;
    const cache = CacheRequest.getCacheForKey(key);
    let newCache = cloneDeep(cache);
    newCache = {
      ...newCache,
      ...product
    };

    productsForNative.push(newCache);
    CacheRequest.setCacheForKey(key, newCache);
  });

  const top4Products = productsList.slice(0, 4);
  const top4Picture = [];
  top4Products.forEach(({ productId }) => {
    const cache = CacheRequest.getCacheForKey(`${productMetaSharedKey}${productId}`) || {};
    const pictureId = cache.pictureId;
    if (!pictureId) {
      return;
    }

    top4Picture.push({
      pictureId,
      prepared: true
    });
  });

  CacheRequest.setCacheForKey(productListCacheKey, { productsList });

  const oldCatalogueRowMeta = CacheRequest.getCacheForKey(catalogueRowMetaCacheKey);

  const newCatalogueRowMeta = {
    ...oldCatalogueRowMeta,
    productCount: productsList.length,
    pictureIds: top4Picture
  };
  CacheRequest.setCacheForKey(catalogueRowMetaCacheKey, newCatalogueRowMeta);

  return {
    productsList,
    products: productsForNative,
    catalogueMeta: newCatalogueRowMeta
  };
};

// Updating top4 products should also change catalogue row
const updateExistingProducts = async ({ products = [], catalogueId }) => {
  const changes = updateExistingProductsInLocal(products, catalogueId);
  updateExistingProductsInNative(changes.nativeCacheChanges);
};

const updateExistingProductsInLocal = (products = [], catalogueId) => {
  const nativeCacheChanges = [];
  const sharedCacheKey = connector.PRODUCT_ROW_META.cacheKey;
  let smallestUpdatePosition = 0;

  products.forEach(product => {
    const productId = product.productId;
    const key = `${sharedCacheKey}${productId}`;
    const cache = CacheRequest.getCacheForKey(key);
    let oldCache = cloneDeep(cache);

    const productListObject = (PRODUCT_POSITION_MAP[catalogueId] || {})[productId];
    const productPosition = (productListObject || {}).position || 0;
    smallestUpdatePosition =
      productPosition < smallestUpdatePosition
        ? productListObject.position
        : smallestUpdatePosition;

    productListObject.stock = typeof product.stock === 'number' ? product.stock : 1;

    const newCache = {
      ...oldCache,
      ...product
    };
    nativeCacheChanges.push(newCache);
    CacheRequest.setCacheForKey(key, newCache);
  });

  updateProductListHeight({ index: smallestUpdatePosition });

  return {
    nativeCacheChanges
  };
};

const updateSlug = ({ companySlug, catalogueSlug, randomSlug, catalogueId }) => {
  const key = `${connector.CATALOGUE_LINK.cacheKey}${catalogueId}`;
  const linkMeta = CacheRequest.getCacheForKey(key);

  const updates = {};

  if (companySlug) {
    updates.companySlug = companySlug;
  }

  if (catalogueSlug) {
    updates.catalogueSlug = catalogueSlug;
  }

  if (randomSlug) {
    updates.randomSlug = randomSlug;
  }

  const newCache = {
    ...linkMeta,
    ...updates
  };

  CacheRequest.setCacheForKey(key, newCache);
};

const setProductStockCount = ({ stock, productIds }) => {
  const catalogueId = getActiveCatalogueId();
  let index = null;
  let count = 0;

  const listKey = `${connector.PRODUCT_LIST_META.cacheKey}${catalogueId}`;
  const { productsList } = CacheRequest.getCacheForKey(listKey);

  for (let i = 0; i < productsList.length; i += 1) {
    const productId = productsList[i].productId;
    if (productIds.indexOf(productId) > -1) {
      if (typeof index !== 'number') {
        index = i;
      }
      productsList[i] = { ...productsList[i], stock };
      count += 1;

      if (count === productIds.length) {
        break;
      }
    }
  }

  const nativeProductRowChanges = [];
  productIds.forEach(productId => {
    const key = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
    const cache = CacheRequest.getCacheForKey(key);
    cache.stock = stock;
    nativeProductRowChanges.push(cache);
    CacheRequest.setCacheForKey(key, cache);
  });
  CacheRequest.setCacheForKey(listKey, { productsList });

  setProductPositionMap({ productList: productsList, catalogueId });

  updateProductListHeight({ index });

  updateExistingProductsInNative(nativeProductRowChanges);
  changeProductsListInNative({ productsList, catalogueId });
};

const updateProductListHeight = ({ index }) => {
  const ref = getProductListRef();
  if (ref) {
    ref.resetAfterIndex(index);
  }
};

const setProductPositionMap = ({ productList, catalogueId }) => {
  productList.forEach(row => {
    PRODUCT_POSITION_MAP[catalogueId] = {
      ...(PRODUCT_POSITION_MAP[catalogueId] || {}),
      [row.productId]: row
    };
  });
};

const getProductRows = ({ productIds, catalogueId }) => {
  return productIds.map(id => PRODUCT_POSITION_MAP[catalogueId][id]).filter(row => !!row);
};

const resetSearchPriceFilterAndTags = () => {
  resetProductPriceFilter();
  resetProductSearchTerm();
};

const deleteDiscountFromProducts = async ({ productIds }) => {
  const loaderKey = `deleteDiscountFromProducts${Date.now()}`;
  toggleGlobalLoader(loaderKey, true);

  const sharedKey = connector.PRODUCT_ROW_META.cacheKey;
  const productNativeChange = [];

  productIds.forEach(id => {
    const key = `${sharedKey}${id}`;
    const cache = CacheRequest.getCacheForKey(key);
    const newCache = { ...cache };
    delete newCache.discount;
    productNativeChange.push(newCache);
    CacheRequest.setCacheForKey(key, newCache);
  });

  // If required convert api call to accept multiple products ids
  await Promise.all([
    updateExistingProductsInNative(productNativeChange),
    Api.updateProduct({ productId: productIds[0], updates: { discount: null } })
  ]);
  toggleGlobalLoader(loaderKey, false);
};

// Mixpanel Helpers
const getProductPropsForMixpanel = ({ productId }) => {
  const props = {};

  const key = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
  const cache = CacheRequest.getCacheForKey(key);
  const currencyCode = getCompanyCurrencyCode();

  if (currencyCode) {
    props.product_currency = currencyCode;
  }

  props.product_name = cache.name;
  props.product_discount = cache.discount;
  props.product_description = cache.description;

  props.product_pictureId = cache.pictureId;
  props.product_prepared = cache.isPrepared;
  props.product_id = cache.productId;

  return props;
};

export {
  OPERATION_STATUS,
  PRODUCT_ROW_TYPES,
  PRODUCT_SEARCH,
  FILTER_OPTIONS,
  ACTIVE_PRODUCT_ID_META,
  UPLOAD_IMAGE_MODAL,
  PRODUCT_LIST_REF,
  PRODUCT_POSITION_MAP,
  attachProductListListener,
  removeProductListListener,
  getProductList,
  attachProductMetaListener,
  removeProductMetaListener,
  getProductMeta,
  previewCatalogue,
  uploadFromFilePicker,
  uploadProducts,
  getProductMetaFromCache,
  getProductListFromCache,
  deleteProducts,
  setProductSearchTerm,
  getProductSearchTerm,
  resetProductSearchTerm,
  createProductsFromCSV,
  setActiveProductId,
  getActiveProductId,
  resetActiveProductId,
  isProductSelected,
  reorderProduct,
  getImageUploadingMeta,
  handleDragAndDrop,
  openImageUploadModal,
  closeImageUploadModal,
  uploadPicturesToProduct,
  getProductPicturesUploadingMeta,
  updateProductListHeight,
  getProductListRef,
  setProductListRef,
  // Firebase listener handler
  setLastFetchDate,
  getLastFetchDate,
  handleProductChangeListener,
  setProductStockCount,
  setProductPositionMap,
  getProductRows,
  setProductPriceFilter,
  getProductPriceFilter,
  resetProductPriceFilter,
  getPriceFilterSelectedOption,
  getPriceFilterInputValues,
  resetSearchPriceFilterAndTags,
  deleteDiscountFromProducts,
  getProductPropsForMixpanel
};
