import React, { useState, useCallback } from 'react';
import './styles.scss';

import CatalogueHeader from './CatalogueHeader';
import SearchHeader from './SearchHeader';
import EditCatalogueHeader from './EditCatalogueHeader';

const CATALOGUE_HEADER = 'CATALOGUE_HEADER';
const SEARCH_HEADER = 'SEARCH_HEADER';
const EDIT_CATALOGUE_HEADER = 'EDIT_CATALOGUE_HEADER';

export default () => {
  const [headerType, setHeaderType] = useState(CATALOGUE_HEADER);

  const showSearchHeader = useCallback(() => {
    setHeaderType(SEARCH_HEADER);
  }, []);

  const showCatalogueHeader = useCallback(() => {
    setHeaderType(CATALOGUE_HEADER);
  }, []);

  const showEditCatalogueHeader = useCallback(() => {
    setHeaderType(EDIT_CATALOGUE_HEADER);
  }, []);

  if (headerType === CATALOGUE_HEADER) {
    return (
      <CatalogueHeader
        showSearchHeader={showSearchHeader}
        showEditCatalogueHeader={showEditCatalogueHeader}
      />
    );
  }

  if (headerType === SEARCH_HEADER) {
    return <SearchHeader showCatalogueHeader={showCatalogueHeader} />;
  }

  if (headerType === EDIT_CATALOGUE_HEADER) {
    return <EditCatalogueHeader onBackClick={showCatalogueHeader} />;
  }

  return null;
};
