import React from 'react';
import Tag from 'qs-data-manager/Tags';

import './styles.scss';

export default ({ tag, tagCountVisible, onToggleTag }) => {
  const isSelected = Tag.isTagSelected(tag.title);
  return (
    <div
      className={`popoverTag ${isSelected && 'popoverSelectedTag'}`}
      onClick={() => onToggleTag(tag.title, isSelected)}
    >
      <span className={`popoverTagText ${!tag.v && 'inactivePopoverTagText'}`}>
        {tag.title}

        {tagCountVisible && tag.productCount ? `(${tag.productCount})` : ''}
      </span>
    </div>
  );
};
