import React, { useState, useEffect, useCallback, useRef } from 'react';
import eventbus from 'eventing-bus';
import {
  SAVE_BUTTON_META,
  saveProductDetailsChanges,
  isCurrentImageDefault,
  deleteCurrentImage,
  RENDERED_PRODUCT_IMAGE_META,
  setPictureAsDefault
} from 'qs-data-manager/ProductDetails';
import { getPictureIdFromImageUrl, getElementParents } from 'qs-helpers';

import './styles.scss';
import { ReactComponent as TrashIcon } from 'qs-assets/Media/trash.svg';
import { deleteProducts, resetActiveProductId } from 'qs-data-manager/Products';
import { getActiveCatalogueId } from 'qs-data-manager/Catalogues';

export default ({ activeProductId } = {}) => {
  const [saveButtonState, setSaveButtonState] = useState({});
  const [deleteModal, setDeleteModal] = useState({
    showDeleteModal: false,
    showDeleteButton: true
  });
  const [isDefaultImage, setIsDefaultImage] = useState(() => isCurrentImageDefault());

  // Effect for when productIds change
  useEffect(() => {
    setDeleteModal({
      showDeleteModal: false,
      showDeleteButton: true
    });
    setSaveButtonState({});
    const isDefault = isCurrentImageDefault();
    setIsDefaultImage(isDefault);
  }, [activeProductId]);

  const onSaveClick = useCallback(e => {
    if (e) {
      e.stopPropagation();
    }
    setSaveButtonState(prevState => {
      if (Object.keys(prevState).length) {
        saveProductDetailsChanges({ data: prevState });
      }
      return {};
    });
  }, []);

  // Effect for when some data is changed in the bottom sheet
  useEffect(() => {
    const removeEventbus = eventbus.on(
      SAVE_BUTTON_META.eventbusKey,
      ({ id, hasChanged, data, eventType = SAVE_BUTTON_META.eventType.PRODUCT_META.id }) => {
        if (eventType === SAVE_BUTTON_META.eventType.AUTO_SAVE.id) {
          onSaveClick();
          return;
        }

        if (!hasChanged) {
          setSaveButtonState(prevState => {
            const newState = { ...prevState };
            delete newState[id];
            return newState;
          });
          return;
        }

        setSaveButtonState(prevState => ({
          ...prevState,
          [id]: data
        }));
      }
    );

    return () => removeEventbus();
  }, [onSaveClick]);

  useEffect(() => {
    const removeEventbus = eventbus.on(RENDERED_PRODUCT_IMAGE_META.eventbusKey, () => {
      const isDefaultImage = isCurrentImageDefault();
      setIsDefaultImage(isDefaultImage);
    });

    return removeEventbus;
  }, []);

  const setAsDefault = useCallback(() => {
    const pictureUrl = RENDERED_PRODUCT_IMAGE_META.meta.imageUrl;
    const pictureId = getPictureIdFromImageUrl({ url: pictureUrl });
    setPictureAsDefault({ pictureUrl, pictureId });
    setIsDefaultImage(true);
  }, []);

  const deleteRenderedImage = useCallback(() => {
    setDeleteModal({
      showDeleteModal: false,
      showDeleteButton: true
    });
    deleteCurrentImage(activeProductId);
  }, [activeProductId]);

  const deleteCurrentProduct = useCallback(() => {
    setDeleteModal({
      showDeleteModal: false,
      showDeleteButton: true
    });
    resetActiveProductId();
    const catalogueId = getActiveCatalogueId();
    deleteProducts([activeProductId], catalogueId, {
      showLoader: true,
      makeRemoteChanges: true
    });
  }, [activeProductId]);

  const onTrashClick = useCallback(() => {
    const isDefaultImage = isCurrentImageDefault();

    setDeleteModal(prevState => ({
      ...prevState,
      showDeleteModal: !prevState.showDeleteModal,
      showDeleteButton: !isDefaultImage
    }));
  }, []);

  return (
    <div id={'ProductDetailsHeader'} className={'ProductDetailsHeaderQuerySelector'}>
      {isDefaultImage ? (
        <div />
      ) : (
        <div onClick={setAsDefault} className={'setAsDefault'}>
          Set as default product image
        </div>
      )}

      <div className={'headerRight'}>
        <TrashIcon onClick={onTrashClick} className={'trashIcon'} />
        {!!Object.keys(saveButtonState).length ? (
          <div onClick={onSaveClick} className={'productDetailsSaveButton'}>
            save
          </div>
        ) : null}

        {deleteModal.showDeleteModal && (
          <div
            className={`deleteContainer${
              deleteModal.showDeleteButton ? '' : ' deleteContainerOverWrite'
            }`}
          >
            {deleteModal.showDeleteButton && (
              <button className="deleteImage" onClick={deleteRenderedImage}>
                Delete current image
              </button>
            )}
            {deleteModal.showDeleteButton && <div className="separator" />}
            <button className="deleteProduct" onClick={deleteCurrentProduct}>
              Delete product
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
