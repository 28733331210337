import React, { useEffect, useState } from 'react';
import { getAppVersion } from 'qs-helpers';
import './SettingsLeftPanel.scss';
import SettingsLeftCompanyView from '../CompanyProfile/SettingsLeftCompanyView/SettingsLeftCompanyView';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import {
  getConfigFromCache,
  attachConfigsListener,
  removeConfigsListener
} from 'qs-data-manager/Config';
import { onLogout } from 'qs-data-manager/Authentication';
import PaymentGatewayRow from './PaymentGatewayRow/PaymentGatewayRow';
import LinkSettingsRow from '../../Common/LinkSettingsRow/LinkSettingsRow';
import ButtonSettingsRow from '../../Common/ButtonSettingsRow/ButtonSettingsRow';
import ProductDemoRow from './ProductDemoRow/ProductDemoRow';

export default ({ handleSettingsItemClick, selectedId }) => {
  const createSettingsData = function(linkConfigs) {
    return [
      {
        componentType: 'COMPANY_NAME_RENDERER',
        componentId: 'COMPANY_NAME'
      },
      {
        componentType: 'LINK_RENDERER',
        rowData: {
          componentId: 'QUICKSELL_PARTNER',
          description: 'Sell QuickSell in your local region and earn 35% commission',
          linkUrl: linkConfigs.partnerLink,
          imageSrc: 'https://web.quicksell.co/static/media/ic_partner.png',
          title: 'Become a QuickSell partner'
        }
      },
      {
        componentType: 'PRODUCT_DEMO_RENDERER'
      },
      {
        componentType: 'LINK_RENDERER',
        rowData: {
          componentId: 'NEW_UPDATES',
          description: 'Learn about our latest updates and new feature launches',
          linkUrl: linkConfigs.newUpdates,
          imageSrc: 'https://web.quicksell.co/static/media/ic_new_updates.png',
          title: 'New Updates',
          componentEvent: { eventName: 'new_updates_clicked', props: { from: 'SettingsScreen' } }
        }
      },
      {
        componentType: 'LINK_RENDERER',
        rowData: {
          componentId: 'KNOWLEDGE_CENTER',
          description: 'Read and learn about QuickSell features from our knowledge center guides',
          linkUrl: linkConfigs.knowledgeGuide,
          imageSrc: 'https://web.quicksell.co/static/media/ic_knowledge_guide.png',
          title: 'Knowledge Center',
          componentEvent: {
            eventName: 'knowledge_center_clicked',
            props: { from: 'SettingsScreen' }
          }
        }
      },
      ,
      /* TODO will be added in next release, hence leaving config commented
      {
        componentType: 'BUTTON_RENDERER',
        rowData: {
          componentId: 'PLANS_BILLING',
          description: 'Manage your current plan and billing information',
          imageSrc: 'https://web.quicksell.co/static/media/ic_plans.png',
          title: 'Plans and Billing'
        }
      }
      */ {
        componentType: 'BUTTON_RENDERER',
        rowData: {
          componentId: 'INVENTORY_SETTINGS',
          description: 'Manage your inventory settings at a global level',
          imageSrc: 'https://web.quicksell.co/static/media/ic_inventory.png',
          title: 'Inventory settings'
        }
      },
      {
        componentType: 'BUTTON_RENDERER',
        rowData: {
          componentId: 'LANGUAGE_SETTINGS',
          description: 'Use QuickSell in your language',
          imageSrc: 'https://web.quicksell.co/static/media/ic_choose_app_language.png',
          title: 'Language settings'
        }
      },
      {
        componentType: 'BUTTON_RENDERER',
        rowData: {
          componentId: 'CATALOGUE_SETTINGS',
          description: 'Manage your catalogue settings',
          imageSrc: 'https://web.quicksell.co/static/media/ic_catalogue.png',
          title: 'Catalogue settings'
        }
      },
      {
        componentType: 'BUTTON_RENDERER',
        rowData: {
          componentId: 'CHECKOUT_SETTINGS',
          description: 'Manage your checkout settings',
          imageSrc: 'https://web.quicksell.co/static/media/ic_checkout.png',
          title: 'Checkout settings'
        }
      },
      {
        componentId: 'PAYMENT_SETTINGS',
        componentType: 'PAYMENT_SETTINGS_RENDERER'
      },
      {
        componentType: 'LINK_RENDERER',
        rowData: {
          componentId: 'ABOUT_US',
          description: 'More information about QuickSell app',
          linkUrl: 'https://quicksell.co/about.html',
          imageSrc: 'https://web.quicksell.co/static/media/ic_about_us.png',
          title: 'About Us'
        }
      },
      ,
      /* TODO will be added in next release, hence leaving commented
      {
        componentType: 'BUTTON_RENDERER',
        rowData: {
          componentId: 'SUGGESTION_FEEDBACKS',
          description: 'Let us know how we can improve',
          imageSrc: 'https://web.quicksell.co/static/media/ic_feedback.png',
          title: 'Suggestions & Feedback'
        }
      }*/ {
        componentType: 'LINK_RENDERER',
        rowData: {
          componentId: 'HELP_TRANSLATE',
          description: 'Help us translate QuickSell app in your native language',
          linkUrl: linkConfigs.translationInviteLink,
          imageSrc: 'https://web.quicksell.co/static/media/ic_translation.png',
          title: 'Help us translate',
          componentEvent: {
            eventName: 'help_us_translate_clicked',
            props: { from: 'SettingsScreen' }
          }
        }
      }
    ];
  };

  const [settingsDataOptions, setSettingsDataOptions] = useState(() =>
    createSettingsData(getConfigFromCache() || {})
  );

  // Attach config listeners. These will be used to fetch the urls for the links.
  useEffect(() => {
    const configListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (loading || refreshing || err) {
        return;
      }

      setSettingsDataOptions(createSettingsData(data));
    };

    attachConfigsListener(configListener);

    return () => removeConfigsListener(configListener);
  }, []);

  const getClassForContainer = function(componentId) {
    return `settings-row-container ${selectedId === componentId ? 'selected-setting' : ''}`;
  };

  return (
    <div className="settingsList">
      {settingsDataOptions.map(settingsOptionData => {
        switch (settingsOptionData.componentType) {
          case 'COMPANY_NAME_RENDERER':
            return (
              <SettingsLeftCompanyView
                onSelected={handleSettingsItemClick}
                settingComponentId={settingsOptionData.componentId}
                classForContainer={getClassForContainer(settingsOptionData.componentId)}
              />
            );
          case 'PAYMENT_SETTINGS_RENDERER':
            return (
              <PaymentGatewayRow
                onSelected={handleSettingsItemClick}
                settingComponentId={settingsOptionData.componentId}
                classForContainer={getClassForContainer(settingsOptionData.componentId)}
              />
            );
          case 'PRODUCT_DEMO_RENDERER':
            return <ProductDemoRow classForContainer={getClassForContainer()} />;
          case 'BUTTON_RENDERER':
            return (
              <ButtonSettingsRow
                onSelected={handleSettingsItemClick}
                buttonRowData={settingsOptionData.rowData}
                classForContainer={getClassForContainer(settingsOptionData.componentId)}
              />
            );
          case 'LINK_RENDERER':
            return (
              <LinkSettingsRow
                rowData={settingsOptionData.rowData}
                classForContainer={getClassForContainer()}
              />
            );
          default:
            return null;
        }
      })}
      <div className="logout-button-container">
        <button className="logout-button" onClick={onLogout}>
          Logout
        </button>
      </div>

      <div className={'settings-app-version'}>APP VERSION: {getAppVersion()}</div>
    </div>
  );
};
