import React, { Component } from 'react';

import './styles.css';
import ImagePlaceholder from 'qs-assets/Media/image-placeholder.png';
import RightArrow from 'qs-assets/Media/right-arrow.png';
import DownArrow from 'qs-assets/Media/down-triangle-arrow.png';
import eventbus from 'eventing-bus';
import {
  UPLOAD_IMAGE_MODAL,
  uploadProducts,
  closeImageUploadModal
} from 'qs-data-manager/Products';
import { getCurrencySymbol, toggleGlobalLoader } from 'qs-helpers';
import { getCompanyCurrencyCode } from 'qs-data-manager/Company';

class ImageUploaderOption extends Component {
  constructor(props) {
    super(props);

    const currencyCode = getCompanyCurrencyCode();
    const currencySymbol = getCurrencySymbol({ currencyCode });
    this.state = {
      shouldShow: false,
      firstImage: '',
      image: null,
      noOfFiles: null,
      displayName: '',
      canFileNameBePrice: true,
      imageUploaderFieldOption: 'title',
      currencySymbol,
      allImages: null
    };
  }

  componentDidMount() {
    this.removeEventbus = eventbus.on(UPLOAD_IMAGE_MODAL.eventbusKey, data => {
      const { file, noOfFiles, canFileNameBePrice, shouldShow, allImages } = data;

      if (shouldShow) {
        this.computeImage(file);
      }

      this.setState({
        noOfFiles,
        canFileNameBePrice,
        shouldShow: !!shouldShow,
        displayName: (file || {}).name || '',
        allImages
      });
    });
  }

  componentWillUnmount() {
    if (this.removeEventbus) {
      this.removeEventbus();
    }
  }

  computeImage = image => {
    let reader = new FileReader();
    if (!reader) {
      return;
    }

    reader.readAsDataURL(image);
    reader.onload = () => {
      this.setState({
        image: reader.result || null
      });
      reader = null;
    };
    reader.onerror = () => {
      reader = null;
    };
  };

  closeModal = () => {
    this.setState({
      shouldShow: false
    });
    closeImageUploadModal();
  };

  handleImageUploaderFieldOption = ({ target: { value } }) => {
    this.setState({
      imageUploaderFieldOption: value
    });
  };

  handleImageFilesUpload = async () => {
    const { allImages, imageUploaderFieldOption } = this.state;

    const loaderKey = `handleImageFilesUpload${Date.now()}`;

    const catalogueId = (UPLOAD_IMAGE_MODAL.meta || {}).catalogueId || null;
    this.closeModal();

    if (!catalogueId) {
      console.error(`handleImageFilesUpload: Could not find catalogueId: ${catalogueId}`);
      return;
    }

    toggleGlobalLoader(loaderKey, true);

    await uploadProducts({
      catalogueId,
      images: allImages,
      fileNameMappedTo: imageUploaderFieldOption
    });

    toggleGlobalLoader(loaderKey, false);
  };

  render() {
    const {
      shouldShow,
      displayName,
      canFileNameBePrice,
      noOfFiles,
      imageUploaderFieldOption,
      image,
      currencySymbol
    } = this.state;

    if (!shouldShow) {
      return null;
    }

    const firstImageStyle = {
      backgroundImage: `url(${image})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    };

    return (
      <div className="ImageUploaderOption">
        <div className={'headerContainer'}>
          <div className="CloseCancelUpload" onClick={this.closeModal}>
            Cancel upload
          </div>
          <h3 className="uploadFiles">Upload {noOfFiles || 0} files</h3>
          <div>Use file name to auto fill product information</div>
        </div>
        <div className="ImageUploaderOptionContent">
          <div className={'imageContainer'}>
            <img src={ImagePlaceholder} alt="" />
            <p className="ImageUploaderFileName">{displayName}</p>
          </div>
          <div className="arrowContainer">
            <div
              className="custom-select"
              style={{ background: `url(${DownArrow}) no-repeat 100% 50%` }}
            >
              <select
                value={imageUploaderFieldOption}
                onChange={this.handleImageUploaderFieldOption}
              >
                <option value="">None</option>
                <option value="title">Product Title</option>
                <option value="description">Product Desc</option>
                <option value="price" disabled={!canFileNameBePrice}>
                  Product Price
                </option>
              </select>
              <div className="custom-select-right-arrow" />
            </div>
            <div className={'rightArrowContainer'}>
              <img src={RightArrow} alt="" />
            </div>
          </div>
          <div className="ImageUploaderOptionContentRight">
            <div className="ImageUploaderOptionContentRightImage">
              <div style={firstImageStyle} />
            </div>
            <div className="SelectedFileUploadText">Product {imageUploaderFieldOption}</div>
            <div className="ImageUploaderFileName">
              {` ${imageUploaderFieldOption === 'price' ? currencySymbol : ''}  ${displayName}`}
            </div>
          </div>
        </div>
        <div className={'uploadImagesContainer'}>
          <button className="UploadFilesBtn" onClick={this.handleImageFilesUpload}>
            Upload {noOfFiles || 0} products
            <img src={RightArrow} className="m-l-5" alt="" />
          </button>
        </div>
      </div>
    );
  }
}

export default ImageUploaderOption;
