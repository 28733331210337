import React from 'react';
import './styles.scss';
import ColoredExcel from 'qs-assets/Media/coloredExcel.png';
import ColoredFolder from 'qs-assets/Media/coloredFolder.png';

const EmptyProductListNormal = ({ uploadFromPicker }) => {
  return (
    <div id={'NoProductListContainer'}>
      <div className={'noProducts'}>No products created yet in this catalogue</div>
      <div className={'uploadButtons'}>
        <div className={'buttonContainer fromFiles'}>
          <input
            type="file"
            name="photos"
            accept="image/*"
            multiple
            onChange={uploadFromPicker}
            className={'uploadFromFile'}
          />
          <img src={ColoredFolder} className={'icon'} alt={''} />
          Upload from files
        </div>
        <div className={'buttonContainer fromExcel'}>
          <input
            type="file"
            name="photos"
            accept=".csv,.xls,.xlsx"
            multiple
            onChange={uploadFromPicker}
            className={'uploadFromFile'}
          />
          <img src={ColoredExcel} className={'icon'} alt={''} />
          Upload from excel
        </div>
      </div>
    </div>
  );
};

const EmptyProductListSearch = () => {
  return (
    <div id={'NoProductListContainer'}>Could not find products matching your search criteria</div>
  );
};

export default {
  NORMAL: EmptyProductListNormal,
  SEARCH: EmptyProductListSearch
};
