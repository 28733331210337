import React from 'react';
import './SettingsRow.scss';

export default ({ Icon, imageSrc, title, children }) => (
  <section className={`settings-section-container`}>
    {Icon ? (
      <div className="icon-container">
        <Icon />
      </div>
    ) : null}
    {imageSrc ? (
      <div className="icon-container">
        <img src={imageSrc} alt="" />
      </div>
    ) : null}
    <div className="settings-section-text-container">
      <h4 className="settings-section-text--title">{title}</h4>
      <div className="settings-section-text--description">{children}</div>
    </div>
  </section>
);
