import workerCommonConfig from '../WebWorker/config';
import CacheRequest from 'qs-data-manager/CacheRequest';
import cloneDeep from 'lodash.clonedeep';
import Api from '../Api';
import eventbus from 'eventing-bus';
import { connector } from 'qs-data-manager/ApiAndCacheConnector';
import { updateExistingProductsInNative } from 'qs-data-manager/Dexie/ProductDexieHelpers';
import {
  upsertCatalogueRowInNative,
  updateCataloguePictureMeta
} from 'qs-data-manager/Dexie/CatalogueDexieHelpers';

export const UPLOAD_PRODUCT_PICURES_HEADER = {
  eventbusKey: id => `PRODUCT_HEADER_IMAGE_UPLOAD${id}`,
  meta: {} // { [productId: { totalImages, uploaded }] }
};

export const UPLOAD_PRODUCT_HEADER = {
  eventbusKey: id => `PRODUCT_HEADER_IMAGE_UPLOAD${id}`,
  meta: {} // { [catalogueId: { totalImages, uploaded }] }
};

export const IMAGE_UPLOAD_HELPER = {
  PRODUCT_UPLOAD: {
    key: 'PRODUCT_UPLOAD'
  },
  PRODUCT_EXTRA_PICTURE_UPLOAD: {
    key: 'PRODUCT_EXTRA_PICTURE_UPLOAD'
  }
};

const upsertCatalogueImageMeta = ({ catalogueId, totalImages, uploaded = 0 }) => {
  const existingCatalogueData = UPLOAD_PRODUCT_HEADER.meta[catalogueId];
  if (existingCatalogueData) {
    const updatedTotal = existingCatalogueData.totalImages + totalImages;
    existingCatalogueData.totalImages = updatedTotal;
    return { uploaded: existingCatalogueData.uploaded, totalImages: updatedTotal };
  }

  UPLOAD_PRODUCT_HEADER.meta[catalogueId] = {
    uploaded,
    totalImages
  };

  return { uploaded, totalImages };
};

export const clearCatalogueImageMeta = () => {
  for (const catalogueId in UPLOAD_PRODUCT_HEADER.meta) {
    if(UPLOAD_PRODUCT_HEADER.meta.hasOwnProperty(catalogueId)) {
      delete UPLOAD_PRODUCT_HEADER.meta[catalogueId];
    }
  }
}

const upsertProductImageMeta = ({ productId, totalImages, uploaded = 0 }) => {
  const existingProductData = UPLOAD_PRODUCT_PICURES_HEADER.meta[productId];
  if (existingProductData) {
    const updatedTotal = existingProductData.totalImages + totalImages;
    existingProductData.totalImages = updatedTotal;
    return { uploaded: existingProductData.uploaded, totalImages: updatedTotal };
  }

  UPLOAD_PRODUCT_PICURES_HEADER.meta[productId] = {
    uploaded,
    totalImages
  };

  return { uploaded, totalImages };
};

export const clearProductImageMeta = () => {
  for (const productId in UPLOAD_PRODUCT_PICURES_HEADER.meta) {
    if(UPLOAD_PRODUCT_PICURES_HEADER.meta.hasOwnProperty(productId)) {
      delete UPLOAD_PRODUCT_PICURES_HEADER.meta[productId];
    }
  }
}

const calcRemainingAndPercent = ({ uploaded, totalImages }) => {
  let percent = Math.round((uploaded / totalImages) * 100);
  percent = percent > 0 ? percent : 0;
  const remaining = totalImages - uploaded;
  return { percent, remaining };
};

// Map to inform catalogue row of uploaded picture status
// FORMAT: { catalogueId: { pictureId: true } }
export const CATALOGUE_PICTURE_PREPARED_MAP = {};

const changeProgressbarMeta = ({ catalogueId, uploadedImages = 1 }) => {
  if (UPLOAD_PRODUCT_HEADER.meta[catalogueId]) {
    let meta = UPLOAD_PRODUCT_HEADER.meta[catalogueId];
    const prevUploaded = meta.uploaded;
    const newUploaded = prevUploaded + uploadedImages;

    UPLOAD_PRODUCT_HEADER.meta[catalogueId] = {
      ...UPLOAD_PRODUCT_HEADER.meta[catalogueId],
      uploaded: newUploaded
    };

    meta = UPLOAD_PRODUCT_HEADER.meta[catalogueId];

    const { percent, remaining } = calcRemainingAndPercent(meta);

    const key = UPLOAD_PRODUCT_HEADER.eventbusKey(catalogueId);
    let shouldShow = true,
      uploaded = newUploaded,
      totalImagesToProcess = meta.totalImages;

    if (remaining <= 0) {
      shouldShow = false;
      uploaded = 0;
      totalImagesToProcess = 0;
      delete UPLOAD_PRODUCT_HEADER.meta[catalogueId];
    }

    // update the catalogue row table with the necessary data
    updateCataloguePictureMeta({ catalogueId, uploaded, totalImagesToProcess });

    eventbus.publish(key, {
      shouldShow,
      percent: percent,
      remaining
    });
  }
};

const changeProductPicturesHeader = ({ productId, uploadedImages = 1 }) => {
  if (UPLOAD_PRODUCT_PICURES_HEADER.meta[productId]) {
    let meta = UPLOAD_PRODUCT_PICURES_HEADER.meta[productId];
    const prevUploaded = meta.uploaded;

    UPLOAD_PRODUCT_PICURES_HEADER.meta[productId] = {
      ...UPLOAD_PRODUCT_PICURES_HEADER.meta[productId],
      uploaded: prevUploaded + uploadedImages
    };

    meta = UPLOAD_PRODUCT_PICURES_HEADER.meta[productId];

    const { percent, remaining } = calcRemainingAndPercent(meta);

    const key = UPLOAD_PRODUCT_PICURES_HEADER.eventbusKey(productId);
    let shouldShow = true;

    if (remaining <= 0) {
      shouldShow = false;
      delete UPLOAD_PRODUCT_PICURES_HEADER.meta[productId];
    }

    eventbus.publish(key, {
      shouldShow,
      percent: percent,
      remaining
    });
  }
};

// Handles side effect of when product picture is done uploading
const productImageUploadDone = async ({ isPrepared, error, pictureId, productId, catalogueId }) => {
  let promises = [];
  const ebKey = workerCommonConfig.UPLOAD_IMAGES.eventbusKey(pictureId);

  eventbus.publish(ebKey, {
    isPrepared: true,
    error: !!error,
    pictureId
  });

  const productRowCacheKey = `${connector.PRODUCT_ROW_META.cacheKey}${productId}`;
  const cache = CacheRequest.getCacheForKey(productRowCacheKey);
  const newCache = {
    ...cache
  };

  if (error) {
    newCache.localImageError = true;
    newCache.prepared = isPrepared;
  } else {
    newCache.pictureId = pictureId;
    newCache.isPrepared = isPrepared;
    const pictures = [{ pictureId, prepared: true }];
    promises.push(updateExistingProductsInNative([newCache]));
    promises.push(Api.updateProductPicture({ productId, pictures }));
  }

  changeProgressbarMeta({ catalogueId, uploadedImages: 1 });
  CacheRequest.setCacheForKey(productRowCacheKey, newCache);

  if (
    CATALOGUE_PICTURE_PREPARED_MAP &&
    CATALOGUE_PICTURE_PREPARED_MAP[catalogueId] &&
    CATALOGUE_PICTURE_PREPARED_MAP[catalogueId][pictureId]
  ) {
    const catalogueRowCacheKey = `${connector.CATALOGUE_ROW_META.cacheKey}${catalogueId}`;
    const catalogueRowCache = CacheRequest.getCacheForKey(catalogueRowCacheKey);

    const newPictureIds = (catalogueRowCache.pictureIds || []).map(pictureState => {
      if (pictureState && pictureState.pictureId === pictureId) {
        return {
          pictureId,
          prepared: true
        };
      } else {
        return pictureState;
      }
    });
    const newCatalogueRowCache = {
      ...catalogueRowCache,
      pictureIds: newPictureIds
    };

    CacheRequest.setCacheForKey(catalogueRowCacheKey, newCatalogueRowCache);
    promises.push(upsertCatalogueRowInNative({ [catalogueId]: newCatalogueRowCache }));
    delete CATALOGUE_PICTURE_PREPARED_MAP[catalogueId][pictureId];
  }

  if (promises.length) {
    await Promise.all(promises);
  }
};

// Handles side effect of when extra picture are done uploading to a single product
const pictureUploadToProductDone = async ({ isPrepared, error, pictureId, productId }) => {
  const basicInfoCacheKey = `${connector.BASIC_INFO.cacheKey}${productId}`;
  const basicInfoCache = CacheRequest.getCacheForKey(basicInfoCacheKey);

  const newBasicInfoCache = cloneDeep(basicInfoCache);
  if (newBasicInfoCache && newBasicInfoCache.pictures && newBasicInfoCache.pictures[pictureId]) {
    newBasicInfoCache.pictures[pictureId].prepared = isPrepared;
  }

  changeProductPicturesHeader({ productId, uploadedImages: 1 });
  CacheRequest.setCacheForKey(basicInfoCacheKey, newBasicInfoCache);
  Api.updateExistingPicture({
    productId,
    pictures: [{ pictureId, prepared: !!isPrepared }]
  });
};

export const onImageUploadDone = async data => {
  if (data && data.extraData.calledFrom === IMAGE_UPLOAD_HELPER.PRODUCT_UPLOAD.key) {
    productImageUploadDone(data);
    return;
  }

  if (data && data.extraData.calledFrom === IMAGE_UPLOAD_HELPER.PRODUCT_EXTRA_PICTURE_UPLOAD.key) {
    pictureUploadToProductDone(data);
    return;
  }
};

export const showProductHeaderProgressbar = ({ catalogueId, totalImages, uploaded }) => {
  const meta = upsertCatalogueImageMeta({ catalogueId, totalImages, uploaded });
  const { percent, remaining } = calcRemainingAndPercent(meta);
  const key = UPLOAD_PRODUCT_HEADER.eventbusKey(catalogueId);
  eventbus.publish(key, {
    shouldShow: true,
    percent,
    remaining
  });
};

export const showProductPicturesHeader = ({ productId, totalImages, uploaded }) => {
  const meta = upsertProductImageMeta({ productId, totalImages, uploaded });
  const { percent, remaining } = calcRemainingAndPercent(meta);
  const key = UPLOAD_PRODUCT_PICURES_HEADER.eventbusKey(productId);
  eventbus.publish(key, {
    shouldShow: true,
    percent,
    remaining
  });
};
