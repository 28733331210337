import React, { Component } from 'react';
import Switch from 'react-switch';
import Modal from 'react-responsive-modal';

import './styles.scss';
import PropTypes from 'prop-types';
import Api from 'qs-services/Api';
import { toggleGlobalLoader } from 'qs-helpers';
import {
  getInventoryFromCache,
  attachInventoryListener,
  removeInventoryListener,
  getInventory
} from 'qs-data-manager/ProductDetails';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import Loader from 'qs-components/Common/Loader';
import { getActiveCatalogueId } from 'qs-data-manager/Catalogues';
import BulkInventory from '../BulkInventory/BulkInventory';
import { getCompanyIdFromCache } from 'qs-data-manager/Company';
import { setProductStockCount } from 'qs-data-manager/Products';
import CatalogueLib from 'qs-data-manager/Catalogues';
import Mixpanel from 'qs-data-manager/Mixpanel';
import * as Sentry from '@sentry/browser';

const DEBOUNCE_TIME = 500;

class Inventory extends Component {
  static propTypes = {
    activeProductId: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    activeProductIds: PropTypes.any,
    stockCount: PropTypes.number,
    trackInventory: PropTypes.bool,
    autoReduce: PropTypes.bool,
    stockVisible: PropTypes.object,
    orderOnOOS: PropTypes.object
  };

  constructor(props) {
    super(props);
    const { activeProductId, isBulkEditing } = this.props;
    this.companyId = getCompanyIdFromCache();
    this.catalogueId = getActiveCatalogueId();

    const {
      stockCount,
      trackInventory,
      autoReduce,
      showOutOfStockProduct,
      stockManagedFrom,
      allowOrderOutOfStock,
      orderOnOOSControlledFrom,
      availableInCache,
      catalogueOOSProduct,
      catalogueOOSProductAvailable
    } = getInventoryFromCache({
      productId: activeProductId,
      isBulkEditing,
      catalogueId: this.catalogueId
    });

    let fetchingCatalogueStockVisibility = false;
    let fetchingCatalogueOrderOnOOSVisibility = false;

    if (stockManagedFrom === 'CATALOGUE') {
      fetchingCatalogueStockVisibility = true;
      this.fetchCatalogueStock({ catalogueId: this.catalogueId });
    }

    if (orderOnOOSControlledFrom === 'CATALOGUE') {
      fetchingCatalogueOrderOnOOSVisibility = true;
      this.fetchCatalogueOrderOnOOS({ catalogueId: this.catalogueId });
    }

    this.saveInventoryQueue = null;

    this.state = {
      error: false,
      loading: !availableInCache,
      refreshing: false,
      stockCount,
      trackInventory,
      autoReduce,
      showOutOfStockProduct,
      stockManagedFrom,
      fetchingCatalogueStockVisibility,
      fetchingCatalogueOrderOnOOSVisibility,
      allowOrderOutOfStock,
      orderOnOOSControlledFrom,
      catalogueOOSProduct,
      catalogueOOSProductAvailable
    };
  }

  componentDidMount() {
    const { activeProductId, isBulkEditing } = this.props;
    if (!isBulkEditing) {
      attachInventoryListener({ listener: this.inventoryListener, productId: activeProductId });
      getInventory({ productId: activeProductId });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.activeProductId !== this.props.activeProductId && !nextProps.isBulkEditing) {
      if (!this.props.isBulkEditing) {
        removeInventoryListener({
          listener: this.inventoryListener,
          productId: this.props.activeProductId
        });
      }

      attachInventoryListener({
        listener: this.inventoryListener,
        productId: nextProps.activeProductId
      });
      getInventory({ productId: nextProps.activeProductId });
      this.catalogueId = getActiveCatalogueId();

      const {
        stockCount,
        trackInventory,
        autoReduce,
        showOutOfStockProduct,
        stockManagedFrom,
        allowOrderOutOfStock,
        orderOnOOSControlledFrom,
        availableInCache,
        catalogueOOSProduct,
        catalogueOOSProductAvailable
      } = getInventoryFromCache({
        productId: nextProps.activeProductId,
        isBulkEditing: nextProps.isBulkEditing,
        catalogueId: this.catalogueId
      });

      let fetchingCatalogueStockVisibility = false;
      let fetchingCatalogueOrderOnOOSVisibility = false;

      if (stockManagedFrom === 'CATALOGUE') {
        fetchingCatalogueStockVisibility = true;
        this.fetchCatalogueStock({ catalogueId: this.catalogueId });
      }

      if (orderOnOOSControlledFrom === 'CATALOGUE') {
        fetchingCatalogueOrderOnOOSVisibility = true;
        this.fetchCatalogueOrderOnOOS({ catalogueId: this.catalogueId });
      }

      this.setState({
        stockCount,
        trackInventory,
        autoReduce,
        showOutOfStockProduct,
        stockManagedFrom,
        allowOrderOutOfStock,
        orderOnOOSControlledFrom,
        error: false,
        loading: !availableInCache,
        refreshing: false,
        catalogueOOSProduct,
        catalogueOOSProductAvailable,
        fetchingCatalogueStockVisibility,
        fetchingCatalogueOrderOnOOSVisibility
      });
    }
  }

  componentWillUnmount() {
    const { activeProductId, isBulkEditing } = this.props;
    if (!isBulkEditing) {
      removeInventoryListener({ listener: this.inventoryListener, productId: activeProductId });
    }
  }

  fetchCatalogueStock = ({ catalogueId }) => {
    CatalogueLib.getCatalogueStock({ catalogueId }).then(value => {
      this.setState({
        showOutOfStockProduct: value,
        fetchingCatalogueStockVisibility: false
      });
    });
  };

  fetchCatalogueOrderOnOOS = ({ catalogueId }) => {
    CatalogueLib.getCatalogueOrderOnOOS({ catalogueId }).then(value => {
      this.setState({
        allowOrderOutOfStock: value,
        fetchingCatalogueOrderOnOOSVisibility: false
      });
    });
  };

  inventoryListener = (error, payload) => {
    const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);
    if (err) {
      this.setState({
        error: err,
        loading,
        refreshing
      });
      return;
    }

    if (loading) {
      this.setState({
        loading,
        refreshing
      });
      return;
    }

    const stockManagedFrom =
      typeof data.showOutOfStockProduct === 'boolean' ? 'PRODUCT' : 'CATALOGUE';
    let fetchingCatalogueStockVisibility = false;

    if (stockManagedFrom === 'CATALOGUE') {
      fetchingCatalogueStockVisibility = true;
      this.fetchCatalogueStock({ catalogueId: this.catalogueId });
    }

    const orderOnOOSControlledFrom =
      typeof data.allowOrdersOnOutOfStock === 'boolean' ? 'PRODUCT' : 'CATALOGUE';
    let fetchingCatalogueOrderOnOOSVisibility = false;

    if (orderOnOOSControlledFrom === 'CATALOGUE') {
      fetchingCatalogueOrderOnOOSVisibility = true;
      this.fetchCatalogueOrderOnOOS({ catalogueId: this.catalogueId });
    }

    this.setState({
      trackInventory: data.trackInventory,
      stockCount: typeof data.count === 'number' ? data.count : 1,
      autoReduce: data.autoReduceInventory === 'VISITOR',
      showOutOfStockProduct: data.showOutOfStockProduct,
      stockManagedFrom,
      fetchingCatalogueStockVisibility,
      fetchingCatalogueOrderOnOOSVisibility,
      allowOrderOutOfStock: data.allowOrdersOnOutOfStock,
      orderOnOOSControlledFrom,
      error: err,
      loading,
      refreshing
    });
  };

  changeStockAvailability = stockCount => {
    this.setState({ stockCount: stockCount });
    Mixpanel.sendEvent({
      eventName: 'manage_inventory',
      props: {
        status: !!stockCount ? 'IN_STOCK' : 'OUT_OF_STOCK'
      }
    });
    this.remoteSaveInventory(stockCount);
  };

  reduceInventory = () => {
    const { stockCount } = this.state;
    const currentStockCount = Number(stockCount);
    const newStockCount = currentStockCount <= 2 ? 1 : currentStockCount - 1;
    this.setState({ stockCount: newStockCount });
    this.remoteSaveInventory(newStockCount);
  };

  increaseInventory = () => {
    const { stockCount } = this.state;
    const newStockCount = Number(stockCount) + 1;
    this.setState({ stockCount: newStockCount });
    this.remoteSaveInventory(newStockCount);
  };

  remoteSaveInventory = async stockCount => {
    const { activeProductId } = this.props;

    if (this.saveInventoryQueue) {
      clearTimeout(this.saveInventoryQueue);
    }

    this.saveInventoryQueue = setTimeout(async () => {
      const loaderKey = `remoteSaveInventory${Date.now()}`;
      toggleGlobalLoader(loaderKey, true);
      try {
        const catalogueId = getActiveCatalogueId();
        setProductStockCount({ stock: Number(stockCount), productIds: [activeProductId] });
        await Api.setStockCount({ productId: activeProductId, stockCount: Number(stockCount) });
        CatalogueLib.getCatalogueTags(catalogueId);
        toggleGlobalLoader(loaderKey, false);
      } catch (err) {
        console.error('err', err);
        toggleGlobalLoader(loaderKey, false);
        Sentry.captureException(err);
      }
    }, DEBOUNCE_TIME);
  };

  onTrackInventoryChange = async trackInventory => {
    const { activeProductId } = this.props;

    const loaderKey = `onTrackInventoryChange${Date.now()}`;
    toggleGlobalLoader(loaderKey, true);

    this.setState({ trackInventory });
    try {
      await Api.setInventoryTracking({ productIds: [activeProductId], value: trackInventory });
      toggleGlobalLoader(loaderKey, false);
    } catch (err) {
      console.error('err', err);
      toggleGlobalLoader(loaderKey, false);
      Sentry.captureException(err);
    }
  };

  changeAutoReduceQuantity = async switchValue => {
    const { activeProductId } = this.props;
    const loaderKey = `changeAutoReduceQuantity${Date.now()}`;

    toggleGlobalLoader(loaderKey, true);

    const value = switchValue ? 'VISITOR' : 'COMPANY';
    this.setState({ autoReduce: switchValue });

    try {
      await Api.autoReduceQuantity({
        productIds: [activeProductId],
        value
      });
      toggleGlobalLoader(loaderKey, false);
    } catch (err) {
      console.error('err', err);
      toggleGlobalLoader(loaderKey, false);
      Sentry.captureException(err);
    }
  };

  onStockVisibilityChange = async switchValue => {
    const { activeProductId } = this.props;
    const value = switchValue;
    const updates = {};

    const loaderKey = `onStockVisibilityChange${Date.now()}`;
    toggleGlobalLoader(loaderKey, true);

    updates.showOutOfStockProduct = switchValue;

    if (this.state.showOutOfStockProduct === null && typeof switchValue === 'boolean') {
      updates.stockManagedFrom = 'PRODUCT';
    } else if (switchValue === null) {
      updates.showResetModal = false;
      updates.stockManagedFrom = 'CATALOGUE';
    }

    this.setState(updates);

    try {
      await Api.setOutOfStockVisibility({ productIds: [activeProductId], value });
      Mixpanel.sendEvent({
        eventName: 'out_of_stock_visibility_setting_changed',
        props: {
          status: !!switchValue
        }
      });
      toggleGlobalLoader(loaderKey, false);
    } catch (err) {
      console.error('err', err);
      toggleGlobalLoader(loaderKey, false);
      Sentry.captureException(err);
    }
  };

  orderOnOutStockChange = async switchValue => {
    const { activeProductId } = this.props;
    const value = switchValue;
    const updates = {};

    const loaderKey = `orderOnOutStockChange${Date.now()}`;
    toggleGlobalLoader(loaderKey, true);

    updates.allowOrderOutOfStock = value;

    if (this.state.orderOnOOSControlledFrom === null && typeof switchValue === 'boolean') {
      updates.orderOnOOSControlledFrom = 'PRODUCT';
    } else if (value === null) {
      updates.showOrderResetModal = false;
      updates.orderOnOOSControlledFrom = 'CATALOGUE';
    }

    this.setState(updates);

    try {
      await Api.setAllowOrderOnOutOfStock({
        productIds: [activeProductId],
        value
      });
    } catch (err) {
      console.error('err', err);
      Sentry.captureException(err);
    }

    toggleGlobalLoader(loaderKey, false);
  };

  renderStockSettings = () => {
    const { stockManagedFrom } = this.state;

    if (stockManagedFrom === 'CATALOGUE') {
      return <div className="managedByCatalogue">Currently managed by catalogue settings</div>;
    }

    if (stockManagedFrom === 'PRODUCT') {
      return (
        <div onClick={() => this.toggleResetConfirmationModal(true)} className="resetCatalogue">
          Reset to catalogue default
        </div>
      );
    }

    return null;
  };

  renderOrderSettings = () => {
    const { orderOnOOSControlledFrom } = this.state;

    if (orderOnOOSControlledFrom === 'CATALOGUE') {
      return <div className="managedByCatalogue">Currently managed by catalogue settings</div>;
    }

    if (orderOnOOSControlledFrom === 'PRODUCT') {
      return (
        <div onClick={() => this.toggleOrderResetModal(true)} className="resetCatalogue">
          Reset to catalogue default
        </div>
      );
    }

    return null;
  };

  toggleResetConfirmationModal = value => {
    this.setState({ showResetModal: value });
  };

  renderResetModal = () => (
    <Modal
      open={this.state.showResetModal}
      onClose={() => {
        this.toggleResetConfirmationModal(false);
      }}
      center
      styles={{ modal: { backgroundColor: 'white', borderRadius: 10 } }}
      showCloseIcon={false}
    >
      <div className="resetInventoryModalContainer">
        <div className="header">Reset setting</div>
        <div className="title">Are you sure you want to reset this setting</div>
        <div className="buttonCotnainer">
          <div onClick={() => this.toggleResetConfirmationModal(false)} className="cancelButton">
            Cancel
          </div>
          <div onClick={() => this.onStockVisibilityChange(null)} className="acceptButton">
            Yes
          </div>
        </div>
      </div>
    </Modal>
  );

  toggleOrderResetModal = value => {
    this.setState({ showOrderResetModal: value });
  };

  renderOrderResetModal = () => (
    <Modal
      open={this.state.showOrderResetModal}
      onClose={() => {
        this.toggleOrderResetModal(false);
      }}
      center
      styles={{ modal: { backgroundColor: 'white', borderRadius: 10 } }}
      showCloseIcon={false}
    >
      <div className="resetInventoryModalContainer">
        <div className="header">Reset setting</div>
        <div className="title">Are you sure you want to reset this setting</div>
        <div className="buttonCotnainer">
          <div onClick={() => this.toggleOrderResetModal(false)} className="cancelButton">
            Cancel
          </div>
          <div onClick={() => this.orderOnOutStockChange(null)} className="acceptButton">
            Yes
          </div>
        </div>
      </div>
    </Modal>
  );

  renderTackQuantity = () => {
    const { loading, stockCount, trackInventory } = this.state;
    if (loading) {
      return (
        <div className={'trackQuantityLoader'}>
          <Loader size={'small'} />
        </div>
      );
    }

    if (trackInventory) {
      return (
        <div>
          <div className="insertQunatityContainer">
            <div onClick={this.reduceInventory} className="minusContainer noselect">
              -
            </div>
            <div className="quantityInputContainer">
              <input
                type="number"
                value={stockCount}
                className="quantityContainer"
                onChange={e => this.changeStockAvailability(e.target.value)}
              />
            </div>
            <div onClick={this.increaseInventory} className="plusContainer noselect">
              +
            </div>
          </div>
          <div className="dontTrackQuantityContainer">
            <div onClick={() => this.onTrackInventoryChange(false)} className="dontTrackQuantity">
              Do not track quantity
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        onClick={() => this.onTrackInventoryChange(true)}
        className="trackInventoryButtonContainer"
      >
        <div className="trackInventoryButton">Track available quantity</div>
      </div>
    );
  };

  singleProductEditing = () => {
    const {
      stockCount,
      trackInventory,
      autoReduce,
      showOutOfStockProduct,
      showResetModal,
      allowOrderOutOfStock,
      showOrderResetModal,
      loading,
      fetchingCatalogueStockVisibility,
      fetchingCatalogueOrderOnOOSVisibility
    } = this.state;

    return (
      <div className="mainInventoryContainer">
        <div className="stockAvailabilityContainer">
          <div className="ProductEditLabel header inventoryHeader">Stock availability</div>

          <div className={'options'}>
            {loading ? (
              <div className={'stockLoader'}>
                <Loader size={'small'} />
              </div>
            ) : (
              <>
                <div
                  onClick={() => this.changeStockAvailability(0)}
                  className={
                    !!stockCount ? 'outOfStockContainer' : 'outOfStockContainer OOSselected'
                  }
                >
                  out of stock
                </div>

                <div
                  onClick={() => this.changeStockAvailability(1)}
                  className={
                    !!stockCount ? 'availableContainer availableSelected' : 'availableContainer'
                  }
                >
                  available
                </div>
              </>
            )}
          </div>
        </div>

        {!!stockCount && (
          <div className="availableQuantityContainer">
            <div className="ProductEditLabel header inventoryHeader">Available quantity</div>
            {this.renderTackQuantity()}
          </div>
        )}

        {!!stockCount && !!trackInventory && (
          <div className="autoReduceInventoryContainer">
            <div className="ProductEditLabel header inventoryHeader">Auto reduce quantity</div>

            <div
              onClick={() => this.changeAutoReduceQuantity(!autoReduce)}
              className="descriptionContainer"
            >
              <div className="description">
                Auto reduce the inventory when visitor confirms the order
              </div>
              <div>
                <Switch
                  checked={autoReduce}
                  onChange={this.changeAutoReduceQuantity}
                  onColor="#C0DACE"
                  onHandleColor="#4DA47A"
                  handleDiameter={25}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={17}
                  width={40}
                />
              </div>
            </div>
          </div>
        )}

        <div className="stockVisibilityContainer">
          <div className="ProductEditLabel header inventoryHeader">Out of stock visibility</div>

          <div
            onClick={() => {
              this.onStockVisibilityChange(!showOutOfStockProduct);
            }}
            className="descriptionContainer"
          >
            <div className="description">Show out of stock product to catalogue visitor</div>

            <div className="switchContainer">
              {!!loading || !!fetchingCatalogueStockVisibility ? (
                <Loader size={'small'} />
              ) : (
                <Switch
                  checked={showOutOfStockProduct}
                  onChange={this.onStockVisibilityChange}
                  onColor="#C0DACE"
                  onHandleColor="#4DA47A"
                  handleDiameter={25}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={17}
                  width={40}
                />
              )}
            </div>
          </div>

          {this.renderStockSettings()}
        </div>

        {!!showOutOfStockProduct && (
          <div className="orderOnOOSContainer">
            <div className="ProductEditLabel header inventoryHeader">
              Allow order on out of stock
            </div>

            <div
              onClick={() => this.orderOnOutStockChange(!allowOrderOutOfStock)}
              className="descriptionContainer"
            >
              <div className="description">
                Allow visitors to place an order on your out of stock product
              </div>

              <div className="switchContainer">
                {!!loading || !!fetchingCatalogueOrderOnOOSVisibility ? (
                  <Loader size={'small'} />
                ) : (
                  <Switch
                    checked={allowOrderOutOfStock}
                    onChange={this.orderOnOutStockChange}
                    onColor="#C0DACE"
                    onHandleColor="#4DA47A"
                    handleDiameter={25}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={17}
                    width={40}
                  />
                )}
              </div>
            </div>

            {this.renderOrderSettings()}
          </div>
        )}

        {showResetModal && this.renderResetModal()}
        {showOrderResetModal && this.renderOrderResetModal()}
      </div>
    );
  };

  render() {
    const { activeProductIds, isBulkEditing } = this.props;

    return isBulkEditing ? (
      <BulkInventory productIds={activeProductIds} />
    ) : (
      this.singleProductEditing()
    );
  }
}

export default Inventory;
