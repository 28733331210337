import { nativeDB } from 'qs-services/DexieInit';
import * as Sentry from "@sentry/browser";

// helper functions for storing data in native storage
const saveCatalogueListMeta = async (apiResp = {}) => {
  try {
    const { catalogueIds = [] } = apiResp;
    await nativeDB.cataloguesListMeta.clear();
    await nativeDB.cataloguesListMeta.bulkPut(catalogueIds);
  } catch (err) {
    console.error(
      `saveCatalogueListMeta: Could not store data in native db for apiResp for catalogue ids`,
      err
    );
    Sentry.captureException(err);
  }
};

const saveCatalogueRowMeta = async (apiResp = {}) => {
  // return id in object so that you dont have to put external keys into native storage
  const { catalogueMeta = {} } = apiResp;
  const catalogueIds = Object.keys(catalogueMeta);
  if (catalogueIds.length) {
    const keys = [];
    const data = catalogueIds.map(id => {
      keys.push(id);
      return catalogueMeta[id];
    });

    try {
      await nativeDB.catalogueRowMeta.bulkPut(data, keys);
    } catch (err) {
      console.error('Could not store in native db', err);
      Sentry.captureException(err);
    }
  }
};

const saveCatalogueTags = async (apiResp = {}, options = {}) => {
  const { catalogueId } = options;
  const { tags } = apiResp;
  if (!catalogueId || !tags) {
    console.error(`saveCatalogueTags: tags or catalogueId for ${catalogueId} does not exist`);
  }

  await nativeDB.catalogueTags.put(tags, catalogueId);
};

export { saveCatalogueListMeta, saveCatalogueRowMeta, saveCatalogueTags };
