import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import './styles.scss';
import { ReactComponent as ArrowRight } from 'qs-assets/Media/ic_arrow_right.svg';
import { ReactComponent as LockWhite } from 'qs-assets/Media/icon_lock_white.svg';
import PropTypes from 'prop-types';
import eventbus from 'eventing-bus';
import { FEATURE_LIMITING_MODAL_META, FEATURE_LIST } from 'qs-data-manager/FeatureUsage';
import { supportPhoneNumber, openWhatsApp } from 'qs-helpers';

class Index extends Component {
  static propTypes = {
    featureLimitId: PropTypes.string,
    dismissModal: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.removeEventbus = null;

    this.state = {
      showModal: false,
      featureId: null,
    };
  }

  componentDidMount() {
    this.removeEventbus = eventbus.on(FEATURE_LIMITING_MODAL_META.EVENTBUS_KEY, data => {
      const { featureId, visibility } = data;
      this.setState({
        showModal: visibility,
        featureId
      });
    });
  }

  componentWillUnmount() {
    this.closeModal();
    if (this.removeEventbus) {
      this.removeEventbus();
    }
  }

  closeModal = () => {
    this.setState({ showModal: false });
  };

  onContactUs = () => {
    const text = 'I would like to increase my uploading limit to unlimited';
    const contact = supportPhoneNumber();
    openWhatsApp({ contact, text });
    this.closeModal();
  };

  render() {
    const { showModal, featureId } = this.state;

    if (!showModal) {
      return null;
    }

    if (!featureId) {
      return null;
    }

    const meta = FEATURE_LIST[featureId];
    const { title, description, iconStyles } = meta;

    return (
      <Modal
        open={showModal}
        center
        onClose={this.closeModal}
        styles={{
          modal: { backgroundColor: 'transparent', borderRadius: 15, padding: 0, maxWidth: 400 }
        }}
        showCloseIcon={false}
      >
        <div className="featureLimitMainContainer">
          <div className="icon">
            <LockWhite className={'image'} />
          </div>
          <div className="title">{title}</div>
          <div className="description">{description}</div>

          <div onClick={this.onContactUs} className="contactUs">
            <div>Contact us</div>
            <ArrowRight className={'arrowRight'} />
          </div>
        </div>
      </Modal>
    );
  }
}

export default Index;
