import React, { useState, useRef, useEffect, Fragment } from 'react';
import ReactSwitch from 'react-switch';
import SettingsDataRow from '../../Common/SettingsDataRow/SettingsDataRow';
import './CheckoutSettings.scss';
import Modal from 'react-responsive-modal';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import {
  removeCompanySettingsListenerFirebase,
  attachCompanySettingsListenerFirebase,
  getCompanySettingsFirebaseFromCache,
  extractCompanyCheckoutSettings,
  setCompanyCheckoutSettings,
  extractCompanyCheckoutMessageSettings,
  setCompanyCheckoutMessageSettings
} from 'qs-data-manager/Company';
import Loader from '../../../Common/Loader';
import { ReactComponent as EditIcon } from 'qs-assets/Media/pencil.svg';
import toastr from 'toastr';

export default () => {
  const [captureAddress, toggleCaptureAddress] = useState(
    extractCompanyCheckoutSettings(getCompanySettingsFirebaseFromCache())
  );
  const [checkoutMsg, setCheckoutMessage] = useState(
    extractCompanyCheckoutMessageSettings(getCompanySettingsFirebaseFromCache())
  );
  const [showOrderMsgModal, toggleOrderMsgModal] = useState(false);

  //Always keep the component controlled
  const [textAreaVal, setTextAreaVal] = useState(checkoutMsg || '');

  const modalStyles = useRef({
    backgroundColor: 'white',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    width: '400px'
  });

  useEffect(() => {
    const checkoutListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      //TODO check if the error must be shown
      if (loading || refreshing) {
        return;
      }

      if (err) {
        toastr.error('Something went wrong while fetching the checkout information');
        return;
      }

      toggleCaptureAddress(extractCompanyCheckoutSettings(data));
      setCheckoutMessage(extractCompanyCheckoutMessageSettings(data));
      setTextAreaVal(extractCompanyCheckoutMessageSettings(data));
    };

    attachCompanySettingsListenerFirebase(checkoutListener);

    return () => removeCompanySettingsListenerFirebase(checkoutListener);
  }, []);

  const handleCaptureAddressClick = function(_, event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setCompanyCheckoutSettings(!captureAddress);
  };

  const handleOrderMsgClick = function(event) {
    event.preventDefault();
    toggleOrderMsgModal(!showOrderMsgModal);
  };

  const closeTextModal = function() {
    toggleOrderMsgModal(false);
  };

  const handleCancel = function(event) {
    event.preventDefault();
    closeTextModal();
  };

  const submitText = function(event) {
    event.preventDefault();
    closeTextModal();
    setCompanyCheckoutMessageSettings(textAreaVal);
  };

  const renderTextModal = () => (
    <Modal
      open={true}
      center={true}
      onClose={closeTextModal}
      showCloseIcon={false}
      styles={{ modal: modalStyles.current }}
    >
      <h3 className="checkout-section-modal checkout-text--title">Order Confirmation Messsage</h3>
      <p className="checkout-section-modal checkout-text-description">
        {' '}
        Show a message to your customers after they confirm their order{' '}
      </p>
      <textarea
        className="checkout-section-modal checkout-text-area"
        value={textAreaVal}
        placeholder="Thank you for placing an order with us. We will contact you soon"
        onChange={event => setTextAreaVal(event.target.value)}
      />
      <div className="checkout-section-modal button-container">
        <button type="button" className="button-plain" onClick={handleCancel}>
          {' '}
          CANCEL{' '}
        </button>
        <button type="submit" className="button-plain button-submit" onClick={submitText}>
          {' '}
          SET MESSAGE{' '}
        </button>
      </div>
    </Modal>
  );

  let catalogueComponent;
  if (typeof captureAddress === 'boolean' && typeof checkoutMsg === 'string') {
    catalogueComponent = (
      <Fragment>
        <SettingsDataRow
          onSectionClick={handleCaptureAddressClick}
          icon={
            <ReactSwitch
              checked={captureAddress}
              onChange={handleCaptureAddressClick}
              onColor="#C0DACE"
              onHandleColor="#4DA47A"
              handleDiameter={25}
              uncheckedIcon={false}
              checkedIcon={false}
              height={17}
              width={40}
            />
          }
          title={'Capture shipping address'}
        >
          <span>
            Require customers to enter their shipping address before they can confirm an order
          </span>
        </SettingsDataRow>
        <SettingsDataRow
          icon={<EditIcon />}
          title={'Order confirmation message'}
          onSectionClick={handleOrderMsgClick}
        >
          <span>
            {checkoutMsg
              ? checkoutMsg
              : 'Show a message to your customers after they confirm their order'}
          </span>
        </SettingsDataRow>
        {showOrderMsgModal && renderTextModal()}
      </Fragment>
    );
  } else {
    catalogueComponent = <Loader size="large" />;
  }

  return <div className="checkout-section">{catalogueComponent}</div>;
};
