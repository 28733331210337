const selectedInquries = new Set();
let editingListener = {};
let selectAllListener = null;
let rowSelectAllListener = {};
let fetchInquries = null;

const onInquirySelect = (inquiryId, operation) => {
  if (operation) {
    selectedInquries.add(inquiryId);
  } else if (selectedInquries.has(inquiryId) && !operation) {
    selectedInquries.delete(inquiryId);
  }

  notifyEditingListener(selectedInquries.size);
  notifySelectAll(selectedInquries.size);
};

const resetSelectedInquries = () => selectedInquries.clear();

const getSelectedInquiryLength = () => selectedInquries.size;

const isInquirySelected = inquiryId => selectedInquries.has(inquiryId);

const attachFetchInquries = listener => {
  fetchInquries = listener;
};

const toggleSelectAll = areAllSelected => {
  if (areAllSelected && fetchInquries) {
    const inquiries = fetchInquries();
    inquiries.forEach(({ inquiryId }) => {
      selectedInquries.add(inquiryId);
    });
  } else {
    selectedInquries.clear();
  }

  Object.values(rowSelectAllListener).forEach(listener => {
    listener(areAllSelected);
  });
  Object.values(editingListener).forEach(listener => {
    listener(areAllSelected);
  });
};

const attachRowSelectAllListener = (inquiryId, listener) => {
  rowSelectAllListener[inquiryId] = listener;
};
const removeRowSelectAllListener = inquiryId => {
  if (rowSelectAllListener[inquiryId]) {
    delete rowSelectAllListener[inquiryId];
  }
};
const getAllSelectedInquries = () => [...selectedInquries];

const attachEditingListener = (inquiryId, listener) => {
  editingListener[inquiryId] = listener;
  notifyEditingListener(selectedInquries.size);
};
const removeEditingListener = inquiryId => {
  if (editingListener[inquiryId]) {
    delete editingListener[inquiryId];
  }
};
const resetEditing = () => notifyEditingListener(false);

const notifyEditingListener = isEditing => {
  Object.values(editingListener).forEach(listener => {
    listener(isEditing);
  });
};

const attachSelectAllListener = listener => {
  selectAllListener = listener;
};

const notifySelectAll = selectedInquirySize => {
  const totalInquirySize = fetchInquries().length;
  if (selectAllListener) {
    if (selectedInquirySize === totalInquirySize) {
      selectAllListener(true);
    } else {
      selectAllListener(false);
    }
  }
};

export default {
  onInquirySelect,
  getSelectedInquiryLength,
  isInquirySelected,
  getAllSelectedInquries,
  attachEditingListener,
  resetEditing,
  attachSelectAllListener,
  attachRowSelectAllListener,
  attachFetchInquries,
  toggleSelectAll,
  resetSelectedInquries,
  removeEditingListener,
  removeRowSelectAllListener
};
