import React, { useCallback } from 'react';

import './styles.scss';

export default ({ selectedFields = {} } = {}) => {
  const renderString = useCallback(() => {
    let finalData = '';
    Object.keys(selectedFields).forEach(field => {
      if(selectedFields[field]) {
        finalData += `[${field}]`;
      }
    });
    return finalData;
  }, [selectedFields]);

  return (
    <div id={'selectedFieldContainer'}>
      <div>{renderString()}</div>
    </div>
  );
};
