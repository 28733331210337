import Listener from 'qs-helpers/Listener';
import { setCompanyMetaInCache, setCompanySettingsFirebaseInCache, setCompanyShareTextFirebaseInCache } from 'qs-data-manager/Company';
import { setGrtPmInCache } from 'qs-data-manager';

const listener = new Listener();
const LISTENERS_ATTACHED = {};
const END_POINT = {
  COMPANY_META: ({ companyId }) => `companies/${companyId}`,
  GRT_PM: ({ companyId }) => `grt-purchase-managers-companyIds/${companyId}`,
  COMPANY_SETTINGS: ({companyId}) => `company-experiments/${companyId}`,
  COMPANY_SHARE_TEXT: ({companyId}) => `custom-share-text/${companyId}`,
};

const attachCompanyMetaListener = ({ companyId }) => {
  if (!companyId) {
    return;
  }

  attachCompanyListener({ companyId });
  attachGrtPmListener({ companyId });
};

const attachCompanyListener = ({ companyId }) => {
  // Company meta listener
  const key = END_POINT.COMPANY_META({ companyId });

  if (LISTENERS_ATTACHED[key]) {
    return;
  }

  LISTENERS_ATTACHED[key] = true;
  listener.add({ key, type: 'value' }, snapshot => {
    const value = snapshot.val();
    setCompanyMetaInCache({data: value});
  });
};

const attachGrtPmListener = ({ companyId }) => {
  // Grt PM listener
  const key = END_POINT.GRT_PM({ companyId });

  if (LISTENERS_ATTACHED[key]) {
    return;
  }

  LISTENERS_ATTACHED[key] = true;
  listener.add({ key, type: 'value' }, snapshot => {
    const value = snapshot.val();
    setGrtPmInCache(value);
  });
};

const removeCompanyMetaListener = ({ companyId }) => {
  if (!companyId) {
    return;
  }

  const companyMetaKey = END_POINT.COMPANY_META({ companyId });
  const grtPmKey = END_POINT.GRT_PM({ companyId });

  listener.remove({ key: companyMetaKey, type: 'value' });
  listener.remove({ key: grtPmKey, type: 'value' });
};

const attachCompanySettingsFirebaseListener = companyId => {
  if (!companyId) {
    return;
  }

  attachCompanySettingsListener(companyId);
};

const attachCompanySettingsListener = companyId => {
  const key = END_POINT.COMPANY_SETTINGS({ companyId });

  if (LISTENERS_ATTACHED[key]) {
    return;
  }

  LISTENERS_ATTACHED[key] = true;
  listener.add({ key, type: 'value' }, snapshot => {

    //Default to en_US. If nothing is set then the value will be null
    const value = snapshot.val();
    setCompanySettingsFirebaseInCache({data: value});
  });
};

const removeCompanySettingsFirebaseListener = companyId => {
  if (!companyId) {
    return;
  }

  const settingsKey = END_POINT.COMPANY_SETTINGS({companyId});
  LISTENERS_ATTACHED[settingsKey] = false;
  listener.remove({ key: settingsKey, type: 'value' });
}

const attachCompanyShareTextFirebaseListener = companyId => {
  if(!companyId) {
    return
  }

  attachCompanyShareTextListener(companyId)
}

const attachCompanyShareTextListener = companyId => {
  const key = END_POINT.COMPANY_SHARE_TEXT({ companyId });

  if (LISTENERS_ATTACHED[key]) {
    return;
  }

  LISTENERS_ATTACHED[key] = true;
  listener.add({ key, type: 'value' }, snapshot => {

    //Default to en_US. If nothing is set then the value will be null
    const value = snapshot.val();
    setCompanyShareTextFirebaseInCache({data: value});
  });
};

const removeCompanyShareTextFirebaseListener = companyId => {
  if (!companyId) {
    return;
  }

  const shareKey = END_POINT.COMPANY_SHARE_TEXT({companyId});
  LISTENERS_ATTACHED[shareKey] = false;
  listener.remove({ key: shareKey, type: 'value' });
}

export {
  attachCompanyMetaListener,
  removeCompanyMetaListener,
  attachCompanySettingsFirebaseListener,
  removeCompanySettingsFirebaseListener,
  attachCompanyShareTextFirebaseListener,
  removeCompanyShareTextFirebaseListener
};
