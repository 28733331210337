import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Logo from 'qs-assets/Media/logo.png';
import FacebookLogo from 'qs-assets/Media/facebook.png';
import GoogleLogo from 'qs-assets/Media/google.png';
import Loader from 'qs-components/Common/Loader';

import './styles.css';
import { onLogin, AUTH_PROVIDERS, IS_LOGGING_IN } from 'qs-data-manager/Authentication';
import eventbus from 'eventing-bus';
import WithNavRef from 'qs-helpers/WithNavRef';
import Mixpanel from 'qs-data-manager/Mixpanel';
import UnauthorizedLogin from './UnauthorizedLogin';
import LoginErrorDialogue from './LoginErrorDialogue';

class Login extends Component {
  static propTypes = {
    onLogin: PropTypes.func,
    showRedirectionLoader: PropTypes.bool,
    loggingIn: PropTypes.bool,
    isNewUser: PropTypes.bool,
    isPlatinumUser: PropTypes.bool,
    userEmail: PropTypes.string,
    onGoogleLoginFail: PropTypes.func,
    setPlatinumUser: PropTypes.func,
    planType: PropTypes.string,
    setPlanType: PropTypes.func
  };

  // TODO: why are there two `isLoggingIns`
  state = {
    isLoggingIn: false,
    showLoader: {
      isLoggingIn: false
    },
    error: {
      message: '',
      didError: false
    },
    notAllowedToUse: false,
    userEmail: '',
    isNewUser: false
  };

  componentDidMount() {
    eventbus.on(IS_LOGGING_IN.eventbusKey, authState => {
      if (typeof authState.isLoggingIn !== 'undefined') {
        let loader = { ...this.state.showLoader };
        loader.isLoggingIn = authState.isLoggingIn;

        this.setState({
          isLoggingIn: authState.isLoggingIn,
          showLoader: loader
        });
      }

      if (typeof authState.didError !== 'undefined') {
        this.setState({
          error: {
            message: authState.message,
            didError: authState.didError
          }
        });
      }

      if (typeof authState.isNewUser !== 'undefined') {
        this.setState({
          isNewUser: authState.isNewUser
        });
      }

      if (typeof authState.notAllowedToUse !== 'undefined') {
        this.setState({
          notAllowedToUse: authState.notAllowedToUse,
          userEmail: authState.userEmail || ''
        });
      }
    });
  }

  onGoogleLogin = () => {
    const provider = AUTH_PROVIDERS.GOOGLE;
    Mixpanel.sendEvent({
      eventName: 'sign_in_button_clicked',
      props: {
        provider
      }
    });
    onLogin(provider);
  };

  onFacebookLogin = () => {
    const provider = AUTH_PROVIDERS.FACEBOOK;
    Mixpanel.sendEvent({
      eventName: 'sign_in_button_clicked',
      props: {
        provider
      }
    });
    onLogin(provider);
  };

  handleLogout = () => {
    this.setState({
      notAllowedToUse: false,
      userEmail: ''
    });
  };

  renderLoader = () => {
    const { showLoader } = this.state;
    const shouldShow = Object.values(showLoader || {}).filter(value => !!value).length;
    if (!shouldShow) {
      return null;
    }

    return (
      <div className="LoginCardLoading">
        <div className="LoginLoaderContainer">
          <Loader size="large" />
        </div>
      </div>
    );
  };

  closeErrorModal = () => {
    this.setState({
      error: {
        message: '',
        didError: false
      }
    });
  };

  redirectToPayment = () => {
    window.open('https://quicksell.co/pricing?ref=quicksell.co', '_blank');
  };

  render() {
    const { isNewUser, notAllowedToUse, userEmail, error } = this.state;

    return (
      <WithNavRef navigationRef={this.props}>
        <div className="Login full-height flex-r-center-center">
          <UnauthorizedLogin
            handleLogout={this.handleLogout}
            notAllowedToUse={notAllowedToUse}
            redirectToPayment={this.redirectToPayment}
            userEmail={userEmail}
          />

          <LoginErrorDialogue didError={error.didError} closeErrorModal={this.closeErrorModal} />

          <div className="LoginCard">
            {this.renderLoader()}
            <div className="LoginCardHeader">
              {isNewUser && (
                <div className="m-b-15">
                  <span style={{ color: 'red' }}>Please signup through QuickSell app.</span>
                  <a
                    className="m-l-15"
                    href="https://quicksell.co/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    QuickSell
                  </a>
                </div>
              )}
              <h2>GET STARTED</h2>
            </div>
            <div className="LoginCardBody">
              <img src={Logo} className="logo" alt="" />
              <h1>QuickSell</h1>
            </div>
            <div className="LoginCardFooter">
              <button onClick={this.onGoogleLogin} className="google-btn">
                <div className={'googleLogoContainer'}>
                  <img src={GoogleLogo} alt="" />
                </div>

                <span className="googleSignin">Sign in with Google</span>
              </button>
              <button onClick={this.onFacebookLogin} className="facebook-btn">
                <div className={'facebookImageContainer'}>
                  <img className={'image'} src={FacebookLogo} alt="" />
                </div>
                <span className={'facebookText'}>Continue with Facebook</span>
              </button>
            </div>
          </div>
        </div>
      </WithNavRef>
    );
  }
}

export default Login;
