import { nativeDB } from '../../DexieInit';
import * as Sentry from '@sentry/browser';

const deleteProductsFromNative = async (
  { productIds, catalogueId, changeCatalogueRow = true },
  changes
) => {
  if (!changes || !Array.isArray(changes.newProductList)) {
    console.error('deleteProductsFromNative: Missing data in object changes', changes);
    return;
  }

  const promises = [];

  if (changeCatalogueRow) {
    promises.push(nativeDB.catalogueRowMeta.put(changes.newCatalogueRow, catalogueId));
  }

  await Promise.all([
    nativeDB.productRowMeta.bulkDelete(productIds),
    nativeDB.productListMeta.put(changes.newProductList, catalogueId),
    ...promises
  ]);
};

const createNewProductsInNative = async ({ catalogueId }, changes) => {
  try {
    if (
      !changes ||
      !Array.isArray(changes.newProductList) ||
      !changes.productMeta ||
      !changes.newCatalogueRowCache
    ) {
      console.error('createNewProductsInNative: Missing data in object changes', changes);
      return;
    }

    const { newCatalogueRowCache, productMeta, newProductList } = changes;
    const productIds = Object.keys(productMeta);
    const products = productIds.map(id => productMeta[id]);

    await Promise.all([
      nativeDB.catalogueRowMeta.put(newCatalogueRowCache, catalogueId),
      nativeDB.productListMeta.put(newProductList, catalogueId),
      nativeDB.productRowMeta.bulkPut(products)
    ]);
  } catch (err) {
    console.log('createNewProductsInNative: Could not create new products in native');
    Sentry.captureException(err);
  }
};

// this is same as saveProductMetaChangesInNative. WTF?
const updateExistingProductsInNative = async (products = []) => {
  await nativeDB.productRowMeta.bulkPut(products);
};

const addProductsInNative = async ({ products, productsList, catalogueId, catalogueMeta }) => {
  await Promise.all([
    nativeDB.productListMeta.put(productsList, catalogueId),
    nativeDB.productRowMeta.bulkPut(products),
    nativeDB.catalogueRowMeta.put(catalogueMeta, catalogueId)
  ]);
};

const changeProductsListInNative = async ({ productsList, catalogueId }) => {
  await nativeDB.productListMeta.put(productsList, catalogueId);
};

const saveProductMetaChangesInNative = async ({ modifications }) => {
  try {
    await nativeDB.productRowMeta.bulkPut(modifications);
  } catch (err) {
    console.log('saveProductMetaChangesInNative: Could not save product changes into native', err);
    Sentry.captureException(err);
  }
};

const removeExistingPicturesMetaInNative = async pictureId => {
  try {
    await nativeDB.pictureUploadsMeta.delete(pictureId);
  } catch (error) {
    console.log(`removeExistingPicturesMetaInNative failed`);
  }
};

const updateExistingPicturesMetaInNative = async picturesMeta => {
  try {
    await nativeDB.pictureUploadsMeta.bulkPut(picturesMeta);
  } catch (error) {
    console.log(`updateExistingPictureMetaInNative failed`);
  }
};

const getPicturesFromNative = async numberToRetrieve =>
  nativeDB.pictureUploadsMeta
    .orderBy('timestamp')
    .limit(numberToRetrieve)
    .filter(imageMeta => imageMeta.state === 'pending')
    .toArray();

const getPicturesInUploadingStateFromNative = async () =>
  nativeDB.pictureUploadsMeta
    .where('state')
    .equalsIgnoreCase('uploading')
    .toArray();

const setPicturesMetaInNative = async newPicturesToUpload =>
  nativeDB.pictureUploadsMeta.bulkPut(newPicturesToUpload);

const clearPicturesMetaFromNative = async () => {
  try {
    await nativeDB.pictureUploadsMeta.clear();
  } catch (clearError) {
    console.error('clearPicturesMetaFromNative: could not clear the table');
    Sentry.captureException(clearError);
  }
};

export {
  deleteProductsFromNative,
  createNewProductsInNative,
  updateExistingProductsInNative,
  addProductsInNative,
  changeProductsListInNative,
  saveProductMetaChangesInNative,
  removeExistingPicturesMetaInNative,
  updateExistingPicturesMetaInNative,
  getPicturesFromNative,
  setPicturesMetaInNative,
  getPicturesInUploadingStateFromNative,
  clearPicturesMetaFromNative
};
