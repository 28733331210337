import request from './Request.js';
import Config from 'qs-config/AppConfig.js';
import { db } from 'qs-config/FirebaseConfig';
import * as Sentry from '@sentry/browser';

const updateFirebase = updates => db.ref().update(updates);

const getCatalogueIds = async () => {
  return request.authGet(`${Config.qsApiEndPoint}/v1/catalogue/all-catalogue-ids`);
};

const getBatchedCatalogueMeta = async catalogueIds => {
  const stringifiedCatalogueIds = JSON.stringify(catalogueIds);
  return request.authGet(
    `${Config.qsApiEndPoint}/v1/catalogue/catalogue-meta?catalogueIds=${stringifiedCatalogueIds}`
  );
};

const deleteCatalogues = catalogueIds =>
  request.authDelete(`${Config.qsApiEndPoint}/v1/catalogue`, { catalogueIds });

const updateCatalogue = ({ catalogueId, updates }) =>
  request.authPut(`${Config.qsApiEndPoint}/v1/catalogue`, { catalogueId, updates });

const searchCatalogues = async searchTerm => {
  const client = 'DESKTOP';
  const version = 1;
  return request.authGet(
    `${Config.searchEndPoint}/search/catalogue?query=${searchTerm}&client=${client}&version=${version}`,
    {}
  );
};

const createCatalogue = ({ title, catalogueId } = {}) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/catalogue`, {
    title,
    catalogueId
  });
};

const getProductList = async catalogueId => {
  return request.authGet(
    `${Config.qsApiEndPoint}/v1/catalogue/product-list?catalogueId=${catalogueId}`
  );
};

const getBatchedProductList = async (productIds = []) => {
  const stringifiedProductIds = JSON.stringify(productIds);
  return request.authPost(`${Config.qsApiEndPoint}/v1/product/product-meta`, {
    productIds: stringifiedProductIds
  });
};

// linkType can be NORMAL, RESELl
const createCataloguesLink = (catalogueIds, linkType = 'NORMAL') => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/showcase/link`, {
    catalogueIds,
    linkType
  });
};

const createNewProducts = (catalogueId, products) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/catalogue/catalogue-with-products`, {
    catalogueId,
    products
  });
};

const updateProduct = ({ productId, updates }) => {
  try {
    let revisedUpdates = { ...updates };
    if (typeof revisedUpdates.discount !== 'undefined') {
      revisedUpdates.discounted_price = revisedUpdates.discount;
      delete revisedUpdates.discount;
    }

    return request.authPut(`${Config.qsApiEndPoint}/v1/product`, {
      productId,
      updates: revisedUpdates
    });
  } catch (err) {
    console.log('updateProduct Api: Could not update products in remote', err);
    Sentry.captureException(err);
  }
};

const deleteProducts = (productIds, catalogueId) => {
  const data = [{ catalogueId, productIds }];
  return request.authDelete(`${Config.qsApiEndPoint}/v1/product/catalogues`, {
    data
  });
};

const catalogueScreenChanges = lastFetchDate => {
  return request.authGet(
    `${Config.qsApiEndPoint}/v1/catalogue/catalogues-screen/changes?lastFetchDate=${lastFetchDate}`
  );
};

const searchProducts = async (searchTerm = '', catalogueId, tags = []) => {
  const version = 1;
  const client = 'DESKTOP';
  const inStock = tags.indexOf('In stock') > -1;
  const outOfStock = tags.indexOf('Out of stock') > -1;
  const stringifiedArrays = JSON.stringify(tags);

  let data = null;

  if (inStock || outOfStock) {
    data = await searchWithStock({ catalogueId, inStock, outOfStock, tags, query: searchTerm });
  } else {
    const { data: searchData } = await request.authGet(
      `${Config.searchEndPoint}/search/catalogue/product?query=${searchTerm}&client=${client}&version=${version}&catalogueId=${catalogueId}&tags=${stringifiedArrays}`,
      {}
    );
    data = searchData;
  }

  return data;
};

const filterProducts = async ({
  searchTerm,
  catalogueId,
  type,
  price = null,
  minPrice = null,
  maxPrice = null,
  tags
}) => {
  const version = 1;
  const client = 'DESKTOP';
  const inStock = tags.indexOf('In stock') > -1;
  const outOfStock = tags.indexOf('Out of stock') > -1;

  const stringifiedTags = JSON.stringify(tags);

  let data = null;
  if (inStock || outOfStock) {
    data = await searchWithStock({
      catalogueId,
      query: searchTerm,
      tags,
      outOfStock,
      inStock,
      filterType: type,
      price,
      minPrice,
      maxPrice
    });
  } else {
    const { data: searchData } = await request.authGet(
      `${Config.searchEndPoint}/search/catalogue/product/filter?query=${searchTerm}&client=${client}&version=${version}&catalogueId=${catalogueId}&type=${type}&price=${price}&minPrice=${minPrice}&maxPrice=${maxPrice}&tags=${stringifiedTags}`
    );
    data = searchData;
  }

  return data;
};

const productsListScreenChanges = (lastFetchDate, catalogueId) => {
  return request.authGet(
    `${Config.qsApiEndPoint}/v1/product/catalogue-detail-screen/changes?catalogueId=${catalogueId}&lastFetchDate=${lastFetchDate}`
  );
};

const getCatalogueTags = async catalogueId => {
  return request.authGet(
    `${Config.qsApiEndPoint}/v1/catalogue/getCatalogueTags?catalogueId=${catalogueId}`
  );
};

const getCatalogueTagsV2 = async catalogueId => {
  return request.authGet(`${Config.qsApiEndPoint}/v2/catalogue/tags?catalogueId=${catalogueId}`);
};

const setCatalogueTagVisibility = async ({ catalogueId, tagId, visibility }) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/catalogue-tag/toggle-visibility`, {
    catalogueId,
    tagId,
    visibility
  });
};

const reorderCatalogueTags = ({ catalogueId, reorderedTags }) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/catalogue-tag/reorder`, {
    catalogueId,
    reorderedTags
  });
};

// PRIVATE NOTES APIs
const getPrivateNotes = productId => {
  return request.authGet(`${Config.qsApiEndPoint}/v1/product/private-notes?id=${productId}`);
};

const setPrivateNotes = (productIds, privateNotes) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/product/private-notes`, {
    productIds,
    privateNotes
  });
};

const getBasicInfo = async productId => {
  return request.authGet(`${Config.qsApiEndPoint}/v1/product?id=${productId}`);
};

// PRODUCT TAGS APIs
const createTagInProduct = (catalogueId, productIds, tagId) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/product-tag/add`, {
    catalogueId,
    productIds,
    tagId
  });
};

const deleteProductTag = ({ productIds, tagId }) => {
  return request.authDelete(`${Config.qsApiEndPoint}/v1/product-tag/delete-tags`, {
    productIds,
    tagId
  });
};

const reorderTags = ({ productId, reorderedTags }) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/product-tag/reorder`, {
    productId,
    reorderedTags
  });
};

const getCatalogueSettings = catalogueId => {
  return request.authGet(
    `${Config.qsApiEndPoint}/v1/catalogue/experiments?catalogueId=${catalogueId}`
  );
};

const changeCatalogueSettings = ({ catalogueId, changes }) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/catalogue/experiments`, {
    catalogueId,
    changes
  });
};

const changeOutOfStockVisibility = ({ catalogueId, outOfStockVisibility }) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/catalogue/out-of-stock-visibility`, {
    catalogueId,
    outOfStockVisibility
  });
};

const changeOrderOnOutOfStock = ({ catalogueId, allowOrderOnOutOfStock }) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/catalogue/allow-order-on-out-of-stock`, {
    catalogueId,
    allowOrderOnOutOfStock
  });
};

const getCompanySettings = () => {
  return request.authGet(`${Config.qsApiEndPoint}/v1/company/settings`);
};

const getCompanyPlanSettings = () => {
  return request.authGet(`${Config.qsApiEndPoint}/v3/company/plan`);
};

const getCompanyProductSettings = () => {
  return request.authGet(`${Config.qsApiEndPoint}/v1/company/request-demo`);
};

const getConfigDemoLink = () => {
  return request.authGet(`${Config.qsApiEndPoint}/v1/config/request-demo-link`);
};

const downloadCatalogueBrochure = catalogueId => {
  return request.authGet(
    `${Config.qsApiEndPoint}/v1/catalogue/brochure/download?catalogueId=${catalogueId}`
  );
};

const downloadCataloguePdf = ({ catalogueId, catalogueLink }) => {
  return request.authGet(
    `${Config.qsApiEndPoint}/v1/catalogue/pdf/download?catalogueId=${catalogueId}&catalogueLink=${catalogueLink}`
  );
};

const downloadCatalogueImages = ({ catalogueId }) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/catalogue/images/download`, { catalogueId });
};

const downloadCatalogueAsExcel = ({ catalogueId }) => {
  return request.authPost(`${Config.qsApiEndPoint}/v2/catalogue/excel/download`, { catalogueId });
};

const getCatalogueLink = async ({ catalogueId }) => {
  return request.authGet(
    `${Config.qsApiEndPoint}/v1/showcase/get-catalogue-link?catalogueId=${catalogueId}`
  );
};

const reorderProductInRemote = ({ productsList, catalogueId }) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/product/reorder`, {
    products: productsList,
    catalogueId
  });
};

const getInventory = productId => {
  return request.authGet(`${Config.qsApiEndPoint}/v1/product/inventory?id=${productId}`);
};

const setStockCount = ({ productId, stockCount }) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/inventory/app`, {
    productId,
    stock: stockCount
  });
};

const autoReduceQuantity = ({ productIds, value }) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/product/auto-reduce-quantity`, {
    productIds,
    autoReduceQuantity: value
  });
};

const setInventoryTracking = ({ productIds, value }) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/product/track-inventory`, {
    productIds,
    trackInventory: value
  });
};

const setOutOfStockVisibility = ({ productIds, value }) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/product/out-of-stock-visibility`, {
    productIds,
    outOfStockVisibility: value
  });
};

const setAllowOrderOnOutOfStock = ({ productIds, value }) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/product/allow-order-on-out-of-stock`, {
    productIds,
    allowOrderOnOutOfStock: value
  });
};

const bulkUpdateProducts = ({ productIds, updates }) => {
  return request.authPut(`${Config.qsApiEndPoint}/v1/product/bulk`, {
    productIds,
    updates
  });
};

const bulkUpdatePriceAndDiscount = updates => {
  return request.authPut(`${Config.qsApiEndPoint}/v1/product/price/bulk`, {
    updates
  });
};

const deleteProductPicture = ({ productId, pictureIds }) => {
  return request.authDelete(`${Config.qsApiEndPoint}/v1/product/pictures`, {
    productId,
    pictureIds
  });
};

const setBulkStockCount = ({ productIds, stock }) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/inventory/app/bulk`, { productIds, stock });
};

const changeCompanyOutOfStockVisibility = ({ value }) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/company/out-of-stock-visibility`, {
    outOfStockVisibility: value
  });
};

const changeCompanyAllowOrderOnOutOfStock = ({ value }) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/company/allow-order-on-out-of-stock`, {
    allowOrderOnOutOfStock: value
  });
};

const changeCompanyTrackInventory = ({ value }) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/company/track-inventory`, {
    trackInventory: value
  });
};

const changeCompanyAutoReduceInventory = ({ value }) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/company/auto-reduce-quantity`, {
    autoReduceQuantity: value
  });
};

const updateProductPicture = ({ productId, pictures }) => {
  return request.authPut(`${Config.qsApiEndPoint}/v1/product/pictures`, {
    productId,
    pictures
  });
};

const login = auth => request.post(Config.loginEndpoint, { auth });

const addNewPicture = ({ pictures, productId }) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/product/pictures`, {
    productId,
    pictures
  });
};

const updateExistingPicture = ({ productId, pictures }) => {
  return request.authPut(`${Config.qsApiEndPoint}/v1/product/pictures`, {
    productId,
    pictures
  });
};

const getCurrentPlan = () => {
  return request.authGet(`${Config.qsApiEndPoint}/v1/company/plan-features?storeType=DESKTOP`);
};

const getPaymentPlan = () => request.authGet(`${Config.qsApiEndPoint}/v1/plan-feature/desktop`);

const getCustomerPlan = companyId => {
  return db
    .ref(`paid-companies-meta/${companyId}/paymentPlan`)
    .once('value')
    .then(snapshot => snapshot.val());
};

const fetchExtendedTrialDuration = companyId => {
  return db
    .ref(`companies-trial-extended/${companyId}/trialStartTime`)
    .once('value')
    .then(snapshot => snapshot.val());
};

// ORDER

const getOrdersContacts = ({ catalogueId = 0 } = {}) => {
  return request.authGet(
    `${Config.qsApiEndPoint}/v1/order/contacts?catalogueId=${encodeURIComponent(catalogueId)}`
  );
};

const getContactOrders = ({ catalogueId = '', contact } = {}) => {
  return request.authGet(
    `${Config.qsApiEndPoint}/v2/order/contact-orders?catalogueId=${encodeURIComponent(
      catalogueId
    )}&contact=${encodeURIComponent(contact)}`
  );
};

const getOrderInquiries = ({ orderId } = {}) => {
  return request.authGet(
    `${Config.qsApiEndPoint}/v1/order/inquiries?orderId=${encodeURIComponent(
      orderId
    )}&parseText=${true}`
  );
};

const getOrdersContactsMeta = ({ catalogueId = '', contacts } = {}) => {
  return request.authPost(
    `${Config.qsApiEndPoint}/v1/order/contacts-meta?catalogueId=${encodeURIComponent(catalogueId)}`,
    { contacts }
  );
};

const downloadContactsOrderCsv = ({ catalogueId, contacts, timezone = '' }) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/order/contacts/excel/download/zip`, {
    catalogueId,
    contacts,
    timezone
  });
};

const deleteOrder = ({ orderId }) => {
  return request.authDelete(`${Config.qsApiEndPoint}/v1/order/app`, { orderId });
};

const deleteOrders = ({ orderIds }) => {
  return request.authDelete(`${Config.qsApiEndPoint}/v1/order/app/multi`, { orderIds });
};

const deleteInquiries = ({ inquiryIds }) => {
  return request.authDelete(`${Config.qsApiEndPoint}/v1/inquiry/app`, { inquiryIds });
};

const readInquiries = ({ inquiryIds }) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/showcase-visitor-meta/read/inquiries`, {
    inquiryIds
  });
};

const updateInquiriesPrice = ({ inquiryId, price, discountedPrice }) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/inquiry/price`, {
    inquiryId,
    price,
    discountedPrice
  });
};

const deleteContacts = ({ contacts, catalogueId }) => {
  return request.authDelete(`${Config.qsApiEndPoint}/v1/order/contacts/app/multi`, {
    contacts,
    catalogueId
  });
};

const downloadExcel = orderId => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/order/excel/download`, {
    orderId: orderId
  });
};

const downloadPDF = orderId => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/order/pdf/download`, { orderId: orderId });
};

const downloadZip = orderId => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/order/images/download`, { orderId: orderId });
};

const sendOrderToVendor = orderId => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/order/vendor`, { orderId });
};

const getShowCaseIdForResell = url => {
  return new Promise(resolve => {
    return db.ref(`domainMap/${url}`).on('value', snapshot => {
      db.ref(`domainMap/${url}`).off('value');
      resolve(snapshot.val());
    });
  });
};

const getCatalogId = showcaseId =>
  new Promise(resolve =>
    db.ref(`showcases/${showcaseId}/catalogueId`).on('value', snapshot => {
      db.ref(`showcases/${showcaseId}/catalogueId`);
      resolve(snapshot.val());
    })
  );

const getLinks = async productList => {
  const promiseArray = Object.keys(productList).map(productId => {
    return new Promise(resolve => {
      db.ref(`products/${productId}`).on('value', snapshot => {
        db.ref(`products/${productId}`).off('value');
        resolve(snapshot.val());
      });
    });
  });

  return await Promise.all(promiseArray);
};

const getCompanyName = companyId => {
  return new Promise(resolve => {
    db.ref(`companies/${companyId}/name`).on('value', snapshot => {
      db.ref(`companies/${companyId}/name`).off('value');
      resolve(snapshot.val());
    });
  });
};

const duplicateCatalogue = catalogueId => {
  return request.authPost(`${Config.qsApiEndPoint}/resell/duplicate-catalogue`, {
    catalogueId: catalogueId
  });
};

const getResellersProductMeta = catalogueId => {
  return new Promise(resolve => {
    db.ref(`catalogues/${catalogueId}`).on('value', snapshot => {
      db.ref(`catalogues/${catalogueId}`).off('value');
      resolve(snapshot.val());
    });
  });
};

const getBulkProductTags = productIds => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/catalogue-tag/bulk-tags`, {
    productIds
  });
};

const searchWithStock = ({
  catalogueId,
  inStock,
  outOfStock,
  tags,
  query,
  filterType,
  price,
  minPrice,
  maxPrice
}) => {
  const client = 'DESKTOP';
  const version = 1;
  const stringifiedTags = JSON.stringify(tags);
  return request.authGet(
    `${Config.qsApiEndPoint}/v1/catalogue-tag/search-products-stock?catalogueId=${catalogueId}&inStock=${inStock}&outOfStock=${outOfStock}&tags=${stringifiedTags}&query=${query}&client=${client}&version=${version}&filterType=${filterType}&price=${price}&minPrice=${minPrice}&maxPrice=${maxPrice}`
  );
};

const uploadExcel = ({ productsMeta, shouldUpdateExisting, catalogueId, uuid }) => {
  return request.authPost(`${Config.qsApiEndPoint}/v1/product/excel`, {
    productsMeta,
    shouldUpdateExisting,
    catalogueId,
    uuid
  });
};

const getCompanyAndUserObject = () => {
  return request.authGet(`${Config.qsApiEndPoint}/v1/company/company-user-object`);
};

const updateCompanySettings = (value, companyId) =>
  db.ref(`company-experiments/${companyId}`).update(value);

const getCountryCode = () => request.authGet(`${Config.qsApiEndPoint}/v1/config/ip-geo`);

const submitFeedback = ({
  feedbackData,
  companyId,
  userId,
  userName,
  phoneNumber,
  companyName
}) => {
  const keyFromFirebase = db
    .ref()
    .child('posts')
    .push().key;
  return db.ref(`android-user-feedback/${keyFromFirebase}`).update({
    id: keyFromFirebase,
    company_id: companyId,
    user_id: userId,
    feedback: feedbackData,
    user_name: userName,
    company_name: companyName,
    phone_no: phoneNumber
  });
};

const updateCompanyShareText = (value, companyId) =>
  db.ref(`custom-share-text/${companyId}`).update(value);

const updateUserMetaData = (value, userId) => db.ref(`users/${userId}`).update(value);

const updateCompanyMetaData = (value, companyId) => db.ref(`companies/${companyId}`).update(value);

export default {
  updateFirebase,
  getCatalogueIds,
  getBatchedCatalogueMeta,
  deleteCatalogues,
  updateCatalogue,
  searchCatalogues,
  createCatalogue,
  getProductList,
  createCataloguesLink,
  getBatchedProductList,
  deleteProducts,
  createNewProducts,
  updateProduct,
  catalogueScreenChanges,
  searchProducts,
  filterProducts,
  productsListScreenChanges,
  getCatalogueTags,
  getCatalogueTagsV2,
  getBulkProductTags,
  setCatalogueTagVisibility,
  reorderCatalogueTags,
  getPrivateNotes,
  setPrivateNotes,
  getBasicInfo,
  createTagInProduct,
  deleteProductTag,
  reorderTags,
  getCatalogueSettings,
  changeCatalogueSettings,
  changeOutOfStockVisibility,
  changeOrderOnOutOfStock,
  getCompanySettings,
  getCompanyPlanSettings,
  getCompanyProductSettings,
  getConfigDemoLink,
  downloadCatalogueBrochure,
  downloadCataloguePdf,
  downloadCatalogueImages,
  downloadCatalogueAsExcel,
  getCatalogueLink,
  reorderProductInRemote,
  getInventory,
  setStockCount,
  autoReduceQuantity,
  setInventoryTracking,
  setOutOfStockVisibility,
  setAllowOrderOnOutOfStock,
  bulkUpdateProducts,
  bulkUpdatePriceAndDiscount,
  deleteProductPicture,
  setBulkStockCount,
  changeCompanyOutOfStockVisibility,
  changeCompanyAllowOrderOnOutOfStock,
  changeCompanyTrackInventory,
  changeCompanyAutoReduceInventory,
  updateProductPicture,
  login,
  addNewPicture,
  updateExistingPicture,
  getCurrentPlan,
  getPaymentPlan,
  getCustomerPlan,
  fetchExtendedTrialDuration,

  // ORDER
  getOrdersContacts,
  getContactOrders,
  getOrderInquiries,
  getOrdersContactsMeta,
  downloadContactsOrderCsv,
  deleteOrder,
  deleteOrders,
  deleteInquiries,
  readInquiries,
  updateInquiriesPrice,
  deleteContacts,
  downloadExcel,
  downloadPDF,
  downloadZip,
  sendOrderToVendor,
  getShowCaseIdForResell,
  getCatalogId,
  getLinks,
  getCompanyName,
  duplicateCatalogue,
  getResellersProductMeta,
  searchWithStock,
  uploadExcel,
  getCompanyAndUserObject,

  //Company settings
  updateCompanySettings,
  updateCompanyShareText,
  submitFeedback,

  //Config
  getCountryCode,

  //user
  updateUserMetaData,

  //companies
  updateCompanyMetaData
};
