import React, { useState, useEffect } from 'react';
import ReactSwitch from 'react-switch';
import SettingsDataRow from '../../Common/SettingsDataRow/SettingsDataRow';
import {
  getCompanySettingsFirebaseFromCache,
  extractCompanyPaymentsSettings,
  setCompanyPaymentsSettings,
  attachCompanySettingsListenerFirebase,
  removeCompanySettingsListenerFirebase
} from 'qs-data-manager/Company';
import Loader from '../../../Common/Loader';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';

export default () => {
  const [paymentSettings, setPaymentSettings] = useState(
    extractCompanyPaymentsSettings(getCompanySettingsFirebaseFromCache())
  );
  const [paymentsError, setPaymentsError] = useState();

  useEffect(() => {
    const paymentsListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      //TODO check if the error must be shown
      if (loading || refreshing) {
        return;
      }

      if (err) {
        setPaymentsError('Something went wrong while fetching the payments information');
        return;
      }
      const paymentData = extractCompanyPaymentsSettings(data);
      setPaymentSettings(paymentData);
    };

    attachCompanySettingsListenerFirebase(paymentsListener);

    return () => removeCompanySettingsListenerFirebase(paymentsListener);
  }, []);

  const handleForcePayments = function(_, event) {
    //Stop the event from propogating so that this handler is not called twice
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    setCompanyPaymentsSettings(!paymentSettings);
  };

  const iconComponent = switchChecked => (
    <ReactSwitch
      checked={switchChecked}
      onChange={handleForcePayments}
      onColor="#C0DACE"
      onHandleColor="#4DA47A"
      handleDiameter={25}
      uncheckedIcon={false}
      checkedIcon={false}
      height={17}
      width={40}
    />
  );

  let paymentComponent;
  if (paymentSettings === false || paymentSettings === true) {
    paymentComponent = (
      <SettingsDataRow
        icon={iconComponent(paymentSettings)}
        title={'Payments'}
        onSectionClick={handleForcePayments}
      >
        <span>
          Collect payments on your catalogue and disallow visitors from confirming an order without
          payment
        </span>
      </SettingsDataRow>
    );
  } else if (paymentsError) {
    paymentComponent = <span>{paymentsError}</span>;
  } else {
    paymentComponent = <Loader size="large" />;
  }

  return <div>{paymentComponent}</div>;
};
