import React, { useState, useCallback, useEffect } from 'react';
import { CSV_UPLOADER_EB_KEY, EXCEL_UPLOAD_META } from 'qs-data-manager/Catalogues';
import RenderSelectedFields from './RenderSelectedFields';
import RenderExcelColumnName from './RenderExcelColumnName';
import { createProductsFromCSV } from 'qs-data-manager/Products';

import './styles.scss';
import eventbus from 'eventing-bus';
import Checkbox from 'qs-components/Common/CheckBox';

export default ({ excelColumnNames = [], csvData = [] } = {}) => {
  const [activeComponent, setActiveComponent] = useState({
    index: 0,
    id: EXCEL_UPLOAD_META.RENDER_ORDER[0]
  });
  const [componentCSVMapping, setComponentCSVMapping] = useState({});
  const [shouldUpdateExisting, setShouldUpdateExisting] = useState(true);

  const onExcelColumnNameClick = useCallback(
    excelColumnName => {
      const previousSelectedColumns = componentCSVMapping[activeComponent.id];

      let newSelectedColumns = null;
      if (previousSelectedColumns) {
        if (previousSelectedColumns[excelColumnName]) {
          newSelectedColumns = { ...previousSelectedColumns };
          delete newSelectedColumns[excelColumnName];
        } else {
          newSelectedColumns = { ...previousSelectedColumns, [excelColumnName]: true };
        }
      } else {
        newSelectedColumns = { [excelColumnName]: true };
      }

      setComponentCSVMapping(prevState => ({
        ...prevState,
        [activeComponent.id]: newSelectedColumns
      }));
    },
    [activeComponent, componentCSVMapping]
  );

  const onNextClick = useCallback(e => {
    e.stopPropagation();
    setActiveComponent(prevState => ({
      index: prevState.index + 1,
      id: EXCEL_UPLOAD_META.RENDER_ORDER[prevState.index + 1]
    }));
  }, []);

  const onBackClick = useCallback(e => {
    e.stopPropagation();
    setActiveComponent(prevState => ({
      index: prevState.index - 1,
      id: EXCEL_UPLOAD_META.RENDER_ORDER[prevState.index - 1]
    }));
  }, []);

  const cancelUpload = useCallback(() => {
    eventbus.publish(CSV_UPLOADER_EB_KEY, false);
  }, []);

  const onUpload = useCallback(
    e => {
      e.stopPropagation();
      createProductsFromCSV({
        headers: excelColumnNames,
        data: csvData,
        componentCSVMapping,
        shouldUpdateExisting
      });
    },
    [excelColumnNames, csvData, componentCSVMapping, shouldUpdateExisting]
  );

  const onChangeUpdateExisting = useCallback(() => {
    setShouldUpdateExisting(prevState => !prevState);
  }, []);

  const renderNext = useCallback(() => {
    if (activeComponent.index === EXCEL_UPLOAD_META.RENDER_ORDER.length - 1) {
      return;
    }

    if (
      componentCSVMapping[activeComponent.id] &&
      Object.keys(componentCSVMapping[activeComponent.id]).length
    ) {
      return (
        <div onClick={onNextClick} className={'nextButton'}>
          next
        </div>
      );
    }

    return null;
  }, [activeComponent, componentCSVMapping, onNextClick]);

  return (
    <div id={'UploaderContainer'}>
      <div id={'UploaderHeaderContainer'}>
        <div className={'leftContainer'}>
          {!(activeComponent.index === 0) && (
            <div onClick={onBackClick} className={'backButton'}>
              back
            </div>
          )}
          <div onClick={cancelUpload} className={'cancelUpload'}>
            cancel upload
          </div>
        </div>
        <div className={'uploaderTitle'}>csv uploader</div>
        <div className={'rightContainer'}>
          {activeComponent.index === EXCEL_UPLOAD_META.RENDER_ORDER.length - 1 ? (
            <div onClick={onUpload} className={'upload'}>
              Upload
            </div>
          ) : (
            <div onClick={onNextClick} className={'skip'}>
              skip
            </div>
          )}
          {renderNext()}
        </div>
      </div>

      <div className={'scrollableContainer'}>
        <div className={'uploadedProductCount'}>10 products</div>
        <div className={'componentTitle'}>{EXCEL_UPLOAD_META[activeComponent.id].title}</div>
        <RenderSelectedFields selectedFields={componentCSVMapping[activeComponent.id]} />

        {!!(activeComponent.id === EXCEL_UPLOAD_META.TITLE.id) ? (
          <div className={'titleMatchesContainer'}>
            <Checkbox checked={shouldUpdateExisting} onCheckChanged={onChangeUpdateExisting} />
            <div>Update existing product if title matches</div>
          </div>
        ) : null}

        <div className={'fieldsInstructionLabel'}>
          {`Click on a field to map it to the ${EXCEL_UPLOAD_META[activeComponent.id].title}`}
        </div>

        <RenderExcelColumnName
          excelColumnNames={excelColumnNames}
          selectedColumns={componentCSVMapping[activeComponent.id]}
          onColumnNameClick={onExcelColumnNameClick}
        />
      </div>
    </div>
  );
};
