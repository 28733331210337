import Listener from 'qs-helpers/Listener';
import OrderLib from 'qs-data-manager/Order';

const listener = new Listener();
const LISTENERS_ATTACHED = {};
const END_POINT = {
  INQUIRIES_COUNT: ({ companyId }) => `company-meta/${companyId}/unreadInquiries`
};

const attachInquiriesCountListener = ({ companyId }) => {
  if (!companyId) {
    return;
  }

  const key = END_POINT.INQUIRIES_COUNT({ companyId });

  if (LISTENERS_ATTACHED[key]) {
    return;
  }

  LISTENERS_ATTACHED[key] = true;
  let isAttachingListener = true;

  listener.add({ key, type: 'value' }, snapshot => {
    const value = snapshot.val();
    const ts = Date.now();
    let lastFetchTs = null;

    if (isAttachingListener) {
      lastFetchTs = ts;
      isAttachingListener = false;
    }

    OrderLib.handleUnreadInquiryChange({ unreadInquiryCount: value, ts, lastFetchTs });
  });
};

const removeInquiriesCountListener = ({ companyId }) => {
  const key = END_POINT.INQUIRIES_COUNT({ companyId });

  listener.remove({ key, type: 'value' });
};

export { attachInquiriesCountListener, removeInquiriesCountListener };
