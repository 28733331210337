import React, { useState, useCallback } from 'react';

import './styles.scss';
import { BOTTOM_SHEET_TABS } from 'qs-data-manager/ProductDetails';

export default ({ onChangeTab, activeTab } = {}) => {
  // const [activeTab, setActiveTab] = useState(BOTTOM_SHEET_TABS.BASIC_INFO);

  const changeActiveTab = useCallback(
    activeTab => {
      // setActiveTab(activeTab);
      onChangeTab(activeTab);
    },
    [onChangeTab]
  );

  const onBasicInfoClick = useCallback(() => {
    changeActiveTab(BOTTOM_SHEET_TABS.BASIC_INFO);
  }, [changeActiveTab]);

  const onInventoryClick = useCallback(() => {
    changeActiveTab(BOTTOM_SHEET_TABS.INVENTORY);
  }, [changeActiveTab]);

  const onNotesClick = useCallback(() => {
    changeActiveTab(BOTTOM_SHEET_TABS.PRIVATE_NOTES);
  }, [changeActiveTab]);

  return (
    <div id={'BottomSheetHeader'}>
      <div
        onClick={onBasicInfoClick}
        className={`option ${activeTab === BOTTOM_SHEET_TABS.BASIC_INFO ? 'activeTab' : ''}`}
      >
        basic info
      </div>
      <div
        onClick={onInventoryClick}
        className={`option ${activeTab === BOTTOM_SHEET_TABS.INVENTORY ? 'activeTab' : ''}`}
      >
        inventory
      </div>
      <div
        onClick={onNotesClick}
        className={`option ${activeTab === BOTTOM_SHEET_TABS.PRIVATE_NOTES ? 'activeTab' : ''}`}
      >
        notes
      </div>
    </div>
  );
};
