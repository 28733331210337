import React, { useState, useCallback, useEffect } from 'react';
import Checkbox from 'qs-components/Common/CheckBox';
import { selectedProducts } from 'qs-data-manager/Selected';
import { ReactComponent as DeleteIcon } from 'qs-assets/Media/trash.svg';
import { deleteProducts } from 'qs-data-manager/Products';
import './styles.scss';
import { getActiveCatalogueId, setActiveCatalogueId } from 'qs-data-manager/Catalogues';
import Mixpanel from 'qs-data-manager/Mixpanel';

export default ({ goBack } = {}) => {
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [deleteIconVisibility, setDeleteIconVisibility] = useState(false);

  useEffect(() => {
    const onSelectedValueChange = (all = {}) => {
      const visibility = !!Object.keys(all).length;
      const areAllSelected = !!selectedProducts.areAllSelected();

      setDeleteIconVisibility(visibility);
      setCheckboxValue(areAllSelected);
    };

    selectedProducts.activate();
    selectedProducts.addListener(onSelectedValueChange);

    Mixpanel.sendEvent({ eventName: 'edit_catalogue_tags_button_clicked' });

    return () => {
      selectedProducts.deactivate();
    };
  }, []);

  const deleteSelectedProducts = useCallback(
    e => {
      e.stopPropagation();
      const shouldRemove = window.confirm(
        'Are you sure you want to delete multiple product from catalogue?'
      );
      if (shouldRemove) {
        const productIds = Object.keys(selectedProducts.getAll() || {});
        deleteProducts(productIds);
        const catalogueId = getActiveCatalogueId();
        setActiveCatalogueId(catalogueId);
        goBack();
      }
    },
    [goBack]
  );

  const checkboxValueChange = useCallback(
    e => {
      e.stopPropagation();
      const newValue = !checkboxValue;
      setCheckboxValue(newValue);
      if (newValue) {
        selectedProducts.setAll();
      } else {
        selectedProducts.removeAll();
      }
    },
    [checkboxValue]
  );

  return (
    <div id={'EditProductHeader'} className={'productListHeader'}>
      <div className={'container editProductContainer'}>
        <div className={'leftContainer'}>
          {!!selectedProducts.getAllItemsCount() && (
            <>
              <div className={'checkboxContainer'}>
                <Checkbox checked={checkboxValue} onCheckChanged={checkboxValueChange} />
              </div>
              <div className={'selectAll'} onClick={checkboxValueChange}>
                {!!checkboxValue ? 'Deselect all' : 'Select all'}
              </div>
            </>
          )}
        </div>

        <div className={'rightContainer'}>
          {!!deleteIconVisibility ? (
            <div className={'deleteIconContainer'}>
              <DeleteIcon onClick={deleteSelectedProducts} className={'deleteIcon'} />
            </div>
          ) : (
            <div onClick={goBack} className={'back'}>
              back
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
