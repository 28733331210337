import React, { useEffect, useState, useRef } from 'react';
import {
  getCountryCodeConfigFromCache,
  attachCountryConfigListenerFirebase,
  attachCountryCodeConfigListener,
  getCountryCodeConfig,
  removeCountryCodeConfigListener,
  attachCountryConfigListener,
  removeCountryConfigListener,
  attachExperimentsListener,
  removeExperimentsListener,
  getExperimentsFromCache,
  getCountryConfigFromCache
} from 'qs-data-manager/Config';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import {
  extractPaymentGatewayOption,
  removeCompanySettingsListenerFirebase,
  attachCompanySettingsListenerFirebase,
  getCompanySettingsFirebaseFromCache
} from 'qs-data-manager/Company';
import { showPaymentSettingsOption } from 'qs-helpers/CompanySettings';
import ClickHandlerWithLoaderContainer from '../../../../Common/ClickHandlerWithLoader/ClickHandlerWithLoaderContainer';
import SettingsRow from '../../../Common/SettingsRow/SettingsRow';
import toastr from 'toastr';

export default ({onSelected, settingComponentId, classForContainer}) => {
  const companyExperiementsConfig = useRef(
    extractPaymentGatewayOption(getCompanySettingsFirebaseFromCache())
  );
  const experiementsConfig = useRef(extractPaymentGatewayOption(getExperimentsFromCache()));
  const countryPaymentConfig = useRef(extractPaymentGatewayOption(getCountryConfigFromCache()));

  const [showPaymentGateway, togglePaymentGateway] = useState(
    showPaymentSettingsOption(
      companyExperiementsConfig.current,
      experiementsConfig.current,
      countryPaymentConfig.current
    )
  );

  //Attach cache listeners for company experiments
  useEffect(() => {
    const companyExperimentsListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (loading || refreshing) {
        return;
      }

      if (err) {
        toastr.error('Something went wrong while fetching the info for payment option');
        return;
      }

      companyExperiementsConfig.current = extractPaymentGatewayOption(data);
      togglePaymentGatewayOption();
    };

    attachCompanySettingsListenerFirebase(companyExperimentsListener);

    return () => removeCompanySettingsListenerFirebase(companyExperimentsListener);
  }, []);

  //Attach cahce listeners for experiments
  useEffect(() => {
    const experimentsListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (loading || refreshing) {
        return;
      }

      if (err) {
        toastr.error('Something went wrong while fetching the info for payment option');
        return;
      }

      experiementsConfig.current = extractPaymentGatewayOption(data);
      togglePaymentGatewayOption();
    };

    attachExperimentsListener(experimentsListener);

    return () => removeExperimentsListener(experimentsListener);
  }, []);

  // Make call to fetch the country code of the user
  useEffect(() => {
    const ipData = getCountryCodeConfigFromCache();
    //No need to query the ip api call again
    if (ipData) {
      attachCountryConfigListenerFirebase(ipData.geo.countryCode);
      return;
    }

    const countryCodeListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (loading || refreshing || err) {
        return;
      }

      if (data) {
        attachCountryConfigListenerFirebase(data.geo.countryCode);
      }
    };

    attachCountryCodeConfigListener(countryCodeListener);
    getCountryCodeConfig();

    return () => removeCountryCodeConfigListener(countryCodeListener);
  }, []);

  // listen to country config firebase listener
  useEffect(() => {
    const countryListener = function(error, payload) {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);

      if (loading || refreshing) {
        return;
      }

      if (err) {
        toastr.error('Something went wrong while fetching the necessary config for payment option');
        return;
      }

      if (data) {
        countryPaymentConfig.current = extractPaymentGatewayOption(data);
        togglePaymentGatewayOption();
      }
    };

    attachCountryConfigListener(countryListener);

    return () => removeCountryConfigListener(countryListener);
  }, []);

  const togglePaymentGatewayOption = function() {
    if (
      showPaymentSettingsOption(
        companyExperiementsConfig.current,
        experiementsConfig.current,
        countryPaymentConfig.current
      )
    ) {
      togglePaymentGateway(true);
      return;
    }

    togglePaymentGateway(false);
  };

  let paymentGatewayRow = null;
  if (showPaymentGateway) {
    paymentGatewayRow = (
      <ClickHandlerWithLoaderContainer
        clickProps={{
          clickHandler: onSelected,
          clickParams: settingComponentId
        }}
        type="button"
        classForContainer={classForContainer}
      >
        <SettingsRow
          imageSrc={'https://web.quicksell.co/static/media/ic_quicksell_payments.png'}
          title="QuickSell Payment Settings"
        >
          <span>Manage your payment settings</span>
        </SettingsRow>
      </ClickHandlerWithLoaderContainer>
    );
  }

  return paymentGatewayRow;
};
