import moment from 'moment-timezone';
import cloneDeep from 'lodash.clonedeep';
import eventbus from 'eventing-bus';

// import Bugsnag from '../../Lib/Bugsnag';
import Api from 'qs-services/Api';
import * as Sentry from "@sentry/browser";
import CommonError from 'qs-components/Common/CommonError/CommonError';

const OPERATION_STATUS = {
  LOADING: 'LOADING',
  REFRESHING: 'REFRESHING',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
  PREPARING: 'PREPARING',
  PROGRESS: 'PROGRESS',
  UPDATE: 'UPDATE'
};

const SECTION_HEADER = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  THIS_WEEK: 'This week',
  THIS_MONTH: 'This month',
  LAST_MONTH: 'Last month',
  OLDER: 'Older'
};

const MERCHANT_APPROVAL = {
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED'
};

const FILTER = {
  ORDER_ITEMS: 'ORDER_ITEMS',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED'
};

const UNREAD_INQUIRIES_COUNT = {
  EVENT_BUS_KEY: 'UNREAD_INQUIRIES_COUNT',
  LOCAL_STORAGE_KEY: 'UNREAD_INQUIRIES_COUNT_LS',
  unreadInquiries: 0,
  lastFetchTs: null
};

const CONTACT_COLORS = ['#FFF'];

// Data cache
let contactsCache = {};
let contactsMetaCache = {};
let contactOrdersCache = {};
let orderInquiriesCache = {};

// Promises cache
let getContactsPromise = {};
let getContactOrdersPromise = {};
let getOrderInquiriesPromise = {};
let acceptInquiryPromise = {};
let rejectInquiryPromise = {};
let acceptAllInquiriesPromise = {};
let rejectAllInquiriesPromise = {};
let downloadExcelPromise = {};
let downloadContactsExcelPromise = {};
let mailExcelPromise = {};
let mailContactsExcelPromise = {};
let downloadPdfPromise = {};
let mailPdfPromise = {};
let deleteOrderPromise = {};
let setStockCountPromise = {};

// Listeners
let contactsListeners = {};
let contactOrdersListeners = {};
let contactMetaListeners = {};
let orderInquiriesListeners = {};

const notifyContactsListeners = ({ catalogueId = '' }, err, payload) => {
  if (!contactsListeners[catalogueId]) {
    return;
  }

  contactsListeners[catalogueId].forEach(listener => {
    listener(err, cloneDeep(payload));
  });
};

const notifyContactOrderListeners = ({ catalogueId = '', contact }, err, payload) => {
  if (!contactOrdersListeners[catalogueId] || !contactOrdersListeners[catalogueId][contact]) {
    return;
  }

  contactOrdersListeners[catalogueId][contact].forEach(listener => {
    listener(err, cloneDeep(payload));
  });
};

const notifyContactMetaListeners = ({ catalogueId = '', contact }, meta) => {
  if (!contactMetaListeners[catalogueId] || !contactMetaListeners[catalogueId][contact]) {
    return;
  }

  contactMetaListeners[catalogueId][contact].forEach(listener => {
    listener(cloneDeep(meta));
  });
};

const notifyOrderInquiriesListeners = ({ orderId }, err, payload) => {
  if (!orderInquiriesListeners[orderId]) {
    return;
  }

  orderInquiriesListeners[orderId].forEach(listener => {
    listener(err, cloneDeep(payload));
  });
};

const reset = () => {
  contactsCache = {};
  contactsMetaCache = {};
  contactOrdersCache = {};
  orderInquiriesCache = {};

  getContactsPromise = {};
  getContactOrdersPromise = {};
  getOrderInquiriesPromise = {};
  acceptInquiryPromise = {};
  rejectInquiryPromise = {};
  acceptAllInquiriesPromise = {};
  rejectAllInquiriesPromise = {};
  downloadExcelPromise = {};
  downloadContactsExcelPromise = {};
  mailExcelPromise = {};
  mailContactsExcelPromise = {};
  downloadPdfPromise = {};
  mailPdfPromise = {};
  deleteOrderPromise = {};

  contactsListeners = {};
  contactOrdersListeners = {};
  contactMetaListeners = {};
  orderInquiriesListeners = {};
};

const getContacts = ({ catalogueId = '' }) => {
  if (contactsCache[catalogueId]) {
    notifyContactsListeners({ catalogueId }, null, {
      status: OPERATION_STATUS.REFRESHING,
      data: contactsCache[catalogueId]
    });
  } else {
    notifyContactsListeners({ catalogueId }, null, {
      status: OPERATION_STATUS.LOADING
    });
  }

  if (!getContactsPromise[catalogueId]) {
    getContactsPromise[catalogueId] = Api.getOrdersContacts({ catalogueId });
  }

  getContactsPromise[catalogueId]
    .then(response => {
      const { contacts } = response;
      contactsCache[catalogueId] = contacts;

      notifyContactsListeners({ catalogueId }, null, {
        status: OPERATION_STATUS.SUCCESS,
        data: contacts
      });

      delete getContactsPromise[catalogueId];
    })
    .catch(err => {
      notifyContactsListeners({ catalogueId }, err);

      delete getContactsPromise[catalogueId];

      if (err && err.message !== 'Network request failed') {
        // Bugsnag.notify(err, {
        //   file: 'Services/DataManager/Order.js',
        //   function: 'getContacts',
        //   params: { catalogueId }
        // });
      }
    });
};

const attachContactsListener = ({ catalogueId = '' }, listener) => {
  if (!contactsListeners[catalogueId]) {
    contactsListeners[catalogueId] = [];
  }

  contactsListeners[catalogueId].push(listener);
};

const removeContactsListener = ({ catalogueId = '' }, listener) => {
  if (!contactsListeners[catalogueId]) {
    return;
  }

  const pos = contactsListeners[catalogueId].indexOf(listener);

  if (pos !== -1) {
    contactsListeners[catalogueId].splice(pos, 1);
  }
};

const getContactOrders = ({ catalogueId = '', contact }) => {
  if (!contactOrdersCache[catalogueId]) {
    contactOrdersCache[catalogueId] = {};
  }

  if (!getContactOrdersPromise[catalogueId]) {
    getContactOrdersPromise[catalogueId] = {};
  }

  if (contactOrdersCache[catalogueId][contact]) {
    notifyContactOrderListeners({ catalogueId, contact }, null, {
      status: OPERATION_STATUS.REFRESHING,
      data: contactOrdersCache[catalogueId][contact]
    });
  } else {
    notifyContactOrderListeners({ catalogueId, contact }, null, {
      status: OPERATION_STATUS.LOADING
    });
  }

  if (!getContactOrdersPromise[catalogueId][contact]) {
    getContactOrdersPromise[catalogueId][contact] = Api.getContactOrders({ catalogueId, contact });
  }

  getContactOrdersPromise[catalogueId][contact]
    .then(({ orders }) => {
      contactOrdersCache[catalogueId][contact] = orders;

      notifyContactOrderListeners({ catalogueId, contact }, null, {
        status: OPERATION_STATUS.SUCCESS,
        data: orders
      });

      delete getContactOrdersPromise[catalogueId][contact];
    })
    .catch(err => {
      notifyContactOrderListeners({ catalogueId, contact }, err);

      delete getContactOrdersPromise[catalogueId][contact];

      if (err && err.message !== 'Network request failed') {
        // Bugsnag.notify(err, {
        //   file: 'Services/DataManager/Order.js',
        //   function: 'getContactOrders',
        //   params: { catalogueId, contact }
        // });
      }
    });
};

const attachContactOrdersListener = ({ catalogueId = '', contact }, listener) => {
  if (!contactOrdersListeners[catalogueId]) {
    contactOrdersListeners[catalogueId] = {};
  }

  if (!contactOrdersListeners[catalogueId][contact]) {
    contactOrdersListeners[catalogueId][contact] = [];
  }

  contactOrdersListeners[catalogueId][contact].push(listener);
};

const removeContactOrdersListener = ({ catalogueId = '', contact }, listener) => {
  if (!contactOrdersListeners[catalogueId] || !contactOrdersListeners[catalogueId][contact]) {
    return;
  }

  const pos = contactOrdersListeners[catalogueId][contact].indexOf(listener);

  if (pos !== -1) {
    contactOrdersListeners[catalogueId][contact].splice(pos, 1);
  }
};

const getOrderInquiries = ({ orderId }) => {
  if (orderInquiriesCache[orderId]) {
    notifyOrderInquiriesListeners({ orderId }, null, {
      status: OPERATION_STATUS.REFRESHING,
      data: orderInquiriesCache[orderId]
    });
  } else {
    notifyOrderInquiriesListeners({ orderId }, null, {
      status: OPERATION_STATUS.LOADING
    });
  }

  if (!getOrderInquiriesPromise[orderId]) {
    getOrderInquiriesPromise[orderId] = Api.getOrderInquiries({ orderId });
  }

  getOrderInquiriesPromise[orderId]
    .then((orderInquirydata) => {
      orderInquiriesCache[orderId] = orderInquirydata;

      notifyOrderInquiriesListeners({ orderId }, null, {
        status: OPERATION_STATUS.SUCCESS,
        data: orderInquirydata
      });

      delete getOrderInquiriesPromise[orderId];
    })
    .catch(err => {
      notifyOrderInquiriesListeners({ orderId }, err);

      delete getOrderInquiriesPromise[orderId];

      if (err && err.message !== 'Network request failed') {
        // Bugsnag.notify(err, {
        //   file: 'Services/DataManager/Order.js',
        //   function: 'getOrderInquiries',
        //   params: { orderId }
        // });
      }
    });
};

const getOrderInquiriesIfOrderExists = function(orderId) {
  return orderInquiriesCache[orderId]
}

const attachOrderInquiriesListener = ({ orderId }, listener) => {
  if (!orderInquiriesListeners[orderId]) {
    orderInquiriesListeners[orderId] = [];
  }

  orderInquiriesListeners[orderId].push(listener);
};

const removeOrderInquiriesListener = ({ orderId }, listener) => {
  if (!orderInquiriesListeners[orderId] || !orderInquiriesListeners[orderId]) {
    return;
  }

  const pos = orderInquiriesListeners[orderId].indexOf(listener);

  if (pos !== -1) {
    orderInquiriesListeners[orderId].splice(pos, 1);
  }
};

const getContactsMeta = async ({ catalogueId = '', contacts }) => {
  const { meta } = await Api.getOrdersContactsMeta({ catalogueId, contacts });

  if (!contactsMetaCache[catalogueId]) {
    contactsMetaCache[catalogueId] = {};
  }

  meta.forEach(item => {
    contactsMetaCache[catalogueId][item.phone] = item;
    notifyContactMetaListeners({ catalogueId, contact: item.phone }, item);
  });
};

const attachContactMetaListener = async ({ catalogueId = '', contact }, listener) => {
  if (!contactMetaListeners[catalogueId]) {
    contactMetaListeners[catalogueId] = {};
  }

  if (!contactMetaListeners[catalogueId][contact]) {
    contactMetaListeners[catalogueId][contact] = [];
  }

  contactMetaListeners[catalogueId][contact].push(listener);

  if (contactsMetaCache[catalogueId] && contactsMetaCache[catalogueId][contact]) {
    listener(contactsMetaCache[catalogueId][contact]);
  }
};

const removeContactMetaListener = async ({ catalogueId = '', contact }, listener) => {
  if (!contactMetaListeners[catalogueId] || !contactMetaListeners[catalogueId][contact]) {
    return;
  }

  const pos = contactMetaListeners[catalogueId][contact].indexOf(listener);

  if (pos === -1) {
    return;
  }

  contactMetaListeners[catalogueId][contact].splice(pos, 1);

  if (!contactMetaListeners[catalogueId][contact].length) {
    delete contactMetaListeners[catalogueId][contact];
  }
};

const acceptInquiry = async ({ orderId, inquiryId }) => {
  if (acceptInquiryPromise[inquiryId]) {
    return acceptInquiryPromise[inquiryId];
  }

  acceptInquiryPromise[inquiryId] = Api.acceptInquiries({ inquiryIds: [inquiryId] });

  await acceptInquiryPromise[inquiryId];

  delete acceptInquiryPromise[inquiryId];

  const {inquiries} = orderInquiriesCache[orderId]
  for (let i = 0; i < inquiries.length; i += 1) {
    if (inquiries[i].inquiryId === inquiryId) {
      inquiries[i].merchantApproval = MERCHANT_APPROVAL.ACCEPTED;
      break;
    }
  }
};

const setStockCount = async ({ orderId, inquiryId, productId, stockCount }) => {
  if (setStockCountPromise[inquiryId]) {
    return setStockCountPromise[inquiryId];
  }

  setStockCountPromise[inquiryId] = new Promise((resolve, reject) => {
    Api.setStockCount({ productId, stockCount })
      .then(() => {
        const {inquiries} = orderInquiriesCache[orderId]
        for (let i = 0; i < inquiries.length; i += 1) {
          if (inquiries[i].inquiryId === inquiryId) {
            inquiries[i].stockCount = stockCount;
            break;
          }
        }

        resolve();
        delete setStockCountPromise[inquiryId];
      })
      .catch(ex => {
        reject(ex);
        delete setStockCountPromise[inquiryId];

        if (ex && ex.message !== 'Network request failed') {
          // Bugsnag.notify(ex, {
          //   file: 'Services/DataManager/Order.js',
          //   function: 'setStockCount',
          //   params: { orderId, inquiryId, productId, stockCount }
          // });
        }
      });
  });
};

const rejectInquiry = async ({ orderId, inquiryId }) => {
  if (rejectInquiryPromise[inquiryId]) {
    return rejectInquiryPromise[inquiryId];
  }

  rejectInquiryPromise[inquiryId] = Api.rejectInquiries({ inquiryIds: [inquiryId] });

  await rejectInquiryPromise[inquiryId];

  delete rejectInquiryPromise[inquiryId];

  const {inquiries} = orderInquiriesCache[orderId]
  for (let i = 0; i < inquiries.length; i += 1) {
    if (inquiries[i].inquiryId === inquiryId) {
      inquiries[i].merchantApproval = MERCHANT_APPROVAL.REJECTED;
      break;
    }
  }
};

const acceptAllInquiries = async ({ orderId }) => {
  if (acceptAllInquiriesPromise[orderId]) {
    return acceptAllInquiriesPromise[orderId];
  }

  acceptAllInquiriesPromise[orderId] = new Promise(async (resolve, reject) => {
    try {
      const inquiryIds = orderInquiriesCache[orderId].inquiries.map(inquiry => inquiry.inquiryId);

      await Api.acceptInquiries({ inquiryIds });

      orderInquiriesCache[orderId] = orderInquiriesCache[orderId].inquiries.map(inquiry => ({
        ...inquiry,
        merchantApproval: MERCHANT_APPROVAL.ACCEPTED
      }));

      resolve();
    } catch (ex) {
      reject(ex);
      Sentry.captureException(ex);
    }

    delete acceptAllInquiriesPromise[orderId];
  });

  return acceptAllInquiriesPromise[orderId];
};

const rejectAllInquiries = async ({ orderId }) => {
  if (rejectAllInquiriesPromise[orderId]) {
    return rejectAllInquiriesPromise[orderId];
  }

  rejectAllInquiriesPromise[orderId] = new Promise(async (resolve, reject) => {
    try {
      const inquiryIds = orderInquiriesCache[orderId].inquiries.map(inquiry => inquiry.inquiryId);

      await Api.rejectInquiries({ inquiryIds });

      orderInquiriesCache[orderId] = orderInquiriesCache[orderId].inquiries.map(inquiry => ({
        ...inquiry,
        merchantApproval: MERCHANT_APPROVAL.REJECTED
      }));

      resolve();
    } catch (ex) {
      reject(ex);
      Sentry.captureException(ex);
    }

    delete rejectAllInquiriesPromise[orderId];
  });

  return rejectAllInquiriesPromise[orderId];
};

const createSections = (list, dateField, { colors } = {}) => {
  const dataBlob = {};

  const startOfToday = moment().startOf('day');
  const startOfYesterday = moment()
    .subtract(1, 'day')
    .startOf('day');
  const startOfWeek = moment().startOf('isoWeek');
  const startOfMonth = moment().startOf('month');
  const startOfLastMonth = moment()
    .subtract(1, 'month')
    .startOf('month');
  const endOfLastMonth = moment()
    .subtract(1, 'month')
    .endOf('month');

  list.forEach((item, index) => {
    const date = moment(item[dateField] || new Date());

    if (colors && colors.length) {
      item.color = colors[index % colors.length];
    }

    if (date.isSameOrAfter(startOfToday)) {
      if (!dataBlob.TODAY) {
        dataBlob.TODAY = [];
      }

      dataBlob.TODAY.push(item);
    } else if (date.isSameOrAfter(startOfYesterday)) {
      if (!dataBlob.YESTERDAY) {
        dataBlob.YESTERDAY = [];
      }

      dataBlob.YESTERDAY.push(item);
    } else if (date.isSameOrAfter(startOfWeek)) {
      if (!dataBlob.THIS_WEEK) {
        dataBlob.THIS_WEEK = [];
      }

      dataBlob.THIS_WEEK.push(item);
    } else if (date.isSameOrAfter(startOfMonth)) {
      if (!dataBlob.THIS_MONTH) {
        dataBlob.THIS_MONTH = [];
      }

      dataBlob.THIS_MONTH.push(item);
    } else if (date.isSameOrAfter(startOfLastMonth) && date.isBefore(endOfLastMonth)) {
      if (!dataBlob.LAST_MONTH) {
        dataBlob.LAST_MONTH = [];
      }

      dataBlob.LAST_MONTH.push(item);
    } else {
      if (!dataBlob.OLDER) {
        dataBlob.OLDER = [];
      }

      dataBlob.OLDER.push(item);
    }
  });

  const sectionIds = [];
  if (dataBlob.TODAY) {
    sectionIds.push('TODAY');
  }
  if (dataBlob.YESTERDAY) {
    sectionIds.push('YESTERDAY');
  }
  if (dataBlob.THIS_WEEK) {
    sectionIds.push('THIS_WEEK');
  }
  if (dataBlob.THIS_MONTH) {
    sectionIds.push('THIS_MONTH');
  }
  if (dataBlob.LAST_MONTH) {
    sectionIds.push('LAST_MONTH');
  }
  if (dataBlob.OLDER) {
    sectionIds.push('OLDER');
  }

  const sections = sectionIds.map(sectionId => ({
    title: sectionId,
    data: dataBlob[sectionId]
  }));

  return { dataBlob, sections, sectionIds };
};

const downloadExcel = ({ orderId }, callback) => {
  callback(null, { status: OPERATION_STATUS.PREPARING });

  if (downloadExcelPromise[orderId]) {
    return downloadExcelPromise[orderId];
  }

  downloadExcelPromise[orderId] = new Promise(async (resolve, reject) => {
    try {
      const { excelLink, fileName } = await Api.downloadOrderCsv({
        orderId,
        timezone: moment.tz.guess()
      });
      resolve({ excelLink, fileName });
    } catch (ex) {
      reject(ex);
      Sentry.captureException(ex);
    }

    delete downloadExcelPromise[orderId];
  });

  downloadExcelPromise[orderId]
    .then(({ localFilePath }) => {
      callback(null, {
        status: OPERATION_STATUS.SUCCESS,
        value: localFilePath
      });
    })
    .catch(err => {
      callback(err);

      if (err && err.message !== 'Network request failed') {
        // Bugsnag.notify(err, {
        //   file: 'Services/DataManager/Order.js',
        //   function: 'downloadExcel',
        //   params: { orderId }
        // });
      }
    });
};

const downloadContactsExcel = ({ catalogueId, contacts = [] }, callback) => {
  callback(null, { status: OPERATION_STATUS.PREPARING });

  downloadContactsExcelPromise[contacts] = new Promise(async (resolve, reject) => {
    try {
      const { fileLink } = await Api.downloadContactsOrderCsv({
        catalogueId,
        contacts,
        timezone: moment.tz.guess()
      });
      resolve({ fileLink });
    } catch (ex) {
      reject(ex);
      Sentry.captureException(ex);
    }

    delete downloadContactsExcelPromise[contacts];
  });

  downloadContactsExcelPromise[contacts]
    .then(({ fileLink }) => {
      callback(null, {
        status: OPERATION_STATUS.SUCCESS,
        value: fileLink
      });
    })
    .catch(err => {
      callback(err);

      if (err && err.message !== 'Network request failed') {
        // Bugsnag.notify(err, {
        //   file: 'Services/DataManager/Order.js',
        //   function: 'downloadContactsExcel',
        //   params: { catalogueId }
        // });
      }
    });
};

const mailExcel = ({ orderId }) => {
  if (mailExcelPromise[orderId]) {
    return mailExcelPromise[orderId];
  }

  mailExcelPromise[orderId] = new Promise(async (resolve, reject) => {
    try {
      const { email } = await Api.mailOrderCsv({ orderId, timezone: moment.tz.guess() });

      resolve({ email });
    } catch (ex) {
      reject(ex);

      if (ex && ex.message !== 'Network request failed') {
        Sentry.captureException(ex);
      }
    }

    delete mailExcelPromise[orderId];
  });

  return mailExcelPromise[orderId];
};

const mailContactsExcel = ({ catalogueId, contacts = [] }) => {
  if (mailContactsExcelPromise[catalogueId]) {
    return mailContactsExcelPromise[catalogueId];
  }

  mailContactsExcelPromise[catalogueId] = new Promise(async (resolve, reject) => {
    try {
      const { email } = await Api.mailContactsOrderCsv({
        catalogueId,
        contacts,
        timezone: moment.tz.guess()
      });

      resolve({ email });
    } catch (ex) {
      reject(ex);

      if (ex && ex.message !== 'Network request failed') {
        Sentry.captureException(ex);
      }
    }

    delete mailContactsExcelPromise[catalogueId];
  });

  return mailContactsExcelPromise[catalogueId];
};

const downloadPdf = ({ orderId }, callback) => {
  callback(null, { status: OPERATION_STATUS.PREPARING });

  if (downloadPdfPromise[orderId]) {
    return downloadPdfPromise[orderId];
  }

  downloadPdfPromise[orderId] = new Promise(async (resolve, reject) => {
    try {
      const { pdfLink, fileName } = await Api.downloadOrderPdf({
        orderId,
        timezone: moment.tz.guess()
      });

      resolve({ pdfLink, fileName });
    } catch (ex) {
      reject(ex);
      Sentry.captureException(ex);
    }

    delete downloadPdfPromise[orderId];
  });

  downloadPdfPromise[orderId]
    .then(({ localFilePath }) => {
      callback(null, {
        status: OPERATION_STATUS.SUCCESS,
        value: localFilePath
      });
    })
    .catch(err => {
      callback(err);

      if (err && err.message !== 'Network request failed') {
        // Bugsnag.notify(err, {
        //   file: 'Services/DataManager/Order.js',
        //   function: 'downloadPdf',
        //   params: { orderId }
        // });
      }
    });
};

const mailPdf = ({ orderId }) => {
  if (mailPdfPromise[orderId]) {
    return mailPdfPromise[orderId];
  }

  mailPdfPromise[orderId] = new Promise(async (resolve, reject) => {
    try {
      const { email } = await Api.mailOrderPdf({ orderId, timezone: moment.tz.guess() });

      resolve({ email });
    } catch (ex) {
      reject(ex);

      if (ex && ex.message !== 'Network request failed') {
        Sentry.captureException(ex);
      }
    }

    delete mailPdfPromise[orderId];
  });

  return mailPdfPromise[orderId];
};

const deleteOrder = ({ orderId, catalogueId = '', contact }) => {
  if (deleteOrderPromise[orderId]) {
    return deleteOrderPromise[orderId];
  }

  deleteOrderPromise[orderId] = new Promise(async (resolve, reject) => {
    try {
      await Api.deleteOrder({ orderId });

      let shouldGoBackToContacts = false;

      const inquiries = orderInquiriesCache[orderId].inquiries || [];

      const itemCount = inquiries.length;
      const unreadItemCount = inquiries.filter(inquiry => !inquiry.read).length;

      contactsMetaCache[catalogueId][contact].itemCount -= itemCount;
      contactsMetaCache[catalogueId][contact].unreadItemCount -= unreadItemCount;

      if (!contactsMetaCache[catalogueId][contact].itemCount) {
        delete contactsMetaCache[catalogueId][contact];

        for (let i = 0; i < contactsCache[catalogueId].length; i += 1) {
          if (contactsCache[catalogueId][i].phone === contact) {
            contactsCache[catalogueId].splice(i, 1);
            break;
          }
        }

        shouldGoBackToContacts = true;
      }

      for (let i = 0; i < contactOrdersCache[catalogueId][contact].length; i += 1) {
        if (contactOrdersCache[catalogueId][contact][i].orderId === orderId) {
          contactOrdersCache[catalogueId][contact].splice(i, 1);
          break;
        }
      }

      delete orderInquiriesCache[orderId];

      notifyContactOrderListeners({ catalogueId, contact }, null, {
        status: OPERATION_STATUS.UPDATE,
        data: contactOrdersCache[catalogueId][contact]
      });
      notifyContactMetaListeners({ catalogueId, contact }, contactsMetaCache[catalogueId][contact]);
      notifyContactsListeners({ catalogueId }, null, {
        status: OPERATION_STATUS.UPDATE,
        data: contactsCache[catalogueId]
      });
      notifyOrderInquiriesListeners({ orderId }, null, {
        status: OPERATION_STATUS.UPDATE,
        data: orderInquiriesCache[orderId] || []
      });

      resolve({ shouldGoBackToContacts });
    } catch (ex) {
      reject(ex);

      if (ex && ex.message !== 'Network request failed') {
        Sentry.captureException(ex);
      }
    }

    delete deleteOrderPromise[orderId];
  });

  return deleteOrderPromise[orderId];
};

const deleteOrders = async ({ orderIds, catalogueId = '', contact }) => {
  await Api.deleteOrders({ orderIds });

  let shouldGoBackToContacts = false;

  orderIds.forEach(orderId => {
    for (let i = 0; i < contactOrdersCache[catalogueId][contact].length; i += 1) {
      if (contactOrdersCache[catalogueId][contact][i].orderId === orderId) {
        const inquiries = contactOrdersCache[catalogueId][contact][i].inquiries || [];

        const itemCount = inquiries.length;
        const unreadItemCount = inquiries.filter(inquiry => !inquiry.read).length;

        contactsMetaCache[catalogueId][contact].itemCount -= itemCount;
        contactsMetaCache[catalogueId][contact].unreadItemCount -= unreadItemCount;

        if (!contactsMetaCache[catalogueId][contact].itemCount) {
          delete contactsMetaCache[catalogueId][contact];

          for (let i = 0; i < contactsCache[catalogueId].length; i += 1) {
            if (contactsCache[catalogueId][i].phone === contact) {
              contactsCache[catalogueId].splice(i, 1);
              break;
            }
          }
        }

        contactOrdersCache[catalogueId][contact].splice(i, 1);
        break;
      }
    }

    delete orderInquiriesCache[orderId];
  });

  if (!contactOrdersCache[catalogueId][contact].length) {
    shouldGoBackToContacts = true;
  }

  notifyContactOrderListeners({ catalogueId, contact }, null, {
    status: OPERATION_STATUS.UPDATE,
    data: contactOrdersCache[catalogueId][contact]
  });
  notifyContactMetaListeners({ catalogueId, contact }, contactsMetaCache[catalogueId][contact]);
  notifyContactsListeners({ catalogueId }, null, {
    status: OPERATION_STATUS.UPDATE,
    data: contactsCache[catalogueId]
  });

  return { shouldGoBackToContacts };
};

const deleteInquiries = async ({ catalogueId, contact, orderId, inquiryIds }) => {
  const orderInquiries = orderInquiriesCache[orderId].inquiries || [];

  if (orderInquiries.length === inquiryIds.length) {
    const { shouldGoBackToContacts } = await deleteOrder({ orderId, catalogueId, contact });

    if (shouldGoBackToContacts) {
      return { shouldGoBackToContacts: true };
    } else {
      return { shouldGoBack: true };
    }
  }

  await Api.deleteInquiries({ inquiryIds });

  const inquiriesToDelete = [];
  const remainingInquiries = [];
  orderInquiries.forEach(inquiry => {
    if (inquiryIds.indexOf(inquiry.inquiryId) !== -1) {
      inquiriesToDelete.push(inquiry);
    } else {
      remainingInquiries.push(inquiry);
    }
  });

  const itemCount = inquiriesToDelete.length;
  const unreadItemCount = inquiriesToDelete.filter(inquiry => !inquiry.read).length;

  contactsMetaCache[catalogueId][contact].itemCount -= itemCount;
  contactsMetaCache[catalogueId][contact].unreadItemCount -= unreadItemCount;

  for (let i = 0; i < contactOrdersCache[catalogueId][contact].length; i += 1) {
    if (contactOrdersCache[catalogueId][contact][i].orderId === orderId) {
      contactOrdersCache[catalogueId][contact][i].inquiries = contactOrdersCache[catalogueId][
        contact
      ][i].inquiries.filter(inquiry => inquiryIds.indexOf(inquiry.inquiryId) === -1);
      break;
    }
  }

  orderInquiriesCache[orderId].inquiries = remainingInquiries;

  notifyContactOrderListeners({ catalogueId, contact }, null, {
    status: OPERATION_STATUS.UPDATE,
    data: contactOrdersCache[catalogueId][contact]
  });
  notifyContactMetaListeners({ catalogueId, contact }, contactsMetaCache[catalogueId][contact]);
  notifyContactsListeners({ catalogueId }, null, {
    status: OPERATION_STATUS.UPDATE,
    data: contactsCache[catalogueId]
  });
  notifyOrderInquiriesListeners({ orderId }, null, {
    status: OPERATION_STATUS.UPDATE,
    data: orderInquiriesCache[orderId]
  });

  return {};
};

const readInquiries = async ({ orderId, inquiryIds = [], contact, catalogueId = '' }) => {
  contactsMetaCache[catalogueId][contact].unreadItemCount -= inquiryIds.length;
  UNREAD_INQUIRIES_COUNT.unreadInquiries -= inquiryIds.length;

  for (let i = 0; i < contactOrdersCache[catalogueId][contact].length; i += 1) {
    if (contactOrdersCache[catalogueId][contact][i].orderId === orderId) {
      for (let j = 0; j < contactOrdersCache[catalogueId][contact][i].inquiries.length; j += 1) {
        contactOrdersCache[catalogueId][contact][i].inquiries[j].read = true;
      }
      break;
    }
  }

  const {inquiries} = orderInquiriesCache[orderId]
  for (let i = 0; i < inquiries.length; i += 1) {
    inquiries[i].read = true;
  }

  notifyContactOrderListeners({ catalogueId, contact }, null, {
    status: OPERATION_STATUS.UPDATE,
    data: contactOrdersCache[catalogueId][contact]
  });
  notifyContactMetaListeners({ catalogueId, contact }, contactsMetaCache[catalogueId][contact]);
  notifyContactsListeners({ catalogueId }, null, {
    status: OPERATION_STATUS.UPDATE,
    data: contactsCache[catalogueId]
  });
  notifyOrderInquiriesListeners({ orderId }, null, {
    status: OPERATION_STATUS.UPDATE,
    data: orderInquiriesCache[orderId]
  });

  Api.readInquiries({ inquiryIds })
    .then(() => {})
    .catch(ex => {
      if (ex && ex.message !== 'Network request failed') {
        // Bugsnag.notify(ex, {
        //   file: 'Services/DataManager/Order.js',
        //   function: 'readInquiries',
        //   params: { orderId, inquiryIds, contact, catalogueId }
        // });
      }
    });
};

const updateInquiriesPrice = async ({ catalogueId = '', contact, orderId, inquiryId, price, discountedPrice }) => {
  let error = null;
  try {
    await Api.updateInquiriesPrice({ inquiryId, price, discountedPrice })

    for (let i = 0; i < contactOrdersCache[catalogueId][contact].length; i += 1) {
      const orderDetails = contactOrdersCache[catalogueId][contact][i]
      if (orderDetails.orderId === orderId) {
        for (let j = 0; j < orderDetails.inquiries.length; j += 1) {
          const inquiryDetails = orderDetails.inquiries[j]
          if (inquiryDetails.inquiryId === inquiryId) {
            inquiryDetails.product.price = price;
            inquiryDetails.product.discounted_price = discountedPrice;
            break;
          }
        }
        break;
      }
    }

    const { inquiries } = orderInquiriesCache[orderId]
    for (let i = 0; i < inquiries.length; i += 1) {
      const inquiryDetails = inquiries[i]
      if (inquiryDetails.inquiryId === inquiryId) {
        inquiryDetails.product.price = price;
        inquiryDetails.product.discounted_price = discountedPrice;
        break;
      }
    }
  } catch (ex) {
    error = new CommonError({
      message: `Something went wrong while updating the price`
    })
  }

  notifyContactOrderListeners({ catalogueId, contact }, error, {
    status: OPERATION_STATUS.UPDATE,
    data: contactOrdersCache[catalogueId][contact]
  });
  notifyContactMetaListeners({ catalogueId, contact }, contactsMetaCache[catalogueId][contact]);
  notifyContactsListeners({ catalogueId }, error, {
    status: OPERATION_STATUS.UPDATE,
    data: contactsCache[catalogueId]
  });
  notifyOrderInquiriesListeners({ orderId }, error, {
    status: OPERATION_STATUS.UPDATE,
    data: orderInquiriesCache[orderId]
  });
}

const deleteContacts = async ({ contacts, catalogueId = '' }) => {
  await Api.deleteContacts({ contacts, catalogueId });

  let shouldGoBack = false;

  contacts.forEach(contact => {
    if (contactOrdersCache[catalogueId] && contactOrdersCache[catalogueId][contact]) {
      contactOrdersCache[catalogueId][contact].forEach(order => {
        delete orderInquiriesCache[order.orderId];
      });

      delete contactOrdersCache[catalogueId][contact];
    }

    if (typeof contactsCache[catalogueId] !== 'undefined') {
      for (let i = 0; i < contactsCache[catalogueId].length; i += 1) {
        if (contactsCache[catalogueId][i].phone === contact) {
          contactsCache[catalogueId].splice(i, 1);
          break;
        }
      }
    }

    if (contactsMetaCache[catalogueId] && contactsMetaCache[catalogueId][contact]) {
      delete contactsMetaCache[catalogueId][contact];
    }
  });

  if (!contactsCache[catalogueId].length) {
    shouldGoBack = true;
  }

  contacts.forEach(contact => {
    notifyContactMetaListeners({ catalogueId, contact }, contactsMetaCache[catalogueId][contact]);
  });
  notifyContactsListeners({ catalogueId }, null, {
    status: OPERATION_STATUS.UPDATE,
    data: contactsCache[catalogueId]
  });

  return { shouldGoBack };
};

const handleUnreadInquiryChange = ({ unreadInquiryCount, ts, lastFetchTs }) => {
  let prevFetchTS = UNREAD_INQUIRIES_COUNT.lastFetchTs;
  if (lastFetchTs) {
    prevFetchTS = lastFetchTs;
  }

  const prevUnread = (() => UNREAD_INQUIRIES_COUNT.unreadInquiries)();
  const showRefreshButton = prevFetchTS < ts && prevUnread !== unreadInquiryCount;

  const data = {
    unreadInquiryCount,
    showRefreshButton
  };

  UNREAD_INQUIRIES_COUNT.lastFetchTs = ts;

  const stringifiedTS = JSON.stringify(ts);
  localStorage.setItem(UNREAD_INQUIRIES_COUNT.LOCAL_STORAGE_KEY, stringifiedTS);
  UNREAD_INQUIRIES_COUNT.unreadInquiries = unreadInquiryCount;
  eventbus.publish(UNREAD_INQUIRIES_COUNT.EVENT_BUS_KEY, data);
};

const setLastInquiryFetchTimestamp = ts => {
  UNREAD_INQUIRIES_COUNT.lastFetchTs = ts;
  const stringifiedTS = JSON.stringify(ts);
  localStorage.setItem(UNREAD_INQUIRIES_COUNT.LOCAL_STORAGE_KEY, stringifiedTS);

  const data = {
    showRefreshButton: false,
    unreadInquiryCount: UNREAD_INQUIRIES_COUNT.unreadInquiries
  };

  eventbus.publish(UNREAD_INQUIRIES_COUNT.EVENT_BUS_KEY, data);
};

const getLocalUnreadInquiries = ts => UNREAD_INQUIRIES_COUNT.unreadInquiries;

const rehydrateInquiryMeta = ts => {
  const parsedTS = JSON.parse(ts);
  UNREAD_INQUIRIES_COUNT.lastFetchTs = parsedTS;
};

export default {
  MERCHANT_APPROVAL,
  OPERATION_STATUS,
  SECTION_HEADER,
  FILTER,
  CONTACT_COLORS,
  UNREAD_INQUIRIES_COUNT,
  getContacts,
  attachContactsListener,
  removeContactsListener,
  getContactsMeta,
  attachContactMetaListener,
  removeContactMetaListener,
  getContactOrders,
  attachContactOrdersListener,
  removeContactOrdersListener,
  getOrderInquiries,
  getOrderInquiriesIfOrderExists,
  attachOrderInquiriesListener,
  removeOrderInquiriesListener,
  acceptInquiry,
  rejectInquiry,
  acceptAllInquiries,
  rejectAllInquiries,
  createSections,
  downloadExcel,
  downloadContactsExcel,
  mailExcel,
  mailContactsExcel,
  downloadPdf,
  mailPdf,
  deleteOrder,
  deleteOrders,
  deleteInquiries,
  readInquiries,
  updateInquiriesPrice,
  deleteContacts,
  setStockCount,
  reset,
  handleUnreadInquiryChange,
  setLastInquiryFetchTimestamp,
  rehydrateInquiryMeta,
  getLocalUnreadInquiries
};
